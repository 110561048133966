import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { Modal } from 'ui';
import { Select } from 'components/Form';
import { useSession } from "hooks/Lectures/useSession";
import { LectureSessionAttendeeStatus } from '../../types';
import { connect } from 'react-redux';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface AttendanceStatusChangeModaltInterface {
    session: any;
    lecturesId: number;
    sessionId: number;
    attendeeIds: number[];
    visible?: boolean | undefined;
    onCancel(cancel: boolean): any;
    onSubmit(): any;
}

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const AttendanceStatusChangeModal: React.FC<AttendanceStatusChangeModaltInterface> = ({session, lecturesId, sessionId, attendeeIds, visible = false, onCancel, onSubmit }) => {
    const { removeAttendance, changeAttendanceStatus, sessionLoading } = useSession();
    const intl = useIntl();
    const [form] = Form.useForm();

    const handleOnCancel = () => {
        form.resetFields();
        onCancel(false)
    }

    const handleFormSubmit = async (values: any) => {
        const status = values.status;
        if (status === LectureSessionAttendeeStatus.Removed) {
            if (await removeAttendance(lecturesId, sessionId, attendeeIds, status)) {
                form.resetFields();
                onSubmit();
            }
        } else {
            if (await changeAttendanceStatus(lecturesId, sessionId, attendeeIds, status)) {
                form.resetFields();
                onSubmit();
            }
        }
    };

    const statusOptions = {
        [LectureSessionAttendeeStatus.Planned]: intl.formatMessage({ id: 'general.lectures.session_attendee_status_PLANNED' }),
        [LectureSessionAttendeeStatus.Attended]: intl.formatMessage({ id: 'general.lectures.session_attendee_status_ATTENDED' }),
        [LectureSessionAttendeeStatus.Missed]: intl.formatMessage({ id: 'general.lectures.session_attendee_status_MISSED' }),
        [LectureSessionAttendeeStatus.Removed]: intl.formatMessage({ id: 'general.lectures.remove_from_attendance_list' }),
    }

    const parseFilterOptions = () => {
        if (session.active_user_type === 'SUPERVISION') {
            return {[LectureSessionAttendeeStatus.Removed]: intl.formatMessage({ id: 'general.lectures.remove_from_attendance_list' })};
        }

        return statusOptions;
    }

    return (
        <Modal
            open={visible}
            isLoading={sessionLoading}
            onCancel={() => handleOnCancel()}
            title={intl.formatMessage({ id: 'general.lectures.change_attendance_status' })}
            okText={intl.formatMessage({ id: 'general.submit' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            onOk={form.submit}
        >
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout}>
                <Select
                    showArrow
                    name='status'
                    label={intl.formatMessage({ id: 'general.lectures.change_status_to' })}
                    manualOptions={parseFilterOptions()}
                    customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                    customLayout={true} {...formItemLayout}
                />
            </Form>
        </Modal>
    )
};
export default connect(mapStateToProps)(AttendanceStatusChangeModal);
