import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import DefaultLayout from 'components/DefaultLayout';
import { Form, message, Input, InputNumber, Tooltip, Button, Tabs } from 'antd';
import Spinner from 'components/Spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import { Switch } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import apiClient from 'utils/apiClient';
import MaterialButtons from './MaterialButtons';
import MaterialModal from './MaterialModal';
import { ActivityModelInterface, MaterialType } from './types';
import MaterialCard from './MaterialCard';
import generalHelper from 'utils/generalHelpers';
import { clearCampaignActivityData } from 'store/actions/customCampaignActivityAction';
import { clearActivityCreationSettings } from 'store/actions/activityCreationModalActions';
import './styles.scss';
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        campaign: state.customCampaignActivityReducer,
        activityCreationSettings: state.activityCreationModalActions
    };
}
const mapDispatchToProps = (dispatch: any) => ({
    clearCampaignActivityData: () => dispatch(clearCampaignActivityData()),
    clearActivityCreationSettings: () => dispatch(clearActivityCreationSettings()),
});

interface LearningMaterialInterface {
    campaign?: any;
    clearCampaignActivityData?: any
    clearActivityCreationSettings: any;
    activityCreationSettings?: {type: 'PROGRAM', path: string, title:string}
}

const LearningMaterial: React.FC<LearningMaterialInterface> = ({ campaign, clearCampaignActivityData, clearActivityCreationSettings, activityCreationSettings }) => {
    const { locale } = useLocaleContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isMaterialsLoading, setIsMaterialsLoading] = useState(false);
    const [courseTitle, setCourseTitle] = useState<string>('');
    const [selectLanguageList, setSelectLanguageList] = useState({});
    const [materials, setMaterials] = useState({});
    const [selectedLanguage, setSelectedLanguage] = useState(locale);
    const [material, setMaterial] = useState();
    const [disable, setDisable] = useState({ learn: false, test: false, assignment: false, assessment: false, handouts: false, learning_test: false, certificate: false});
    const [activity, setActivity] = useState<ActivityModelInterface>();
    const [isActivityLoading, setIsActivityLoading] = useState(false);

    const [showMaterialModal, setShowMaterialModal] = useState(false);
    const [type, setType] = useState<MaterialType>('');
    const [customActivityCampaign, setCustomActivityCampaign] = useState({campaignId: null, type: null});

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const intl = useIntl();
    const { TabPane } = Tabs;
    const params = useParams();

    const courseId: any = params.courseId;
    const activityId: any = params.activityId;

    useEffect(() => {
        loadLanguages();

        if (activityId) {
            loadActivity();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    useEffect(()=> {
        if (campaign && campaign.campaignId) {
          setCustomActivityCampaign(campaign);
          clearCampaignActivityData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[campaign])

    const loadLanguages = async () => {
        let response = await apiClient.request(`/api/v1/language`, {}, 'GET');
        const languageList = Object.values(response.languages).filter((language: any) => !!language.publish)
        setSelectLanguageList(languageList);
    };

    const loadActivity = async () => {
        setIsLoading(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}`, [], 'GET');
            setActivity(response.activity);
            const responseActivity = response.activity;

            if (responseActivity.courseNameTranslations[selectedLanguage]) {
                setCourseTitle(responseActivity.courseNameTranslations[selectedLanguage]);
            } else {
                setCourseTitle(responseActivity.courseNameTranslations[Object.keys(responseActivity.courseNameTranslations)[0]])
            }

            form.setFieldsValue({
                activityTitle: responseActivity.title[selectedLanguage],
                minutes: responseActivity.minutes,
                sequencePriority: responseActivity.sequencePriority,
                statusSwitch: responseActivity.status === 'ONLINE',
                language: selectedLanguage,
            });

            loadMaterials(responseActivity.id);
        } catch (error: any) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            if (error.status > 400) {
                navigate('/403')
              }
        } finally {
            setIsLoading(false);
        }
    }

    /**
     * 
     * @param id 
     * @param language 
     */
    const loadMaterials = async (id: number) => {
        setIsMaterialsLoading(true);
        try {

            const response = await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${id}/material/${selectedLanguage}`, {}, 'GET');

            disableMaterialButtons(response.materials);

            setMaterials(response.materials);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsMaterialsLoading(false);
        }
    }

    /**
     * 
     * @param language 
     */
    const changeLanguage = async (language: string) => {
        setDisable({ learn: false, test: false, assignment: false, assessment: false, handouts: false, learning_test: false, certificate: false})
        setSelectedLanguage(language);
        setIsMaterialsLoading(true)
        try {
            form.setFieldsValue({
                activityTitle: activity?.title[language],
            });

            const response = await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/${language}`, {}, 'GET');

            disableMaterialButtons(response.materials);

            setMaterials(response.materials);

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsMaterialsLoading(false);
        }
    }

    const disableMaterialButtons = (responseMaterials: any) => {
        const hasAssignment = responseMaterials.filter((el: any) => el.type === 'ASSIGNMENT');
        const hasLearning = responseMaterials.filter((el: any) => ['PDF_MATERIAL', 'VIDEO_MATERIAL', 'SCORM_LEARNING', 'EMBEDDED_DOC'].includes(el.type));
        const hasTest = responseMaterials.filter((el: any) => ['SCORM_TEST', 'LEARNING_TEST'].includes(el.type));
        const hasHandouts = responseMaterials.filter((el: any) => el.type === 'FILE');
        const hasAssessment = responseMaterials.filter((el: any) => el.type === 'ASSESSMENT');
        const hasCertificate = responseMaterials.filter((el: any) => el.type === 'CERTIFICATE');

        if (!!hasAssignment.length) {
            setDisable({ ...disable, assignment: !!hasAssignment.length, assessment: true, test: true, learn: true, handouts: true });
        }

        if (!!hasAssessment.length) {
            setDisable({ ...disable, assessment: !!hasAssessment.length, assignment: true, test: true, learn: true, handouts: true });
        }

        if (!!hasLearning.length || !!hasTest.length) {
            setDisable({ ...disable, assignment: true, assessment: true, test: !!hasTest.length, learn: !!hasLearning.length });
        }

        if (!!hasHandouts.length) {
            setDisable({ ...disable, assignment: true, assessment: true, test: true, learn: true, handouts: false, certificate: true });
        }

        if (!!hasCertificate.length) {
            setDisable({ learn: true, test: true, assignment: true, assessment: true, handouts: true, learning_test: true, certificate: true});
        }

    }

    const addActivityToCampaign = async () => {
        if (!customActivityCampaign.campaignId) {
            return;
        }

        setIsActivityLoading(true);
        try {
            await coursesApiClient.request(`/api/v1/courses/campaigns/${customActivityCampaign.campaignId}/add-activity`, {
                course: courseId,
                activityId: activityId
            }, 'POST');

            backToCampaign();
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsActivityLoading(false);
        }
    }

    const backToCampaign = () => {
        navigate(customActivityCampaign.type ? `/campaign/create/${customActivityCampaign.campaignId}/activities` : `/campaign/${customActivityCampaign.campaignId}/activities`)
        clearCampaignActivityData();
    }

    /**
     * 
     * @param values 
     */
    const submitForm = async (values: any) => {
        setIsLoading(true);

        const parsedValues = {
            name: values.activityTitle,
            statusSwitch: values.statusSwitch,
            minutes: values.minutes,
            sequencePriority: values.sequencePriority,
            language: selectedLanguage
        }

        try {
            await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}`, parsedValues, 'PUT');

            if (activityCreationSettings?.type) {
                const path = activityCreationSettings.path

                clearActivityCreationSettings();
                navigate(path);
            } else {
                navigate(`/courses/${courseId}/edit`);
            }

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    const breadCrumb = () => {
        if (activityCreationSettings?.type) {
            return [{ name: activityCreationSettings.title, path: activityCreationSettings.path },]
        }

        return [{ name: intl.formatMessage({ id: 'courses.manage_content' }), path: customActivityCampaign.campaignId ? customActivityCampaign.type ? `/campaign/create/${customActivityCampaign.campaignId}/activities` : `/campaign/${customActivityCampaign.campaignId}/activities` :`/courses/${courseId}/edit` },]
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={courseTitle} breadcrumb={breadCrumb()} />
            <Spinner spinning={isLoading}>
                <Form form={form} onFinish={submitForm}>
                    <Tabs activeKey={selectedLanguage} onChange={changeLanguage} type="card" className='tabs-wrapper'>
                        {Object.values(selectLanguageList).map((language: any) => <TabPane tab={language.code} key={language.code} />)}
                    </Tabs>
                    <div className='activity-settings-wrapper'>
                        <div className='material-buttons-wrapper'>
                            <FormattedMessage id='courses.create_activity_settings' />
                        </div>
                        <div className='activity-settings-container'>
                            <Form.Item name='activityTitle' label={intl.formatMessage({ id: 'courses.activity_name' })} >
                                <Input style={{ width: 380 }} />
                            </Form.Item>
                            <div className='activity-form-settings'>
                                <div className='third-container'>
                                    <Form.Item
                                        label=
                                        {
                                            <span>
                                                {intl.formatMessage({ id: 'courses.sequence_priority' })}
                                                <Tooltip title={intl.formatMessage({ id: 'courses.sequence_priority_info' })}>
                                                    <i className='fal fa-question-circle header-item' />
                                                </Tooltip>
                                            </span>
                                        }
                                        name="sequencePriority"
                                    >
                                        <InputNumber max={1000} />
                                    </Form.Item>
                                </div>
                                <div className='second-container'>
                                    <Form.Item name='minutes' label={intl.formatMessage({ id: 'general.minutes' })} >
                                        <InputNumber min={0} />
                                    </Form.Item>
                                </div>
                                <div className='first-container'>
                                    <Switch
                                        isFormItem
                                        label={
                                            <>
                                                {intl.formatMessage({ id: 'general.online' })}
                                                <Tooltip title={intl.formatMessage({ id: 'courses.course_status_explanation' })}>
                                                    <i className='fal fa-question-circle header-item' />
                                                </Tooltip>
                                            </>
                                        }
                                        name='statusSwitch'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='material-buttons-wrapper'>
                            <FormattedMessage id='courses.create_learning_content' />
                            <hr className='form-group-separator' />
                            <MaterialButtons disable={disable} setShowMaterialModal={setShowMaterialModal} setType={setType} />
                        </div>
                    </div>
                    <Spinner spinning={isMaterialsLoading}>
                        <div className='material-panel'>
                            {!generalHelper.isEmpty(materials) ? Object.values(materials).map((material: any) =>
                                <MaterialCard
                                    key={material.id}
                                    setMaterial={setMaterial}
                                    setType={setType}
                                    setShowMaterialModal={setShowMaterialModal}
                                    material={material}
                                    activityId={activityId}
                                    reload={loadMaterials}
                                    loading={setIsMaterialsLoading}
                                    courseId={courseId}
                                />)
                                :
                                <div className='empty-material-card'><FormattedMessage id='courses.no_materials' /></div>
                            }
                        </div>

                    </Spinner>
                </Form>
            </Spinner>
            <MaterialModal
                visible={showMaterialModal}
                onCancel={() => setShowMaterialModal(false)}
                type={type}
                setMaterial={setMaterial}
                language={selectedLanguage}
                activityId={activityId}
                material={material}
                courseId={courseId}
                reload={loadMaterials}
            />
            <DefaultLayout.PageFooter
                right={
                    <>
                        {customActivityCampaign.campaignId ?
                            <>
                                <Button onClick={() => backToCampaign()}>
                                    <FormattedMessage id='general.cancel' />
                                </Button>
                                <Button loading={isActivityLoading} type='primary' onClick={() => addActivityToCampaign()}>
                                    <FormattedMessage id='general.save' />
                                </Button>
                            </>
                            :
                            <>
                                <NavLink to={`/courses/${courseId}/edit`}>
                                    <Button>
                                        <FormattedMessage id='general.cancel' />
                                    </Button>
                                </NavLink>
                                <Button loading={isActivityLoading} type='primary' onClick={() => form.submit()}>
                                    <FormattedMessage id='general.save' />
                                </Button>
                            </>
                        }
                    </>
                }
            />
        </DefaultLayout.PageLayout>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(LearningMaterial);
