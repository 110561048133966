import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useIntl, FormattedMessage} from 'react-intl';
import {Breadcrumb, Card, Table, Tag} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import useQueryApiClient from 'utils/useApiClient';
import { LocaleText } from 'ui';
import Spinner from 'components/Spinner';
import moment from 'moment';

interface AssessmentActivityInterface {
    locale?: any;
    programActivityAttemptId: number;
}

const AssessmentActivity: React.FC<AssessmentActivityInterface> = ({ 
    programActivityAttemptId
 }) => {
    const [assessmentSettings, setAssessmentSettings] = useState<any>();

    const intl = useIntl();

    const {data: activityFormData, isLoading} = useQueryApiClient({
        request: {
            url: `api/v1/courses/assessment-results/get-assessment/${programActivityAttemptId}`,
            method: 'GET'
        },
        onSuccess: (response: any): void => setAssessmentSettings(response.material.settings)
    });

    /**
     * 
     * @param statusType 
     * @returns 
     */
    const getStatusTag = (statusType: 'VALUE' | 'TAG' = 'TAG') => {
        let status: string = activityFormData?.submission?.status
        let color = ''
        let messageId = ''

        switch (status) {
            case 'NOT_STARTED':
            case 'IN_PROGRESS':
                color = 'blue'
                messageId = 'courses.topic_status.pending_grading'
                break;
            case 'PASSED':
                color = 'green'
                messageId = 'courses.topic_status.passed'
                break;
            case 'FAILED':
                color = 'red'
                messageId = 'courses.topic_status.failed'
                break;
            default:
                color = 'gold'
                messageId = 'courses.topic_status.not_started'
                break;
        }

        if (statusType === 'TAG') {
            return (
                <Tag color={color}>
                    <FormattedMessage id={messageId} />
                </Tag>
            )
        } else if (statusType === 'VALUE') {
            return status;
        }
    }

    const getProgramTitle = (program: any) => {
        return <LocaleText text={program.title} />;
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'assessment.final_assessment'}),
            key: 'status',
            render: () => getStatusTag()
        },
        {
            title: intl.formatMessage({id: 'grading.grade'}),
            dataIndex: 'grade'
        },
        {
            title: intl.formatMessage({id: 'general.comments'}),
            dataIndex: 'comments'
        }
    ];

    return (
        <Spinner spinning={isLoading}>
            {activityFormData && assessmentSettings && (
                <DefaultLayout.PageLayout withoutPageHeader>
                    <div className='assessment-form-wrapper'>
                        <Breadcrumb className="breadcrumb">
                            <Breadcrumb.Item>
                                <NavLink to='../'>
                                    <FormattedMessage id={'student.my_courses'} />
                                </NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink to={`/materials/${activityFormData.program?.id}`}>
                                    {getProgramTitle(activityFormData.program)}
                                </NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{assessmentSettings.title ? <>{assessmentSettings.title}</> : <FormattedMessage id='assessment.practical_assessment' />}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="flex align-center title">
                            <NavLink to='../'>
                                <ArrowLeftOutlined className="arrow"/>
                            </NavLink>
                            <h1>{assessmentSettings.title ? <>{assessmentSettings.title}</> : <FormattedMessage id='assessment.practical_assessment' />}</h1>
                        </div>
                        <DefaultLayout.PageContent style={{background: 'none', padding: '0px'}}>
                            <Card className='assessment-card'>
                                <div className='assessment-detail'>
                                    <div className='assessment-section'>
                                        <div className='assessment-title-bar'>
                                            <div className='flex align-center flex-wrap'>
                                                <div className='assessment-title flex flex-wrap space-between'>
                                                    <h3>{assessmentSettings.title ? <>{assessmentSettings.title}</> : <FormattedMessage id='assessment.practical_assessment' />}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='assessment-description-content' dangerouslySetInnerHTML={{ __html: assessmentSettings.description }}></div>
                                    </div>
                                </div>
                                {activityFormData.attemptData.finalAssessment.isSubmitted && (
                                    <div className='mt-10'>
                                        <div className='flex gap-10'>
                                            <h4><FormattedMessage id='assessment.final_assessment' /> </h4>
                                            <div className='flex gap-10 bold'>
                                                <span>
                                                    {activityFormData?.attemptData?.finalAssessment?.gradedByUser?.name + ' ' + activityFormData?.attemptData?.finalAssessment?.gradedByUser?.surname}
                                                </span>
                                                <span>
                                                    {moment(activityFormData.attemptData.finalAssessment.gradedDate).format('DD.MM.YYYY HH:mm')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='separator' />
                                        <Table
                                            columns={columns}
                                            pagination={false}
                                            dataSource={[activityFormData.attemptData.finalAssessment]}
                                        />
                                    </div>
                                )}
                            </Card>
                        </DefaultLayout.PageContent>
                    </div>
                </DefaultLayout.PageLayout>
            )}
        </Spinner>
    )
}
export default AssessmentActivity;