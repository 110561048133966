import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { InputEdit, Table } from 'ui';
import TranslationsToolbar from './TranslationsToolbar';
import useQueryApiClient from 'utils/useApiClient';
import {TranslationInterface} from '../types';

const Translations: React.FC = () => {
    const [reload, setReload] = useState(false);
    const [filter, setFilter] = useState({});
    const intl = useIntl();

    const { appendData } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/languages/translation/:id',
            method: 'PUT',
        },
        onSuccess: (): void => {
            setReload(true)
        },
    });

    const submit = (value: string, id?: number) => {
        appendData({ translation: value }, { id: id });
    }

    const columns = [
        {
            title: intl.formatMessage({ id: 'system.languages.string' }),
            dataIndex: 'key',
            key: 'key',
            width: '25%',
        },
        {
            title: intl.formatMessage({ id: 'system.languages.module' }),
            dataIndex: 'module',
            key: 'module',
            render: (text: string, record: TranslationInterface) => intl.formatMessage({ id: `system.${record.module}` })
        },
        {
            title: intl.formatMessage({ id: 'system.languages.first_language' }),
            dataIndex: 'firstLangTranslation',
            key: 'firstLangTranslation',
            render: (text: string, record: TranslationInterface) => {
                if (!record?.firstLangId) {
                    return;
                }

                return <InputEdit className={'cell-w-250'} submit={submit} labelId={record.firstLangId} label={record.firstLangTranslation} />
            }
        },
        {
            title: intl.formatMessage({ id: 'system.languages.second_language' }),
            dataIndex: 'secondLangTranslation',
            key: 'secondLangTranslation',
            render: (text: string, record: TranslationInterface) => {
                if (!record?.secondLangId) {
                    return;
                }

                return <InputEdit className={'cell-w-250'} submit={submit} labelId={record.secondLangId} label={record.secondLangTranslation} />
            }
        }
    ];

    return (
        <>
            <TranslationsToolbar setFilter={setFilter} reload={setReload} filter={filter} />
            <Table
                columns={columns}
                url={'/api/v1/languages/translation'}
                setReload={setReload}
                reload={reload}
                filter={filter}
                rowSelection={false}
                rowKey={'id'}
            />
        </>
    )
}
export default Translations;
