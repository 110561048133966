import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Select } from 'components/Form';
import { FileProperties } from 'ui';
import coursesApiClient from 'utils/coursesApiClient';
import { Upload, Row, Col, Button, message, FormInstance, Form, Slider, InputNumber, Tooltip } from 'antd';
import Spinner from 'components/Spinner';

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

interface PdfFromInterface {
    language: string;
    handleVersionSelect: Function;
    versionList: any[];
    activityId: number;
    setMaterial: React.Dispatch<React.SetStateAction<any>>;
    reloadVersions: Function;
    form: FormInstance;
    pages: number;
    passRate: number;
    setPassRate: React.Dispatch<React.SetStateAction<number>>;
    versionsLoading: boolean;
    setFileTitle: React.Dispatch<React.SetStateAction<string>>;
    fileTitle: string;
    setPages: React.Dispatch<React.SetStateAction<number>>;
    courseId: number;
}

const PdfFrom: React.FC<PdfFromInterface> = ({ language, handleVersionSelect, versionList, activityId, setMaterial, reloadVersions, form, pages, passRate, setPassRate, versionsLoading, setFileTitle, fileTitle, setPages, courseId }) => {
    const [fileUpload, setFileUpload] = useState(false);

    const intl = useIntl();

    const uploadMaterial = async (file: any) => {
        setFileUpload(true);
        try {
            let bodyFormData = new FormData();

            if (file.file) {
                bodyFormData.append('file', file.file);
            } else {
                return;
            }

            bodyFormData.append('language', language);
            bodyFormData.append('type', 'PDF_MATERIAL');

            let response = await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/add`, bodyFormData, 'POST');

            const pdfSettings = JSON.parse(response.material.settings.file_settings);
            setFileTitle(pdfSettings.title + '.pdf');

            setMaterial(response.material.settings);
            setPages(pdfSettings.pages)

            const versions = await reloadVersions();

            const lastVersion: any = Object.values(versions).pop();

            form.setFieldsValue({
                version: lastVersion + ''
            });

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setFileUpload(false);
        }
    }

    return (
        <Spinner spinning={fileUpload} opaqueH>
            <Row>
                <Col className='file-settings-title' span={formItemLayout.labelCol.span} >
                    <FormattedMessage id='courses.learning_file' />
                </Col>
                <Col span={formItemLayout.wrapperCol.span} className='file-settings-style'>
                    <Upload
                        name='file'
                        multiple={false}
                        customRequest={(file: any) => uploadMaterial(file)}
                        fileList={[]}
                    >
                        <Button>
                            <i className="fa-solid fa-arrow-up-from-bracket"></i> <FormattedMessage id='general.upload_files' />
                        </Button>
                    </Upload>
                </Col>
            </Row>

            <FileProperties layout={formItemLayout} showSize={false} fileProperties={{
                title: fileTitle,
                titleLabel: 'courses.learning_file_title',
            }} />

            <Select
                loading={versionsLoading}
                customLayout={formItemLayout}
                label={intl.formatMessage({ id: "general.version" })}
                name='version'
                manualOptions={versionList}
                onChange={(_value: any, options: any) => handleVersionSelect(options.children, setFileTitle)}
            />

            <Row>
                <Col span={formItemLayout.labelCol.span} className='pdf-pass-rate-label'>
                    <FormattedMessage id='courses.pdf_min_slide_count' />
                    <Tooltip title={intl.formatMessage({ id: 'courses.video_view_all_hint' })}>
                        <i className='fal fa-question-circle header-item' />
                    </Tooltip>
                </Col>
                <Col span={formItemLayout.wrapperCol.span}>
                    <div className='pdf-pass-rate-input' >
                        <Form.Item name="passRate" label=' '>
                            <Slider
                                min={0}
                                max={pages}
                                value={passRate}
                                step={1}
                                onChange={(value: any) => { form.setFieldsValue({ passRate: value }); setPassRate(value); }}
                                style={{ width: 100 }}
                            />
                        </Form.Item>
                        <Form.Item className='number-input' name="passRate" label=' ' >
                            <InputNumber
                                min={0}
                                max={pages}
                                step={1}
                                value={passRate}
                                addonAfter={`/${pages}`}
                                onChange={(value: any) => setPassRate(value)}
                            />
                        </Form.Item>
                    </div>
                </Col>
            </Row>
            <Form.Item {...formItemLayout} name="secondsOnSlide" label={intl.formatMessage({ id: "courses.min_seconds_on_slide" })}>
                <InputNumber
                    min={0}
                    max={100}
                />
            </Form.Item>
        </Spinner>
    )
}
export default PdfFrom;
