import React from 'react';
import {Tooltip} from 'antd';
import dayGridPlugin from '@fullcalendar/daygrid'
import {FullCalendar} from 'ui'
import {MySessionsCalendarData} from '../types';
import './styles.scss'

interface MySessionsCalendarProps {
    events: MySessionsCalendarData[];
    setYearAndMonth?: (v: string) => any;
}

const MySessionsCalendar: React.FC<MySessionsCalendarProps> = ({events}) => {
    const renderTooltip = (eventInfo: any) => (
        <div className='flex flex-column'>
            <span>{eventInfo.timeText}</span>
            {eventInfo.event.extendedProps.tooltipContent.map((p: string) => <span>{p}</span>)}
        </div>
    )

    const renderEventContent = (eventInfo: any) => (
        <Tooltip
            className={'flex align-center w-100'}
            placement='top'
            title={renderTooltip(eventInfo)}
        >
            <div className='w-full h-full'>
                <div className='fc-daygrid-event-dot' style={{borderColor: eventInfo.backgroundColor}}></div>
            </div>
        </Tooltip>
    )

    return (
        <div className='my-session-calendar'>
            <FullCalendar
                plugins={[dayGridPlugin]}
                dayHeaderContent={(d: any) => d.text.charAt(0)}
                headerToolbar={{
                    left: 'prev',
                    center: 'title',
                    right: 'next'
                }}
                titleFormat={{ 
                    year: 'numeric',
                    month: 'short'
                }}
                contentHeight={'auto'}
                eventContent={renderEventContent}
                events={events}
                displayEventEnd={true}
            />
        </div>
    )
};

export default MySessionsCalendar;