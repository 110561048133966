import React, {useState, useEffect} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {Form, FormInstance, message} from 'antd';
import {Select} from 'components/Form';
import {PdfForm, EmbeddedDocForm, ScromForm, VideoForm} from './Forms';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const materialSelectType = {
    'SCORM_LEARNING': <FormattedMessage id='courses.material_type_scorm'/>,
    'PDF_MATERIAL': <FormattedMessage id='courses.material_type_pdf_material'/>,
    'VIDEO_MATERIAL': <FormattedMessage id='courses.material_type_video_material'/>,
    'EMBEDDED_DOC': <FormattedMessage id='courses.material_type_embedded_doc'/>
};

interface LearningMaterialFormInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    viewMaterial?: any
    onCancel: any;
    reload: Function;
    courseId: number;
}

const LearningMaterialForm: React.FC<LearningMaterialFormInterface> = ({
                                                                           language,
                                                                           form,
                                                                           activityId,
                                                                           viewMaterial,
                                                                           onCancel,
                                                                           reload,
                                                                           courseId,
                                                                       }) => {
    const [learningType, setLearningType] = useState<'SCORM_LEARNING' | 'PDF_MATERIAL' | 'VIDEO_MATERIAL' | 'EMBEDDED_DOC' | ''>(''),
        [versionList, setVersionList] = useState<any>([]),
        [isMaterialLoading, setIsMaterialLoading] = useState(false),
        [material, setMaterial] = useState<any>(),
        [isVersionsLoading, setIsVersionsLoading] = useState(false),
        [pages, setPages] = useState(0),
        [passRate, setPassRate] = useState(0),
        [fileTitle, setFileTitle] = useState(''),
        [embeddedSrc, setEmbeddedSrc] = useState(''),

        intl = useIntl();

    useEffect(() => {
        if (viewMaterial) {

            setLearningType(viewMaterial.type);
            setMaterial(viewMaterial);

            form.setFieldsValue({
                materialType: viewMaterial.type,
                version: viewMaterial.version
            })

            const materialSettings = typeof viewMaterial.settings === 'string' ? JSON.parse(viewMaterial.settings) : viewMaterial.settings;

            const materialFileSettings = JSON.parse(viewMaterial.fileSettings);

            switch (viewMaterial.type) {
                case 'SCORM_LEARNING':
                    const title = materialFileSettings.file_title ? materialFileSettings.file_title : 'Learning Activity'
                    setFileTitle(title);

                    if (viewMaterial.settings) {
                        form.setFieldsValue({
                            expectedLearningResult: materialSettings.expected_learning_result
                        });
                    }

                    if (viewMaterial.fileSettings) {
                        form.setFieldsValue({
                            learningFileScormVersion: materialFileSettings.learning_file_scorm_version
                        });
                    }
                    break;
                case 'PDF_MATERIAL':
                    if (viewMaterial.fileSettings) {
                        setFileTitle(materialFileSettings.title + '.pdf');

                        setPages(materialFileSettings.pages);
                    }

                    if (viewMaterial.settings) {

                        setPassRate(materialFileSettings.pass_rate);

                        form.setFieldsValue({
                            passRate: materialSettings.pass_rate,
                            secondsOnSlide: materialSettings.seconds_on_slide
                        });
                    }

                    break;
                case 'VIDEO_MATERIAL':
                    const parseVideoId = () => {
                        switch (materialFileSettings.video_provider) {
                            case 'youtube':
                                return 'https://www.youtube.com/watch?v='
                            case 'vimeo':
                                return 'https://vimeo.com/';
                            default:
                                return '';
                        }
                    }

                    form.setFieldsValue({
                        videoProvider: materialFileSettings.video_provider,
                        videoId: parseVideoId() + materialFileSettings.video_id,
                        viewAllToComplete: !!materialSettings?.view_all_to_complete,
                        pauseOnBlur: !!materialSettings?.pause_on_blur,
                        videoProgress: !!materialSettings?.video_progress,
                    });
                    break;
                case 'EMBEDDED_DOC':
                    setEmbeddedSrc(materialSettings.src);
                    form.setFieldsValue({
                        src: materialSettings.src,
                        hyperlinkToFile: materialSettings.hyperlink_to_file,
                        secondsOnFile: materialSettings.secondsOnFile,
                    });
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMaterial])

    useEffect(() => {
        if (learningType !== '') {
            getVersionList();
        }

        // default values
        if (!viewMaterial) {
            switch (learningType) {
                case 'SCORM_LEARNING':
                    break;
                case 'PDF_MATERIAL':
                    break;
                case 'VIDEO_MATERIAL':
                    form.setFieldsValue({
                        viewAllToComplete: false,
                        pauseOnBlur: false,
                        videoProgress: false
                    });
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningType])

    const parseSrc = (link: string) => {
        const rex = /<iframe[^>]+src="(https:\/\/[^">]+)"/g;
        const src = rex.exec(link)

        if (src) {
            setEmbeddedSrc(src[1]);
        }
    }
    const handleVersionSelect = async (version: number) => {

        setIsMaterialLoading(true);
        clearLearningForm();
        try {

            const parsedValues = {
                version: version,
                type: learningType,
                language: language
            }

            const response = await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material`, parsedValues, 'POST');

            const responseData = response.material;
            setMaterial(responseData);

            switch (learningType) {
                case 'EMBEDDED_DOC':
                    setEmbeddedSrc(responseData.settings.src);

                    form.setFieldsValue({
                        src: responseData.settings.src,
                        hyperlinkToFile: responseData.settings.hyperlink_to_file,
                        secondsOnFile: responseData.settings.secondsOnFile,
                    });
                    break;
                case 'SCORM_LEARNING':
                    setFileTitle(responseData.learningFileTitle);

                    if (responseData.settings) {

                        form.setFieldsValue({
                            expectedLearningResult: responseData?.settings?.expected_learning_result,
                            learningFileScormVersion: responseData?.file_settings ? responseData?.file_settings.learning_file_scorm_version : responseData?.fileSettings.learning_file_scorm_version,
                        });
                    }
                    break;
                case 'PDF_MATERIAL':

                    if (responseData.fileSettings) {
                        const fileSettings = JSON.parse(responseData.fileSettings);
                        setFileTitle(fileSettings.title + '.pdf');

                        setPages(fileSettings.pages);
                    }

                    if (responseData.settings) {

                        setPassRate(responseData.settings.pass_rate);

                        form.setFieldsValue({
                            passRate: responseData.settings.pass_rate,
                            secondsOnSlide: responseData.settings.seconds_on_slide
                        });
                    }
                    break;
                case 'VIDEO_MATERIAL':
                    if (responseData.fileSettings) {
                        const fileSettings = JSON.parse(responseData.fileSettings);

                        form.setFieldsValue({
                            videoId: (fileSettings.video_provider === 'youtube' ? 'https://www.youtube.com/watch?v=' : 'https://vimeo.com/') + fileSettings.video_id,
                            videoProvider: fileSettings.video_provider
                        });
                    }

                    if (responseData.settings) {
                        const settings = responseData.settings;

                        form.setFieldsValue({
                            viewAllToComplete: settings.view_all_to_complete,
                            pauseOnBlur: settings.pause_on_blur,
                            videoProgress: settings.video_progress
                        });
                    } else {
                        form.setFieldsValue({
                            viewAllToComplete: false,
                            pauseOnBlur: false,
                            videoProgress: false
                        });
                    }

                    break;
                default:
                    break;
            }

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setIsMaterialLoading(false);
        }
    }

    const clearLearningForm = () => {

        setFileTitle('');
        form.setFieldsValue({
            expectedLearningResult: [],
            learningFileScormVersion: [],
            passRate: null,
            secondsOnSlide: null
        });
    }

    const youtubeParser = (url: string) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }


    const vimeoParser = (url: string) => {
        var match = /vimeo.*\/(\d+)/i.exec(url);

        if (match) {
            return match[1];
        }
    }

    const getVersionList = async () => {
        setIsVersionsLoading(false);

        try {
            const response = await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/${learningType}/versions/${language}`, {}, 'GET');

            const versions: any = {};
            Object.values(response.versions).map((el: any) => [
                versions[el.version] = el.version
            ])
            setVersionList(versions);

            return versions;
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setIsVersionsLoading(false);
        }
    };

    const submitForm = async (values: any) => {
        if (!material && ['SCORM_LEARNING', 'PDF_MATERIAL', 'VIDEO_MATERIAL'].includes(learningType)) {
            return;
        }

        try {
            const parsedValues: any = {
                type: learningType,
                language: language
            }

            switch (learningType) {
                case 'SCORM_LEARNING':
                    parsedValues.expectedLearningResult = values.expectedLearningResult;
                    parsedValues.learningFileScormVersion = values.learningFileScormVersion;
                    break;
                case 'PDF_MATERIAL':
                    parsedValues.settings = {
                        passRate: values.passRate,
                        secondsOnSlide: values.secondsOnSlide
                    }
                    break;
                case 'VIDEO_MATERIAL':
                    parsedValues.settings = {
                        viewAllToComplete: values.viewAllToComplete,
                        pauseOnBlur: values.pauseOnBlur,
                        videoProgress: values.videoProgress,
                    }
                    break;
                case 'EMBEDDED_DOC':
                    parsedValues.settings = {
                        src: values.src,
                        hyperlink_to_file: values.hyperlinkToFile,
                        secondsOnFile: values.secondsOnFile,
                    }
                    break;
                default:
                    break;
            }

            if ((learningType === 'VIDEO_MATERIAL' || learningType === 'EMBEDDED_DOC') && !material) {
                await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/add`, parsedValues, 'POST');
            } else {
                await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/${material.id}`, parsedValues, 'PUT');
            }
            
            onCancel();
            setFileTitle('');
            setMaterial(undefined);
            form.resetFields();
            reload(activityId);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        }
    }

    const materialForm = {
        SCORM_LEARNING: <ScromForm
            fileTitle={fileTitle}
            setFileTitle={setFileTitle}
            setMaterial={setMaterial}
            language={language}
            handleVersionSelect={handleVersionSelect}
            versionList={versionList}
            activityId={activityId}
            reloadVersions={getVersionList}
            form={form}
            versionsLoading={isVersionsLoading}
            courseId={courseId}
        />,
        PDF_MATERIAL: <PdfForm
            fileTitle={fileTitle}
            setFileTitle={setFileTitle}
            setMaterial={setMaterial}
            language={language}
            handleVersionSelect={handleVersionSelect}
            versionList={versionList}
            activityId={activityId}
            reloadVersions={getVersionList}
            form={form}
            pages={pages}
            passRate={passRate}
            setPassRate={setPassRate}
            versionsLoading={isVersionsLoading}
            setPages={setPages}
            courseId={courseId}
        />,
        VIDEO_MATERIAL: <VideoForm
            form={form}
            reloadVersions={getVersionList}
            setMaterial={setMaterial}
            activityId={activityId}
            versionList={versionList}
            handleVersionSelect={handleVersionSelect}
            language={language}
            versionsLoading={isVersionsLoading}
            material={material}
            youtubeParser={youtubeParser}
            vimeoParser={vimeoParser}
            courseId={courseId}
        />,
        EMBEDDED_DOC: <EmbeddedDocForm
            reloadVersions={getVersionList}
            versionList={versionList}
            handleVersionSelect={handleVersionSelect}
            versionsLoading={isVersionsLoading}
            parseSrc={parseSrc}
            embeddedSrc={embeddedSrc}
            courseId={courseId}
/>
    }

    return (
        <Form form={form} onFinish={submitForm} preserve={false}>
            <Select
                customLayout={formItemLayout}
                name='materialType'
                label={intl.formatMessage({id: 'courses.learning_file_type'})}
                manualOptions={materialSelectType}
                onChange={(el: any) => {
                    setLearningType(el)
                }}
            />
            {learningType &&
              <Spinner spinning={isMaterialLoading}>
                  {materialForm[learningType]}
              </Spinner>
            }
        </Form>
    )
}
export default LearningMaterialForm;
