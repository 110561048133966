import { useState } from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import { LectureSessionFilterType, MySessionsListData, MySessionsCalendarData, SessionProps } from 'components/Lectures/types';
import { RcFile } from 'antd/lib/upload/interface';
import generalHelpers from "../../utils/generalHelpers";

export const useSession = () => {
    const [handleError] = useHandleError();
    const [sessionLoading, setSessionLoading] = useState<boolean>(false)
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)

    const deleteSession = async (lectureId: number | undefined, sessionId: number): Promise<boolean | null> => {
        setSessionLoading(true);

        try {
            const response = await apiClient.request(`api/v1/courses/lectures/${lectureId}/sessions/${sessionId}`, [], 'DELETE');

            if ("error" in response) {
                throw response;
            };

            if ("deleted" in response) {

                if (!response.deleted) {
                    handleError();
                }

                return response.deleted;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setSessionLoading(false);
        }
    }

    const getSessionyById = async (lectureId: number, sessionId: number, adminView: boolean = false, userTypeId?: number): Promise<SessionProps | null> => {
        setSessionLoading(true);

        try {
            let url = `/api/v1/courses/lectures/${lectureId}/sessions/session/${sessionId}`;

            if (adminView) {
                url += '?userTypeId=' + userTypeId
            }

            const response = await apiClient.request(url, [], 'GET');

            if ("error" in response) {
                throw response;
            };

            if ("session" in response) {
                const session = response.session;

                return session;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setSessionLoading(false);
        }
    }

    const saveSession = async (lectureId: number | undefined, sessionId: number | undefined, sessionData: SessionProps | undefined): Promise<SessionProps[] | null> => {
        if (!lectureId) {
            return null;
        }
        setButtonLoading(true)
        try {
            const requests: any[] = [];

            if (sessionId && !!sessionData) {
                requests.push(apiClient.request(`/api/v1/courses/lectures/${lectureId}/sessions/${sessionId}`, sessionData, 'PUT'))
            } else if (!sessionId && !!sessionData) {
                requests.push(apiClient.request(`/api/v1/courses/lectures/${lectureId}/sessions`, sessionData, 'POST'))
            }

            const result = await Promise.all(requests);

            return !!result && !!result[0] && !!result[0].session ? result[0].session : null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setButtonLoading(false)
        }
    }

    const addSessionAttendance = async (lecturesId: number, sessionId: number, userIds: number[]): Promise<boolean | null> => {
        setSessionLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lecturesId}/sessions/${sessionId}/attendee`, {
                userTypeIds: userIds
            }, 'POST');

            if ("error" in response) {
                throw response;
            };

            if ("added" in response) {

                return !!response.added.length;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setSessionLoading(false);
        }
    }

    const statusChangeSessionAttendance = async (lecturesId: number, sessionId: number, userIds: number[], status: any): Promise<boolean | null> => {
        setSessionLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lecturesId}/sessions/${sessionId}/attendee/status`, {
                userTypeIds: userIds,
                status
            }, 'POST');

            if ("error" in response) {
                throw response;
            };

            if ("added" in response) {

                return !!response.added.length;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setSessionLoading(false);
        }
    }

    const changeAttendanceStatus = async (lecturesId: number, sessionId: number, attendeeIds: number[], status: string): Promise<boolean | null> => {
        setSessionLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lecturesId}/sessions/${sessionId}/attendee/${attendeeIds.join()}`, {
                status: status
            }, 'PUT');

            if ("error" in response) {
                throw response;
            };

            if ("updated" in response) {

                return !!response.updated.length;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setSessionLoading(false);
        }
    }

    const removeAttendance = async (lecturesId: number, sessionId: number, attendeeIds: number[], status: string): Promise<boolean | null> => {
        setSessionLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lecturesId}/sessions/${sessionId}/attendee/${attendeeIds.join()}`, [], 'DELETE');

            if ("error" in response) {
                throw response;
            };

            if ("deleted" in response) {

                return !!response.deleted.length;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setSessionLoading(false);
        }
    }

    const getMySessions = async (
        type: LectureSessionFilterType,
        lectureId: number | undefined,
        adminView: boolean = false,
        userTypeId?: number): Promise<MySessionsListData[]> => {
        setSessionLoading(true);

        try {
            let url = `/api/v1/courses/my/sessions?type=${type}${lectureId ? '&lectureId=' + lectureId : ''}`

            if (adminView) {
                url += '&userTypeId=' + userTypeId
            }

            const response = await apiClient.request(url, [], 'GET');

            if (response && response.data && Array.isArray(response.data)) {
                return response.data as MySessionsListData[];
            }

            throw response;
        } catch (error) {
            handleError(error);
            return [];
        } finally {
            setSessionLoading(false);
        }
    }

    const getMySessionsCalendarData = async (yearAndMonth: string | undefined = undefined): Promise<MySessionsCalendarData[]> => {
        try {
            let url = `/api/v1/courses/my/sessions/calendar${yearAndMonth ? '?yearAndMonth=' + yearAndMonth : ''}`

            const response = await apiClient.request(url, [], 'GET');

            if (response && response.data && Array.isArray(response.data)) {
                return response.data as MySessionsCalendarData[];
            }

            throw response;
        } catch (error) {
            handleError(error);
            return [];
        }
    }

    const downloadSessionFile = async (lectureId: number, sessionId: number, fileId: number): Promise<null | Blob> => {
        setSessionLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lectureId}/sessions/${sessionId}/files/${fileId}`, [], 'GET', true, true);
            if (response && response.type) {
                if (response.type === 'application/json') {
                    const jsonResponse = await response.text();

                    if ("error" in jsonResponse) {
                        throw jsonResponse;
                    };

                    return response;
                }

                return response;
            }

            throw new Error();

        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setSessionLoading(false);
        }
    }

    const storeSessionFile = async (lectureId: number, sessionId: number, file: RcFile): Promise<number | undefined> => {
        setSessionLoading(true);

        try {
            const bodyFormData = new FormData();
            bodyFormData.append('file', file);
            const response = await apiClient.request(`/api/v1/courses/lectures/${lectureId}/sessions/${sessionId}/files`, bodyFormData, 'POST');

            if (!!response && typeof response === 'object' && !!response.id) {
                return response.id;
            }

            throw response;

        } catch (error) {
            handleError(error);
        } finally {
            setSessionLoading(false);
        }
    }

    const deleteSessionFile = async (lectureId: number, sessionId: number, fileId: number): Promise<boolean> => {
        setSessionLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lectureId}/sessions/${sessionId}/files/${fileId}`, [], 'DELETE');

            if (!!response && !!response.deleted) {
                return true;
            }

            throw response;

        } catch (error) {
            handleError(error);
            return false;
        } finally {
            setSessionLoading(false);
        }
    }

    const cancelMySession = async (lectureId: number, sessionId: number): Promise<boolean> => {
        setSessionLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/my/sessions/${lectureId}/cancel/${sessionId}`, [], 'DELETE');

            if (!!response && !!response.canceled) {
                return true;
            }

            throw response;

        } catch (error) {
            handleError(error);
            return false;
        } finally {
            setSessionLoading(false);
        }
    }

    const validateAvailability = async (lectureId: number, data: any
    ): Promise<boolean> => {
        try {
            const response = await apiClient.request(`api/v1/courses/lectures/${lectureId}/sessions/validate`, data, 'POST');

            if (!!response && !!response.isValid) {
                return new Promise((resolve) => resolve(true));
            }

            throw response;

        } catch (error) {
            return new Promise((resolve, reject) => reject(true));
        }
    }

    const sendSessionStudentInterestMessage = async (lectureId: number, sessionId: number, message: string): Promise<boolean> => {
        setSessionLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lectureId}/sessions/${sessionId}/incerase-capacity`, {
                message: message
            }, 'POST');

            if (!!response && !!response.sent) {
                return true;
            }

            throw response;

        } catch (error) {
            handleError(error);
            return false;
        } finally {
            setSessionLoading(false);
        }
    }

    const searchSession = async (q: string) => {
        setSessionLoading(true)
        try {
            let requestUrl: string = 'api/v1/courses/lectures/session/search'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setSessionLoading(false)
        }
    }



    return {
        sessionLoading,
        buttonLoading,
        getSessionyById,
        saveSession,
        addSessionAttendance,
        deleteSession,
        changeAttendanceStatus,
        removeAttendance,
        getMySessions,
        getMySessionsCalendarData,
        downloadSessionFile,
        storeSessionFile,
        deleteSessionFile,
        cancelMySession,
        validateAvailability,
        sendSessionStudentInterestMessage,
        statusChangeSessionAttendance,
        searchSession
    }
}