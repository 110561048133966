import React from 'react';
import { Layout } from 'antd';
import AppearanceStyler from '../../NoAuthHelper/AppearanceStyler';
import { SpecialClientEnumsType } from '../types';
import styles from '../styles.module.scss';

const { Header } = Layout;

interface UnauthorizedHeaderInterface {
    handleValues: Function;
}

const UnauthorizedHeader: React.FC<UnauthorizedHeaderInterface> = ({ handleValues }) => {
    return (
        <>
            <AppearanceStyler
                globalTheme={handleValues(SpecialClientEnumsType.theme)}
                favicon={handleValues(SpecialClientEnumsType.favicon)}
                title={handleValues(SpecialClientEnumsType.title)}
            />
            <Header className={`${handleValues(SpecialClientEnumsType.design)} ${styles.header}`} />
        </>
    );
};

export default UnauthorizedHeader;
