import React, {} from 'react';
import {useIntl} from 'react-intl';
import {Input} from 'antd';
import debounce from "lodash/debounce";

interface SearchInterface {
    setSearchString: React.Dispatch<React.SetStateAction<string>>
    setReload?: React.Dispatch<React.SetStateAction<boolean>>
}

const Search: React.FC<SearchInterface> = ({setSearchString, setReload}) => {

    const {Search} = Input;
    const intl = useIntl();

    const loadOptionsTimeOut = debounce(function (searchValue: string) {
        const searchString = searchValue.replace(/\s+/g, '');
        if (searchValue.length === 0 || searchString.length >= 3) {
            setSearchString(searchValue);
            if (setReload) {
                setReload(true)
            }
        }
    }, 800);

    return (
        <Search
            className="table-search-field"
            allowClear
            placeholder={intl.formatMessage({id: "general.search"})}
            onChange={value => loadOptionsTimeOut(value.target.value)}
        />
    )
};

export default Search;