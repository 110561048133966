import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Button, Row, Col} from "antd";
import DefaultLayout from "components/DefaultLayout";
import Spinner from "components/Spinner";
import generalHelpers from "utils/generalHelpers";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    };
};

interface TestAttemptLandingProps {
    session?: any;
    startLearningTest: Function;
    learningTest: any;
    attempt: any;
    isParentLoading: boolean;
    isPreview: boolean;
    isOverview: boolean;
    isStudentAttempt: boolean;
    lastAttempt: any;
}

const TestAttemptLanding: React.FC<TestAttemptLandingProps> = ({
    session,
    startLearningTest, 
    learningTest,
    attempt,
    isParentLoading,
    isPreview,
    isOverview,
    isStudentAttempt,
    lastAttempt
}) => {

    const intl = useIntl();

    const values = {
        questionsCount: isOverview && attempt ? attempt?.questionsCount : learningTest.totalQuestions,
        toPassScore: isOverview && attempt ? 
            (attempt.scoreType == 'PERCENTAGE' ? attempt.passingScore + '%' : attempt.passingScore) :
            (learningTest.passingScoreType == 'PERCENTAGE' ? learningTest.passingScore + '%' : learningTest.passingScore),
        timeLimitStatus: isOverview && attempt ? attempt.timeLimitStatus : learningTest.timeLimitStatus,
        timeLimit: isOverview && attempt ? 
            (attempt.timeLimitStatus ? attempt.timeLimit : intl.formatMessage({id: 'learning_tests.no_time_limit_status'})) :
            (learningTest.timeLimitStatus ? learningTest.timeLimit : intl.formatMessage({id: 'learning_tests.no_time_limit_status'}))
    }

    const getAttemptScores = () => {
        if (session.active_user_type !== 'STUDENT') {
            return attempt.scoreType == 'PERCENTAGE' ? ((attempt.attemptScore / attempt.totalScore) * 100).toFixed(1) + "%" + ` (${attempt.attemptScore} ${intl.formatMessage({id: 'learning_tests.points'})})` : attempt.attemptScore;
        }

        return values.questionsCount;
    }

    const shouldDisplay = (): boolean => {
        return (!attempt || attempt.attemptStatus !== 'PASSED') && (isStudentAttempt || isPreview);
    };

    return (
        <DefaultLayout.PageLayout style={{marginTop: isPreview ? '0px' : '80px'}}>
            <DefaultLayout.PageContent style={{padding: 0, background: 'none'}}>
                <Spinner spinning={isParentLoading}>
                    {learningTest && <div className={`learning-test-landing-page`} style={{padding: isPreview ? '0px 24px 0px' : '70px 24px 92px'}}>
                        <div className={`learning-test-landing-card`}>
                            <h4 className='color-1'><FormattedMessage id="learning_tests.knowledge_assesment_test" /></h4>
                            <h1>{learningTest.title}</h1>
                            <div className='flex justify-center'>
                                <Row gutter={[30, 0]} wrap className='test-stats'>
                                    <Col>
                                        <label className='color-1'><FormattedMessage id="question_pools.questions" /></label>
                                        {attempt?.id ?
                                            <div>{getAttemptScores()}</div>
                                            :
                                            values.questionsCount
                                        }
                                    </Col>
                                    <Col>
                                        <label className='color-1'><FormattedMessage id="learning_tests.to_pass_score" /></label>
                                        <div>{values.toPassScore}</div>
                                    </Col>
                                    {values.timeLimitStatus ?
                                        <Col>
                                            <label className='color-1'><FormattedMessage id="learning_tests.time_limit" /></label>
                                            <div>{generalHelpers.convertSecondsToHHMMSS(values.timeLimit * 60)}</div>
                                        </Col>
                                        : null
                                    }
                                </Row>
                            </div>
                            {shouldDisplay() && <Button type='primary' size='large' onClick={() => startLearningTest(true)}><FormattedMessage id='learning_tests.start_test' /></Button>}
                            {(lastAttempt && lastAttempt.attempt_status != 'IN_PROGRESS') || isOverview ? <Button type='primary' ghost size='large' onClick={() => startLearningTest(false)} style={{marginLeft: '20px'}}><FormattedMessage id='learning_tests.test_overview' /></Button> : null}
                        </div>
                    </div>}
                </Spinner>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
        
    );
};

export default connect(mapStateToProps)(TestAttemptLanding);
