import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import DefaultLayout from 'components/DefaultLayout';
import {useParams, useNavigate} from 'react-router-dom';
import {Tabs} from "antd";
import TemplateForm from "components/Phishing/TemplateForm";
import SimulationsForm from "components/Phishing/SimulationsForm";

const PhishingTemplatesForm: React.FC = ({}) => {
    const [activeTabKey, setActiveTabKey] = useState('settings');

    const intl = useIntl();
    const params = useParams();
    const simulationId = params.id;
    const tab = params.tab;
    const navigate = useNavigate();

    useEffect(() => {
        if (tab) {
            setActiveTabKey(tab)
        }
    }, [])

    const tabChangeHandle = (tab: string) => {
        setActiveTabKey(tab)
        if (simulationId) {
            navigate(`/phishing/templates/${simulationId}/${tab}`)
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                breadcrumb={
                    [{name: intl.formatMessage({id: 'phishing.phishing'}), path: '/phishing/templates'}]
                }
                title={intl.formatMessage({id: 'phishing.phishing_tempalte_manager'})}
            />
            <DefaultLayout.PageContent>
                <Tabs onChange={tabChangeHandle} activeKey={activeTabKey}>
                    <Tabs.TabPane tab={intl.formatMessage({id: 'general.settings'})} key="settings">
                        <TemplateForm/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={intl.formatMessage({id: 'phishing.simulations'})} key="simulations">
                        <SimulationsForm/>
                    </Tabs.TabPane>
                </Tabs>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
};

export default PhishingTemplatesForm;
