import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Input, Popover, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import { Table, Search } from 'ui'
import useHandleError from 'utils/useHandleError';
import FlexRow from 'components/FlexRow';
import debounce from 'lodash/debounce';
import generalHelpers from 'utils/generalHelpers';
import './styles.scss';
import useQueryApiClient from 'utils/useApiClient';
import Spinner from 'components/Spinner';

const mapStateToProps = (state: any) => ({ session: state.session });

const OrganizationList: React.FC = ({ session }: any) => {
    const [reload, setReload] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [searchString, setSearchString] = useState('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);

    const intl = useIntl();

    const { appendData: deleteReseller } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/organizations/:id',
            method: 'DELETE',
        },
        onSuccess: () => {
            setReload(true)
        }
    });

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    const showAddNewResellers = () => {
        if (session.active_user_type === 'SUPER_ADMIN') {
            return true
        } else if (session.active_user_type === 'DISTRIBUTOR_ADMIN') {
            if (session.special_rights.includes('can_add_new_resellers')) {
                return true
            }
        }

        return false
    };

    const { appendData: inheritTemplates, isLoading } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/mail-templates/inherit/organization/:id',
            method: 'POST',
        },
        onSuccess: () => {
            setVisibleTooltip(null);
        }
    });

    let columns = [
        {
            title: intl.formatMessage({ id: 'general.company' }),
            dataIndex: 'name',
            render: (text: string, record: any) => (
                <NavLink to={`/organizations/${record.id}/admins`}>
                    {record.name}
                </NavLink>
            )
        },
        {
            title: intl.formatMessage({ id: 'general.start_date' }),
            key: 'begin_date',
            sorter: true,
            render: (value: string, record: any) =>
                record.beginDate ? moment(record.beginDate).format('DD.MM.YYYY') : '-'
        },
        {
            title: intl.formatMessage({ id: 'general.end_date' }),
            key: 'end_date',
            sorter: true,
            render: (value: string, record: any) => {
                let color;
                if (record.endDate && record.endDate) {
                    if (moment(record.endDate) < moment()) color = 'date-color';
                    return (
                        <div className={color}>
                            {moment(record.endDate).format('DD.MM.YYYY')}
                        </div>)
                } else {
                    return '-'
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'general.customers' }),
            sorter: true,
            key: 'customers',
            render: (text: string, record: any) => record.totalCustomers
        },
        {
            title: intl.formatMessage({ id: 'general.resellers' }),
            key: 'resellers',
            render: () => '-'
        },
        {
            title: intl.formatMessage({ id: 'general.admins' }),
            key: 'admins',
            render: (text: string, record: any) => record.totalAdmins

        },
        {
            title: intl.formatMessage({ id: 'organization.agreement_changes' }),
            key: 'agreement_changes',
            render: () => '-'
        },
        {
            title: intl.formatMessage({ id: 'organization.agreement_status' }),
            key: 'agreement_status',
            render: (text: string, record: any, tag: any) => {
                let color;
                if (record.agreementStatus) {
                    if (record.agreementStatus === 'ACTIVE') color = 'green';
                    if (record.agreementStatus === 'INACTIVE') color = 'red';
                    return (
                        <Tag color={color} key={tag}>
                            {intl.formatMessage({ id: `agreement.status.${record.agreementStatus}` })}
                        </Tag>
                    )
                } else {
                    return '-';
                }
            }
        },
        {
            key: 'actions',
            render: (text: string, record: any) => {
                const content = <>
                    {generalHelpers.isPlatformAdmin(session.active_user_type) ?
                        <>
                            {
                                record.agreement ?
                                    <NavLink to={`/organizations/${record.id}/agreement`}>
                                        <div className='popover-item'>
                                            <FormattedMessage id='organization.edit_agreement' />
                                        </div>
                                    </NavLink>
                                    :
                                    <NavLink to={`/organizations/${record.id}/agreement`}>
                                        <div className='popover-item'>
                                            <FormattedMessage id='organization.add_agreement' />
                                        </div>
                                    </NavLink>
                            }
                        </>
                        : null
                    }
                    {generalHelpers.isPlatformAdmin(session.active_user_type) ?
                        <>
                            <NavLink to={`/organizations/${record.id}/edit`}>
                                <div className='popover-item'>
                                    <FormattedMessage id='organization.edit_reseller' />
                                </div>
                            </NavLink>
                            <div className='popover-item delete-item' onClick={() => {
                                deleteReseller({}, { id: record.id })
                            }}>
                                <FormattedMessage id='organization.delete_reseller' />
                            </div>
                        </>
                        : null
                    }
                    <Spinner spinning={isLoading}>
                        <div className='popover-item delete-item' onClick={() => {
                            inheritTemplates({}, { id: record.id })
                        }}>
                            <FormattedMessage id='organization.inherit_templates' />
                        </div>
                    </Spinner>
                </>;

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger='click'
                        placement='bottomRight'
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className='fal fa-ellipsis-v' style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'general.resellers' })} />
            <DefaultLayout.PageContent>
                <FlexRow
                    right={
                        <Search setReload={setReload} setSearchString={setSearchString} />
                    }
                    left={
                        <>
                            {(showAddNewResellers()) &&
                                <NavLink to='/organizations/add'>
                                    <Button type='primary' icon={<PlusOutlined />}>
                                        <FormattedMessage id='organization.add_resellers' />
                                    </Button>
                                </NavLink>
                            }
                        </>
                    }
                />
                <Table
                    columns={columns}
                    url={'/api/v1/organizations'}
                    setReload={setReload}
                    reload={reload}
                    rowKey={'id'}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    search={searchString}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
}

export default connect(mapStateToProps)(OrganizationList);
