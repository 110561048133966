import React from 'react';
import { useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { constructValidationRules } from './validations';
import './styles.scss';

const TextArea = ({error, className, name, label, validation = {}, customRules, validateTrigger, customLayout, style, tooltip, maxLength, ...props}: any) => {
  const intl = useIntl();

  let labelCol: any = { span: 8 };
  let wrapperCol: any = { span: 6 };

  const defaultLength = 2000;

  if (customLayout) {
    if (typeof customLayout === 'object') {
      labelCol = customLayout.labelCol || undefined;
      wrapperCol = customLayout.wrapperCol || undefined;
    } else {
      labelCol = undefined;
      wrapperCol = undefined;
    }
  }

  return (
    <Form.Item
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      label={label}
      rules={customRules || constructValidationRules(validation, intl)}
      validateTrigger={validateTrigger}
      style={style}
    >
      <Input.TextArea maxLength={maxLength ? maxLength : defaultLength} autoComplete="off" {...props} className={`default-input-field ${className || ''}`} />
    </Form.Item>
  );
}

export default TextArea;
