import Spinner from 'components/Spinner';
import React from 'react';
import { useParams } from 'react-router-dom';
import useQueryApiClient from 'utils/useApiClient';
import styles from './styles.module.scss';

const AdfsRedirect: React.FC = () => {
    const params = useParams();
    const client: String | undefined = params.client;

    useQueryApiClient({
        request: {
            url: '/api/v1/authentication/' + client,
        },
        onSuccess: (response): void => {
            if (response?.data && response.data !== '') {
                window.location.assign(response.data);
            } else {
                window.location.assign('/');
            }
        },
    });

    return (
        <div className={styles.redirectSpinner}>
            <Spinner />
        </div>
    );
};

export default AdfsRedirect;
