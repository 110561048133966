import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';

import DefaultLayout from "components/DefaultLayout";

import useHandleError from "utils/useHandleError";
import coursesApiClient from "utils/coursesApiClient";
import Spinner from "components/Spinner";
import LearningTestAttempt from 'components/LearningTest/TestAttempt';


const LearningTestOverview: React.FC<any> = ({ }) => {
    const [attempt, setAttempty] = useState<any>();

    const intl = useIntl();

    const params = useParams();
    const [handleError] = useHandleError();
    const [isLoading, setIsLoading] = useState(false);
    const attemptId = params.id;

    useEffect(() => {
        if (attemptId) {
            loadAttempt()
        }
    }, [attemptId])

    const loadAttempt = async () => {

        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/test-attempts/${attemptId}`, [], 'GET');
            setAttempty(response.testAttempt)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                loading={isLoading}
                breadcrumb={[
                    {
                        name: intl.formatMessage({id: 'learning_tests.learning_tests'}), 
                        path: '/learning/tests'
                    }
                ]}
                title={attempt ? attempt.learningTest?.title : intl.formatMessage({id: 'learning_tests.learning_tests'})}
                />
            <DefaultLayout.PageContent withBottomMargin>
                <Spinner spinning={isLoading}>
                    {attempt && <LearningTestAttempt
                        defaultLearningTestId={attempt.learningTest.id}
                        defaultTestAttemptId={attempt.id}
                        isOverview={true}
                    />}
                </Spinner>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    );
};

export default LearningTestOverview;
