import React, {useState, useEffect} from 'react';
import DefaultLayout from 'components/DefaultLayout';
import {connect} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {NavLink, useNavigate} from 'react-router-dom';
import {Modal, Form, Button, Space, Popover} from 'antd';
import {PlusOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {config} from 'config/config';
import apiClient from 'utils/apiClient';
import {Select} from 'components/Form';
import generalHelpers from "utils/generalHelpers";
import {Table} from 'ui'
import useHandleError from "utils/useHandleError";
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        mailTemplateFilterOptions: state.mailTemplateFilterOptions,
        mailTemplateCustomerOptions: state.mailTemplateCustomerOptions,
    }
}

const CustomerTemplates: React.FC<any> = ({session}: any) => {
      const { locale } = useLocaleContext();
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('')
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [handleError] = useHandleError();
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);

    const intl = useIntl();
    const [form] = Form.useForm();

    const columns = [
        {
            title: intl.formatMessage({id: 'emailing.templates.template_name'}),
            render: (text: string, record: any) => {
                const translations = record.translations;
                let titleObject = translations.filter((el: any) => el.language === locale)[0];

                if (!titleObject) {
                    titleObject = translations[Object.keys(translations)[0]]
                }
                return (
                    <NavLink to={`/e-mailing/manage-templates/customer/${record.id}/edit`}>
                        {titleObject.title}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'emailing.templates.template_type'}),
            dataIndex: 'templateType',
            key: 'templateType',
            render: (text: string, record: any) => intl.formatMessage({id: `email_templates.template_type.${record.templateType}`})
        },
        {
            title: intl.formatMessage({id: 'emailing.templates.template_category'}),
            dataIndex: 'categoryCode',
            key: 'categoryCode',
            render: (text: string, record: any) => intl.formatMessage({id: `email_templates.categories.${record.categoryKey}`})
        },
        // {
        //     title: intl.formatMessage({id: 'general.language'}),
        //     dataIndex: 'language',
        //     key: 'language',
        //     render: (text: string, record: any) => {
        //
        //         let html;
        //         if (record.html_template) {
        //             html = record.html_template
        //         } else {
        //             html = record.html
        //         }
        //
        //         return (
        //             <div>
        //                 {html ?
        //                     <Radio.Group>
        //                         {
        //                             Object.entries(html).map((el: any, index: any) => {
        //                                 return (
        //                                     <>
        //                                         {el ?
        //                                             <Radio.Button style={{textTransform: 'uppercase'}} value={el[0]}
        //                                                           onClick={() => {
        //                                                               if (record.html_template) {
        //                                                                   setLanguageEditRecord(record.html_template[el[0]]);
        //                                                               } else {
        //                                                                   setLanguageEditRecord(record.html[el[0]]);
        //                                                               }
        //                                                               setTemplateSubject(record.subject[el[0]])
        //                                                               setSelecteTemplate(record);
        //                                                               setSelectedLanguageKey(el[0]);
        //                                                               setShowEditModal(true)
        //                                                           }}
        //                                             >
        //                                                 {el[0]}
        //                                             </Radio.Button>
        //                                             : null
        //                                         }
        //                                     </>
        //                                 )
        //                             })
        //                         }
        //                     </Radio.Group>
        //                     : null
        //                 }
        //             </div>
        //
        //         )
        //     }
        //
        // },
        {
            key: 'actions',
            render: (text: string, record: any) => {

                const deleteButton = (
                    <div onClick={() => {
                        confirmTemplateDeletion(record.id);
                        setVisibleTooltip(null)
                    }} className="popover-item">
                        <FormattedMessage id="general.delete"/>
                    </div>
                );

                const content = <>
                    <NavLink to={`/e-mailing/manage-templates/customer/${record.id}/edit`}>
                        <div className="popover-item">
                            <FormattedMessage id="general.edit"/>
                        </div>
                    </NavLink>
                    {record.templateType === 'CUSTOM' && session.active_user_type === 'CUSTOMER_ADMIN' &&
                        deleteButton
                    }
                    {record.category_code !== 'system_events' && ['DISTRIBUTOR_ADMIN', 'RESELLER_ADMIN'].includes(session.active_user_type) &&
                        deleteButton
                    }
                    {session.active_user_type === 'SUPER_ADMIN' &&
                        deleteButton
                    }
                </>;

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    const confirmTemplateDeletion = (uuid: string) => {
        Modal.confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            icon: <ExclamationCircleOutlined/>,
            content: intl.formatMessage({id: 'emailing.templates.begin_deletion'}),
            okText: intl.formatMessage({id: 'general.delete'}),
            okType: 'danger',
            cancelText: intl.formatMessage({id: 'general.cancel'}),
            onOk() {
                deleteTemplate(uuid);
            }
        })
    };

    const deleteTemplate = async (uuid: string) => {
        try {
            await apiClient.request(`/api/v1/mail-templates/customer/${uuid}/delete`, {}, 'DELETE');
            // loadStandardTemplates();
            setReload(true)
        } catch (error) {
            handleError(error)
        }
    };


    const selectOwner = async (value: any) => {
        try {
            if (value?.customerId) {
                setFilter('customerId=' + value.customerId);
                setReload(true)
                setShowFilterModal(false)
            }
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <DefaultLayout.PageContent>
            <Space size={10}>
                {session.active_user_type === 'CUSTOMER_ADMIN' &&
                  <NavLink to={'/e-mailing/manage-templates/customer/add'}>
                    <Button type="primary">
                      <PlusOutlined/> <FormattedMessage id='general.add'/>
                    </Button>
                  </NavLink>
                }
                {generalHelpers.isAdmin(session.active_user_type) &&
                  <Button
                    type="primary"
                    onClick={() => {
                        setShowFilterModal(true)
                    }}
                  >
                    <FormattedMessage id="general.filter"/>
                  </Button>
                }
            </Space>
            <Table
                columns={columns}
                url='/api/v1/mail-templates/customer'
                setReload={setReload}
                fetchOnLoad={!generalHelpers.isAdmin(session.active_user_type)}
                reload={reload}
                filter={filter}
                rowKey='id'
                search={search}
            />
            {generalHelpers.isAdmin(session.active_user_type) &&
              <Modal
                className="user-list-modal"
                open={showFilterModal}
                title={intl.formatMessage({id: 'emailing.templates.select_organization'})}
                onOk={() => form.submit()}
                onCancel={() => setShowFilterModal(false)}
                okText={intl.formatMessage({id: 'general.select'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
              >
                <Form form={form} onFinish={selectOwner}>
                  <Select
                    allowClear
                    showSearch
                    name='customerId'
                    dataKey='customers'
                    label={intl.formatMessage({id: "general.customer"})}
                    url={config.api.routes.backend.selectCustomers}
                    integerKey={true}
                    customLayout={true}
                    dropdownStyle={{minWidth: "385px"}}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  />
                </Form>
              </Modal>
            }
        </DefaultLayout.PageContent>
    );
}

export default connect(mapStateToProps)(CustomerTemplates);

