import React, { useEffect, useState } from 'react';
import { ThemeSwitch } from 'ui';
import Helmet from 'react-helmet';
import { ThemeType } from 'pages/Customers/NoAuthCustomerForm/types';

const AppearanceStyler: React.FC<any> = ({ globalTheme, favicon, title }) => {
    const [theme, setTheme] = useState<ThemeType>();

    useEffect(() => {
        setTheme(globalTheme);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalTheme]);

    return (
        <>
            <ThemeSwitch theme={theme ? { themeColorGlobal: theme.themeColor } : {}} />
            <Helmet>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <title>{title}</title>
            </Helmet>
        </>
    );
};

export default AppearanceStyler;
