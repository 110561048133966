import React, { createContext, useContext, useState, ReactNode } from 'react';

interface GlobalDesignContext {
    logo: string;
    setLogo: (value: string) => void;
    favicon: string
    setFavicon: (value: string) => void;

}

const initialGlobalDesignContextValue: GlobalDesignContext = {
    logo: '',
    setLogo: () => {},
    favicon: '',
    setFavicon: () => {},
};

const GlobalDesignContext = createContext<GlobalDesignContext>(initialGlobalDesignContextValue);
export const useGlobalDesignContext = () => useContext(GlobalDesignContext);

interface Props {
    children: ReactNode;
}

const GlobalDesignContextProvider = ({ children }: Props) => {
    const [logo, setLogo] = useState('');
    const [favicon, setFavicon] = useState('');
    
    
    return (
        <GlobalDesignContext.Provider value={{ logo, setLogo, favicon, setFavicon }}>
            {children}
        </GlobalDesignContext.Provider>
    );
};

export default GlobalDesignContextProvider;
