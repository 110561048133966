import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, message, Modal } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import LearningActivity from 'components/LearningActivity';
import { Select } from 'components/Form';
import LanguageSelect from 'components/LanguageSelect';
import PdfMaterialPlayer from 'components/StudentComponents/PdfMaterialPlayer';
import VideoMaterialPlayer from 'components/StudentComponents/VideoMaterialPlayer';

interface ScormPreviewModalInterface {
    visible: boolean;
    onCancel: any;
    activityId: number;
    materialType: string;
    courseId: number;
}

const ScormPreviewModal: React.FC<ScormPreviewModalInterface> = ({ courseId, visible, onCancel, activityId = 0, materialType }) => {
    const [showPreviewFile, setShowPreviewFile] = useState(false);
    const [versionList, setVersionList] = useState<any>([]);
    const [selectedVersion, setSelectedVersion] = useState<string | undefined>();
    const [isVersionsLoading, setIsVersionsLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>('');

    const intl = useIntl();

    const getVersionList = async (id: number, language: string) => {
        try {
            setIsVersionsLoading(true);
            let versionList = await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${id}/version-list/${language}`, {}, 'GET');
            setVersionList(versionList);
            setIsVersionsLoading(false);
        } catch (error) {
            setVersionList([]);
            console.error(error);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        }
    };    

    const getLearningView = () => {
        switch (materialType) {
            case 'SCORM_LEARNING':
            case 'SCORM_TEST':
                return (
                    <LearningActivity learningActivityId={activityId} type={materialType === 'SCORM_TEST' ? 'test' : 'learn'} language={selectedLanguage} version={selectedVersion} />
                )
            case 'PDF_MATERIAL':
                return (
                    <PdfMaterialPlayer learningActivityId={activityId} type={'learn'} language={selectedLanguage} version={selectedVersion} />
                )
            case 'VIDEO_MATERIAL':
                return (
                    <div className='activity-wrapper'>
                        <div className='activity-content'>
                            <VideoMaterialPlayer
                                scormData={{
                                    learningActivityId: activityId,
                                    type: 'learn'
                                }}
                                language={selectedLanguage}
                            />
                        </div>
                    </div>
                )
            default:
                break;
        }
    }

    const handleClose = () => {
        onCancel(false)
        setVersionList([]);
    }

    return (
        <>
            <Modal
                open={visible}
                title={intl.formatMessage({ id: 'courses.preview_material' })}
                onCancel={() => handleClose()}
                cancelText={intl.formatMessage({ id: 'general.close' })}
                onOk={() => { setShowPreviewFile(true); onCancel(false); setVersionList([]); }}
                destroyOnClose
            >
                <LanguageSelect
                    name='previewLanguage'
                    label={intl.formatMessage({ id: 'general.language' })}
                    onChange={(language: string) => { getVersionList(activityId, language); setSelectedLanguage(language); }}
                />
                <Select
                    loading={isVersionsLoading}
                    label={intl.formatMessage({ id: "general.version" })}
                    name='version'
                    onChange={(version: string) => setSelectedVersion(version)}
                    manualOptions={{ ...versionList.versionList }}
                />
            </Modal>
            <Modal
                open={showPreviewFile}
                title={intl.formatMessage({ id: 'courses.preview_material' })}
                cancelText={intl.formatMessage({ id: 'general.back' })}
                onCancel={() => setShowPreviewFile(false)}
                width="84vw"
                destroyOnClose
                footer={[
                    <Button onClick={() => setShowPreviewFile(false)}>
                        <FormattedMessage id='general.cancel' />
                    </Button>
                ]}
            >
                {getLearningView()}
            </Modal>
        </>
    );
}

export default ScormPreviewModal;
