import React, {useState} from 'react';
import { useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';


import useHandleError from "utils/useHandleError";

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

interface RecordType {
    key: string;
    name: string;
}

interface GroupsInterface {
}

const Groups: React.FC<GroupsInterface> = ({}) => {
    const [selectedUser, setSelectedUser] = useState('')
    const [admins, setAdmins] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [customers, setCustomer] = useState<RecordType[]>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);

    const params = useParams();
    const intl = useIntl();
    const programId = params.id;
    const [handleError] = useHandleError();



    return (
        <>
Groups
        </>
    )
};

export default connect(mapStateToProps)(Groups);
