import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';

const ShortRoute: React.FC<any> = (props) => {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        getToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getToken = async () => {
        setLoading(true);
        try {
            let response = await apiClient.request('/api/v1/get-url/' + params.code, {}, 'GET');

            navigate(response.url);
        } catch (error) {
            navigate('/');
        } finally {
            setLoading(false);
        }
    };

    return <Spinner spinning={loading} opaqueH></Spinner>;
};

export default ShortRoute;
