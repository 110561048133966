import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import DefaultLayout from 'components/DefaultLayout';
import {FilterModal, LogDetailsModal} from 'components/Modals';
import FlexRow from 'components/FlexRow';
import {Button, Table} from 'ui';
import moment from 'moment/moment';
import {EyeOutlined} from '@ant-design/icons';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

interface HistoryInterface {
    program: any;
    launchButton: HTMLElement;
}

const History: React.FC<HistoryInterface> = ({program, launchButton}) => {
    const [reload, setReload] = useState(false);
    const [filter, setFilter] = useState('');
    const [showFilter, setShowFilter] = useState<boolean>(false);

    const [showLogDetails, setShowLogDetails] = useState(false);
    const [logDetailsContent, setLogDetailsContent] = useState<any>({});

    const params = useParams();
    const intl = useIntl();
    const programId = params.id;

    const parseEventDiff = (oldValues: any, newValues: any) => {
        return {
            old: JSON.parse(oldValues),
            ...JSON.parse(newValues)
        }
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'general.event_type'}),
            dataIndex: 'event_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_subject_type'}),
            dataIndex: 'event_subject_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_object_type'}),
            dataIndex: 'event_object_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator_type'}),
            dataIndex: 'event_initiator_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator'}),
            dataIndex: 'fullName'
        },
        {
            title: intl.formatMessage({id: 'general.student_email'}),
            render: (_: string, record: any) => (record.event_initiator_type === 'STUDENT') && record.email
        },
        {
            title: intl.formatMessage({id: 'general.created_at'}),
            render: (_: string, record: any) => moment(record.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            title: intl.formatMessage({id: 'system.audit.attributes'}),
            key: 'attributes',
            render: (_: string, record: any) => {
                return <p
                    className='description-icon'
                    onClick={() => {
                        setShowLogDetails(true);
                        setLogDetailsContent(parseEventDiff(record.event_data_old, record.event_data_new))
                    }}
                    >
                    <FormattedMessage id={'general.show'}/> <EyeOutlined />
                </p>
            }
        }
    ];
    return (
        <>
            <FlexRow
                right={
                    <Button type='default' onClick={() => setShowFilter(true)}>
                        <FormattedMessage id='general.filter'/>
                    </Button>
                }
            />

            <div className={'table-filter-modal'}>
                <FilterModal
                    title={'general.filter'}
                    okProp={'general.filter'}
                    visible={showFilter}
                    onCancel={setShowFilter}
                    modules={{
                        createdAt: { visible: true, label: 'general.created_at' },
                        eventSubjectType: { mode: 'multiple', visible: true },
                        students: { mode: 'multiple', visible: true, params: '&value=email' }
                    }}
                    setFilter={setFilter}
                    load={setReload}
                    filter={filter}
                />
            </div>

            <Table
                columns={columns}
                url={`/api/v1/courses/learning-program/${programId}/history`}
                setReload={setReload}
                rowSelection={false}
                reload={reload}
                rowKey={'id'}
                scroll={{ x: 800 }}
                filter={filter}
            />

            <LogDetailsModal
                visible={showLogDetails}
                onCancel={setShowLogDetails}
                content={logDetailsContent}
            />

            <DefaultLayout.PageFooter
                right={
                    <>
                        {launchButton}
                    </>
                }
            />
        </>
    )
};

export default connect(mapStateToProps)(History);
