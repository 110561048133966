import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal, Empty, Button, Input, Form, Tag, message} from 'antd';
import Switch from 'components/Form/Switch'
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import {config} from 'config/config';
import useHandleError from "utils/useHandleError";
import {useDate} from 'hooks/useDate';
import { FileTableExtended } from 'ui';
import moment from 'moment';
import SubmissionDetail from 'components/AssignmentActivity/SubmissionDetail'

interface AssignmentSubmissionInterface {
    open: boolean;
    onGraded: Function;
    onCancel: any;
    isLoading: boolean;
    activityFormData: any;
    canGrade: boolean,
    setCanGrade: React.Dispatch<React.SetStateAction<boolean>>
}

const AssignmentSubmission: React.FC<AssignmentSubmissionInterface> = ({
    open,
    onGraded,
    onCancel,
    isLoading,
    activityFormData,
    canGrade,
    setCanGrade,
}) => {
    const [submittedFiles, setSubmittedFiles] = useState<any>();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [assignmentSettings, setAssignmentSettings] = useState<any>();
    const [submission, setSubmission] = useState<any>();
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const intl = useIntl();
    const {formatDate} = useDate();
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const [handleError] = useHandleError();

    useEffect(() => {
        if(activityFormData) {
            let settings = activityFormData.material.settings;

            // Get assignment deadline
            let date: any;
            if (settings?.deadline) {
                date = moment(activityFormData.enrollment.enroll_date);
                date.add(settings.deadline, 'days').endOf('day').toString();
            } else {
                date = moment(activityFormData.enrollment.enroll_date);
            }
            
            let submission = activityFormData.submission;
            let deadline: any = moment(date).format('YYYY-MM-DD HH:mm:ss')
            let isMissedDeadline = moment().startOf('day') >= moment(deadline);
            if( isMissedDeadline && submission.status === 'NOT_SUBMITTED' ) submission.status = 'MISSED_DEADLINE';
            submission.deadline = deadline;
            submission.deadline_missed = isMissedDeadline;

            // Can grade flag
            setCanGrade(canGrade && !!!isMissedDeadline && !['RETURNED'].includes(submission.status))

            if (submission.submitted_files && submission.submitted_files?.length > 0) {
                const fileList = Array.isArray(submission.submitted_files) ? submission.submitted_files : Object.values(submission.submitted_files);
                const submittedFilesArray = fileList.map((file: any) => {
                    file.date = formatDate(file.date);
                    return file;
                });
                
                setSubmittedFiles(submittedFilesArray)
            } else {
                setSubmittedFiles([])
            }

            form.setFieldsValue(submission)

            setAssignmentSettings(settings)
            setSubmission(submission)
        }
        
    }, [activityFormData])

    /**
     * 
     * @param statusType 
     * @returns 
     */
    const getStatusTag = (statusType: 'VALUE' | 'TAG' = 'TAG') => {
        let status: string = submission?.status
        let color = ''
        let messageId = ''

        switch (status) {
            case 'SUBMITTED':
                color = 'blue'
                messageId = 'courses.topic_status.pending_grading'
                break;
            case 'PASSED':
                color = 'green'
                messageId = 'courses.topic_status.passed'
                break;
            case 'FAILED':
                color = 'red'
                messageId = 'courses.topic_status.failed'
                break;
            case 'MISSED_DEADLINE':
                color = 'red'
                messageId = 'courses.topic_status.missed_deadline'
                break;
            case 'RETURNED':
                color = 'red'
                messageId = 'courses.topic_status.submission_returned'
                break;
            default:
                color = 'gold'
                messageId = 'courses.topic_status.not_submitted'
                break;
        }

        if (statusType === 'TAG') {
            return (
                <Tag color={color}>
                    <FormattedMessage id={messageId} />
                </Tag>
            )
        } else if (statusType === 'VALUE') {
            return status;
        }
    }

    const submitForm = async (values: any, returnSubmission: boolean = false) => {
        const formValues = form.getFieldsValue();
        const parsedValues = {
            grade: formValues?.grade,
            grade_comments: formValues?.grade_comments,
            is_return_submission: returnSubmission
        }

        try {
            setIsSubmitLoading(true);
            let response = await apiClient.request(`/api/v1/courses/grading/grade-work/${activityFormData.programActivityAttempt.id}`, parsedValues, 'POST');
            message.success(intl.formatMessage({id: "courses.evaluation_submitted_success"}));
            setIsSubmitLoading(false);
            onGraded()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false);
        }
    };
    
    const getModalButtons = (canGrade: boolean) => {
        let buttons: any = [<Button key="cancel" onClick={() => onCancel()}><FormattedMessage id='general.cancel' /></Button>];

        if( canGrade ) {
            buttons = [...buttons, [
                <Button key="return_submission" onClick={() => submitForm(null, true)} type='primary' danger ghost disabled={isSubmitLoading || isLoading}><FormattedMessage id='grading.return_submission' /></Button>,
                <Button key="grade" onClick={() => form.submit()} type='primary' disabled={isSubmitLoading || isLoading}><FormattedMessage id='grading.grade' /></Button>
            ]]
        }

        return buttons;
    }

    return (
        <Form form={form} onFinish={submitForm}>
            <Modal
                destroyOnClose={true}
                width={1000}
                className="grade-modal"
                title={intl.formatMessage({id: 'grading.grading'})}
                open={open}
                onOk={() => {
                    form.submit();
                }}
                onCancel={onCancel}
                okText={intl.formatMessage({id: 'grading.grade'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
                footer={getModalButtons(canGrade)}
            >
                <Spinner spinning={isLoading || isSubmitLoading}>
                    {activityFormData && <div className='assignment-submission'>
                        <div className='grading-detail-section'>
                            <div className='grading-detail-title-bar'>
                                <div className='grading-detail-title flex'>
                                    <h3><FormattedMessage id='general.submitted_files' /></h3>
                                </div>
                            </div>
                            <div className='resource-container'>
                                {submittedFiles && !!submittedFiles.length ? <FileTableExtended 
                                    key={'grading_detail_materials'} 
                                    fileArray={submittedFiles} 
                                    showMenu={false} 
                                    allowDelete={false} 
                                    showLabels={false}
                                    dateBelowTitle={true}
                                    cols={['title', 'date', 'type', 'size']}
                                    urls={{
                                        download: `/api/v1/courses/assignment-results/storage/{path}`,
                                    }}
                                    theme='with-bg'
                                /> : <Empty
                                    description={intl.formatMessage({id: 'general.no_uploads'})}
                                />}
                            </div>
                        </div>
                        <div className='grading-detail-section'>
                            <div className='grading-detail-title-bar'>
                                <div className='grading-detail-title flex'>
                                    <h3><FormattedMessage id='general.comments' /></h3>
                                </div>
                            </div>
                            <div className='resource-container'>
                                <Form.Item name='comments' >
                                    <TextArea disabled={true} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='grading-detail-section'>
                            <div className='grading-detail-title-bar'>
                                <div className='flex align-center flex-wrap'>
                                    <div>
                                        <div className='grading-detail-title flex flex-wrap'>
                                            <h3><FormattedMessage id='courses.submission_status' /></h3>
                                            <span className='status-bar-value'>
                                                {getStatusTag()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SubmissionDetail 
                                submission={submission} 
                                canGrade={canGrade}
                                evaluationType={assignmentSettings?.evaluation_type}
                            />
                        </div>
                    </div>}
                </Spinner>
            </Modal>
        </Form>
    )
};

export default AssignmentSubmission;
