import React, {useEffect, useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {Select} from 'components/Form';
import {FileProperties} from 'ui';
import coursesApiClient from 'utils/coursesApiClient';
import {Upload, Row, Col, Button, message, FormInstance, Form, Slider, InputNumber, Tooltip} from 'antd';
import Spinner from 'components/Spinner';
import {Input, TextArea} from "components/Form";

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

interface EmbeddedDocFormInterface {
    versionList: any[];
    reloadVersions: Function;
    versionsLoading: boolean;
    handleVersionSelect: Function;
    parseSrc: Function;
    embeddedSrc: string;
    courseId: number;
}

const EmbeddedDocForm: React.FC<EmbeddedDocFormInterface> = ({
                                                                 versionList,
                                                                 reloadVersions,
                                                                 versionsLoading,
                                                                 handleVersionSelect,
                                                                 parseSrc,
                                                                 embeddedSrc,
                                                                 courseId
                                                             }) => {

    const intl = useIntl();

    return (
        <Spinner spinning={false} opaqueH>
            <TextArea
                customLayout={formItemLayout}
                name="src"
                rows={3}
                label={intl.formatMessage({id: 'courses.embede_doc_src'})}
                validation={{required: true}}
                onChange={(el: any) => parseSrc(el.target.value)}
            />
            <Row>
                <Col offset={formItemLayout.labelCol.span}>
                    <div className={'bg-gray p-1 base-border-radius mb-5'}>
                        {embeddedSrc}
                    </div>
                </Col>
            </Row>

            <Input
                customLayout={formItemLayout}
                name='hyperlinkToFile'
                label={intl.formatMessage({id: 'courses.hyperlink_to_file'})}
            />
            <Select
                loading={versionsLoading}
                customLayout={formItemLayout}
                label={intl.formatMessage({id: "general.version"})}
                name='version'
                manualOptions={versionList}
                onChange={(_value: any, options: any) => handleVersionSelect(options.children)}
            />
            <Form.Item {...formItemLayout} name="secondsOnFile" label={intl.formatMessage({ id: "courses.min_seconds_on_file" })}>
                <InputNumber
                    min={0}
                />
            </Form.Item>
        </Spinner>
    )
}
export default EmbeddedDocForm;
