import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {message, Popover, Button, Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import {PlusOutlined} from '@ant-design/icons';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import {Table} from 'ui';
import { useLocaleContext } from 'context/LocaleContext';

const StandardTemplates: React.FC<any> = ({}) => {
    const { locale } = useLocaleContext();
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState('');

    const intl = useIntl();

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        setVisibleTooltip(visible ? recordId : null);
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'emailing.templates.template_name'}),
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => {
                const translations = record.translations;
                let titleObject = translations.filter((el: any) => el.language === locale)[0];

                if (!titleObject) {
                    titleObject = translations[Object.keys(translations)[0]]
                }

                return (
                    <NavLink to={`/e-mailing/manage-templates/${record.id}/edit`}>
                        {titleObject.title}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'emailing.templates.template_type'}),
            render: (text: string, record: any) => intl.formatMessage({id: `email_templates.template_type.${record.templateType}`})
        },
        {
            title: intl.formatMessage({id: 'emailing.templates.template_category'}),
            render: (text: string, record: any) => intl.formatMessage({id: `email_templates.categories.${record.categoryKey}`})
        },
        // {
        //     title: intl.formatMessage({id: 'general.language'}),
        //     dataIndex: 'language',
        //     key: 'language',
        //     render: (text: string, record: any) => {
        //         return (
        //             <div>
        //                 {
        //                     record.html_template ?
        //                         <Radio.Group>
        //                             {
        //                                 Object.entries(record.html_template).map((el: any, index: any) => {
        //                                     return (
        //                                         <>
        //                                             {el ?
        //                                                 <Radio.Button style={{textTransform: 'uppercase'}} value={el[0]}
        //                                                               onClick={() => {
        //                                                                   if (record.html_template) {
        //                                                                       setLanguageEditRecord(record.html_template[el[0]]);
        //                                                                   } else {
        //                                                                       let htmlString = JSON.parse(record.html);
        //                                                                       setLanguageEditRecord(htmlString[el[0]]);
        //                                                                   }
        //                                                                   setTemplateSubject(record.subject[el[0]])
        //                                                                   setSelecteTemplate(record);
        //                                                                   setSelectedLanguageKey(el[0]);
        //                                                                   setShowEditModal(true)
        //                                                               }}
        //                                                 >
        //                                                     {el[0]}
        //                                                 </Radio.Button>
        //                                                 : null
        //                                             }
        //                                         </>
        //                                     )
        //                                 })
        //                             }
        //                         </Radio.Group>
        //                         : null
        //                 }
        //             </div>
        //         )
        //     }
        // },
        {
            key: 'actions',
            render: (record: any) => {
                const content = (
                    <>
                        <NavLink to={`/e-mailing/manage-templates/${record.id}/edit`}>
                            <div className="popover-item">
                                <FormattedMessage id="general.edit"/>
                            </div>
                        </NavLink>
                        <div onClick={() => {
                            confirmTemplateDeletion(record.id);
                            setVisibleTooltip(null)
                        }} className="popover-item">
                            <FormattedMessage id="general.delete"/>
                        </div>
                    </>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible: boolean) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                );
            },
        },
    ];

    const confirmTemplateDeletion = (id: number) => {
        Modal.confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            icon: <ExclamationCircleOutlined/>,
            content: intl.formatMessage({id: 'emailing.templates.begin_deletion_org'}),
            okText: intl.formatMessage({id: 'general.delete'}),
            okType: 'danger',
            cancelText: intl.formatMessage({id: 'general.cancel'}),
            onOk() {
                return deleteTemplate(id);
            }
        })
    };

    const deleteTemplate = async (id: number) => {
        try {
            await apiClient.request(`/api/v1/mail-templates/${id}/delete`, {}, 'DELETE');
        } catch (error: any) {
            message.error(error);
            console.error(error);
        }
    };

    return (
        <DefaultLayout.PageContent style={{borderTopLeftRadius: 0}}>
            <NavLink to="/e-mailing/manage-templates/add">
                <Button type="primary">
                    <PlusOutlined/> <FormattedMessage id="general.add"/>
                </Button>
            </NavLink>
            <Table
                columns={columns}
                url='/api/v1/mail-templates'
                setReload={setReload}
                reload={reload}
                rowKey='id'
                search={search}
            />
        </DefaultLayout.PageContent>
    );
}

export default StandardTemplates;