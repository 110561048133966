import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import DefaultLayout from "components/DefaultLayout";
import { Tabs } from 'antd';
import { AccessRightsTab, CompetenciesTab, EventFeedTab, LearningTab, SettingsTab, UsersTab, NotificationsTab } from 'components/Audiences/AudienceTabs';
import { useParams, useNavigate } from 'react-router-dom';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';


const Audience: React.FC = () => {
    const intl = useIntl();
    const { TabPane } = Tabs;
    const params = useParams();
    const audienceId = params.id;
    const tab = params.tab;
    const [audience, setAudience] = useState<any>();
    const [isLoading, setIsLoading] = useState(!!audienceId);
    const [reload, setReload] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState('settings')
    const navigate = useNavigate();

    useEffect(() => {
        if (tab) {
            setActiveTabKey(tab)
        }
    }, [])

    useEffect(() => {
        if (audienceId) {
            loadAudience()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audienceId, reload])

    const loadAudience = async () => {
        setIsLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/audiences/audience/${audienceId}`, [], 'GET');
            if (!response.audience) {
                navigate('/audiences');
            }
            const audience = response.audience;
            audience.isEditable = () => !!audience.actions?.edit;
            setAudience(response.audience)
        } catch (error) {
            navigate('/audiences');
        } finally {
            setIsLoading(false);
        }
    }

    const reloadAudience = () => {
        setReload(!reload);
    }

    const tabProps = {
        audience,
        reloadAudience,
    }

    const tabChangeHandle = (tab: string) => {
        setActiveTabKey(tab)
        navigate(`/audiences/${audienceId}/${tab}`)
    }

    return (
        <>
            <DefaultLayout.PageLayout style={{ marginBottom: 64 }}>
                <DefaultLayout.PageHeader
                    title={audience ? audience.name : intl.formatMessage({ id: 'audience.audience' })}
                    breadcrumb={[{
                        name: intl.formatMessage({ id: 'audience.audience' }),
                        path: '/audiences'
                    }]}
                />
                <DefaultLayout.PageContent >
                    <Spinner spinning={isLoading} >
                        {audience && <Tabs destroyInactiveTabPane onChange={tabChangeHandle} activeKey={activeTabKey}>
                            <TabPane tab={intl.formatMessage({ id: 'audience.settings' })} key='settings'>
                                <SettingsTab {...tabProps} />
                            </TabPane>
                            <TabPane tab={intl.formatMessage({ id: 'general.users' })} key='users'>
                                <UsersTab {...tabProps}/>
                            </TabPane>
                            <TabPane tab={intl.formatMessage({ id: 'audience.learning' })} key='learning'>
                                <LearningTab {...tabProps} />
                            </TabPane>
                            <TabPane tab={intl.formatMessage({ id: 'audience.competencies' })} key='competencies'>
                                <CompetenciesTab {...tabProps} />
                            </TabPane>
                            <TabPane tab={intl.formatMessage({ id: 'audience.access_rights' })} key='access-rights'>
                                <AccessRightsTab {...tabProps} />
                            </TabPane>
                            <TabPane tab={intl.formatMessage({ id: 'general.notifications' })} key='notifications'>
                                <NotificationsTab {...tabProps} />
                            </TabPane>
                            <TabPane tab={intl.formatMessage({ id: 'audience.event_feed' })} key='event-feed'>
                                <EventFeedTab {...tabProps} />
                            </TabPane>
                        </Tabs>}
                    </Spinner>
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>
        </>
    );
};

export default Audience;