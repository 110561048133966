import { AudienceTabProps, AudienceNotificationProps, AudienceNotificationScheduleCron } from 'components/Audiences/types';
import { MailScenarioStatusType } from 'pages/Emailing/types';
import { Table, Search, Button } from "ui";
import { Switch } from 'components/Form';
import FlexRow from "components/FlexRow";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { List, Modal, Space } from 'antd';
import apiClient from 'utils/apiClient';
import useHandleError from 'utils/useHandleError';
import AudienceNotificationCreationModal from 'components/Audiences/Modals/AudienceNotificationCreationModal';
import { useLocaleContext } from 'context/LocaleContext';
import './styles.scss'

const NotificationsTab: React.FC<AudienceTabProps> = ({ audience, reloadAudience }) => {
    const { locale } = useLocaleContext();
    
    const [reload, setReload] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [scenarioData, setScenarioData] = useState<AudienceNotificationProps | null>(null);
    const [templateOptions, setTemplateOptions] = useState<any>({});

    const intl = useIntl();
    const [handleError] = useHandleError();
    const { confirm } = Modal;

    useEffect(() => {
        const loadTemplateOptions = async () => {
            setIsLoading(true);
            try {
                const response = await apiClient.request(`api/v1/audiences/options/${audience.id}/mail-templates`, [], 'GET');
                if ("error" in response) {
                    throw response
                };

                if (response.options) {
                    setTemplateOptions(response.options);
                }
            } catch (error) {
                handleError(error);
            } finally {
                setIsLoading(false);
            }
        }


        loadTemplateOptions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audience])

    const updateScenario = async (scenarioId: number, postData: any) => {
        setIsLoading(true)
        try {
            const response = await apiClient.request(`api/v1/audiences/${audience.id}/scenarios/${scenarioId}`, postData, 'PUT');
            if ("error" in response) {
                throw response
            };

            setReload(true);
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const warnDeletion = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, record: any) => {
        event.preventDefault();

        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: `${intl.formatMessage({ id: 'general.delete' })} ?`,
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                removeScenario(record.scenarioId);
            }
        });
    }

    const removeScenario = async (scenarioId: number) => {
        setIsLoading(true)
        try {
            const response = await apiClient.request(`api/v1/audiences/${audience.id}/scenarios/${scenarioId}`, [], 'DELETE');
            if ("error" in response) {
                throw response
            };
            setReload(true)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const fetchScenario = async (scenarioId: number) => {
        setIsLoading(true);
        try {
            const response = await apiClient.request(`api/v1/audiences/${audience.id}/scenarios/${scenarioId}`, [], 'GET');
            if ("error" in response) {
                throw response
            };

            setScenarioData(response);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const openNotificationModal = async (scenarioId: number | null = null) => {
        if (scenarioId) {
            await fetchScenario(scenarioId);
        } else {
            setScenarioData(null);
        }

        setShowCreateModal(true);
    }

    const columns = [
        {
            key: 'title',
            title: intl.formatMessage({ id: 'emailing.templates.template_name' }),
            render: (record: any) => {
                const translation = locale && record.translations && record.translations.length
                    ? record.translations.filter((translation: any) => translation.language === locale)
                    : null;
                return translation.length ? translation[0].title : ''
            }
        },
        {
            key: 'cron_expression',
            title: intl.formatMessage({ id: 'general.schedule' }),
            render: (record: any) => {
                switch (record.cronExpression) {
                    case AudienceNotificationScheduleCron.Daily:
                        return intl.formatMessage({ id: 'emailing.scenarios.daily' });
                    case AudienceNotificationScheduleCron.Weekly:
                        return intl.formatMessage({ id: 'emailing.scenarios.weekly' });
                    case AudienceNotificationScheduleCron.Monthly:
                        return intl.formatMessage({ id: 'emailing.scenarios.monthly' });
                }
            }
        },
        {
            key: 'description',
            title: intl.formatMessage({ id: 'general.description' }),
            render: (record: any) => record.description
        },
        {
            key: 'status',
            title: intl.formatMessage({ id: 'emailing.notifications.allow_notifications' }),
            render: (_text: string, record: any) => {
                return (
                    <Switch
                        checked={record.status === MailScenarioStatusType.Active}
                        loading={isLoading}
                        onClick={() => updateScenario(record.scenarioId, {
                            status: record.status === MailScenarioStatusType.Active ? MailScenarioStatusType.Inactive : MailScenarioStatusType.Active
                        })}
                        disabled={!audience.isEditable()}
                    />
                )
            }
        },
        {
            key: 'actions',
            title: intl.formatMessage({ id: 'general.actions' }),
            render: (_text: string, record: any) => {
                return (<Space>
                    {!!Object.keys(templateOptions).length && <NavLink onClick={event => openNotificationModal(record.scenarioId)} to="#">
                        {intl.formatMessage({ id: 'general.edit' })}
                    </NavLink>}
                    <NavLink onClick={event => warnDeletion(event, record)} to="#">
                        {intl.formatMessage({ id: 'general.delete' })}
                    </NavLink>
                </Space>)
            },
        }
    ];

    const getTableColumns = () => {
        if (!audience.isEditable()) {
            return columns.filter((column: any) => column.key !== 'actions')
        }

        return columns;
    }

    const handleNotificationModalSubmit = () => {
        setShowCreateModal(false);
        setReload(!reload);
    }

    return (<>
        <List className='audience-notification-header-list'
            header={<FormattedMessage id='general.notifications' />}
            bordered
            dataSource={[
                <FormattedMessage id='audience.set_audience_notifications' />,
            ]}
            renderItem={(item) => <>{item}</>}
        />
        <FlexRow
            left={audience.isEditable() && !!Object.keys(templateOptions).length ? <Button type='primary' onClick={() => openNotificationModal()}>
                <FormattedMessage id='emailing.notifications.add_new_notification' />
            </Button> : undefined
            }
            right={<Search setSearchString={setSearchString} setReload={setReload} />}
        />
        <Table
            columns={getTableColumns()}
            url={`/api/v1/audiences/${audience.id}/scenarios`}
            setReload={setReload}
            reload={reload}
            rowSelection={false}
            rowKey={'scenarioId'}
            search={searchString}
            isLoading={isLoading}
        />
        {showCreateModal && <AudienceNotificationCreationModal
            visible={showCreateModal}
            audienceId={audience.id}
            scenarioData={scenarioData}
            templateOptions={templateOptions}
            onCancel={() => setShowCreateModal(false)}
            onSubmit={() => handleNotificationModalSubmit()}
        />}
    </>);
};

export default NotificationsTab;