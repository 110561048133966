import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Input, Space } from "antd";
import { Button } from "ui";

interface InputEditInterface {
    submit: Function;
    label?: string;
    labelId?: number;
    className: string;
}

const InputEdit: React.FC<InputEditInterface> = ({
    submit,
    label,
    labelId,
    className,
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [newLabel, setNewLabel] = useState(label);

    const handleSubmit = () => {
        setIsEdit(false);
        submit(newLabel, labelId);
    }

    return (
        <>
            {!isEdit ?
                <>
                    {labelId ?
                        <div className={'mw-30'}>
                            <div className={'pointer ' + className} onClick={() => setIsEdit(true)}>
                                {label ? label : <FormattedMessage id={'general.add'} />}
                            </div>
                        </div>
                        :
                        <Button type={'dashed'} onClick={() => setIsEdit(true)}>
                            <FormattedMessage id={'general.add'} />
                        </Button>
                    }
                </>
                :
                <>
                    <Space size={8}>
                        <Input
                            onChange={(e) => setNewLabel(e.target.value)}
                            defaultValue={label}
                        />
                        <Space size={10}>
                            <i onClick={() => setIsEdit(false)} className='f-16 pointer fa-regular fa-xmark' />
                            <i onClick={handleSubmit} className='f-16 pointer fa-regular fa-check' />
                        </Space>
                    </Space>
                </>
            }
        </>
    )
};

export default InputEdit;