import React, { useState } from 'react';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
    return {
        languageDictionary: state.languageDictionary,
    };
};

type Dictionary = {
    [key: string]: any;
};

interface LanguageSwitchProps {
    languageDictionary: Dictionary;
    isMobile: boolean;
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ languageDictionary, isMobile }) => {
    const { locale, setLocale } = useLocaleContext();

    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (language: string) => {
        setLocale(language);
        setIsOpen(false);
    };

    const PopoverContent = () => {
        return (
            <>
                {Object.entries(languageDictionary).map(([key]: [string, any]) => {
                    if (!['loading', 'failed'].includes(key)) {
                        return (
                            <div
                                key={`c${key}`}
                                className="select-item"
                                style={{ paddingRight: 10, paddingLeft: 10 }}
                                onClick={() => handleSelect(key)}
                            >
                                {key}
                            </div>
                        );
                    }
                    return null;
                })}
            </>
        );
    };

    return (
        <div className={isMobile ? 'session-switch-item-mobile' : 'session-switch-item'}>
            <Popover
                placement="bottom"
                content={<PopoverContent />}
                overlayClassName="session-switch-popover"
                arrowContent={false}
                open={isOpen}
                trigger="click"
                onOpenChange={() => setIsOpen(!isOpen)}
            >
                <div className="role-switch">
                    <i className="fal fa-globe with-accent" />
                    {<span className="display-language-name">{locale}</span>}
                    <i className="fal fa-chevron-down" style={{ fontSize: 10 }} />
                </div>
            </Popover>
        </div>
    );
};

export default connect(mapStateToProps)(LanguageSwitch);
