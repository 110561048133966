import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { Form, Spin, Table, Button, Switch, Alert, Tooltip, Tag, message, Input as AntDInput } from 'antd';
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {config} from 'config/config';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import LicenceCreateModal from 'components/Licences/LicenceCreateModal';
import LicenceFilterModal from 'components/Licences/LicenceFilterModal';
import LicenceExportModal from 'components/Licences/LicenceExportModal';
import LicenceFilterBar from 'components/Licences/LicenceFilterBar';
import apiClient from 'utils/apiClient';
import useHandleError from 'utils/useHandleError';
import './styles.scss';
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    licenceFilter: state.licenceFilter,
  }
}

const { Search } = AntDInput;

const LicenceList: React.FC = ({session, licenceFilter}: any) => {
  const { locale } = useLocaleContext();
  const [userRole, setUserRole] = useState<String|null>(null);
  const [customers, setCustomers] = useState<[]|null>(null);
  const [organizations, setOrganizations] = useState<[]|null>(null);
  const [customersLoading, setCustomersLoading] = useState<boolean>(false);
  const [licences, setLicences] = useState<[]>([]);
  const [customer, setCustomer] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [responsibleAdmins, setResponsibleAdmins] = useState<[]>([]);
  const [responsibleOrganizations, setResponsibleOrganizations] = useState<[]>([]);
  const [filteredLicences, setFilteredLicences] = useState<[]>([]);
  const [validLicences, setValidLicences] = useState<[]>([]);
  const [licencesLoading, setLicencesLoading] = useState<boolean>(false);
  const [showAddNewLicence, setShowAddNewLicence] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filteredCustomerName, setFilteredCustomerName] = useState<String|null>(null);
  const [searchString, setSearchString] = useState<string>('');
  const [validOnly, setValidOnly] = useState<boolean>(false);
  const [licenceConsumption, setLicenceConsumption] = useState<[]>([]);
  const [showExportLicence, setExportLicence] = useState<boolean>(false);
  const [licenseActivities, setLicenseActivities] = useState<any[]>([]);
  const navigate = useNavigate();

  const intl = useIntl();
  const [newLicenceForm] = Form.useForm();
  const [handleError] = useHandleError();

  useEffect(() => setUserRole(session.active_user_type)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  useEffect(() => {
    if (userRole === 'SUPER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN') {
      loadCustomers();
      loadOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  const loadOrganizations = async () => {
    try {
      setCustomersLoading(true);

      const response = await apiClient.request(config.api.routes.backend.selectOrganizations, {}, 'GET');

      setOrganizations(response.organizations);
    } catch(error) {
      handleError(error)
    } finally {
      setCustomersLoading(false);
    }
  }

  const loadCustomers = async () => {
    try {
      setCustomersLoading(true);

      const response = await apiClient.request(config.api.routes.backend.selectCustomers, {}, 'GET');


      if (!licenceFilter.customerId) {
        setShowFilter(true);
      }

      setCustomers(response.customers);
    } catch(error) {
      handleError(error)
    } finally {
      setCustomersLoading(false);
    }
  }

  const showTotal = (total: number, range: number[]) => `${range[0]}-${range[1]} of ${total}`;

  const handleSwitchChange = async (checked: boolean, licenceId: number, serviceId: any, hasPivot: boolean) => {
    try {
      if (!serviceId) {
        await apiClient.request(`/api/v1/licences/${licenceId}/set-validity`, { active: checked }, 'PUT');

        // Clone (and get rid of references to) state so that its not modified directly
        const currLicences: any = JSON.parse(JSON.stringify(licences));

        const licenceIndex: number = currLicences.findIndex((licence: any) => licence.id === licenceId);

        currLicences[licenceIndex].active = checked;

        const currServices = currLicences[licenceIndex].children;

        const currServicesUpdate = (services: any) => {
            return services.map((service: any) => {
                let temp = Object.assign({}, service);

                if (temp.pivot) {
                  temp.pivot.active = checked;
                } else {
                  temp.active = checked;
                }

                return temp;
            });
        };

        currLicences[licenceIndex].services = currServicesUpdate(currServices);
        currLicences[licenceIndex].children = currServicesUpdate(currServices);

        setLicences(currLicences);
        setFilteredLicences(searchTable());
      } else {
        await apiClient.request(`/api/v1/licences/${licenceId}/services/${serviceId}/set-validity`, { active: checked }, 'PUT');

        // Clone (and get rid of references to) state so that it's not edited directly
        const currLicences: any = JSON.parse(JSON.stringify(licences));
        const licenceIndex: number = currLicences.findIndex((licence: any) => licence.id === licenceId);

        const currServices = currLicences[licenceIndex].children;

        if (hasPivot) {
          const serviceIndex = currServices.findIndex((service: any) => service.pivot.id === serviceId);
          currServices[serviceIndex].pivot.active = checked;
        } else {
          const serviceIndex = currServices.findIndex((service: any) => service.id === serviceId);
          currServices[serviceIndex].active = checked;
        }

        setLicences(currLicences);
        setFilteredLicences(searchTable());
      }
    } catch (error) {
      message.error(intl.formatMessage({id: 'error.data_load'}));
      console.error(error);
    }
  };

  const handleDeleteButtonClick = async (record: any) => {
    try {
      await apiClient.request(`/api/v1/licences/${record.id}`, {}, 'DELETE');

      // Clone (and get rid of references to) state so that its not modified directly
      const currLicences: any = JSON.parse(JSON.stringify(licences));

      const licencesWithoutDeleted = currLicences.filter((licence: any) => !(licence.id === record.id));

      setLicences(licencesWithoutDeleted);
      setFilteredLicences(searchTable());
    } catch (error) {
      message.error(intl.formatMessage({id: 'error.data_load'}));
      console.error(error);
    }
  }

  const columns = [
    {
      title: intl.formatMessage({id: 'licensing.licence_id'}),
      dataIndex: 'licence_code',
      render: (_value: string, record: any) => record.isLicence ? record.no : null
    },
    {
      title: intl.formatMessage({id: 'licensing.licence_type'}),
      dataIndex: 'licence_type',
      render: (_value: string, record: any) => {
        if (!record.isLicence) return null;
        if (!record.children) return <Tag>{intl.formatMessage({id: 'licensing.empty'})}</Tag>;

        if (record.isPlatformAccessLicence) {
          return <Tag color="purple">{intl.formatMessage({id: 'services.platform_access'})}</Tag>
        } else if (record.isLearningLicence) {
          return <Tag color="green">{intl.formatMessage({id: 'services.e_learning_content'})}</Tag>
        } else {
          return <Tag color="gold">{intl.formatMessage({id: 'services.feature'})}</Tag>
        }
      }
    },
    {
      title: intl.formatMessage({id: 'general.title'}),
      dataIndex: 'service_name',
      render: (_value: string, record: any) => record.name
    },
    {
      title: intl.formatMessage({id: 'licensing.e_learning_content'}),
      dataIndex: 'e_learning_content',
      width: 250,
      render: (_value: string, record: any) => {
        if (record.type && record.type === 'E_LEARNING_CONTENT') {
          let activities: any = [];
          if (!record.isLicence) {
            let settings = JSON.parse(record.pivot.settings);

            settings.learning_activities.forEach((el: number) => {
                const title = licenseActivities[el];
                activities.push(title[locale] ? title[locale] : title[Object.keys(title)[0]])
            });

            return activities.map((el: any) => el + '; ');

          } else {
            return null;
          }
        }
      }
    },
    {
        title: intl.formatMessage({id: 'licensing.current_access'}),
        dataIndex: 'service_code',
        align: 'center' as 'center',
        render: (_value: string, record: any) => {
            if (!record.isLicence && record.type === "E_LEARNING_CONTENT") {
              if (licenceConsumption[record.pivot.id]['maxUsers'] === 'infinite') {
                return (
                  <Tooltip title={intl.formatMessage({id: 'licensing.infinite_licence'})}>
                  <span>
                  {licenceConsumption[record.pivot.id]['consumed']}/<i className="fas fa-infinity"></i>
                  </span>
                </Tooltip>
                )
              } else {
                return <span>{licenceConsumption[record.pivot.id]['consumed']}/{licenceConsumption[record.pivot.id]['maxUsers']}</span>
              }
            } else if (!record.isLicence && record.type === "PLATFORM_ACCESS") {
                return <span>{record.consumed}/{record.maxUsers}</span>
            }
        }
    },
    {
      title: intl.formatMessage({id: 'licensing.licence_created'}),
      key: 'licence_created',
      render: (_value: string, record: any) => record.isLicence ? moment(record.createdAt).format('DD/MM/YYYY') : null,
    },
    {
      title: intl.formatMessage({id: 'licensing.valid_from'}),
      key: 'valid_from',
      render: (_value: string, record: any) => !record.isLicence ?  moment(record.pivot ? record.pivot.begin_date : record.validFrom).format('DD/MM/YYYY') : null,
    },
    {
      title: intl.formatMessage({id: 'licensing.valid_until'}),
      key: 'valid_until',
      render: (_value: string, record: any) => {
        if (!record.isLicence) {
          let output: string = 'N/A';
          let className: string = '';

          if (record.validTo || record.pivot?.endDate) {
            const date = moment(record.pivot ? record.pivot.endDate : record.validTo);
            const diff = date.diff(moment(), 'days');

            if (diff < 0) {
              className = 'date-passed';
            } else if (diff <= 60) {
              className = 'date-incoming';
            }

            output = date.format('DD/MM/YYYY');
          }

          return <span className={className}>{output}</span>;
        }

        return null;
      }
    },
    {
      title: intl.formatMessage({id: 'licensing.validity'}),
      key: 'validity',
      render: (_value: string, record: any) => {
        let licenceId: number;

        if (record.isLicence) {
          licenceId = record.id;
        } else {
          if (record.pivot) {
            licenceId = record.pivot.licenceId;
          } else {
            licenceId = record.licenceId;
          }
        }

        let serviceId: any = null;
        let hasPivot = false;

        if (!record.isLicence) {
          if (record.pivot) {
            hasPivot = true;
            serviceId = record.pivot.id;
          } else {
            serviceId = record.id;
          }
        }

        return <Switch
          disabled={userRole === 'CUSTOMER_ADMIN'}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={(record.isLicence || !record.pivot) ? record.active : record.pivot.active}
          onChange={(e) => handleSwitchChange(e, licenceId, serviceId, hasPivot)} />
      }
    },
    {
      title: intl.formatMessage({id: 'general.reseller'}),
      key: 'reseller',
      render: (_value: string, record: any) => formatOrganizationName(record)
    },
    {
      key: 'actions',
      render: (_value: string, record: any) => {
        if (!record.isLicence) {
          return null;
        }

        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            <NavLink to={`/licensing/manage-${record.isLicence ? 'licences' : 'services'}/${record.id}/edit`}>
                {userRole === 'CUSTOMER_ADMIN' ?
                  <FormattedMessage id="general.view"/>
                  :
                  <FormattedMessage id="general.edit"/>
                }
            </NavLink>
              {userRole !== 'CUSTOMER_ADMIN' &&
                <div>
                    {!record.children
                        ? <Button
                            type="link"
                            onClick={(_e: any) => handleDeleteButtonClick(record)}
                        >
                            <FormattedMessage id="general.delete"/>
                        </Button>
                        : null}
                </div>
              }
          </span>
        );
      }
    }
  ];

  const formatOrganizationName = (record: any) => {
    if (!record.isLicence) {
      const organizationId = record.pivot ? record.pivot.responsibleOrganizationId : record.responsibleOrganizationId;

      if (!responsibleOrganizations) {
          return;
      } else {
          return responsibleOrganizations[organizationId];
      }
    }

    return null;
  }

  const clearFilter = () => {
    setFilteredCustomerName(null);
    setLicences([]);
    setResponsibleAdmins([]);
    setResponsibleOrganizations([]);
    setShowFilter(true);
  }

  useEffect(() => {
    if (validOnly) {
      setValidLicences(getValidLicences());
    } else {
      if (userRole === 'CUSTOMER_ADMIN') {
          setValidLicences(getValidLicences());
      }
      setFilteredLicences(searchTable());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licences]);

  const searchTable = (): any => {
    const searchableColumns = ['no'];

    if (searchString) {
      const filterableLicences = validOnly ? validLicences : licences;

      return filterableLicences.filter((licence: any) => {
        return Object.keys(licence).some(
          key => searchableColumns.includes(key)
            ? licence[key].toString().toLowerCase().includes(searchString.toLocaleLowerCase())
            : false
        );
      });
    }
    return validOnly ? validLicences : licences;
  };

  useEffect(() => {
    setFilteredLicences(searchTable());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, validOnly, validLicences]);

  const getValidLicences = () => {
    // Clone (and get rid of references to) state so that it's not edited directly
    const currLicences: any = JSON.parse(JSON.stringify(licences));

    let activeLicences: any = currLicences.filter((licence: any) => {
      return licence.active;
    });

    const activeLicencesWithActiveServices: any = [];

    activeLicences.map((licence: any) => {
      if (licence.children) {
        licence.children = licence.children.filter((service: any) => {
          if (service.pivot) {
            if (!service.pivot.active || moment().diff(service.pivot.endDate , 'days') >= 0) {
              return false;
            }
          } else {
            if (!service.active) {
              return false;
            }
            if (service.validTo) {
              if (moment().diff(service.validTo , 'days') >= 0) {
                return false;
              }
            }
          }
          return true;
        });
      }
      activeLicencesWithActiveServices.push(licence);
      return activeLicencesWithActiveServices;
    });

    activeLicences = activeLicencesWithActiveServices.filter((licence: any) => {
      if (!licence.children) {
        return false;
      }

      return !!licence.children.length;
    });

    return activeLicences;
  }

  const handleValidOnlySwitchChange = (checked: boolean) => {
    if (checked) {
      setValidOnly(true);
      setValidLicences(getValidLicences());
      setFilteredLicences(searchTable());
    } else {
      setValidOnly(false);
      setFilteredLicences(searchTable());
    }
  }

  const createNewLicence = async () => {
    const response: any = await apiClient.request(`/api/v1/licences`, { customerId: licenceFilter.customerId }, 'POST');
    navigate(`/licensing/manage-licences/${response.id}/edit`)
  }

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({id: 'licensing.manage_licences'})}/>
      <Spin
        spinning={userRole === 'CUSTOMER_ADMIN' ? false : !(!!customers)}
        wrapperClassName={!customers ? 'spinner-with-white-background': ''}
      >
        <DefaultLayout.PageContent>
        <div className='license-toolbar-container'>
            <div >
              {userRole !== 'CUSTOMER_ADMIN' &&
                <div>
                  <Button
                    className="primary-button license-button"
                    type="primary"
                    onClick={() => { licenceFilter.customerId ? createNewLicence() : setShowAddNewLicence(true) }}
                  >
                    <PlusOutlined /> <FormattedMessage id="licensing.add_new_licence" />
                  </Button>
                  <Button
                    className="primary-button license-button"
                    type="primary"
                    onClick={() => setShowFilter(true)}
                  >
                    <FormattedMessage id="licensing.filter_by_customer" />
                  </Button>
                  <Button
                    className="primary-button license-button"
                    type="primary"
                    onClick={() => setExportLicence(true)}
                  >
                    <FormattedMessage id="licensing.export_report" />
                  </Button>
                </div>
              }
            </div>
            <div>
            <Search
                  className="table-search-field"
                  placeholder={intl.formatMessage({id: 'general.search'})}
                  onChange={value => setSearchString(value.target.value)}
                  value={searchString}
              />
            {filteredCustomerName
              ? 
              <div className='valid-license-switch'>
                {intl.formatMessage({ id: 'licensing.valid_only' })}
                <Tooltip title={intl.formatMessage({ id: 'licensing.licence_valid_only_tooltip' })}>
                  <i className='fal fa-question-circle header-item' />
                </Tooltip>:{' '}
                <Switch checked={validOnly}
                  onChange={(checked: boolean) => handleValidOnlySwitchChange(checked)} />
              </div>
              : null
            }
            </div>
          </div>
          {filteredCustomerName && userRole !== 'CUSTOMER_ADMIN'
            ? <LicenceFilterBar
                filteredCustomerName={filteredCustomerName}
                clearFilter={clearFilter}
              />
            : null}
          {filteredCustomerName || userRole === 'CUSTOMER_ADMIN'
            ? <Table
                locale={{ emptyText: intl.formatMessage({id: "general.found_no_data"}) }}
                pagination={{
                  showTotal: showTotal,
                  pageSizeOptions: ['10', '20'],
                  showSizeChanger: true,
                  locale: { items_per_page: intl.formatMessage({id: "general.page"}), jump_to: intl.formatMessage({id: "general.go_to"}) },
                  showQuickJumper: true
                }}
                rowKey={(record) => record.licenseId}
                style={{marginTop: 16}}
                columns={columns}
                dataSource={filteredLicences}
                size="middle"
                loading={licencesLoading}
                scroll={{ x: 800 }}
              />
          : <Alert
              message={intl.formatMessage({id: 'validation.please_define_customer_filter'})}
              type="info"
              style={{marginTop: 20}}
              showIcon
            />}
        </DefaultLayout.PageContent>
      </Spin>
      <LicenceExportModal
          showExportLicence={showExportLicence}
          setExportLicence={setExportLicence}
          customers={customers}
          customersLoading={customersLoading}
      />
      <LicenceCreateModal
        showAddNewLicence={showAddNewLicence}
        setShowAddNewLicence={setShowAddNewLicence}
        customers={customers}
        customersLoading={customersLoading}
        customer={customer}
        form={newLicenceForm}
      />
      <LicenceFilterModal
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        customers={customers}
        organizations={organizations}
        customersLoading={customersLoading}
        setLicences={setLicences}
        setLicencesLoading={setLicencesLoading}
        setFilteredCustomerName={setFilteredCustomerName}
        setValidOnly={setValidOnly}
        setSearchString={setSearchString}
        setLicenceConsumption={setLicenceConsumption}
        setResponsibleAdmins={setResponsibleAdmins}
        admins={responsibleAdmins}
        setResponsibleOrganizations={setResponsibleOrganizations}
        setCustomer={setCustomer}
        newLicenceForm={newLicenceForm}
        setLicenseActivities={setLicenseActivities}
      />
    </DefaultLayout.PageLayout>
  );
}

export default connect(mapStateToProps)(LicenceList);
