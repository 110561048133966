import React, { useEffect } from 'react';
import { Form } from 'antd';
import { Modal } from 'ui';
import { useIntl } from 'react-intl';
import { Input, Select, Switch } from 'components/Form';
import useQueryApiClient from 'utils/useApiClient';
import { LanguageFormInterface } from '../types';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const LanguageForm: React.FC<LanguageFormInterface> = ({
    item,
    onCancel,
    reload
}) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        if (item?.id !== 'create') {
            form.setFieldsValue(item);
        } else {
            form.resetFields();
            form.setFieldValue('publish', false);
        }
    }, [item]);

    const { appendData, isLoading } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: (item?.id === 'create') ? '/api/v1/languages' : `/api/v1/languages/${item.id}`,
            method: (item?.id === 'create') ? 'POST' : 'PUT',
        },
        onSuccess: (): void => {
            onCancel({})
            form.resetFields();
            reload(true);
        }
    });

    return (
        <Modal
            open={!!item?.id}
            isLoading={isLoading}
            onCancel={() => onCancel({})}
            title={(item?.id === 'create') ? intl.formatMessage({ id: 'system.languages.add_language' }) : intl.formatMessage({ id: 'general.edit_language' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            okText={(item?.id === 'create') ? intl.formatMessage({ id: 'general.add' }) : intl.formatMessage({ id: 'general.save' })}
            onOk={form.submit}
            okButtonProps={{
                loading: isLoading,
            }}
        >
            <Form form={form} onFinish={appendData} {...formItemLayout}>
                <Select
                    allowSearch
                    customLayout
                    name='name'
                    label={intl.formatMessage({ id: 'general.name' })}
                    onChange={(val: string) => {
                        form.setFieldsValue({ code: val });
                    }}
                    url={`/api/v1/enum/language-types`}
                />
                <Input disabled={true} customLayout label={intl.formatMessage({ id: 'system.languages.code' })} name={'code'} />
                <Select
                    customLayout
                    name='languageFamily'
                    label={intl.formatMessage({ id: 'system.languages.language_family' })}
                    url={`/api/v1/enum/language-family-types`}
                />
                <Switch
                    name='publish'
                    hasDefaultLayout={false}
                    isFormItem
                    label={intl.formatMessage({ id: 'system.languages.published' })}
                />
            </Form>
        </Modal >
    );
};

export default LanguageForm;
