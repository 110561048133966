import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Form, Radio, Checkbox, Alert} from "antd";
import HotspotImage from "./Hotspot/HotspotImage";
import MixMatch from "./MixMatch/MixMatch";
import QuestionVideoModal from "./QuestionVideoModal";
import ThumbPreview from "./ThumbPreview";
import {TextArea} from 'components/Form';
import './styles.scss';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

interface ActiveQuestionProps {
    form: any;
    learningTest: any;
    attemptId: any,
    activeQuestion: any,
    updateActiveQuestionData: Function;
    onChangeAnswers: Function;
    isTestOverview: boolean;
}

const ActiveQuestion: React.FC<ActiveQuestionProps> = ({
    form,
    learningTest,
    attemptId,
    activeQuestion,
    updateActiveQuestionData,
    onChangeAnswers,
    isTestOverview,
}) => {
    const [openVideoPopup, setOpenVideoPopup] = useState<boolean>(false);

    const clearHotspotSelection = () => {
        updateActiveQuestionData(activeQuestion, {
            clearHsSelection: 1,
            hsClickCount: 0,
            clickDots: []
        })
    }

    const handleTextClick = (e:any, isImg: boolean) => {
        if (isImg) {
            e.preventDefault();
        }
    };


    const getQuestionAnswers = (activeQuestion: any) => {
        const answers = activeQuestion.question.answers;
        const type = activeQuestion.question.type;
        const fieldName = `selectedAnswers${activeQuestion.poolQuestionId}`;

        if( type == "OC" ) {
            return (
                <Form.Item name={fieldName}>
                    <Radio.Group disabled={isTestOverview} onChange={(e: any) => {
                        onChangeAnswers(e.target.value)
                    }}>
                        {answers.map((answer: any, key: number) => {
                            return <div className='answer-item' key={key}>
                                {!learningTest.hideCorrectAnswers && isTestOverview && answer.is_correct ? <i className='fa fa-check correct-answer' /> : null}
                                <Radio value={answer.id} checked>
                                    <span className='choices-option-content'>
                                        {answer?.storageFiles?.thumbnail?.fileId && <ThumbPreview
                                            fileInfo={answer?.storageFiles?.thumbnail}
                                        />}
                                        <span>{answer.title}</span>
                                    </span>
                                </Radio>
                            </div>
                        })}
                    </Radio.Group>
                </Form.Item>
            )
        } else if( ["MC"].includes(type) ) {
            return (
                <Form.Item name={fieldName}>
                    <Checkbox.Group disabled={isTestOverview} onChange={(value: any) => {
                        onChangeAnswers(value)
                    }}>
                        {answers.map((answer: any, key: number) => {
                            return <div className='answer-item' key={key}>
                                {!learningTest.hideCorrectAnswers && isTestOverview && answer.is_correct ? <i className='fa fa-check correct-answer' /> : null}
                                <Checkbox value={answer.id}>
                                    <span onClick={(e:any) => handleTextClick(e, answer?.storageFiles?.thumbnail)} className='choices-option-content'>
                                        {answer?.storageFiles?.thumbnail?.fileId && <ThumbPreview
                                            fileInfo={answer?.storageFiles?.thumbnail}
                                        />}
                                        <span>{answer.title}</span>
                                    </span>
                                </Checkbox>
                            </div>
                        })}
                    </Checkbox.Group>
                </Form.Item>
            )
        } else if( ["RMC"].includes(type) ) {
            return (
                <Form.Item name={fieldName}>
                    <Checkbox.Group disabled={isTestOverview} onChange={(value: any) => {
                        onChangeAnswers(value)
                    }}>
                        {answers.map((answer: any, key: number) => {
                            return <div className='answer-item' key={key}>
                                {!learningTest.hideCorrectAnswers && isTestOverview ? <span className={`points-awarded ${parseFloat(answer.score) > 0 ? 'is-correct' : 'is-incorrect'}`}>{parseFloat(answer.score)}</span> : null}
                                <Checkbox value={answer.id}>
                                    <span className='choices-option-content'>
                                        {answer?.storageFiles?.thumbnail?.fileId && <ThumbPreview
                                            fileInfo={answer?.storageFiles?.thumbnail}
                                        />}
                                        <span>{answer.title}</span>
                                    </span>
                                </Checkbox>
                            </div>
                        })}
                    </Checkbox.Group>
                </Form.Item>
            )
        } else if( type == "WA" ) {
            return <div className='answer-item-wa'>
                <TextArea
                    name={fieldName}
                    customLayout={formItemLayout}
                    disabled={isTestOverview}
                    onChange={(e: any) => {
                        onChangeAnswers(e.target.value)
                    }}
                />
                {!learningTest.hideCorrectAnswers && isTestOverview && !activeQuestion.isPassed ? answers.map((answer: any, key: number) => {
                    return <div className='answer-item' key={key}>
                        <i className='fa fa-check correct-answer' /> <h4>{answer.title}</h4>
                    </div>
                }) : null}
            </div>
        } else if( type == "HS" ) {
            return <div className='answer-item-hs'>
                {activeQuestion.questionImageUrl && <HotspotImage
                    activeQuestion={activeQuestion}
                    updateActiveQuestionData={updateActiveQuestionData}
                    onChangeAnswers={onChangeAnswers}
                    imageUrl={activeQuestion.questionImageUrl}
                    answers={answers}
                    form={form}
                    isTestOverview={isTestOverview}
                    hideCorrectAnswers={learningTest.hideCorrectAnswers}
                />}
            </div>
        }
        else if( activeQuestion.question.type == "MA" ) {
            return <div className='answer-item-ma'>
                <Form.Item name={fieldName}>
                    <MixMatch
                        activeQuestion={activeQuestion}
                        updateActiveQuestionData={updateActiveQuestionData}
                        onChangeAnswers={onChangeAnswers}
                        answers={answers}
                        form={form}
                        isTestOverview={isTestOverview}
                        hideCorrectAnswers={learningTest.hideCorrectAnswers}
                    />
                </Form.Item>
            </div>
        }
    }

    const getResultButton = () => {
        if (
            parseFloat(activeQuestion.attemptQuestion.pointsAwarded) !== parseFloat(activeQuestion.attemptQuestion.question.score)
            && activeQuestion.attemptQuestion.isPassed
            && activeQuestion.attemptQuestion.question.type === 'RMC'
        ) {
            return (
                <Button type='ghost' style={{width: '100%', color: '#fde306', borderColor: '#fde306'}}>
                    <span className='btn-text'>
                        <FormattedMessage id="learning_tests.partly_correct" />
                    </span>
                    <span className='question-points'>
                        {activeQuestion.attemptQuestion.pointsAwarded}
                        <FormattedMessage id="learning_tests.points" />
                    </span>
                </Button>
            )
        } else if (activeQuestion.attemptQuestion.isPassed) {
            return (
                <Button type='ghost' style={{width: '100%', color: '#73D13D', borderColor: '#73D13D'}}>
                    <span className='btn-text'>
                        <FormattedMessage id="learning_tests.correct" />
                    </span>
                    <span className='question-points'>
                        {activeQuestion.attemptQuestion.pointsAwarded}
                        <FormattedMessage id="learning_tests.points" />
                    </span>
                </Button>
            )
        } else {
            return (
                <Button type='ghost' style={{width: '100%', color: '#CF1322', borderColor: '#CF1322'}}>
                    <span className='btn-text'>
                        <FormattedMessage id="learning_tests.incorrect" />
                    </span>
                    <span className='question-points'>
                        {activeQuestion.attemptQuestion.pointsAwarded}
                        <FormattedMessage id="learning_tests.points"/>
                    </span>
                </Button>
            )
        }
    }

    return (
        <div className={`learning-test-question question-${activeQuestion.question.type}`}>
            <div className='question-title-wrapper'>
                <h2 className='question-title'>{activeQuestion.question.title}</h2>
                {activeQuestion.question?.storageFiles?.thumbnail?.fileId && activeQuestion.question.type != "HS" && <ThumbPreview
                    fileInfo={activeQuestion.question?.storageFiles?.thumbnail}
                />}
            </div>
            {activeQuestion.question.video ?
                <>
                    <QuestionVideoModal
                        open={openVideoPopup}
                        onCancel={() => setOpenVideoPopup(false)}
                        video={activeQuestion.question.video}
                        title={activeQuestion.question.title}
                    />
                    <Button type='ghost' style={{width: '200px', marginBottom: '20px'}} onClick={() => setOpenVideoPopup(true)}><FormattedMessage id='learning_tests.watch_video' /></Button>
                </>
            : null}
            {activeQuestion.question.type === 'HS' && !isTestOverview && (
                <div className={`question-hs-info ${!activeQuestion.hsTotalClicks ? 'flex justify-end' : ''}`}>
                    {activeQuestion.hsTotalClicks > 0 ?
                        <Alert message={
                            <div className='flex align-center justify-between'>
                                <span><FormattedMessage id='learning_tests.limited_click_count' /></span>
                                <span className='flex align-center gap-10'>
                                    <span><FormattedMessage id='learning_tests.click_remaining' />: {activeQuestion.hsTotalClicks - activeQuestion.hsClickedCount}</span>
                                    <Button type='primary' onClick={() => clearHotspotSelection()}><FormattedMessage id="learning_tests.clear_selection" /></Button>
                                </span>
                            </div>
                        } type="warning" showIcon />
                    : <Button onClick={() => clearHotspotSelection()} type='primary'><FormattedMessage id="learning_tests.clear_selection" /></Button>}
                </div>
            )}
            <div className='question-answers'>
                {getQuestionAnswers(activeQuestion)}
            </div>
            {isTestOverview ? <div className='question-overview-status'>
                {getResultButton()}
            </div> : null}
        </div>
    );
};

export default ActiveQuestion;
