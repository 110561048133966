import React from 'react';
import { useLocaleContext } from 'context/LocaleContext';
import { config } from 'config/config';

interface LocaleTextInterface {
    text: any;
    defaultText?: string;
}

const LocaleText: React.FC<LocaleTextInterface> = ({ text, defaultText }) => {
    const { locale } = useLocaleContext();

    let localeText = !!defaultText ? defaultText : '';

    if (text && text[locale && config.api.defaultAppLanguage]) {
        localeText = text[locale && config.api.defaultAppLanguage];
    } else if (text) {
        localeText = text[Object.keys(text)[0]];
    }

    return <>{localeText}</>;
};

export default LocaleText;
