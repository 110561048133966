import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { message, Button, Form, Input, Row, Col, Modal, Table } from 'antd';
import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import useHandleError from "utils/useHandleError";
import apiClient from 'utils/apiClient';
import {RequestSelect} from 'components/Form';
import '../styles.scss';


interface AssessmentInstructorsInterface {
    activityFormData: any;
    assessmentSettings: any;
    submission: any;
    loadAssignment: Function;
    setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitLoading: boolean;
    session?: any;
}
const mapStateToProps = (state: any) => ({
    session: state.session,
});

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const AssessmentInstructors: React.FC<AssessmentInstructorsInterface> = ({
    activityFormData,
    assessmentSettings,
    submission,
    loadAssignment,
    setIsSubmitLoading,
    isSubmitLoading,
    session
}) => {
    const [newInstructorId, setNewInstructorId] = useState<number>(0);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();
    const { confirm } = Modal;

    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            key: 'name',
            render: (text: string, record: any, index: number) => {
                return record.instructorUser.name + ' ' + record.instructorUser.surname
            },
        },
        {
            title: intl.formatMessage({id: 'general.email'}),
            key: 'email',
            render: (text: string, record: any, index: number) => {
                return record.instructorUser.email
            },
        },
        {
            title: intl.formatMessage({id: 'general.actions'}),
            key: 'actions',
            render: (text: string, record: any, index: number) => {
                return (
                    <>
                        {activityFormData.attemptData.canUpdateInstructors && <Button type='link' disabled={record.isSubmitted} loading={isSubmitLoading} onClick={() => deleteInstructor(record.instructorUser.typeId)} style={{padding: 0}}><FormattedMessage id='general.delete' /></Button>}
                    </>
                )
            },
        }
    ];

    const deleteInstructor = async (instructorId: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'assessment.deletion_instructor' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                deleteInstructorSubmit(instructorId)
            }
        });
    };

    const deleteInstructorSubmit = async (instructorId: number) => {
        const parsedValues = {
            instructorId: instructorId,
        }

        try {
            setIsSubmitLoading(true);
            let response = await apiClient.request(`/api/v1/courses/assessment-results/delete-instructor/${submission.id}`, parsedValues, 'POST');
            message.success(intl.formatMessage({id: "general.success"}));
            setIsSubmitLoading(false);
            loadAssignment()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const addInstructor = async () => {
        const parsedValues = {
            instructorId: newInstructorId,
        }

        try {
            setIsSubmitLoading(true);
            let response = await apiClient.request(`/api/v1/courses/assessment-results/add-instructor/${submission.id}`, parsedValues, 'POST');
            message.success(intl.formatMessage({id: "general.success"}));
            setIsSubmitLoading(false);
            loadAssignment()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false);
        }
    };

    return (
        <div className='assessment-overview'>
            {activityFormData.attemptData.canUpdateInstructors && <div className='flex align-center gap-10'>
                <RequestSelect
                    name='manager'
                    isForm={false}
                    customLayout={formItemLayout}
                    selectClassName='request-select'
                    url={`/api/v1/edu/users/mass-search`}
                    param={[`${activityFormData?.studentData?.customerId ? `customerId=${activityFormData.studentData.customerId}` : ''}`, 'key=type_id']}
                    column
                    showSearch
                    onChange={(value: number) => setNewInstructorId(value)}
                />
                <Button type='primary' onClick={() => addInstructor()}><FormattedMessage id='general.add' /></Button>
            </div>}
            <Table
                columns={columns}
                dataSource={activityFormData.attemptData.instructorsData.filter((item: any) => item.instructorUser)}
                pagination={false}
            />
        </div >
    )
}
export default connect(mapStateToProps)(AssessmentInstructors);