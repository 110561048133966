import { AudienceRuleData, AudienceRuleModel, AudienceRuleSetProps } from 'components/Audiences/types';
import AudienceRuleFormItems from 'components/Audiences/AudienceRuleFormItems';
import React, { useEffect, useState } from 'react';
import { Form, List, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'ui/Button';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Switch } from 'components/Form';
import { useAudience } from "hooks/Audiences/useAudience";
import '../styles.scss';
import coursesApiClient from "../../../utils/coursesApiClient";
import useHandleError from "../../../utils/useHandleError";

const AudienceRuleSet: React.FC<AudienceRuleSetProps> = ({ audience, ruleSet, index, form, reloadAudience }) => {
    const intl = useIntl();
    const { confirm } = Modal;
    const [selectedCustomerId, setSelectedCustomerId] = useState<string|null>(null);
    const [customFields, setCustomFields] = useState<any>([]);
    const { deleteAudienceRuleSet } = useAudience();
    const [handleError] = useHandleError();

    useEffect(() => {
        const rulesGroup = form?.getFieldValue(['rulesGroup', ruleSet.id, 'rules']);
        setSelectedCustomerId(rulesGroup?.filter(((rule: AudienceRuleModel) => rule.data === AudienceRuleData.UserCustomer))?.shift()?.value ?? null);
        loadCustomFields()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/users/custom-fields/list', [], 'GET');

            setCustomFields(customFields.fields)
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    const handleOperatorChange = (value: any) => {
        form?.setFieldsValue({
            operatorGroup: {
                [ruleSet.id]: {
                    operator_tmp: !value
                }
            }
        });
    }

    const handleTmpOperatorChange = (value: any) => {
        form?.setFieldsValue({
            operatorGroup: {
                [ruleSet.id]: {
                    operator: !value
                }
            }
        });
    }

    const warnDeletion = async (ruleSetId: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: `${intl.formatMessage({ id: 'audience.rule_set_delete_warning' })}`,
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                deleteRuleSet(ruleSetId);
            }
        });
    }

    const deleteRuleSet = async (ruleSetId: number) => {
        if (await deleteAudienceRuleSet(ruleSetId) && !!reloadAudience) {
            reloadAudience();
        }
    }



    return <List
        className="audience-form-items-list"
        header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{`${intl.formatMessage({ id: 'audience.rule_set' })}: ${ruleSet.name}`}</div>
                <div>{audience.isEditable() ? <i onClick={() => warnDeletion(ruleSet.id)} style={{ opacity: .5, cursor: 'pointer' }}
                    className="fa-regular fa-trash"></i> : <div></div>}</div>
            </div>
        }
        bordered
        dataSource={[
            <>
                <Switch name={['operatorGroup', `${ruleSet.id}`, 'operator']} isFormItem
                    customLayout={{ labelCol: { span: 20 }, wrapperCol: { span: 4 } }}
                    label={intl.formatMessage({ id: 'audience.rule_operator_and_label' })}
                    valuePropName="checked" onChange={handleOperatorChange}
                    disabled={!audience.isEditable()}
                />
                <Switch name={['operatorGroup', `${ruleSet.id}`, 'operator_tmp']} isFormItem
                    customLayout={{ labelCol: { span: 20 }, wrapperCol: { span: 4 } }}
                    label={intl.formatMessage({ id: 'audience.rule_operator_or_label' })}
                    valuePropName="checked" onChange={handleTmpOperatorChange}
                    disabled={!audience.isEditable()}
                />
            </>,

            <Form.List name={['rulesGroup', `${ruleSet.id}`, 'rules']}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => <AudienceRuleFormItems
                            audience={audience}
                            selectedCustomerId={selectedCustomerId}
                            setSelectedCustomerId={setSelectedCustomerId}
                            ruleSetId={ruleSet.id}
                            form={form}
                            customFields={customFields}
                            fields={fields}
                            remove={remove}
                            key={key}
                            name={name}
                            {...restField}
                        />)}

                        {audience.isEditable() ? <Button type="default" onClick={() => add()} icon={<i className="fal fa-plus" />}
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                display: 'block',
                                marginBottom: 8
                            }}>
                            <FormattedMessage id='audience.add_rule' />
                        </Button> : <div></div>}
                    </>
                )}
            </Form.List>
        ]}
        renderItem={(item) => <>{item}</>}
    />
};

export default AudienceRuleSet;
