import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {Table, message, Tag, Modal, Input, Form, DatePicker, Alert, Button } from 'antd';
import {config} from 'config/config';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';
import { Select } from 'components/Form';
import { useParams } from 'react-router-dom';
import './styles.scss';
import { useLocaleContext } from 'context/LocaleContext';

const ScenaroHistoryList: React.FC<any> = ({})  => {
  const { locale } = useLocaleContext();
  const [historyRecords, setHistoryRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mailCategories, setMailCategories] = useState<any>({});
  const [isModalVisible, setISModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedFromLabel, setSelectedFromLabel] = useState<any>();
  const [selectedToLabel, setSelectedToLabel] = useState<any>();
  const [scenarioFromEmails, setScenarioFromEmails] = useState({});
  const [scenarioToEmails, setScenarioToEmails] = useState();
  
  const intl = useIntl();
  const { Search } = Input;
  const [form] = Form.useForm();
  const includeColumn = ['fullName', 'to'];
  const params = useParams();

  const scenarioId = params.id;

  useEffect(() => {
    const loadHistory = async () => {
      try {
        setIsLoading(true);
        let historyRecordsResponse = await apiClient.request(`/api/v1/scenarios-mail-history/${scenarioId}/lang/${locale}`, {}, 'GET');
        setHistoryRecords(historyRecordsResponse.mailHistory);
        let mailCategories = await apiClient.request(config.api.routes.backend.mailCategories, {}, 'GET');
        setMailCategories(mailCategories);
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setIsLoading(false);
      }
    }

    const loadScenarioFromEmails = async () => {
      try {
        setIsLoading(true);
        let historyRecordsResponse = await apiClient.request(`/api/v1/mail-history/${scenarioId}/from-emails`, {}, 'GET');
        let scenarioEmails:any

        if (historyRecordsResponse.mailHistoryFromEmails.length <= 1 ) {

          scenarioEmails ={0: historyRecordsResponse.mailHistoryFromEmails[0]}
        }


        setScenarioFromEmails(scenarioEmails);
        ;
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setIsLoading(false);
      }
    } 

    const loadScenarioToEmails = async () => {
      try {
        setIsLoading(true);
        let historyRecordsResponse = await apiClient.request(`/api/v1/mail-history/${scenarioId}/to-emails`, {}, 'GET');
        
        
        setScenarioToEmails(historyRecordsResponse.mailHistoryToEmails);
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setIsLoading(false);
      }
    } 
    loadScenarioFromEmails();
    loadScenarioToEmails();
    loadHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const  filterBySearchString = (searchText: string, users: never[], includeColumn: string[]) => {
    return !searchText ? users : users.filter((user: any) => { return Object.keys(user).some(key => includeColumn.includes(key) ? user[key].toString().toLowerCase().includes(searchText.toLocaleLowerCase()) : false); });
  }

  const filterHistory = async (values: any) => {
    let startDate = values.startDate.format('YYYY-MM-DD 00:00')
    let endDate = values.endDate.format('YYYY-MM-DD 23:59')
    try{
      let parsedValues = {
        startDate: startDate,
        endDate: endDate,
        id: scenarioId,
        from: selectedFromLabel,
        to: selectedToLabel
      }
      let response = await apiClient.request('/api/v1/filtered-scenarios-mail-history/' + locale, parsedValues, 'POST');
      setHistoryRecords(response.mailHistory);
      setShowFilterModal(false);
    } catch (err){
        setShowFilterModal(false);
        message.error(intl.formatMessage({id: "error.data_load"}));
      }
  }
  
  const  filterBySearch = async (value: string) => {
    
    let data = filterBySearchString(value, historyRecords, includeColumn);
    setHistoryRecords(data);
  }


  const columns = [
    {
      title: intl.formatMessage({id: 'emailing.templates.template_category'}),
      dataIndex: 'templateCategory',
      key: 'templateCategory',
      render: (text: string, record: any) => {
        return intl.formatMessage({id: `email_templates.categories.${Object.keys(mailCategories).length > 0 && mailCategories[record.mailCategoryId]}`})
      }
    },
    {
      title: intl.formatMessage({id: 'emailing.history.receiver_name'}),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text: string, record: any) => record.fullName
    },
    {
      title: intl.formatMessage({id: 'emailing.history.senders_address'}),
      dataIndex: 'from',
      key: 'from',
      render: (text: string, record: any) => record.from
    },
    {
      title: intl.formatMessage({id: 'emailing.history.receiver_address'}),
      dataIndex: 'to',
      key: 'to',
      render: (text: string, record: any) => record.to
    },
    {
      title: intl.formatMessage({id: 'general.date'}),
      dataIndex: 'date',
      key: 'date',
      render: (text: string, record: any) => moment(record.executionTime).format('HH:mm:ss DD.MM.YYYY')
    },
    {
      title: intl.formatMessage({id: 'general.body'}),
      dataIndex: 'body',
      key: 'body',
      render: (text: string, record: any) => {
        return <p
          style={{color: '#19A9CE', cursor: 'pointer'}}
          onClick={() => {
            setModalTitle(intl.formatMessage({id: "emailing.history.email_body"}));
            setModalContent(record.body);
            setISModalVisible(true);
          }}
        >
          <FormattedMessage id={'general.show'}/> <EyeOutlined />
        </p>
      }
    },
    {
      title: intl.formatMessage({id: 'general.status'}),
      dataIndex: 'status',
      render: (text: string, record: any, tag: any) => {
        let color;
        if (record.status) {
          if (record.status === 'SENT') color = 'green';
          if (record.status === 'SENDING') color = 'gold';
          if (record.status === 'FAILED') color = 'red';
          return (
            <>
              <Tag color={color} key={tag}>
                  {intl.formatMessage({id: `email_history.status.${record.status}`})}
              </Tag>
              {
                record.status === 'FAILED'
                  ? <p
                      style={{color: '#19A9CE', cursor: 'pointer'}}
                      onClick={() => {
                        setModalTitle(intl.formatMessage({id: "general.error"}));
                        setModalContent(record.errors);
                        setISModalVisible(true);
                      }}
                    >
                      <FormattedMessage id={'general.show'}/> <EyeOutlined />
                    </p>
                  : null
              }
            </>
          )
        } else {
          return '-';
        }
      }
    },
  ]; 

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({id: 'emailing.history.e_mailing_history'})} />
      <DefaultLayout.PageContent>
      <Button type="primary" style={{marginLeft: 10}} onClick={() => setShowFilterModal(true)}><FormattedMessage id="general.filter"/></Button>
      <Search
          className="table-search-field"
          placeholder={intl.formatMessage({id: "general.search"})}
          onSearch={value => filterBySearch(value)}
         />
          {
          historyRecords.length === 0  ?
          <Alert
          message={intl.formatMessage({id: 'emailing.history.history_not_found'})}
          type="info"
          style={{marginTop: 20}}
          showIcon
          />
          :<Table
          locale={{ emptyText: intl.formatMessage({id: "general.found_no_data"}) }}
          style={{marginTop: 16}}
          columns={columns}
          loading={isLoading}
          dataSource={historyRecords}
          size="middle"
          scroll={{ x: 800 }}
          />
          }
      </DefaultLayout.PageContent>
      <Modal
          title={modalTitle}
          open={isModalVisible}
          onOk={() => setISModalVisible(false)}
          onCancel={() => setISModalVisible(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div dangerouslySetInnerHTML={{__html: modalContent}}/>
        </Modal>

      <Modal
        className="history-list-modal"
        open={showFilterModal}
        title={intl.formatMessage({id: 'emailing.history.email_history_filter'})}
        onOk={()=>form.submit()}
        onCancel={() => setShowFilterModal(false)}
        okText={intl.formatMessage({id: 'general.filter'})}
        cancelText={intl.formatMessage({id: 'general.cancel'})}
      >
        <Form form={form} onFinish={filterHistory}>
        <Form.Item name="startDate" label={intl.formatMessage({id: "general.start_date"})} rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item name="endDate" label={intl.formatMessage({id: "general.end_date"})} rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]} >
          <DatePicker />
        </Form.Item>
        <Select
          showSearch
          allowClear
          name='from'
          manualOptions={scenarioFromEmails}
          label={intl.formatMessage({id: "general.from"})}
          integerKey={true}
          dataKey='mailHistoryEmails'
          onChange={(el:any, option:any)=>{option ? setSelectedFromLabel(option.children) : setSelectedFromLabel(null)}}
          valueKey='value'
          labelKey='label'
          customLayout={true}
          distinct={true}
          filterOption={(input: string, option: any) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          />
        <Select
          showSearch
          allowClear
          name='to'
          label={intl.formatMessage({id: "general.to"})}
          manualOptions={scenarioToEmails}
          customRules={[ {required: false}]}
          onChange={(el:any, option:any)=>{option ? setSelectedToLabel(option.children) : setSelectedToLabel(null)}}
          valueKey='value'
          labelKey='label'
          customLayout={true}
          distinct={true}
          filterOption={(input: string, option: any) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          />
        </Form>
      </Modal>
    </DefaultLayout.PageLayout>
  )
}

export default ScenaroHistoryList;
