import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal, Button} from 'antd';
import {Table} from 'ui';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from 'react-redux';
import {Editor} from '@tinymce/tinymce-react';
import {config} from 'config/config';
import {useParams} from "react-router-dom";
import {usePhishing} from "hooks/Phishing/usePhishing";

interface DataType {
    id: number;
    isTemplate: 1 | 0;
    simulationId: string;
    title: string;
    language?: string
}

const PhishingSimulationsModal = ({visible, onCancel, reloadCampaignSimulations}: any) => {
    const {saveCampaignSimulations, loading} = usePhishing();

    const [reload, setReload] = useState(false);
    const [phishingTemplate, setPhishingTemplate] = useState<any>();
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);

    const params = useParams();
    const campaignId = params.id;
    const intl = useIntl();


    const loadPhishingTemplate = async (templateId: any) => {
        let response = await coursesApiClient.request(`/api/v1/courses/gophish/templates/${templateId}`, [], 'GET');
        setPhishingTemplate(response.gophishTemplate);
        setShowTemplateModal(true);
    }

    const loadPhishingLandingPage = async (landingPageId: any) => {
        let response = await coursesApiClient.request(`/api/v1/courses/gophish/pages/${landingPageId}`, [], 'GET');

        const newWindow: any = window.open("", response.gophishPage.name);
        newWindow.document.write(response.gophishPage.html);
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'phishing.simulation_id'}),
            dataIndex: 'simulation_id',
            render: (value: string, record: any) => record.isTemplate ? record.simulationId : null
        },
        {
            title: intl.formatMessage({id: 'general.language'}),
            dataIndex: 'language',
            render: (value: string, record: any) => !record.isTemplate ? record.language : null
        },
        {
            title: intl.formatMessage({id: 'phishing.table_template_id'}),
            dataIndex: 'template_id',
            render: (value: string, record: any) => !record.isTemplate ? `${record.simulation_id}.${record.language}` : null
        },
        {
            title: intl.formatMessage({id: 'phishing.simulation_template_name'}),
            key: 'title',
            render: (value: string, record: any) => {
                return (
                    <div>
                        {record.title}
                    </div>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'phishing.phishing_email'}),
            key: 'template_title',
            render: (value: string, record: any) => !record.isTemplate ? record.template_title : null,
        },
        {
            title: intl.formatMessage({id: 'phishing.phishing_landing_page'}),
            key: 'landing_page_title',
            render: (value: string, record: any) => !record.isTemplate ? record.landing_page_title : null,
        },
    ];

    const submitForm = async () => {
        if (campaignId) {
            const values = {templateId: selectedRowKeys}
            await saveCampaignSimulations(values, campaignId);
            onCancel();
            reloadCampaignSimulations(true);
        }
    }

    return (
        <>
            <Modal
                className="results-modal"
                title={intl.formatMessage({id: 'phishing.phishing_simulations_modal'})}
                open={visible}
                onCancel={onCancel}
                onOk={() => submitForm()}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
                okText={intl.formatMessage({id: 'general.select'})}
                okButtonProps={{disabled: loading}}
            >
                <Table
                    columns={columns}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    url={'/api/v1/courses/phishing/templates'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={true}
                    rowKey={'id'}
                    scroll={{x: 800}}
                    selectionProps={{
                        getCheckboxProps: (record: DataType) => ({
                            disabled: !record.isTemplate && record?.language !== undefined,
                        })
                    }}
                />
            </Modal>
            <Modal
                className="results-modal"
                title={intl.formatMessage({id: 'phishing.phishing_template'})}
                open={showTemplateModal}
                onCancel={() => setShowTemplateModal(false)}
                footer={
                    [
                        <Button onClick={() => setShowTemplateModal(false)}>
                            <FormattedMessage id="general.cancel"/>
                        </Button>
                    ]
                }
            >
                {phishingTemplate ?
                    <Editor
                        apiKey={config.api.tinymceApiKey}
                        disabled={true}
                        value={phishingTemplate.html}
                        init={{
                            height: 500,
                            relative_urls: false,
                            remove_script_host: false,
                            menubar: false,
                            plugins: [],
                            toolbar: ''
                        }}
                    />
                    :
                    null
                }
            </Modal>
        </>
    )
}
export default PhishingSimulationsModal;
