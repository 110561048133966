import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {NavLink, useNavigate} from 'react-router-dom';
import {config} from 'config/config';
import DefaultLayout from "components/DefaultLayout";
import './styles.scss';
import useHandleError from "utils/useHandleError";
import {Search, Table} from "ui";
import moment from "moment/moment";
import {Modal, Popover, Tag} from "antd";
import {Button} from "ui";
import apiClient from "utils/apiClient";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import coursesApiClient from "utils/coursesApiClient";
import Spinner from "components/Spinner";
import useQueryApiClient from "../../../utils/useApiClient";
import {Switch} from "../../../components/Form";
import { useLocaleContext } from 'context/LocaleContext';

const LearningProgramList: React.FC<any> = ({}) => {
    const { locale } = useLocaleContext();
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [customFields, setCustomFields] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState('');

    const intl = useIntl();
    const [handleError] = useHandleError();
    const {confirm} = Modal;
    const navigate = useNavigate();

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/courses/learning-program/custom-fields/program-custom-fields', [], 'GET');
            setCustomFields(customFields.fields);
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    const columns = [
        {
            width: 65,
            key: 'icon',
            render: (record: any) => {
                return (
                    <div className='content-column'>
                        {
                            record.thumbnail ? <i className={`fal ${record.thumbnail}`}/> :
                                <i className="fal fa-book"></i>
                        }
                    </div>
                );
            }
        },
        {
            title: intl.formatMessage({id: 'general.title'}),
            sorter: true,
            key: 'title',
            render: (_text: string, record: any) => {
                const title = record.title[locale] ? record.title[locale] : record.title[Object.keys(record.title)[0]]
                return (
                    <NavLink to={`/learning/programs/${record.id}`}>
                        {title}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.begin_date'}),
            sorter: true,
            key: 'begin_date',
            render: (_text: string, record: any) => record.begin_date ? (moment(record.begin_date).format(config.defaultDateFormat)) : '-'
        },
        {
            title: intl.formatMessage({id: 'general.end_date'}),
            sorter: true,
            key: 'end_date',
            render: (_text: string, record: any) => record.end_date ? moment(record.end_date).format(config.defaultDateFormat) : '-'
        },
        {
            title: intl.formatMessage({id: 'campaign.users_enrolled'}),
            sorter: true,
            key: 'users_enrolled',
            render: (_text: string, record: any) => {
                return record.users_enrolled;
            }
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            sorter: true,
            key: 'status',
            render: (_text: string, record: any, tag: any) => {
                let color;
                if (record.status === 'DRAFT') color = 'gold';
                if (record.status === 'ONGOING') color = 'blue';
                if (record.status === 'FINISHED') color = 'green';
                return (
                    <Tag color={color} key={tag} className={record.status}>
                        {intl.formatMessage({id: `campaign.status.${record.status}`})}
                    </Tag>
                );
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <>
                        <NavLink to={`/learning/programs/${record.id}`}>
                            <div className="popover-item">
                                <FormattedMessage id="general.edit"/>
                            </div>
                        </NavLink>
                        {(record.status === 'DRAFT' || record.is_disabled )&&
                            <div className="popover-item" onClick={() => {
                                warnDeletion(record);
                                setVisibleTooltip(null);
                            }}>
                                <FormattedMessage id="general.delete"/>
                            </div>
                        }
                        <div className="popover-item" onClick={() => {
                            record.is_disabled ?
                                disableProgram([], {id: record.id, process: !record.is_disabled ? 'disable' : 'enable'})
                                : warnDisable(record);
                            setVisibleTooltip(null);
                        }}>
                            {
                                record.is_disabled ?
                                    <FormattedMessage id="general.enable"/> :
                                    <FormattedMessage id="general.disable"/>
                            }
                        </div>
                        <Spinner spinning={isLoading}>
                            <div className="popover-item" onClick={() => replicateProgram(record.id)}>
                                <FormattedMessage id="general.copy"/>
                            </div>
                        </Spinner>
                    </>
                );

                return (
                    <>
                        <Popover
                            open={record.id === visibleTooltip}
                            content={content}
                            trigger="click"
                            placement="bottomRight"
                            arrowPointAtCenter
                            onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                        >
                            <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                            </div>
                        </Popover>
                    </>
                )
            },
        },
    ];

    const replicateProgram = async (id: number) => {
        setIsLoading(true)
        try {
            const newProgram = await apiClient.request(`/api/v1/courses/learning-program/options/replicate/${id}`, [], 'POST');

            navigate(`/learning/programs/${newProgram.data.id}`)
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        loadCustomFields();
    }, []);

    const warnDeletion = async (record: any) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: !!record.is_disabled ? 'courses.disabled_deletion' : 'courses.delete_program'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                deleteProgram([], {id: record.id});
            }
        });
    }

    const warnDisable = async (record: any) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'courses.disable_program'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                disableProgram([], {id: record.id, process: !record.is_disabled ? 'disable' : 'enable'});
            }
        });
    }

    const {appendData: disableProgram} = useQueryApiClient({
        request: {
            disableOnMount: true,
            method: 'POST',
            url: '/api/v1/courses/learning-program/:id/action/:process'
        },
        onSuccess: () => {
            setReload(true);
        },
    });

    const {appendData: deleteProgram} = useQueryApiClient({
        request: {
            disableOnMount: true,
            method: 'DELETE',
            url: `/api/v1/courses/learning-program/:id/delete`
        },
        onSuccess: () => {
            setReload(true);
        },
    });

    const handleChange = (value: any) => {

        if (value) {
            setFilter('filter[isDisabled]=true');
        } else {
            setFilter('');
        }
        setReload(true)
    };


    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'general.learning_programs'})}/>
            <DefaultLayout.PageContent>
                <div className="search-bar-row">
                    <NavLink to='/learning/programs/create'>
                        <Button type='primary'>
                            <FormattedMessage id='programs.create_learning_program'/>
                        </Button>
                    </NavLink>
                    <div className="search">
                        <Search setSearchString={setSearchString} setReload={setReload}/>
                        <div className="search">
                            <Switch onChange={handleChange} isFormItem name='isDisabled'
                                    className={'remove-flex-grow mb-0'}
                                    label={intl.formatMessage({id: 'program.program_is_disabled'})}/>
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns}
                    url={'/api/v1/courses/learning-program'}
                    setReload={setReload}
                    reload={reload}
                    rowClassName={(record: any) => record.is_disabled && record.status !== 'DRAFT' ? 'table-row-red' : ''}
                    rowSelection={false}
                    rowKey={'id'}
                    filter={filter}
                    search={searchString}
                    hasCustomSorter={true}
                    scroll={{x: true}}
                    customFields={customFields}
                    learningProgramCustomColumns={true}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    );
};

export default LearningProgramList;