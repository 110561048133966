import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal, Button} from 'antd';
import useHandleError from 'utils/useHandleError';
import './styles.scss';
import coursesApiClient from "../../../utils/coursesApiClient";
import FileDownload from "js-file-download";

interface HandoutsModalInterface {
    visible: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    files: any;
}

const HandoutsModal: React.FC<HandoutsModalInterface> = ({
                                                         visible,
                                                         onCancel,
                                                         files,
                                                     }) => {
    const intl = useIntl();
    const [handleError] = useHandleError();


    const downloadFile = async (settings: any) => {
        try {
            let response = await coursesApiClient.request(`api/v1/courses/material/storage/${settings.file_path}`, {}, 'GET', true, true);
            FileDownload(response, settings.title);
        } catch (error) {
            handleError(error);
        }
    }

    const renderHandouts = () => {
        return files.map((el: any) => {
            const fileSettings = JSON.parse(el.fileSettings);
            return (
                <div className={'flex flex-column align-start'}>
                    <Button onClick={() => downloadFile(fileSettings)} type='link'>
                        {fileSettings.title}
                    </Button>
                </div>
            )
        })
    }

    return (
            <Modal
                open={visible}
                title={intl.formatMessage({id: 'general.handout_modal'})}
                onCancel={() => onCancel(false)}
                footer={
                    <Button onClick={() => onCancel(false)}>
                        <FormattedMessage id='general.cancel'/>
                    </Button>
                }
            >
                {renderHandouts()}
            </Modal>
    )
};

export default HandoutsModal;
