import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Progress, Table, Tooltip } from 'antd';
import FileDownload from 'js-file-download';
import { CampaignStatistics, DashboardChartProps} from './types';
import coursesApiClient from 'utils/coursesApiClient';
import { useLocaleContext } from 'context/LocaleContext';


const CampaignTable: React.FC<DashboardChartProps> = ({ customerUuid }) => {
  const { locale } = useLocaleContext();
  const [data, setData] = useState<CampaignStatistics[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.title' }),
      dataIndex: 'campaignName',
      render: (campaignName: string, record: any) => {
        const parsedTitle = JSON.parse(record.title);

        const title = parsedTitle[locale] ? parsedTitle[locale] : parsedTitle[Object.keys(parsedTitle)[0]]

        return (
            <NavLink to={`/learning/programs/${record.id}`}>{title}</NavLink>
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'campaign.users_enrolled' }),
      dataIndex: 'students',
      width: '25%',
    },
    {
      title: intl.formatMessage({ id: 'general.progress' }),
      dataIndex: 'progress',
      render: (progress: any, record: any) => {
        const completed = ~~(((parseInt(record.in_progress) + parseInt(record.completed)) / record.students) * 100);
        return (
          <Tooltip title={`${completed}% ${intl.formatMessage({ id: 'general.completed' }).toLowerCase()}`}>
            <Progress
              percent={record.students}
              success={{
                percent: completed,
                strokeColor: 'rgb(33, 208, 160)',
              }}
              showInfo={false}
              size="small"
              strokeColor="rgb(247, 216, 0)"
              trailColor="rgb(232, 232, 232)"
            />
          </Tooltip>
        );
      },
    },
  ];

  const getProgressValuesInPercent = (progress: any) => ({
    total: progress.students,
    notStarted: progress.not_started / progress.students * 100,
    inProgress: progress.in_progress / progress.students * 100,
    completed: progress.completed / progress.students * 100,
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const url = `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/campaigns-list`;
        const response = await coursesApiClient.request(url);
        const responseData = response.statistic;

        const data = responseData.map((campaign: any) => {
          campaign.progress = getProgressValuesInPercent(campaign);

          return campaign;
        });

        setData(data);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, customerUuid]);

  const handleDownloadClick = async () => {
    try {
      setIsDownloading(true);

      const url = `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/export-campaigns-details`;

      const response = await coursesApiClient.request(url, {language: locale}, 'GET', true, true);

      if (response.fileName) {
        FileDownload(response, response.fileName);
      } else {
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      }
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <div className="chart-header">
        <p className="chart-title small-margin">
          <FormattedMessage id="general.learning_programs" />
        </p>
        {/*{role === 'CUSTOMER_ADMIN' &&*/}
        {/*    <button className="chart-download-button" onClick={handleDownloadClick}>*/}
        {/*        <Spin spinning={isDownloading} size="small">*/}
        {/*            <i className="fal fa-file-download" />*/}
        {/*        </Spin>*/}
        {/*    </button>*/}
        {/*}*/}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        pagination={false}
        rowKey="campaignId"
        scroll={{ y: '18vw' }}
        size="small"
      />
    </>
  );
}

export default CampaignTable;
