import React, { useState } from 'react';
import { Modal } from 'ui';
import { useIntl } from 'react-intl';
import { Select } from 'components/Form';
import useQueryApiClient from 'utils/useApiClient';
import './styles.scss';

interface AttendeeChangeModalInterface {
    open: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    data: any;
    load: any;
    activity: any;
}

const AttendeeChangeModal: React.FC<AttendeeChangeModalInterface> = ({
    open,
    onCancel,
    data,
    load,
    activity,
}) => {
    const [attempt, setAttempt] = useState();
    const intl = useIntl();

    const { appendData } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: `/api/v1/courses/learning-program/:id/students/attendee`,
            method: 'POST',
        },
        onSuccess: (): void => {
            onCancel(false);
            load();
        },
    });

    const parseData = (data: any) => {
        if (!data) {
            return {};
        }

        const transformedData: any = {};
        Object.values(data).map((el: any) => {
            const label = `${el.attempt}. ${el.date}`;
            const key = el.id;
            transformedData[key] = label;
        });

        return transformedData;
    };

    return (
        <Modal
            open={!!open}
            onCancel={() => onCancel(false)}
            className="add_remove_activity"
            destroyOnClose={true}
            title={intl.formatMessage({ id: 'program.lecture_attendance_attempt_change' })}
            okText={intl.formatMessage({ id: 'general.change' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            onOk={() =>
                attempt
                    ? appendData(
                          { attemptId: parseInt(attempt), attendanceId: open },
                          { id: activity.enrollmentId }
                      )
                    : null
            }
        >
            <Select
                allowClear
                showSearch
                showArrow
                onChange={setAttempt}
                customLayout={true}
                label={intl.formatMessage({ id: 'program.attempt' })}
                customRules={[
                    {
                        required: true,
                        message: intl.formatMessage({ id: 'validation.field_required' }),
                    },
                ]}
                manualOptions={parseData(data)}
                incrementKey={true}
            />
        </Modal>
    );
};

export default AttendeeChangeModal;
