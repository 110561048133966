import React, {useEffect, useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {
    Button,
    Upload,
    Form,
    Input as AntDInput
} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import {connect} from 'react-redux';
import Spinner from 'components/Spinner';
import moment from 'moment';
import {DatePicker, Input} from "../Form";
import {config} from "config/config";
import {useCertificate} from "hooks/Certificates/useCertificate";
import SubmissionDetail from "../AssignmentActivity/SubmissionDetail";

interface CertificateActivityInterface {
    programActivityAttemptId: number;
}

const formLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
};

const mapStateToProps = (state: any) => ({
    session: state.session
});

const CertificateActivity: React.FC<CertificateActivityInterface> = ({
                                                                         programActivityAttemptId,
                                                                     }) => {
    const {saveSubmission, getCertificate, loading} = useCertificate()
    const [certificate, setCertificate] = useState<any>()
    const [files, setFiles] = useState<any>([]);
    const [disable, setDisable] = useState(false)

    const intl = useIntl();
    const [form] = Form.useForm();
    const {TextArea} = AntDInput;


    useEffect(() => {
        loadCertificate()
    }, [])
    const loadCertificate = async () => {
        let response = await getCertificate(programActivityAttemptId)

        if (response.data?.submission) {
            setFormData(response)
            response.data.submission.deadline = response.data.deadline

            if (response.data.submission.status !== 'NOT_SUBMITTED') {
                response.data.submission.submission_date = response.data.submission.created_at
            }
        }
        setCertificate(response.data);
    }

    const setFormData = (response: any) => {
        if (response.data.submission.status !== 'NOT_SUBMITTED') {
            setDisable(true)
        }

        form.setFieldsValue({
            ...response.data.submission,
            issue_date: response.data.submission.issue_date ? moment(response.data.submission.issue_date) : null,
            expire_date: response.data.submission.expire_date ? moment(response.data.submission.expire_date) : null
        })
        if (response.data.files.length) {
            setFiles(response.data.files)
        }
    }

    const fileUploadAllowed = (cert: any) => {
        if (!cert?.settings) {
            return false
        }
        const settings = (typeof cert.settings === 'string') ? JSON.parse(cert.settings) : cert.settings
        
        return !!settings.fileUpload || !!settings.file_upload
    }

    const submitForm = async (values: any) => {
        let bodyFormData = new FormData();

        Object.entries(values).map((value: any) => {
            if (value[0] === 'files' && value[1]) {
                const fileList = value[1].fileList;
                Object.entries(fileList).map((file: any) => {
                    bodyFormData.append(`file_${file[0]}`, file[1].originFileObj);
                })
            }
            if (moment.isMoment(value[1])) {
                bodyFormData.append(value[0], moment(value[1]).format(config.DBDateFormat));
            } else if (!!value[1]) {
                bodyFormData.append(value[0], value[1]);
            }
        })

        const response = await saveSubmission(bodyFormData, programActivityAttemptId);

        if (response.data?.submission) {
            response.data.submission.deadline = response.data.deadline
            response.data.submission.submission_date = response.data.submission.created_at
            setFormData(response)
            setCertificate(response.data);
        }
    }

    return (
        <Spinner spinning={loading}>
            {certificate &&
              <DefaultLayout.PageLayout withoutPageHeader>
                <div className='assignment-form-wrapper'>
                  <DefaultLayout.PageContent>
                    <h1 className={'flex justify-center'}>
                        {certificate.settings.title}
                    </h1>
                    <div
                      dangerouslySetInnerHTML={{__html: certificate && certificate.settings.description ? certificate.settings.description : ''}}/>
                    <div className={'flex'}>
                      <div className={'bold'}>
                        <FormattedMessage id={'general.deadline'}/>:
                      </div>
                        {certificate.deadline}
                    </div>
                    <Form disabled={disable} form={form} onFinish={submitForm} {...formLayout}>
                        <span className={'hidden'}>
                            <Input
                              name='id'
                              label={intl.formatMessage({id: 'general.title'})}
                            />
                        </span>
                      <Input
                        name='title'
                        label={intl.formatMessage({id: 'general.title'})}
                        customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                      />

                      <DatePicker
                        label={intl.formatMessage({id: 'courses.issue_date'})}
                        customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                        name={'issue_date'}
                        defaultValue={null}
                        format={config.defaultDateFormat}
                      />
                      <DatePicker
                        label={intl.formatMessage({id: 'courses.expire_date'})}
                        name={'expire_date'}
                        defaultValue={null}
                        format={config.defaultDateFormat}
                      />
                        {fileUploadAllowed(certificate) ?
                          <Form.Item
                            label={intl.formatMessage({id: 'general.upload_files'})}
                            name="files"
                            rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                          >
                            <Upload
                              beforeUpload={() => false}
                              listType="picture"
                              isImageUrl={() => false}
                              defaultFileList={files}
                            >
                              <Button>
                                <FormattedMessage id={'general.upload'}/>
                              </Button>
                            </Upload>
                          </Form.Item>
                            : null
                        }
                      <Form.Item
                        name='comment'
                        label={intl.formatMessage({id: "general.comment"})}
                      >
                        <TextArea autoSize={{minRows: 5, maxRows: 10}} showCount maxLength={255}/>
                      </Form.Item>
                        {certificate?.submission &&
                          <SubmissionDetail
                            submission={certificate.submission}
                          />
                        }
                    </Form>
                      {!disable &&
                        <div className={'flex justify-end mt-5'}>
                          <Button type={'primary'} onClick={() => form.submit()}>
                            <FormattedMessage id={'general.submit'}/>
                          </Button>
                        </div>
                      }
                  </DefaultLayout.PageContent>
                </div>
              </DefaultLayout.PageLayout>
            }
        </Spinner>
    )
}
export default connect(mapStateToProps)(CertificateActivity);