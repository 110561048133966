import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import { Form, Card, message, Checkbox } from 'antd';
import { switchToAdministrationEnvironment } from 'store/actions/environment';
import { Input } from 'components/Form';
import { setSession } from 'store/actions/session';
import apiClient from 'utils/apiClient';
import jwt from 'utils/jwt';
import { config } from 'config/config';
import Logo from 'components/Logo';
import { PhoneNumberSelect, Button } from 'ui';
import DefaultUnauthorizedLayout from 'components/DefaultUnauthorizedLayout';
import styles from './styles.module.scss';

const Login: React.FC = ({}: any) => {
    const [inProgress, setInProgress] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [inputTextLength, setInputTextLength] = useState(0);
    const [phoneLogin, setPhoneLogin] = useState(false);
    const intl = useIntl();
    const [form] = Form.useForm();
    const params = useParams();
    const uuid: any = params.uuid;

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            form.submit();
        }
      }

    const submitForm = async (values: any) => {
        try {
            setInProgress(true);

            let login = values.email;

            if (values.phoneNumber) {
                login = values.phoneNumber.replace(/\s/g, '');
            }

            const countryCode = values.code;

            await jwt.login(login, values.password, uuid, countryCode);

            let authMeResponse = await apiClient.request(config.api.routes.auth.me, {}, 'GET'); // TODO: Izvērtēt, ko ar šo darīt (Vajag REDUX saglabāt)

            if (authMeResponse.data.active_user_type !== 'STUDENT') {
                switchToAdministrationEnvironment();
            }

            await setSession(authMeResponse.data);
            window.location.assign('/');
        } catch (e) {
            let errorMessage = 'unsuccessful_log_in_message';
            let error: any = e;

            console.error(error);

            form.setFieldsValue({ password: '' });

            if (error.message === 'not_found_active_user_account') {
                errorMessage = 'login.' + error.message;
            }
            if (error.message === 'customer_has_no_platform_access') {
                errorMessage = 'users.' + error.message;
            }
            if (error.message === 'exceeded_max_allowed_students_count') {
                errorMessage = 'error.' + error.message;
            }
            if (error.message === 'user_is_not_activated') {
                errorMessage = 'error.' + error.message;
            }

            message.error(intl.formatMessage({ id: errorMessage }));

            setInProgress(false);
        }
    };

    const suffix = showPassword ? (
        <i
            onClick={() => setShowPassword(false)}
            className={`far fa-eye-slash ${styles.iconColor}`}
        />
    ) : (
        <i onClick={() => setShowPassword(true)} className={`far fa-eye ${styles.iconColor}`} />
    );

    return (
        <DefaultUnauthorizedLayout className={styles.center} showLanguageChange={true}>
            <Card className={styles.card}>
                <div className={styles.logoContainer}>
                    <Logo />
                </div>
                <p className={styles.loginText}>
                    <FormattedMessage id='login.please_log_in_to_continue' />
                </p>
                <Form
                onFinish={submitForm} 
                form={form}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      form.submit();
                    }
                  }}
                >
                    {phoneLogin ? (
                        <PhoneNumberSelect
                            defaultCode={config.api.defaultCode}
                            form={form}
                            wrapperClassName={styles.phoneInputField}
                            name='phoneNumber'
                            placeholder={intl.formatMessage({ id: 'users.form.phone_number' })}
                            prefix={<i className='fa-solid fa-mobile-screen' />}
                        />
                    ) : (
                        <Input
                            name='email'
                            className={styles.inputField}
                            autoComplete='on'
                            customRules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'login.email.validation' }),
                                },
                            ]}
                            prefix={<i className={`fal fa-user ${styles.iconColor}`} />}
                            placeholder={intl.formatMessage({ id: 'login.email' })}
                            customLayout={true}
                        />
                    )}
                    <Input
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        className={styles.inputField}
                        onChange={() => setInputTextLength(form.getFieldValue('password').length)}
                        customRules={[
                            {
                                required: true,
                                message: intl.formatMessage({ id: 'login.password.validation' }),
                            },
                        ]}
                        prefix={<i className={`fal fa-lock ${styles.iconColor}`} />}
                        placeholder={intl.formatMessage({ id: 'login.password' })}
                        customLayout={true}
                        suffix={inputTextLength > 0 && suffix}
                    />
                </Form>
                <div className={styles.loginOptionsWrapper}>
                    <div>
                        {!uuid?.params.uuid && (
                            <Checkbox onChange={(el: any) => setPhoneLogin(el.target.checked)}>
                                <FormattedMessage id='login.use_phone_to_login' />
                            </Checkbox>
                        )}
                    </div>
                    <NavLink to='/forgot-password' className={styles.forgotPasswordText}>
                        <FormattedMessage id='login.forgot_password' />
                    </NavLink>
                </div>
                <Button
                    type='primary'
                    className={styles.loginButton}
                    loading={inProgress}
                    onClick={form.submit}
                    onKeyDown={handleKeyDown}
                >
                    <FormattedMessage id='login.log_in' />
                </Button>
                {/* <FlexRow
                        right={
                            <LoginInstructionButton position='LOGIN' visible={true} />
                        }
                    /> */}
            </Card>
        </DefaultUnauthorizedLayout>
    );
};

export default Login;
