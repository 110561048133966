import React, { useEffect, useState } from 'react';
import { Divider, Form } from 'antd';
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import TagBuilder from "./TagBuilder";
import Button from "../Button";
import {
    UsersFilter,
    OrganizationsFilter,
    OrganizationalUnitsFilter,
    PositionsFilter,
    DepartmentsFilter,
    DateFilter,
    CompetenceFilter,
    LearningProgramFilter,
    LectureFilter,
    SessionFilter,
    LectorsFilter,
    LectorsDepartmentsFilter,
    AudienceFilter,
    CompetenceBlockFilter,
    AttemptsFilter,
    RegisterTypeFilter
} from "./FilterComponent";
import generalHelpers from "utils/generalHelpers";
import { useLocaleContext } from 'context/LocaleContext';

const Filter: React.FC<any> = ({
    filter,
    filterType,
    setLoad,
    clearFilters,
    setClearFilters,
    form,
}) => {
    const { locale } = useLocaleContext();
    const [filterKey, setFilterKey] = useState<string>('');
    const [customers, setCustomers] = useState<string[]>([]);
    const [departments, setDepartments] = useState<string[]>([]);
    const [orgUnits, setOrgUnits] = useState<string[]>([]);
    const [position, setPosition] = useState<string[]>([]);
    const [filterValues, setFilterValues] = useState<any>({
        competences: {},
        departments: {},
        program: {},
        position: {},
        customersUuid: {},
        orgUnit: {},
        userTypeId: {}
    })
    const [tags, setTags] = useState({})
    const [tempFilter, setTempFilter] = useState<any>({})

    const intl = useIntl();

    useEffect(() => {
        if (clearFilters) {
            setClearFilters(false)
            setTags({})
            setTempFilter({})
            form.resetFields();
        }
    }, [clearFilters])

    const handleFormSubmit = () => {
        const filterQuery = generalHelpers.filterParser(tempFilter);

        filter(filterQuery)
        setLoad(true)
    }

    const clearFilter = () => {
        filter('')
        setTempFilter('')
        form.resetFields();
        setTags({})
    }

    const tagBuilder = () => {
        const fieldsValue = form.getFieldsValue()

        const tempTags: any[] = []

        Object.entries(fieldsValue).map((filter: any) => {
            if (filter[0].toLowerCase().includes('status') && filter[1] && filter[1].length) {
                const statusArray: any = {
                    VALID: 'competences.status_valid',
                    EXPIRING: 'competences.status_expiring',
                    NOT_VALID: 'competences.status_not_valid',
                    ACTIVE: 'user.status.ACTIVE',
                    BLOCKED: 'user.status.BLOCKED',
                    IN_PROGRESS: 'general.in_progress',
                    NOT_STARTED: 'general.not_started',
                    COMPLETED: 'general.completed',
                    FAILED: 'general.failed',
                    PASS_ON_TIME: 'general.passed_on_time',
                    DELAYED: 'general.delayed',
                    MANUAL_ENROLLMENT: 'general.enrollment.manual_enrollment',
                    ENROLLMENT_CANCELLED: 'general.enrollment.enrollment_cancelled',
                    OVERDUE_DEADLINE: 'general.overdue_deadline',
                    EXECUTED_ON_TIME: 'general.executed_on_time',
                    EXECUTED_LATE: 'general.executed_late',
                }

                if (!Array.isArray(filter[1])) {

                    setTempFilter((tempFilter: any) => ({ ...tempFilter, [filter[0]]: filter[1] }));

                    tempTags.push(
                        {
                            key: filter[0],
                            label: intl.formatMessage({ id: statusArray[filter[1]] }),
                            id: filter[1]
                        }
                    )

                } else {
                    setTempFilter((tempFilter: any) => ({ ...tempFilter, [filter[0]]: filter[1].join(',') }));
                    
                    Object.values(filter[1]).map((element: any) => {

                        tempTags.push(
                            {
                                key: filter[0],
                                label: intl.formatMessage({ id: statusArray[element] }),
                                id: element
                            }
                        )
                    })
                }
            } else if (filter[1] && filter[1].length && filter[1][0] && moment.isMoment(filter[1][0])) {

                setTempFilter((tempFilter: any) => ({ ...tempFilter, [filter[0]]: [filter[1][0], filter[1][1]] }));

                tempTags.push(
                    {
                        key: filter[0],
                        label: moment(filter[1][0]).format('DD.MM.YYYY') + ' - ' + moment(filter[1][1]).format('DD.MM.YYYY'),
                        id: moment(filter[1][0]).format('YYYY-MM-DD') + ',' + moment(filter[1][1]).format('YYYY-MM-DD')
                    }
                )
            } else if (['isCancelled', 'userAbsence'].includes(filter[0])) {
                if (filter[1] !== undefined) {
                    setTempFilter((tempFilter: any) => ({ ...tempFilter, [filter[0]]: filter[1] }));

                    Object.values(filter[1]).map((element: any) => {
                        tempTags.push(
                            {
                                key: filter[0],
                                label: intl.formatMessage({ id: element ? 'general.yes' : 'general.no' }),
                                id: element
                            }
                        )
                    })
                }
            } else if (filter[1] && filter[1].length) {
                let temp: any = [];

                if (tempFilter[filter[0]] && tempFilter[filter[0]].length) {

                    temp = tempFilter[filter[0]].concat(filter[1])

                    temp = generalHelpers.arrayUnique(temp)

                    setTempFilter((tempFilter: any) => ({ ...tempFilter, [filter[0]]: temp }));
                } else {
                    temp = filter[1]

                    setTempFilter((tempFilter: any) => ({ ...tempFilter, [filter[0]]: filter[1] }));
                }

                Object.values(temp).map((element: any) => {
                    let title = '';
                    if (typeof filterValues[filter[0]][element] === 'object') {
                        title = filterValues[filter[0]][element][locale] ? filterValues[filter[0]][element][locale] : filterValues[filter[0]][element][Object.keys(filterValues[filter[0]][element])[0]]
                    } else {
                        title = filterValues[filter[0]][element]
                    }

                    tempTags.push(
                        {
                            key: filter[0],
                            label: title,
                            id: element
                        }
                    )
                })
            } else if (filter[0].toLowerCase().includes('count') && filter[1]) {
                const label: any = {
                    attemptsCount: 'general.attempts_count',
                }

                setTempFilter((tempFilter: any) => ({ ...tempFilter, [filter[0]]: filter[1] }));

                tempTags.push(
                    {
                        key: filter[0],
                        label: intl.formatMessage({ id: label[filter[0]] }, { count: filter[1] }),
                        id: filter[1]
                    }
                )
            } else if (filter[0].toLowerCase().includes('bool') && filter[1]) {
                const label: any = {
                    attemptsHistoryBool: 'general.multiple_attempts',
                }

                setTempFilter((tempFilter: any) => ({ ...tempFilter, [filter[0]]: filter[1] }));

                tempTags.push(
                    {
                        key: filter[0],
                        label: intl.formatMessage({ id: label[filter[0]] }, { count: filter[1] }),
                        id: !!filter[1]
                    }
                )
            }
        })

        setTags(tempTags)
    }

    const filterButtons = () => {
        switch (filterType) {
            case 'LEARNING_PROGRAM':
                return (
                    <>
                        <UsersFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            orgUnits={orgUnits}
                            departments={departments}
                            customers={customers}
                            tagBuilder={tagBuilder}
                            position={position}
                        />
                        <OrganizationsFilter
                            form={form}
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            setCustomers={setCustomers}
                            tagBuilder={tagBuilder}
                        />
                        <PositionsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            setPosition={setPosition}
                            orgUnits={orgUnits}
                            departments={departments}
                            customers={customers}
                            tagBuilder={tagBuilder}
                        />
                        <OrganizationalUnitsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            setOrgUnits={setOrgUnits}
                            departments={departments}
                            customers={customers}
                            tagBuilder={tagBuilder}
                        />
                        <DepartmentsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            setDepartments={setDepartments}
                            customers={customers}
                            tagBuilder={tagBuilder}
                        />
                        <DateFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            name={'regDate'}
                            tagBuilder={tagBuilder}
                            title={'general.search_by_register_date'}
                            buttonTitle={'general.registration_period'}
                        />
                        <CompetenceFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            tagBuilder={tagBuilder}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                        />
                        <LearningProgramFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <DateFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            name={'validToDate'}
                            tagBuilder={tagBuilder}
                            title={'general.certificate_expatriation'}
                            buttonTitle={'general.certificate_expatriation'}
                        />
                        <AttemptsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <RegisterTypeFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                    </>
                )
            case 'LECTURE':
                return (
                    <>
                        <UsersFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            orgUnits={orgUnits}
                            departments={departments}
                            customers={customers}
                            tagBuilder={tagBuilder}
                            position={position}
                        />
                        <OrganizationsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            setCustomers={setCustomers}
                            tagBuilder={tagBuilder}
                        />
                        <PositionsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            setPosition={setPosition}
                            orgUnits={orgUnits}
                            departments={departments}
                            customers={customers}
                            tagBuilder={tagBuilder}
                        />
                        <OrganizationalUnitsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            setOrgUnits={setOrgUnits}
                            departments={departments}
                            customers={customers}
                            tagBuilder={tagBuilder}
                        />
                        <DepartmentsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            setDepartments={setDepartments}
                            customers={customers}
                            tagBuilder={tagBuilder}
                        />
                        <LectureFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <SessionFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <DateFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            name={'regDate'}
                            tagBuilder={tagBuilder}
                            title={'general.search_by_register_date'}
                            buttonTitle={'general.registration_period'}
                        />
                        <DateFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            name={'sessionDate'}
                            tagBuilder={tagBuilder}
                            title={'general.search_by_session_date'}
                            buttonTitle={'general.session_date'}
                        />
                    </>
                )
            case 'SESSIONS':
                return (
                    <>
                        <DateFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            name={'sessionDate'}
                            tagBuilder={tagBuilder}
                            title={'general.search_by_session_date'}
                            buttonTitle={'general.session_date'}
                        />
                        <OrganizationsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            setCustomers={setCustomers}
                            tagBuilder={tagBuilder}
                        />
                        <LectureFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <SessionFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <LectorsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <LectorsDepartmentsFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                    </>
                )
            case 'AUDIENCE':
                return (
                    <>
                        <AudienceFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <CompetenceBlockFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <CompetenceFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                        <LearningProgramFilter
                            filterKey={filterKey}
                            setFilterKey={setFilterKey}
                            form={form}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            tagBuilder={tagBuilder}
                        />
                    </>
                )
        }
    }

    return (
        <>
            <div className={'flex c-gray align-center gap-5'}>
                <i className="fa-light fa-filter"></i>
                <FormattedMessage id={'general.filter'} />
            </div>
            <div>
                <Form form={form} onFinish={handleFormSubmit}>
                    {filterButtons()}
                </Form>
                <Divider plain className={'m-2'} />
                <div className={'flex justify-between'}>
                    <TagBuilder
                        tags={tags}
                        form={form}
                        setTags={setTags}
                        tempFilter={tempFilter}
                        setTempFilter={setTempFilter}
                    />
                    <div className={'flex gap-5 p-2'}>
                        <Button type={'primary'} onClick={() => handleFormSubmit()}>
                            <FormattedMessage id={'general.filter'} />
                        </Button>
                        <Button type={'outlined'} onClick={() => clearFilter()}>
                            <FormattedMessage id={'general.clear'} />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filter;