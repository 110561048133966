import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, Button, FormInstance,} from 'antd';
import apiClient from 'utils/apiClient';
import useHandleError from 'utils/useHandleError';

const VARIABLE_NODE_CLASS_NAME = 'email_template.variable';

const mapStateToProps = (state: any) => {
    return {
        campaignData: state.campaignData,
        session: state.session
    };
}

interface VariablesInterface {
    mailable: string;
    editor: any;
    managingOrganization?: string;
    form: FormInstance;
    inputType: 'htmlTemplate' | 'smsTemplate';
}


const Variables: React.FC<VariablesInterface> = ({mailable, editor, managingOrganization, form, inputType}) => {
    const [variables, setVariables] = useState([]);

    const [handleError] = useHandleError();
    const intl = useIntl();

    useEffect(() => {
        if (mailable) {
            loadVariables();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailable]);

    const loadVariables = async () => {
        try {
            // custom/{mailable}/variables

            const response = await apiClient.request(`/api/v1/mail-templates/standard/${mailable}/variables`, {}, 'GET');
            return setVariables(response);
        } catch (error) {
            handleError(error)
        }
    }

    const addVariableToSmsText = (variable: string) => {

        let smsTemplate = form.getFieldValue('smsTemplate');

        if (!smsTemplate) {
            smsTemplate = '';
        }

        smsTemplate += ` |*${variable}*| `

        form.setFieldsValue({
            smsTemplate: smsTemplate
        });
    }

    return (
        <Form.Item
            label={intl.formatMessage({id: "general.variables"})}
        >
            {variables && variables.length > 0
                ? variables.map((variable: string) => {
                    if (variable === 'mailTemplate') {
                        return;
                    }

                    return (
                        <Button size="small" style={{marginRight: 8, marginBottom: 4, padding: 4}}
                                onClick={() => {
                                    if (inputType === 'htmlTemplate') {
                                        switch (variable) {
                                            case 'managing_organization_support_email':
                                            case 'managing_organization_support_phone':
                                                // editor.insertContent(`<span data-variable="${variable}" class="${VARIABLE_NODE_CLASS_NAME}">{{${intl.formatMessage({id: "email_template.variable." + variable}, {organization: managingOrganization ? managingOrganization : intl.formatMessage({id: 'email_template.managing_organization'})})}}}</span> `);
                                                return true;

                                            default:
                                                editor.insertContent(`<span data-variable="${variable}" class="${VARIABLE_NODE_CLASS_NAME}">{{${intl.formatMessage({id: "email_template.variable." + variable})}}}</span> `);
                                                return true;
                                        }
                                    } else if (inputType === 'smsTemplate') {
                                        addVariableToSmsText(variable)
                                    }

                                }}
                        >
                            {variable === 'managing_organization_support_email' || variable === 'managing_organization_support_phone' ?
                                managingOrganization ?
                                    variable === 'managing_organization_support_email' ?
                                        <FormattedMessage
                                            id='email_template.variable.managing_organization_support_email'
                                            values={{organization: managingOrganization}}/>
                                        :
                                        <FormattedMessage
                                            id='email_template.variable.managing_organization_support_phone'
                                            values={{organization: managingOrganization}}/>
                                    :
                                    <FormattedMessage id={`email_template.variable.${variable}`}/>
                                :
                                <FormattedMessage id={`email_template.variable.${variable}`}/>
                            }
                        </Button>
                    )
                })
                :
                <p className='c-red'>
                    <FormattedMessage id={"emailing.templates.choose_mailable"}/>
                </p>
            }
        </Form.Item>
    )
}

export default connect(mapStateToProps)(Variables);
