import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Form, Transfer} from 'antd';
import useHandleError from 'utils/useHandleError';
import {connect} from "react-redux";
import {LocaleText, Modal} from 'ui'
import coursesApiClient from "utils/coursesApiClient";
import {Select} from "../../../Form";
import generalHelper from "../../../../utils/generalHelpers";
import {useParams} from "react-router-dom";
import generalHelpers from "../../../../utils/generalHelpers";

interface ActivityModalInterface {
    visible: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    setReload: React.Dispatch<React.SetStateAction<boolean>>;
    session: any;
    program: any;
}

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

const ActivityModal: React.FC<ActivityModalInterface> = ({
                                                             visible,
                                                             onCancel,
                                                             session,
                                                             setReload,
                                                             program
                                                         }) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const [handleError] = useHandleError();
    const [isCourseListLoading, setIsCourseListLoading] = useState(false);
    const isCustomerAdmin = session.active_user_type === 'CUSTOMER_ADMIN';
    const [courseList, setCourseList] = useState([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activities, setActivities] = useState([])
    const params = useParams();
    const programId = params.id;

    useEffect(() => {
        if (generalHelpers.isPlatformAdmin(session.active_user_type)) {
            loadCourses(program.ownerUuid, program.ownerType);
        } else {
            loadCourses(session.organization.organization_id, session.organization.organization_type);
        }
    }, []);

    const loadActivities = async (courseId: any) => {
        let response;
        let ids: string[] = selectedCourses;

        if (courseId) {
            ids = ids.concat(courseId);
            setSelectedCourses((selectedCourses: any) => [...selectedCourses, courseId])
        }

        let url = `/api/v1/courses/campaigns/${ids}/learning-activities/valid`;

        if (generalHelpers.isPlatformAdmin(session.active_user_type)) {
            switch (program.ownerType) {
                case "CUSTOMER":
                    url += '?customerId=' + program.ownerUuid
                    break;

                case "ORGANIZATION":
                    url += '?organizationId=' + program.ownerUuid
                    break;
            }
        } else if (!isCustomerAdmin) {
            switch (session.organization.organization_type) {
                case "CUSTOMER":
                    url += '?customerId=' + session.organization.organization_id
                    break;

                case "ORGANIZATION":
                    url += '?organizationId=' + session.organization.organization_id
                    break;
            }
        }

        if (ids?.length) {
            response = await coursesApiClient.request(url, {}, 'POST');
            setActivities(response.activities);
        } else {
            setActivities([]);
        }
    };

    const loadCourses = async (id: number, type: 'CUSTOMER' | 'ORGANIZATION') => {
        setCourseList([]);
        form.setFieldsValue({includedCourses: []});
        if (!id) {
            return;
        }
        try {
            setIsCourseListLoading(true);

            let url = '/api/v1/courses/courses/valid';

            if (!isCustomerAdmin) {
                switch (type) {
                    case "CUSTOMER":
                        url += '?customerId=' + id
                        break;

                    case "ORGANIZATION":
                        url += '?organizationId=' + id
                        break;
                }
            }

            const response = await coursesApiClient.request(url, {}, 'POST');
            const courses = response.courses;

            const courseListObject: any = {};

            Object.values(courses).map((course: any) => {
                return courseListObject[course.id] = <LocaleText text={course.name_translations} defaultText={course.name}/>;
            });

            setCourseList(courseListObject);
        } catch (error) {
            handleError(error)
        } finally {
            setIsCourseListLoading(false);
        }
    };

    const submitForm = async () => {
        setIsLoading(true)
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-program/materials/${programId}/add`, {learningActivities: targetKeys}, 'POST');
            onCancel(false)
            setReload(true)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleChange = (keys: any) => {
        setTargetKeys(keys);
    }

    const selectChange = (sourceSelectedKeys: any) => {
        setSelectedKeys(sourceSelectedKeys);
    }

    const parseActivities = (activities: any) => {
        let collectedActivities: any = [];

        Object.values(activities).map((course: any) => {
            Object.values(course).map((activity: any) => {
                if (activity.status === 'ONLINE') {
                    collectedActivities.push({
                        key: activity.id,
                        titles: <LocaleText text={activity.title}/>
                    });
                }
                return collectedActivities;
            })
            return collectedActivities;
        })

        return collectedActivities;
    }

    return (
        <Modal
            width={'70%'}
            isLoading={isLoading}
            open={visible}
            title={intl.formatMessage({id: 'program.activity_append_modal'})}
            onOk={() => form.submit()}
            onCancel={() => onCancel(false)}
            okText={intl.formatMessage({id: 'general.add'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
        >
            <Form form={form} onFinish={submitForm}>
                <Select
                    name="includedCourses"
                    label={intl.formatMessage({id: 'campaign.included_courses'})}
                    valueKey="value"
                    labelKey="label"
                    manualOptions={courseList}
                    customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                    showSearch
                    customLayout={{ labelCol: {span: 6}, wrapperCol: {span: 18} }}
                    loading={isCourseListLoading}
                    mode="multiple"
                    onSelect={(el: any) => loadActivities(el)}
                    disabled={generalHelper.isEmpty(courseList)}
                    showArrow
                    filterOption={(input: string, option: any) => {
                        return option?.children?.props?.defaultText?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                />
                <Transfer
                    className='transferer-style h-20'
                    locale={{
                        notFoundContent: intl.formatMessage({id: 'general.found_no_data'}),
                        selectInvert: intl.formatMessage({id: 'campaign.invert_values'}),
                        selectAll: intl.formatMessage({id: 'campaign.select_all_values'}),
                        removeAll: intl.formatMessage({id: 'campaign.remove_all_values'})
                    }}
                    dataSource={parseActivities(activities)}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onSelectChange={(e) => selectChange(e)}
                    onChange={(e) => handleChange(e)}
                    render={(item: any) => item.titles}
                    oneWay
                    showSelectAll={false}
                />
            </Form>
        </Modal>
    )
};

export default connect(mapStateToProps)(ActivityModal);
