import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Input, Radio, FormInstance, message, Tooltip } from 'antd';
import { Switch, InputNumber } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import { FileTable, FileUpload, RichTextEditor } from 'ui';
import Spinner from 'components/Spinner';
import TemplateEditor from 'components/TemplateEditor';

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

type AssignmentFormType = {
    type: 'ASSIGNMENT',
    language: string,
    settings: {
        title: string;
        description: string;
        fileUpload: boolean;
        evaluationType: 'EXAMINATION' | 'RANGE' | 'COMMENT' | 'NO_EVALUATION';
        deadline: number;
        passRate?: number;
    }
}

interface AssignmentFormInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    viewMaterial?: any;
    onCancel: any;
    reload: Function;
    courseId: number;
}

const AssignmentForm: React.FC<AssignmentFormInterface> = ({
    language,
    form,
    activityId,
    viewMaterial,
    onCancel,
    reload,
    courseId,
}) => {
    const [evaluationType, setEvaluationType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState<string>('');
    const [editor, setEditor] = useState<any>();

    const intl = useIntl();

    useEffect(() => {
        if (viewMaterial) {

            const materialSettings = typeof viewMaterial.settings === 'string' ? JSON.parse(viewMaterial.settings) : viewMaterial.settings;

            if (viewMaterial.settings) {
                form.setFieldsValue({
                    title: materialSettings.title,
                    evaluationType: materialSettings.evaluation_type,
                    deadline: materialSettings.deadline,
                    passRate: materialSettings.pass_rate,
                    fileUpload: !!materialSettings.file_upload
                });
                setEvaluationType(materialSettings.evaluation_type ? materialSettings.evaluation_type : '')
                setDescription(materialSettings.description ? materialSettings.description : '')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMaterial]);

    const uploadMaterial = async (materialId: number) => {

        const values = form.getFieldsValue();

        if (!values.materialsFiles) {
            return;
        }

        try {
            let bodyFormData = new FormData();

            for (let i = 0; i < values.materialsFiles.length; i++) {
                if (values.materialsFiles[i].originFileObj) {
                    bodyFormData.append('file_' + i, values.materialsFiles[i].originFileObj);
                } else {
                    bodyFormData.append('file_' + i, values.materialsFiles[i]);
                }
            }

            await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/${materialId}/upload-assignment-file`, bodyFormData, 'POST', true, true);

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    const submitForm = async (values: any) => {
        setIsLoading(true);
        try {
            const parsedValues: AssignmentFormType = {
                type: 'ASSIGNMENT',
                language: language,
                settings: {
                    title: values.title,
                    description: description,
                    fileUpload: !!values.fileUpload,
                    evaluationType: values.evaluationType,
                    deadline: values.deadline
                }
            }

            if (values.evaluationType === 'RANGE') {
                parsedValues.settings.passRate = values.passRate
            }
            let response;

            if (viewMaterial) {
                response = await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/${viewMaterial.id}`, parsedValues, 'PUT');
                await uploadMaterial(viewMaterial.id);
            } else {
                response = await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/add`, parsedValues, 'POST');
                await uploadMaterial(response.material.settings.id);
            }

            onCancel();
            reload(activityId);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Form form={form} onFinish={submitForm} >
            <Spinner spinning={isLoading}>
                <Form.Item 
                    {...formItemLayout}
                    name='title'
                    label={intl.formatMessage({ id: 'general.title' })}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                >
                    <Input />
                </Form.Item>
                <TemplateEditor 
                    height={300}
                    layout={formItemLayout}
                    label={intl.formatMessage({ id: 'general.description' })}
                    html={description}
                    setHtml={(value: any) => setDescription(value ?? '')}
                    setEditor={setEditor}
                />
                <Form.Item
                    {...formItemLayout}
                    name='evaluationType'
                    label={intl.formatMessage({ id: 'courses.evaluation_type' })}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                >
                    <Radio.Group onChange={(event: any) => setEvaluationType(event.target.value)}>
                        <Radio value='EXAMINATION'>
                            <FormattedMessage id='courses.examination' />
                        </Radio>
                        <Radio value='RANGE'>
                            <FormattedMessage id='courses.rating_range' />
                        </Radio>
                        <Radio value='NO_EVALUATION'>
                            <FormattedMessage id='courses.no_evaluation' />
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <InputNumber
                    name='deadline'
                    label={
                        <span>
                            {intl.formatMessage({ id: 'courses.deadline_in_days' })}
                            <Tooltip title={intl.formatMessage({ id: 'courses.deadline_in_days_hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </span>
                    }
                    customLayout={formItemLayout}
                    min={1}
                />
                {evaluationType === 'RANGE' &&
                    <InputNumber
                        name='passRate'
                        label={intl.formatMessage({ id: 'courses.pass_rate' })}
                        validation={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                        customLayout={formItemLayout}
                        min={0}
                        max={100}
                        formatter={(value: number) => `${value}%`}
                        parser={(value: string) => value!.replace('%', '')}
                    />
                }
                <Switch
                    customLayout={formItemLayout}
                    defaultChecked={false}
                    hasDefaultLayout={false}
                    isFormItem
                    name='fileUpload'
                    label={<span>
                        {intl.formatMessage({ id: 'courses.assignment_upload' })}
                        <Tooltip title={intl.formatMessage({ id: 'courses.assignment_upload_hint' })}>
                            <i className='fal fa-question-circle header-item' />
                        </Tooltip>
                    </span>}
                />
                <FileUpload multiple={true} layout={formItemLayout} name="materialsFiles" label="courses.assignment_materials" buttonLabel='general.upload_files' />
                {viewMaterial && viewMaterial.fileSettings &&
                    <FileTable 
                        activityId={activityId} 
                        courseId={courseId} 
                        fileArray={JSON.parse(viewMaterial.fileSettings)} 
                        isAssignment={true} 
                        assignmentId={viewMaterial.id} 
                        allowAction={true} 
                    />
                }
            </Spinner>
        </Form>
    )
}
export default AssignmentForm;
