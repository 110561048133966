import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { UploadFile } from "antd/lib/upload/interface";

export interface LectureProps {
    id?: number,
    programId?: number,
    title?: string,
    description?: string,
    completedType?: LectureCompletedType,
    isEditable?: boolean,
    customFieldValues?: any,
    customFields?: any,
}
export interface SessionProps {
    id?: number,
    lecturesId?: number,
    title?: string,
    description?: string,
    type?: LectureSessionType,
    eventType?: LectureSessionEventType,
    interval?: LectureSessionIntervalType,
    dayOfTheWeek?: CheckboxValueType[],
    dayOfTheMonth?: number,
    date?: string,
    start?: string,
    end?: string,
    period?: string[] | undefined,
    link?: string,
    zoom?: any,
    lectors?: SessionLectorProps[] | undefined,
    locations?: SessionResourceProps[] | undefined,
    resources?: SessionResourceProps[] | undefined,
    licenses?: SessionResourceProps[] | undefined,
    files?: UploadFile[] | undefined,
    registeredBy?: LectureSessionRegisteredByType,
    limitedPeriod?: boolean | 1 | 0,
    closesBefore?: number,
    opensBefore?: number,
    remindBefore?: number,
    capacity?: number,
    attendees?: number,
    isEditable?: boolean,
    yearAndMonth?: number,
    actions?: {
        edit?: boolean,
        cancel?: boolean,
    }
    status?: LectureSessionAttendeeStatus | LectureSessionStatus,
    customFieldValues?: any,
    customFields?: any,
    access?: string
}

export interface SessionLectorProps {
    id: number,
    name?: string,
}

export interface SessionResourceProps {
    id: number,
    name?: string,
}

export interface SessionTabProps {
    lecture: LectureProps,
    session: SessionProps,
    userSession?: any,
    reloadSession(): any,
    locale?: any,
}

export interface MySessionsListData {
    name: string,
    sessions: SessionProps[],
}

export interface MySessionsCalendarData {
    id: string,
    allDay: boolean,
    start: string,
    end: string,
    backgroundColor: string,
    color: string,
    tooltipContent: string[]
}

export interface LectureNotificationsProps {
    signUpLectures? : {
        id: number,
        title: string,
    }[],
    pastSessions? : {
        id: number,
        title: string,
        lecturesId: number
    }[],
    pastAttendanceSessions? : {
        id: number,
        title: string,
        lecturesId: number
    }[],
}

export enum LectureCompletedType {
    Automatic = 'AUTOMATIC',
    Manual = 'MANUAL',
}

export enum LectureSessionType {
    Online = 'ONLINE',
    ClassRoom = 'CLASSROOM',
}

export enum LectureSessionEventType {
    OneTime = 'ONETIME',
    Reccuring = 'RECCURING',
}

export enum LectureSessionRegisteredByType {
    TrainingManager = 'TRAININ_GMANAGER',
    TrainingAndDirectManager = 'TRAINING_AND_DIRECTMANAGER',
    SelfRegistration = 'SELF_REGISTRATION',
}

export enum LectureSessionStatus {
    Planned = 'PLANNED',
    Completed = 'COMPLETED',
    InProgress = 'IN_PROGRESS',
    NotStarted = 'NOT_STARTED',
    NotApplicable = 'NOT_APPLICABLE',
}

export enum LectureSessionAttendeeStatus {
    Planned = 'PLANNED',
    Attended = 'ATTENDED',
    Missed = 'MISSED',
    Removed = 'REMOVED',
}

export enum LectureSessionIntervalType {
    Daily = 'DAILY',
    Weekly = 'WEEKLY',
    Monthly = 'MONTHLY',
}

export enum LectureSessionFilterType {
    Upcoming = 'UPCOMING',
    Past = 'PAST',
}
