import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import moment from 'moment';
import {Table, Input as AntDInput, Popover, Tag, Tooltip, Progress, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import CampaignFilterModal from "components/FormCampaign/CampaignFilterModal";
import InstructionButton from "components/VideoInstructions/InstructionButton";
import DefaultLayout from 'components/DefaultLayout';
import FlexRow from 'components/FlexRow';
import {Switch} from 'components/Form';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import CampaignCreationModal from 'components/CampaignForm/Modals/CampaignCreationModal';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        campaignData: state.campaignData,
        campaignCustomerFilter: state.campaignCustomerFilter,
    };
};

const ManageELearning: React.FC = ({session}: any) => {
    const [isCampaignLoading, setIsCampaignLoading] = useState(false);
    const [campaignList, setCampaignList] = useState([]);
    const [campaignOriginalList, setCampaignOriginalList] = useState([]);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showOnlyUnfinishedCampaigns, setShowOnlyUnfinishedCampaigns] = useState(true);

    const [showCampaignFilterModal, setShowCampaignFilterModal] = useState(false);
    const [showCreationModal, setShowCreationModal] = useState(false)

    const intl = useIntl();
    const {Search} = AntDInput;
    const includeColumn = ['name'];

    const loadCampaign = async () => {
        setIsCampaignLoading(true);
        let customerUuid = session.organization.organization_uuid;
        if (customerUuid !== undefined) {
            let url = showOnlyUnfinishedCampaigns ? `/api/v1/courses/customer/${customerUuid}/unfinished-campaigns` : `/api/v1/courses/customer/${customerUuid}/campaigns`;
            let campaignList = await coursesApiClient.request(url, {}, 'GET');
            setCampaignList(campaignList.campaigns);
            setCampaignOriginalList(campaignList.campaigns);
        }
        setIsCampaignLoading(false);
    };

    const columns = [
        {
            width: 65,
            render: (record: any) => {
                return (
                    <div className='content-column'>
                        {
                            record.campaignThumbnail ? <i className={`fal ${record.campaignThumbnail}`}/> :
                                <i className="fal fa-book"></i>
                        }
                    </div>
                );
            }
        },
        {
            title: intl.formatMessage({id: 'general.title'}),
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            render: (_text: string, record: any) => {
                return (
                    <NavLink
                        to={record['status'] === 'DRAFT' ? `/campaign/${record.id}/settings` : `/campaign/${record.id}/overview`}>
                        {record.name}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.begin_date'}),
            dataIndex: 'beginDate',
            key: 'beginDate',
            sorter: (firstEntry: any, secondEntry: any) => moment(firstEntry.beginDate).unix() - moment(secondEntry.beginDate).unix(),
            render: (_text: string, record: any) => record.beginDate ? (moment(record.beginDate).format('DD.MM.YYYY')) : '-'
        },
        {
            title: intl.formatMessage({id: 'general.end_date'}),
            dataIndex: 'deadline',
            key: 'deadline',
            sorter: (firstEntry: any, secondEntry: any) => moment(firstEntry.deadline).unix() - moment(secondEntry.deadline).unix(),
            render: (_text: string, record: any) => record.deadline ? moment(record.deadline).format('DD.MM.YYYY') : '-'
        },
        {
            title: intl.formatMessage({id: 'campaign.campaign_progress'}),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: (firstEntry: any, secondEntry: any) => firstEntry.campaignProgress - secondEntry.campaignProgress,
            render: (_text: string, record: any) => {
                if (record.campaignProgress !== undefined) {
                    return (
                        <Tooltip
                            title={intl.formatMessage({id: 'campaign.procent_completed'}, {amount: record.campaignProgress})}>
                            <Progress success={{percent: record.campaignProgress, strokeColor: '#52C419'}}
                                      percent={record.campaignOngoingProgress + record.campaignProgress}
                                      showInfo={false} strokeColor='#6A8ECB' trailColor='#F9AD15'/>
                        </Tooltip>
                    );
                }
            }
        },
        {
            title: intl.formatMessage({id: 'campaign.users_enrolled'}),
            dataIndex: 'studentsCount ',
            render: (_text: string, record: any) => {
                return record.studentsCount;
            }
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            dataIndex: 'status',
            key: 'status',
            render: (_text: string, record: any, tag: any) => {
                let color;
                if (record.status) {
                    let readyParams = record.readyToLaunch;
                    let ready = false;

                    if (!record.phishingEnabled) {
                        if (readyParams.activities && readyParams.certificate && readyParams.settings && readyParams.students) {
                            ready = true
                        }
                    }

                    if (!!record.phishingEnabled) {
                        if (readyParams.activities && readyParams.certificate && readyParams.settings && readyParams.students && readyParams.phishing) {
                            ready = true
                        }
                    }

                    if (ready && record.status === 'DRAFT') {
                        const launchCampaign = async () => {
                            try {
                                setIsCampaignLoading(true);
                                setCampaignList([]);
                                await apiClient.request(`/api/v1/courses/campaigns/${record.id}/launch`, {}, 'POST');
                                loadCampaign();
                                setIsCampaignLoading(false);
                            } catch (error) {
                                console.error(error);
                                setIsCampaignLoading(false);
                            }
                        };
                        return (
                            <Button onClick={() => launchCampaign()} className='launch-button'>
                                <i style={{paddingRight: '8px'}} className="fal fa-rocket"/>
                                <FormattedMessage id="campaign.launch_campaign"/>
                            </Button>
                        )
                    } else {
                        if (record.status === 'DRAFT') color = 'gold';
                        if (record.status === 'ONGOING') color = 'blue';
                        if (record.status === 'FINISHED') color = 'green';
                        return (
                            <Tag color={color} key={tag} className={record.status}>
                                {intl.formatMessage({id: `campaign.status.${record.status}`})}
                            </Tag>
                        );
                    }
                } else {
                    return '-';
                }
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <>
                        <NavLink to={`/campaign/${record.id}/settings`}>
                            <div className="popover-item">
                                <FormattedMessage id="general.edit"/>
                            </div>
                        </NavLink>
                        {
                            record.status === 'DRAFT' ?
                                <div onClick={() => {
                                    deleteCampaign(record.id);
                                }} className="popover-item">
                                    <FormattedMessage id="general.delete"/>
                                </div>
                                : null
                        }
                    </>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const showTotal = (total: any, range: any) => {
        return `${range[0]}-${range[1]} of ${total}`
    };

    const deleteCampaign = async (id: any) => {
        setVisibleTooltip(null);
        await coursesApiClient.request(`/api/v1/courses/campaigns/${id}/delete`, {}, 'POST');
        loadCampaign();
    };

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const filterBySearchString = (searchText: string, data: never[], includeColumn: string[]) => {
        return !searchText ? campaignOriginalList : campaignOriginalList.filter((data: any) => {
            return Object.keys(data).some(key => includeColumn.includes(key) ? data[key].toString().toLowerCase().includes(searchText.toLocaleLowerCase()) : false);
        });
    };

    const filterBySearch = async (value: string) => {
        let data = filterBySearchString(value, campaignOriginalList, includeColumn);
        setCampaignList(data);
    };

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'courses.manage_content'})}/>

            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                        <>
                            {
                                session.active_user_type !== 'SUPERVISION' &&
                              <>
                                <Button type="primary" icon={<PlusOutlined/>}
                                        onClick={() => setShowCreationModal(true)}>
                                  <FormattedMessage id="campaign.add_new_campaign"/>
                                </Button>
                                <Button type="primary" icon={<i className="fal fa-filter"></i>}
                                        onClick={() => setShowCampaignFilterModal(true)}>
                                  <FormattedMessage id="general.filter"/>
                                </Button>
                              </>
                            }
                        </>
                    }
                    right={
                        <>
                            <label>{intl.formatMessage({id: "campaigns.dont_show_finished"})}</label>
                            <Switch
                                name="showUnfinishedCampaigns"
                                onChange={(finished: boolean) => {
                                    setShowOnlyUnfinishedCampaigns(finished);
                                }}
                                defaultChecked={showOnlyUnfinishedCampaigns}

                            />
                            <Search
                                className="table-search-field"
                                placeholder={intl.formatMessage({id: "general.search"})}
                                onChange={(value: any) => filterBySearch(value.target.value)}
                            />
                            <InstructionButton position='E_LEARNING_MANAGEMENT'/>
                        </>
                    }
                />
                <Table
                    locale={{
                        emptyText: intl.formatMessage({id: "general.found_no_data"}),
                        filterConfirm: intl.formatMessage({id: "general.filter"}),
                        filterReset: intl.formatMessage({id: "general.clear"}),
                        triggerDesc: intl.formatMessage({id: "general.trigger_desc"}),
                        triggerAsc: intl.formatMessage({id: "general.trigger_asc"}),
                        cancelSort: intl.formatMessage({id: "general.cancel_sort"}),
                    }}
                    loading={isCampaignLoading}
                    rowKey={(record) => record}
                    style={{margin: 0, marginTop: 15}}
                    columns={columns}
                    dataSource={campaignList}
                    size="middle"
                    scroll={{x: 800}}
                    pagination={{
                        showTotal: showTotal,
                        pageSizeOptions: ['10', '20'],
                        showSizeChanger: true,
                        locale: {
                            items_per_page: intl.formatMessage({id: "general.page"}),
                            jump_to: intl.formatMessage({id: "general.go_to"})
                        },
                        showQuickJumper: true
                    }}
                />
            </DefaultLayout.PageContent>
            <CampaignFilterModal
                visible={showCampaignFilterModal}
                onCancel={() => setShowCampaignFilterModal(false)}
                setCampaignList={setCampaignList}
                setCampaignOriginalList={setCampaignOriginalList}
                setLoading={setIsCampaignLoading}
                finished={showOnlyUnfinishedCampaigns}
            />
            <CampaignCreationModal
                visible={showCreationModal}
                onCancel={() => setShowCreationModal(false)}
            />
        </DefaultLayout.PageLayout>
    );
};

export default connect(mapStateToProps)(ManageELearning);
