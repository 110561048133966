export enum SpecialClientEnumsType {
    design = 'design',
    terms = 'terms',
    logo = 'logo',
    env = 'env',
    favicon = 'favicon',
    title = 'title',
    theme = 'theme',
}


export interface SpecialClientInterface {
    design: string;
    terms?: string;
    logo?: string;
    env?: string;
    favicon?: string;
    title?: string;
    theme: any;
}
