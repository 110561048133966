import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import DefaultLayout from "components/DefaultLayout";
import useHandleError from "utils/useHandleError";
import { message, Form, Input, Button, Row, Col, Space, Tag, Modal, Popover } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import FileDownload from 'js-file-download';

import coursesApiClient from "utils/coursesApiClient";
import Spinner from "components/Spinner";
import {QuestionsList} from "components/QuestionPool/index";
import debounce from "lodash/debounce";
import QuestionEdit from "../../../components/QuestionPool/Questions/QuestionEdit";
import {Input as CustomInput} from 'components/Form';

import QuestionsImportModal from '../../../components/QuestionPool/QuestionsImportModal';
import CopyQuestionModel from '../../../components/QuestionPool/CopyQuestionModel';
import QuestionsSidebar from '../../../components/QuestionPool/Questions/QuestionsSidebar';
import QuestionBankStatusSwitch from '../../../components/QuestionPool/QuestionBankStatusSwitch';
import { useLocaleContext } from 'context/LocaleContext';

const formItemLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 14},
};

interface QuestionInterface {
    id: number,
    title: string;
    type: string;
    score: number;
    toPassScore: number;
    isCritical: number;
    hsClickLimit: null|number;
    questionBankId: any;
    questionNo: number;
    answers: any;
    isDraft: number;
    versionNote?: null|string;
    versionNo: number;
    parentId?: null|number;
    video?: null|string;
}

const QuestionBankEdit: React.FC<any> = ({ }) => {
    const { locale } = useLocaleContext();
    const [questionPool, setQuestionPool] = useState<any>();
    const [questionBank, setQuestionBank] = useState<any>();
    const [questionTypes, setQuestionTypes] = useState<any>(null);
    const [questions, setQuestions] = useState<any>([]);
    const [questionPoolId, setQuestionPoolId] = useState<any>();
    const [openQuestionId, setOpenQuestionId] = useState<number | null>(null);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [showImportModel, setShowImportModel] = useState(false);
    const [showCopyQuestionModel, setShowCopyQuestionModel] = useState(false);
    const [copyQuestionsLoading, setCopyQuestionsLoading] = useState(false);
    const [showEditModel, setShowEditModel] = useState(false);
    const [isQuestionBankSubmitProgress, setIsQuestionBankSubmitProgress] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState<any>([]);
    const [showQuestionActions, setShowQuestionActions] = useState<boolean>(false);
    const [currentQuestionAnswers, setCurrentQuestionAnswers] = useState<any>([]);
    
    const intl = useIntl();

    const params = useParams();
    const [handleError] = useHandleError();
    const questionBankId = params.id;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(!!questionBankId);
    const [isLoadingTypes, setIsLoadingTypes] = useState(false);
    const [isLoadingPool, setIsLoadingPool] = useState(false);
    const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
    const [isSaveQuestionLoading, setIsSaveQuestionLoading] = useState(false);
    const [expandAllQuestions, setExpandAllQuestions] = useState(false);
    const [hasFormChanged, setHasFormChanged] = useState<boolean>(false)
    const [saveEvent, setSaveEvent] = useState(false);

    const [editModelform] = Form.useForm();
    const {Search} = Input;
    const { confirm, warning } = Modal;

    useEffect(() => {
        loadQuestionTypes()
    }, [])

    useEffect(() => {
        if (questionBankId) {
            loadQuestionBank()
        }
    }, [questionBankId])

    useEffect(() => {
        if (questionPoolId) {
            loadQuestionPool()
        }
    }, [questionPoolId])

    useEffect(() => {
        if (questionPool) {
            loadQuestions()
        }
    }, [questionPool])

    const loadQuestionTypes = async () => {
        setIsLoadingTypes(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/questions/question-types`, [], 'GET');
            setQuestionTypes(response.question_types)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingTypes(false);
        }
    }
    
    const loadQuestionBank = async () => {
        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${questionBankId}`, [], 'GET');
            setQuestionBank(response.question_bank)

            setQuestionPoolId(response.question_bank.questionPoolId)

            editModelform.setFieldsValue({
                ...response.question_bank,
            });
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }
    
    const loadQuestionPool = async () => {
        setIsLoadingPool(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/${questionPoolId}`, [], 'GET');
            setQuestionPool(response.question_pool)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingPool(false);
        }
    }

    const loadQuestions = async (q: string = '') => {
        setIsLoadingQuestions(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${questionBankId}/questions`, {q}, 'GET');
            setQuestions(response.questions)

            if (params?.question) {
                setOpenQuestionId(parseInt(params.question))
            } else if (!openQuestionId && response.questions.length > 0) {
                setOpenQuestionId(response.questions[0].id)
            }


        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingQuestions(false);
        }
    }

    const exportQuestionBank = async () => {
        try {
          setIsLoading(true);
    
          const response = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${questionBankId}/export`, {language: locale}, 'GET', true, true);
    
          if (response.fileName) {
            FileDownload(response, response.fileName);
          } else {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
          }
        } catch (error) {
          console.error(error);
          message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
          setIsLoading(false);
        }
    };

    const addNewQuestion = (questionType: string) => {
        if (!handleEmptyQuestion()) {
            return;
        }

        const questionTypeObje = questionTypes[questionType];

        const newQuestionData: QuestionInterface = {
            id: 0,
            title: `New ${intl.formatMessage({id: questionTypeObje.title})}`,
            type: questionType,
            score: 10,
            toPassScore: 0,
            isCritical: 0,
            hsClickLimit: null,
            questionBankId: questionBankId,
            questionNo: 1,
            answers: [],
            isDraft: 1,
            versionNote: null,
            versionNo: 1,
            parentId: null,
            video: null
        }
        
        setOpenQuestionId(null);
        setOpenSidebar(false)
        saveQuestion(newQuestionData);
    }

    const saveAnswer = async (answer: any) => {
        // if(answer.title == '') {
        //     return;
        // }

        let bodyFormData = new FormData();

        bodyFormData.append('id', answer.id);
        bodyFormData.append('isCorrect', answer.isCorrect ? "1" : "0");
        bodyFormData.append('questionId', answer.questionId);
        if(answer?.title) {
            bodyFormData.append('title', answer.title);
        }
        if(answer?.description) {
            bodyFormData.append('description', answer.description);
        }
        bodyFormData.append('score', answer?.score || 0);

        if(answer?.data) {
            bodyFormData.append('data', JSON.stringify(answer.data));
        }

        if(answer?.thumbnail?.fileList) {
            bodyFormData.append('thumbnail', answer.thumbnail.fileList[0].originFileObj);
        } else if(answer?.thumbnail) {
            bodyFormData.append('thumbnail', answer.thumbnail);
        }

        if(answer?.thumbnail2?.fileList) {
            bodyFormData.append('thumbnail2', answer.thumbnail2.fileList[0].originFileObj);
        } else if(answer?.thumbnail2) {
            bodyFormData.append('thumbnail2', answer.thumbnail2);
        }

        let response;

        try {
            if (answer.id) {
                response = await coursesApiClient.request(`/api/v1/courses/question-pools/questions/answers/${answer.id}/update`, bodyFormData, 'POST', true, true);
            } else {
                response = await coursesApiClient.request('/api/v1/courses/question-pools/questions/answers/store', bodyFormData, 'POST', true, true);
            }
        } catch (error) {
            handleError(error)
        } finally {

        }
    };

    const saveQuestion = async (values: any, answers: any = [], callback: Function = () => {}) => {
        if(values.title == '') {
            return;
        }
        
        setIsSaveQuestionLoading(true);
        
        const questionTypeObje = questionTypes[values.type];
        let response: any = null;
  
        let bodyFormData = new FormData();
        
        bodyFormData.append('id', values.id);
        bodyFormData.append('questionBankId', values.questionBankId);
        bodyFormData.append('type', values.type);

        bodyFormData.append('score', values.score);
        bodyFormData.append('isCritical', values.isCritical ? "1" : "0");
        bodyFormData.append('shuffleAnswers', values.shuffleAnswers ? "1" : "0");
        bodyFormData.append('toPassScore', values?.toPassScore || 0);
        bodyFormData.append('title', values.title);
        bodyFormData.append('isDraft', values.isDraft ? "1" : "0");
        bodyFormData.append('parentId', values?.parentId || 0);
        
        if(values?.video) {
            bodyFormData.append('video', values.video);
        }

        if( values?.versionNote ) {
            bodyFormData.append('versionNote', values.versionNote);
        }
        
        if(values?.thumbnail?.fileList) {
            bodyFormData.append('thumbnail', values.thumbnail.fileList[0].originFileObj);
        } else if(values?.thumbnail) {
            bodyFormData.append('thumbnail', values.thumbnail);
        }

        if(values?.hsClickLimit) {
            bodyFormData.append('hsClickLimit', values.hsClickLimit);
        }

        try {
            if (values.id) {
                response = await coursesApiClient.request(`/api/v1/courses/question-pools/questions/${values.id}/update`, bodyFormData, 'POST', true, true, {}, 'json');
            } else {
                response = await coursesApiClient.request('/api/v1/courses/question-pools/questions/store', bodyFormData, 'POST', true, true, {}, 'json');
                setOpenQuestionId(response.question.id);
            }
            
            // Save answers
            for(let item of answers) {
                // Clone answers for new question 
                // if this is question revision
                if( values.id && values.id != response.question.id ) {
                    item.id = 0;
                    item.questionId = response.question.id;
                }
                await saveAnswer(item)
            }

            loadQuestions()

            // Reload question if fields updates
            if( response.question.id === values.id ) {
                callback()
            }

            message.success(intl.formatMessage({id: 'question_pools.question_updated'}))
        } catch (error) {
            handleError(error)
        } finally {
            setIsSaveQuestionLoading(false);
        }
    };

    const submitEditModelForm = async (values: any) => {
        setIsQuestionBankSubmitProgress(true);
  
        let parsedValues = {
          ...questionBank,
          ...values,
        }
  
        try {
          let responese = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${questionBankId}/update`, parsedValues, 'PUT');
          setQuestionBank(responese.question_bank)
          setShowEditModel(false)
          message.success(intl.formatMessage({id: 'question_pools.question_bank_updated'}));
        } catch (error) {
            handleError(error)
        } finally {
            setIsQuestionBankSubmitProgress(false);
        }
    }

    const copyQuestionSubmit = async (values: any) => {
        setCopyQuestionsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/questions/copy-bulk-questions`, values, 'POST');

            message.success(intl.formatMessage({ id: "question_pools.question_copied"}));   
            setShowCopyQuestionModel(false)
            setSelectedQuestions([])
            loadQuestions()
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.action_failed' }));
            console.error(error);
        } finally {
            setCopyQuestionsLoading(false);
        }
    }

    const deleteQuestionSubmit = async (values: any) => {
        setIsLoadingQuestions(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/questions/delete-bulk-questions`, values, 'DELETE');
            if (response.deleted) {
                message.success(intl.formatMessage({ id: 'general.deletion_successful' }));  
                if( selectedQuestions.includes(openQuestionId) ) {
                    setOpenQuestionId(null);
                }
                setSelectedQuestions([])
                loadQuestions()
            } else {
                message.error(intl.formatMessage({ id: 'question_pools.question_bank_is_active' }));
                setIsLoadingQuestions(false);
            }
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.action_failed' }));
            console.error(error);
        } finally {
            setIsLoadingQuestions(false);
        }
    }

    const onShowCopyQuestionsModel = () => {
        if(selectedQuestions.length > 0) {
            setShowCopyQuestionModel(true)
        } else {
            message.error(intl.formatMessage({ id: 'question_pools.no_question_selected' }));
        }
    }

    const onCopyQuestions = () => {
        if(selectedQuestions.length > 0) {
            const parsedValues = {
                questionBank: questionBankId,
                questionPool: questionPoolId,
                selectedQuestions
            }
            copyQuestionSubmit(parsedValues)
        } else {
            message.error(intl.formatMessage({ id: 'question_pools.no_question_selected' }));
        }
    }

    const onDeleteQuestions = () => {
        if(selectedQuestions.length === 0) {
            message.error(intl.formatMessage({ id: 'question_pools.no_question_selected' }));
            return false;
        }

        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'question_pools.deletion_questions' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                const parsedValues = {
                    questionBank: questionBankId,
                    questionPool: questionPoolId,
                    selectedQuestions
                }
                deleteQuestionSubmit(parsedValues)
            }
        });
    }

    const getStatusText = (record: any) => {
        return (
            <Tag color={record.isOnline ? 'green' : 'red'}>
                {record.isOnline ? intl.formatMessage({ id: 'question_pools.status_ongoing' }) : intl.formatMessage({ id: 'question_pools.status_draft' })}
            </Tag>
        )
    }

    const warnChanges = async (questionId: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: `${intl.formatMessage({ id: 'question_pools.changes_unsaved' })}`,
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),
            onOk() {
                setSaveEvent(true)
                setOpenQuestionId(questionId);
            },
            onCancel() {
                setOpenQuestionId(questionId);
            }
        });
    }

    const handleEmptyQuestion = () => {
        const addedAnswers = currentQuestionAnswers.length > 0 ? currentQuestionAnswers.filter((item: any) => {return item.title || item.thumbnail}) : [];

        if (questions.length === 0) {
            return true;
        }

        if(!addedAnswers.length) {
            warning({
                title: intl.formatMessage({ id: 'general.error' }),
                content: `${intl.formatMessage({ id: 'question_pools.question_pools.add_answer_error' })}`,
                icon: <CloseCircleOutlined />,
                okText: intl.formatMessage({ id: 'general.close' }),
                okButtonProps: {
                    type: 'default',
                }
            });

            return false;
        } else {
            return true;
        }
    }
    
    const handlePanel = (questionId: number) => {
        if (!handleEmptyQuestion()) {
            return;
        }

        setExpandAllQuestions(false);
        if (hasFormChanged) {
            warnChanges(questionId)
        } else {
            setOpenQuestionId(questionId);
        }

        setHasFormChanged(false)
    }

    const onSelectQuestion = (event: any) => {
        var updatedList = [...selectedQuestions];
        if (event.target.checked) {
            updatedList = [...selectedQuestions, event.target.value];
        } else {
            updatedList.splice(selectedQuestions.indexOf(event.target.value), 1);
        }
        setSelectedQuestions(updatedList);
    }
    
    const onSearchQuestions = debounce((value: string) => loadQuestions(value), 800);
    const onEdit = () => setShowEditModel(true);

    return (
        <DefaultLayout.PageLayout withSidebar withMaxWidth>
            <Spinner spinning={isLoading || isLoadingPool || isLoadingTypes}>
            {questionPool ? <>
                <Modal
                    width={530}
                    className="edit-question-bank"
                    open={showEditModel}
                    onCancel={() => setShowEditModel(false)}
                    title={intl.formatMessage({ id: 'question_pools.edit_question_bank' })}
                    okText={intl.formatMessage({ id: 'general.submit' })}
                    cancelText={intl.formatMessage({ id: 'general.back' })}
                    onOk={editModelform.submit}
                    okButtonProps={{disabled: isQuestionBankSubmitProgress}}
                >
                    <Form form={editModelform} onFinish={submitEditModelForm}>
                        <CustomInput 
                            customLayout={formItemLayout} 
                            name="title" 
                            label={intl.formatMessage({ id: 'question_pools.question_bank_title' })} 
                            validation={{ required: true }}
                        />
                        <CustomInput 
                            customLayout={formItemLayout} 
                            name="idKey" 
                            label={<>
                                {intl.formatMessage({ id: 'question_pools.id_key' })}
                                <Popover content={intl.formatMessage({id: 'question_pools.id_key_explanation'})}>
                                    <i style={{marginLeft: '3px'}} className="fal fa-question-circle"></i>
                                </Popover>
                                {intl.formatMessage({ id: 'general.optional' })}
                            </>} 
                            validation={{ required: true }}
                        />
                    </Form>
                </Modal>

                {questionTypes && <QuestionsSidebar 
                    openSidebar={openSidebar} 
                    setOpenSidebar={setOpenSidebar} 
                    addNewQuestion={addNewQuestion} 
                    questionTypes={questionTypes}
                />}
                
                <QuestionsImportModal
                    visible={showImportModel}
                    questionBankId={questionBankId}
                    onCancel={() => setShowImportModel(false)}
                />

                <CopyQuestionModel
                    visible={showCopyQuestionModel}
                    onCancel={() => setShowCopyQuestionModel(false)}
                    selectedQuestions={selectedQuestions}
                    copyQuestionSubmit={copyQuestionSubmit}
                />
                
                <Row gutter={[16, 0]} style={{paddingTop: '16px'}}>
                    <Col xl={6} lg={8} md={24} sm={24}>
                        <DefaultLayout.PageSidebar style={{marginBottom: 20}}>
                            <div className='question-sidebar-top'>
                                <DefaultLayout.PageHeader
                                    breadcrumb={questionPool && questionBank ? [
                                        {
                                            name: questionPool.title, 
                                            path: `/question-pools/${questionPoolId}/edit`
                                        },
                                    ]: []}
                                    title={questionBank ? questionBank.title : null}
                                    showTitleInBreadcrumb={false}
                                    showEditIcon={true}
                                    onEdit={onEdit}
                                />  
                                {questionBank && <Space style={{marginBottom: '20px'}}>
                                    <span><FormattedMessage id="general.online" />: </span>
                                    <QuestionBankStatusSwitch 
                                        questionBank={questionBank}
                                        onChange={(newQuestionBank: any) => {
                                            setQuestionBank(newQuestionBank)
                                        }}
                                    />
                                    {getStatusText(questionBank)}
                                </Space>}
                                <Space style={{marginBottom: '20px'}} wrap>
                                    <Button type="primary" icon={ <PlusOutlined/> } onClick={() => setOpenSidebar(true)}>
                                        <FormattedMessage id="question_pools.add_question" />
                                    </Button>

                                    <Button type="primary"  ghost onClick={() => setShowImportModel(true)}>
                                    <FormattedMessage id="question_pools.import" />
                                    </Button>

                                    <Button type="primary"  ghost onClick={() => exportQuestionBank()}>
                                    <FormattedMessage id="question_pools.export" />
                                    </Button>
                                </Space>
                                
                                <Search 
                                    allowClear
                                    placeholder={intl.formatMessage({id: 'question_pools.search_placeholder'})} 
                                    onSearch={onSearchQuestions} 
                                    onChange={(e: any) => onSearchQuestions(e.target.value)} 
                                    style={{ width: '100%', marginBottom: '20px' }} 
                                />
                                <Row align='middle' justify='space-between' style={{marginBottom: 20}}>
                                    <Col>
                                        <span>{selectedQuestions.length} <FormattedMessage id="question_pools.items_selected" /></span>
                                    </Col>
                                    <Col>
                                        <Button type='link' onClick={(e: any) => onCopyQuestions()}><FormattedMessage id='question_pools.duplicate' /></Button>
                                    </Col>
                                    <Col>
                                        <Button type='link' onClick={(e: any) => onDeleteQuestions()}><FormattedMessage id='question_pools.delete' /></Button>
                                    </Col>
                                    <Col>
                                        <Popover
                                            visible={showQuestionActions}
                                            content={<Button type='link' onClick={(e: any) => onShowCopyQuestionsModel()}><FormattedMessage id='question_pools.copy_to_another_bank' /></Button>}
                                            trigger="click"
                                            placement="bottomRight"
                                            arrowPointAtCenter
                                            onVisibleChange={(visible) => setShowQuestionActions(visible)}
                                            >
                                            <div style={{cursor: 'pointer', textAlign: 'center'}} className="question-actions">
                                                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
                                            </div>
                                        </Popover>
                                    </Col>
                                </Row>
                            </div>
                            <div className='question-list-wrapper'>
                                {questionTypes && <QuestionsList 
                                    questions={questions}
                                    questionBankId={questionBankId} 
                                    openQuestionId={openQuestionId}
                                    handlePanel={handlePanel}
                                    isLoadingQuestions={isLoadingQuestions || copyQuestionsLoading}
                                    onSelectQuestion={onSelectQuestion}
                                    selectedQuestions={selectedQuestions}
                                    questionTypes={questionTypes}
                                />}
                            </div>
                            
                        </DefaultLayout.PageSidebar>
                    </Col>
                    <Col xl={18} lg={16} md={24} sm={24}>
                        <div className='question-edit-page'>
                            {openQuestionId && questionTypes && <QuestionEdit
                                questionId={openQuestionId}
                                saveQuestion={saveQuestion}
                                isSaveQuestionLoading={isSaveQuestionLoading}
                                isParentLoading={isSaveQuestionLoading}
                                questionTypes={questionTypes}
                                setCurrentQuestionAnswers={setCurrentQuestionAnswers}
                                setOpenQuestionId={setOpenQuestionId}
                                setHasFormChanged={setHasFormChanged}
                                saveEvent={saveEvent}
                                setSaveEvent={setSaveEvent}
                                loadQuestions={loadQuestions}
                                questionIdPrefix={questionBank ? questionBank.idKey : ''}
                            />}
                        </div>
                    </Col>
                </Row>
                </>: null}
            </Spinner>
        </DefaultLayout.PageLayout>
    );
};

export default QuestionBankEdit;
