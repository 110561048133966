import React from 'react';
import {Checkbox, Divider, Form, Input} from 'antd';
import {CheckboxValueType} from "antd/lib/checkbox/Group";
import {FormattedMessage} from "react-intl";
import { useLocaleContext } from 'context/LocaleContext';

interface FilterCheckBoxInterface {
    data: any;
    setData?: any;
    name: any;
    onSearch: any;
}

const FilterCheckBox: React.FC<FilterCheckBoxInterface> = ({
                                                               setData,
                                                               data,
                                                               name,
                                                               onSearch,
                                                           }) => {

    const { locale } = useLocaleContext();
    const {Search} = Input;

    return (
        <>
            <div className={'flex flex-column p-1 checkbox-wrapper'}>
                <Search className={'w-100 h-34'} placeholder="input search text" onSearch={onSearch}/>
                <div className={'scrollable-div'}>
                    <Form.Item className={'mb-0'} name={name}>
                        <Checkbox.Group onChange={(value: CheckboxValueType[]) => setData ? setData(value) : undefined}>
                            {Object.entries(data).map((el: any) => {
                                if (el[1] && typeof el[1] === 'object') {
                                    let title = el[1][locale] ? el[1][locale] : el[1][Object.keys(el[1])[0]]
                                    return (
                                        <div className={'flex p-1 gap-5'}>
                                            <Checkbox value={el[0]}>
                                                {title}
                                            </Checkbox>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={'flex p-1 gap-5'}>
                                            <Checkbox value={el[0]}>
                                                {el[1] ? el[1] : <FormattedMessage id={'competences.without_block'}/>}
                                            </Checkbox>
                                        </div>
                                    )
                                }
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            </div>
            <Divider plain className={'m-2'}/>
        </>
    );
};

export default FilterCheckBox;