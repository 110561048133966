import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, message, Modal, Popover, Button, Tag } from 'antd';
import Switch from 'components/Form/Switch';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Table, Search } from 'ui';
import DefaultLayout from 'components/DefaultLayout';
import { Input, Select } from 'components/Form';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import useHandleError from 'utils/useHandleError';
import './styles.scss';
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
  };
}

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

const ManageContent: React.FC = ({ session }: any) => {
  const { locale } = useLocaleContext();
  const [showAddNewCourse, setShowAddNewCourse] = useState(false);
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [isSwitchLoading, setIsSwitchLoading] = useState(false);
  const [selectLanguageList, setSelectLanguageList ] = useState({});

  const [reload, setReload] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filter, setFilter] = useState('');
  const [formLoading, setFormLoading] = useState(false);

    const [form] = Form.useForm();
    const intl = useIntl();
    const navigate = useNavigate();
    const [handleError] = useHandleError();
    const { confirm } = Modal;

    useEffect(() => {
        loadLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadLanguages = async () => {
        let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
        let languageListForSelect: any = {};
        Object.values(languages.languages).map((value: any) => {
            if (value['publish']) {
                languageListForSelect[value['code']] = value['name'];
            }
            return languageListForSelect;
        });
        setSelectLanguageList(languageListForSelect);
    };

    const deleteCourse = async (id: number) => {
        try {
            const response = await coursesApiClient.request(
                `/api/v1/courses/courses/${id}/delete`,
                {},
                'DELETE'
            );

            if (response.message) {
                message.success(intl.formatMessage({ id: 'courses.course_deleted' }));
            } else {
                message.warning(intl.formatMessage({ id: 'courses.course_in_use' }));
            }
        } catch (error) {
            handleError(error);
        } finally {
            setReload(true);
        }
    };

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'courses.delete_course' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                deleteCourse(id);
            },
        });
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'courses.course_title' }),
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => {
                return (
                    <NavLink to={`/courses/${record.id}/edit`}>
                        {!!record.programId && (
                            <Tag>
                                <FormattedMessage id="program.tag" />
                            </Tag>
                        )}
                        {record.nameTranslations
                            ? record.nameTranslations[locale]
                                ? record.nameTranslations[locale]
                                : record.nameTranslations['EN']
                                ? record.nameTranslations['EN']
                                : record.name
                            : record.name}
                    </NavLink>
                );
            },
        },
        {
            title: intl.formatMessage({ id: 'courses.activities_added' }),
            dataIndex: 'activities ',
            key: 'activities ',
            render: (text: string, record: any) => record.activities,
        },
        {
            title: intl.formatMessage({ id: 'courses.date_created' }),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string, record: any) =>
                record.createdAt ? moment(record.updatedAt).format('DD.MM.YYYY') : '-',
        },
        {
            title: intl.formatMessage({ id: 'courses.last_update' }),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text: string, record: any) =>
                record.updatedAt ? moment(record.updatedAt).format('DD.MM.YYYY') : '-',
        },
        {
            title: intl.formatMessage({ id: 'general.languages' }),
            dataIndex: 'availableLanguages',
            key: 'availableLanguages',
        },
        {
            title: (
                <div>
                    {intl.formatMessage({ id: 'general.online' })}
                    <Popover
                        content={intl.formatMessage({ id: 'courses.course_status_explanation' })}
                    >
                        <i style={{ marginLeft: '3px' }} className="fal fa-question-circle"></i>
                    </Popover>
                </div>
            ),
            dataIndex: 'active',
            key: 'active',
            render: (_text: string, record: any) => {
                return (
                    <Switch
                        key={`course-switch-${record.id}`}
                        onChange={() => onSwitchChange(record)}
                        defaultChecked={record.active}
                        disabled={isSwitchLoading}
                    />
                );
            },
        },
        {
            key: 'actions',
            render: (text: string, record: any, index: number) => {
                const content = (
                    <>
                        <NavLink to={`/courses/${record.id}/edit`}>
                            <div className="popover-item">
                                <FormattedMessage id="general.edit" />
                            </div>
                        </NavLink>
                        <div onClick={() => warnDeletion(record.id)} className="popover-item">
                            <FormattedMessage id="general.delete" />
                        </div>
                    </>
                );

                return (
                    <Popover
                        open={index === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, index)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                );
            },
        },
    ];

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const onSwitchChange = async (record: any) => {
        try {
            setIsSwitchLoading(true);

            record.active = record.active ? 0 : 1;

            await coursesApiClient.request(`/api/v1/courses/courses/${record.id}`, record, 'PUT');

            const messageId = record.active
                ? 'courses.course_can_now_be_included'
                : 'courses.course_now_can_not_be_included';

            message.success(intl.formatMessage({ id: messageId }), 7);
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: 'error.action_failed' }));
        } finally {
            setIsSwitchLoading(false);
        }
    };

    const submitForm = async (values: any) => {
        setFormLoading(true);

        try {
            let uuid: any;
            let parsedValues = {
                ...values,
                active: 0,
                organizationUuid: uuid,
            };
            let responese = await coursesApiClient.request(
                `/api/v1/courses/courses`,
                parsedValues,
                'POST'
            );
            navigate(`/courses/${responese.course.id}/edit`);
        } catch (err) {
            console.error(err);
        } finally {
            setFormLoading(false);
        }
    };

    const handleChange = (value: any) => {
        if (value) {
            setFilter('filter[active]=1');
        } else {
            setFilter('');
        }
        setReload(true);
    };

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                title={intl.formatMessage({ id: 'courses.manage_elearning_content' })}
            />
            <DefaultLayout.PageContent>
                <div className="search-bar-row">
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setShowAddNewCourse(true)}
                    >
                        <FormattedMessage id="courses.add_new_course" />
                    </Button>
                    <div className="search">
                        <Search setSearchString={setSearchString} setReload={setReload} />
                        <div className="search">
                            <Switch
                                onChange={handleChange}
                                isFormItem
                                name="active"
                                className={'remove-flex-grow mb-0'}
                                label={intl.formatMessage({ id: 'courses.show_active' })}
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    width={530}
                    className="add-new-course"
                    open={showAddNewCourse}
                    onCancel={() => setShowAddNewCourse(false)}
                    title={intl.formatMessage({ id: 'courses.add_new_course' })}
                    okText={intl.formatMessage({ id: 'general.submit' })}
                    cancelText={intl.formatMessage({ id: 'general.back' })}
                    onOk={form.submit}
                    okButtonProps={{ loading: formLoading }}
                >
                    <Form form={form} onFinish={submitForm}>
                        <Input
                            customLayout={formItemLayout}
                            name="name"
                            label={intl.formatMessage({ id: 'courses.course_title' })}
                            validation={{ required: true }}
                        />
                        <Select
                            customLayout={formItemLayout}
                            name="firstLanguage"
                            label={intl.formatMessage({ id: 'courses.first_language_to_add' })}
                            manualOptions={selectLanguageList}
                            onChange={(value: any) => {
                                form.setFieldsValue({ firstLanguage: value });
                            }}
                            customRules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'validation.field_required',
                                    }),
                                },
                            ]}
                        />
                    </Form>
                </Modal>
                <Table
                    columns={columns}
                    url={'/api/v1/courses/courses'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    useFilters={true}
                    filter={filter}
                    search={searchString}
                    scroll={{ x: true }}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    );
};

export default connect(mapStateToProps)(ManageContent);
