import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {
    Checkbox,
    Form, Input as AntdInput,
    Input as AntDInput, Modal,
    Radio, message,
    Tooltip, UploadFile,
} from 'antd';
import {Input, Select, Switch, DatePicker, InputNumber, RequestSelect} from 'components/Form';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {IconSelect, TagComponent} from "ui";
import {Button} from "ui";
import useHandleError from "utils/useHandleError";
import {config} from "config/config";
import generalHelpers from "utils/generalHelpers";
import FileUploadList from "ui/FileUploadList";
import {RcFile} from "antd/lib/upload/interface";
import {useProgram} from "../hooks/useProgram";
import FileDownload from "js-file-download";
import './styles.scss';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {LanguageInterface} from "hooks/Language/types";
import { useLocaleContext } from 'context/LocaleContext';

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6},
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

interface SettingsInterface {
    program: any
    setProgram: React.Dispatch<React.SetStateAction<any>>;
    programLanguages: LanguageInterface[] | [];
    session?: any;
    launchButton: HTMLElement;
}

const Settings: React.FC<SettingsInterface> = ({
                                                   session,
                                                   program,
                                                   setProgram,
                                                   programLanguages,
                                                   launchButton,
                                               }) => {
    const { locale } = useLocaleContext();

    const {getProgramFiles, storeProgramFile, deleteProgramFile} = useProgram();
    const [loading, setIsLoading] = useState(false);
    const [campaignType, setCampaignType] = useState('TIMED_EVENT');
    const [language, setLanguage] = useState(config.defaultEnvironmentLocale)
    const [tags, setTags] = useState('')
    const [indefiniteEndDate, setIndefiniteEndDate] = useState(false);
    const [journalEnabled, setJournalEnabled] = useState(false);
    const [customFields, setCustomFields] = useState<any>([]);
    const [fileList, setFileList] = useState<UploadFile[]>();
    const [responsibleUrl, setResponsibleUrl] = useState('');
    const [programTitle, setProgramTitle] = useState<any>({});
    const [disabled, setDisabled] = useState<{type: boolean, permission: boolean}>({type: false, permission: false})
    const [ownerType, setOwnerType] = useState('CUSTOMER')

    const {TextArea} = AntDInput;
    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const params = useParams();

    const programId = params.id;

    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {
        if (program) {
            setIndefiniteEndDate(!program.endDate);
            setTags(program.tags);
            setOwnerType(program.ownerType);
            setResponsibleUrl(`api/v1/edu/users/search-${getSearchUserRoles()}`)
            setCampaignType(program.type)
            setProgramTitle(program.title)

            if (program.status === 'ONGOING' || program.status === 'FINISHED') {
                setDisabled({type: true, permission: !!program.visualPermissions.edit && !program.visualPermissions.admin})
            }

            form.setFieldsValue({
                hasEndDate: !program.endDate,
                programLanguage: locale,
                form: {
                    ...program,
                    title: program.title[locale] ? program.title[locale] : program.title[Object.keys(program.title)[0]],
                    beginDate: moment(program.beginDate),
                    endDate: program.endDate ? moment(program.endDate) : null,
                    materialAccess: !!program.materialAccess,
                    avoidCertificateInvalidity: !!program.avoidCertificateInvalidity,
                    pretest: !!program.pretest,
                    additionalAttempts: !!program.additionalAttempts,
                },
                journal: {
                    ...program?.journal,
                    enabled: program?.journal?.id
                }
            });

            if (program?.journal) {
                setJournalEnabled(!!program.journal.id)
            }

            if (Object.keys(program.customFields).length > 0) {
                if (program.customFieldValues) {
                    Object.entries(program.customFieldValues).map((field: any) => {
                        const fieldOptions = program.customFields.filter((el: any) => el.uuid === field[0])
                        if (fieldOptions[0].field_type === 'MULTIPLE_CHOICE') {
                            form.setFieldsValue({
                                customFields: {
                                    [field[0]]: field[1]
                                }
                            })
                        } else if (fieldOptions[0].field_type === 'DATE') {
                            form.setFieldsValue({
                                customFields: {
                                    [field[0]]: moment(field[1])
                                }
                            })
                        } else {
                            form.setFieldsValue({
                                customFields: {
                                    [field[0]]: field[1]
                                }
                            })
                        }
                    });
                }
            }

            if (generalHelpers.isPlatformAdmin(session.active_user_type)) {
                form.setFieldsValue({
                    ownerType: program.ownerType,
                    ownerUuid: program.ownerUuid,
                });
            }
        } else {
            if (!programId) {
                setResponsibleUrl(`api/v1/edu/users/search-${getSearchUserRoles()}`)
            }
            form.setFieldsValue({
                programLanguage: locale,
                ownerType: 'CUSTOMER',
                form: {
                    type: 'TIMED_EVENT',
                    materialAccess: false,
                    avoidCertificateInvalidity: true,
                    certValidityType: 'CERT_OBTAIN',
                    pretest: true
                }
            });
        }
        fetchFiles();
    }, [program])

    const fetchFiles = async () => {
        if (program && program.id) {
            setFileList(await getProgramFiles(program.id));
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            files: fileList,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList])

    const getTitle = () => {
        const title = form.getFieldValue(['form', 'title']);
        let titleJson = programTitle;
        titleJson[language] = title;

        return titleJson
    }

    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/courses/learning-program/custom-fields/program-custom-fields', [], 'GET');
            setCustomFields(customFields.fields);
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    useEffect(() => {
        loadCustomFields();
    }, []);

    const changeLanguage = (languageKey: string) => {
        const title = form.getFieldValue(['form', 'title']);

        setProgramTitle((programTitle: any) => ({...programTitle, [language]: title}));

        form.setFieldsValue({form: {title: programTitle[languageKey]}})
        setLanguage(languageKey)
    }

    const submitForm = async (values: any) => {
        setIsLoading(true);

        if (campaignType === 'CERTIFICATION' && values.form.certificationValidityTerm === 0) {
            setIsLoading(false);
            message.error(intl.formatMessage({ id: 'validation.field_required' }));
            return;
        }

        const parsedValues = {
            ...values.form,
            tags: tags,
            title: getTitle(),
            journal: values.journal
        };

        if (values.customFields !== undefined) {
            parsedValues['customFields'] = values.customFields;
            Object.entries(parsedValues.customFields).map((field: any) => {
                if ((field[1] === null) || (field[1] == undefined) || (field[1] == '')) {
                    delete parsedValues.customFields[field[0]];
                } else {
                    const fieldOptions = customFields.filter((el: any) => el.uuid === field[0])
                    if (fieldOptions[0].field_type === 'DATE') {
                        parsedValues.customFields[field[0]] = field[1].format(config.DBDateFormat);
                    }
                }
            });
        }

        if (!indefiniteEndDate) {
            parsedValues.endDate = values.form.endDate.format(config.DBDateFormat);
        } else {
            parsedValues.endDate = null;
        }

        if (generalHelpers.isPlatformAdmin(session.active_user_type) && !programId) {
            parsedValues.ownerUuid = values.ownerUuid;
            parsedValues.ownerUuid = values.ownerType;
        }

        let response;

        try {
            if (programId) {
                response = await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/update`, parsedValues, 'PUT');
                setProgram(response.program)
            } else {
                response = await coursesApiClient.request('/api/v1/courses/learning-program/store', parsedValues, 'POST');
                navigate(`/learning/programs/${response.program.id}`)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddFile = async (file: RcFile, callBack: (id?: number) => void) => {
        if (program && program.id) {
            callBack(await storeProgramFile(program.id, file));
        }
    }

    const handleRemoveFile = async (id: number): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: intl.formatMessage({id: 'general.attention'}),
                icon: <ExclamationCircleOutlined/>,
                content: intl.formatMessage({id: 'general.delete_file'}),
                okText: intl.formatMessage({id: 'general.delete'}),
                okType: 'danger',
                cancelText: intl.formatMessage({id: 'general.cancel'}),
                onOk: () => {
                    resolve(true)
                    if (program && program.id) {
                        return deleteProgramFile(program.id, id);
                    }
                },
                onCancel: () => {
                    reject(true)
                }
            })
        })
    }

    const handleDownload = async (file: any) => {
        setIsLoading(true);
        if (program && program.id) {
            try {
                if (file?.originFileObj) {
                    return FileDownload(file.originFileObj, file.name);
                }
                const response = await coursesApiClient.request(`/api/v1/courses/storage-file/${file.uid}`, [], 'GET', true, true);

                FileDownload(response, file.name);
            } catch (error) {
                handleError(error)
            } finally {
                setIsLoading(false);
            }
        }
    }

    const getSearchUserRoles = () => {
        let role

        if (programId) {
            role = program.ownerType === 'CUSTOMER' ? 'customer_admin' : 'admin'
            role += `?owner=${program.ownerUuid}&ownerType=${program.ownerType}`
        } else if (generalHelpers.isPlatformAdmin(session.active_user_type)) {
            role = ownerType === 'CUSTOMER' ? 'customer_admin' : 'admin'
        } else if (session.active_user_type === 'RESELLER_ADMIN') {
            role = 'admin'
            role += `?owner=${session.organization.organization_uuid}&ownerType=${session.organization.organization_type}`
        } else if (session.active_user_type === 'CUSTOMER_ADMIN') {
            role = 'customer_admin'
            role += `?owner=${session.organization.organization_uuid}&ownerType=${session.organization.organization_type}`
        }

        return role;
    }

    const changeResponsibleUrl = (uuid: string) => {
        let role

        if (ownerType === 'ORGANIZATION') {
            role = `admin?owner=${uuid}&ownerType=${ownerType}`
        } else if (ownerType === 'CUSTOMER') {
            role = `customer_admin?owner=${uuid}&ownerType=${ownerType}`
        }

        setResponsibleUrl(`api/v1/edu/users/search-${role}`)
    }

    return (
        <>
            <Form form={form} onFinish={submitForm} {...formItemLayout}>
                <div className='campaign-header-text'>
                    <h1 className='text-30'>{intl.formatMessage({id: 'program.please_set_up_your_campaign_settings'})}</h1>
                    <h1 className='text-18'>{intl.formatMessage({id: 'program.campaign_name_and_identity'})}</h1>
                </div>
                {generalHelpers.isPlatformAdmin(session.active_user_type) && !programId &&
                    <>
                        <Form.Item
                            name="ownerType"
                            label={intl.formatMessage({id: 'general.owner_type'})}
                        >
                            <Radio.Group 
                            disabled={disabled.permission}
                            onChange={(el: any) => {
                                form.setFieldsValue({ownerUuid: ''})
                                setOwnerType(el.target.value);
                            }}>
                                <Radio value="CUSTOMER"><FormattedMessage id='general.customer'/></Radio>
                                <Radio value="ORGANIZATION"><FormattedMessage id='general.reseller'/></Radio>
                            </Radio.Group>
                        </Form.Item>
                        {ownerType === 'CUSTOMER' &&
                            <Select
                                disabled={disabled.permission}
                                allowClear
                                showSearch
                                name='ownerUuid'
                                dataKey='customers'
                                label={intl.formatMessage({id: "general.customer"})}
                                url={config.api.routes.backend.selectCustomersUuid}
                                integerKey={false}
                                customLayout={true}
                                dropdownStyle={{minWidth: "385px"}}
                                onChange={(el: any) => changeResponsibleUrl(el)}
                                filterOption={(input: string, option: any) => {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                            />
                        }
                        {ownerType === 'ORGANIZATION' &&
                            <Select
                                disabled={disabled.permission}
                                allowClear
                                showSearch
                                name='ownerUuid'
                                dataKey='organizations'
                                label={intl.formatMessage({id: "general.reseller"})}
                                url={config.api.routes.backend.selectOrganizationsUuid}
                                integerKey={false}
                                customLayout={true}
                                onChange={(el: any) => changeResponsibleUrl(el)}
                                dropdownStyle={{minWidth: "385px"}}
                                filterOption={(input: string, option: any) => {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                            />
                        }
                    </>
                }

                <Input
                    disabled={disabled.permission}
                    name={['form', 'title']}
                    label={intl.formatMessage({id: 'program.title'})}
                    validation={{required: true}}
                />
                <Form.Item name='programLanguage' {...formItemLayout} label=' ' className='noColon'>
                    <Radio.Group
                        disabled={disabled.permission}
                    >
                        {
                            programLanguages.map((language: LanguageInterface) => {
                                return (
                                    <Radio.Button onClick={() => changeLanguage(language.code)} value={language.code}>
                                        {language.code}
                                    </Radio.Button>
                                )
                            })
                        }
                    </Radio.Group>
                </Form.Item>

                <Form.Item name={['form', 'description']} label={intl.formatMessage({id: 'general.description'})}>
                    <TextArea disabled={disabled.permission} maxLength={440}/>
                </Form.Item>

                <IconSelect disabled={disabled.permission} name={['form', 'thumbnail']}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({id: 'validation.field_required_for_language'})
                            }]}
                            label={intl.formatMessage({id: 'general.thumbnail'})}
                />
                <Form.Item label=' ' className='noColon'>
                </Form.Item>
                <TagComponent
                    disabled={disabled.permission} 
                    label={intl.formatMessage({id: 'general.tags'})}
                    customItemLayout={formItemLayout}
                    tags={tags}
                    setTags={setTags}
                />
                <div className='campaign-header-text'>
                    <h1 className='text-18'>{intl.formatMessage({id: 'general.settings'})}</h1>
                </div>
                <Switch disabled={disabled.permission} checked={indefiniteEndDate} isFormItem name={'hasEndDate'}
                        onChange={(bool: boolean) => setIndefiniteEndDate(bool)}
                        label={intl.formatMessage({id: 'general.indefinite_end_date'})}/>
                {!indefiniteEndDate &&
                    <Form.Item
                        label={intl.formatMessage({id: 'program.end_date'})}
                        name={['form', 'endDate']}
                        rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                    >
                        <DatePicker disabled={disabled.permission} format={config.defaultDateFormat} customLayout={{formItemLayout}}/>
                    </Form.Item>
                }
                <Form.Item
                    name={['form', 'type']}
                    label={intl.formatMessage({id: 'campaign.course_scenario'})}
                >
                    <Radio.Group
                        disabled={disabled?.type || disabled.permission}
                        onChange={(event: any) => {
                            setCampaignType(event.target.value);
                        }}
                    >
                        <Radio checked value='TIMED_EVENT'><FormattedMessage id='campaign.timed_event'/>
                            <Tooltip title={intl.formatMessage({id: 'campaign.timed_event_hint'})}>
                                <i className='fal fa-question-circle header-item'/>
                            </Tooltip>
                        </Radio>
                        <Radio value='CERTIFICATION'><FormattedMessage id='campaign.certification_course'/>
                            <Tooltip title={intl.formatMessage({id: 'campaign.certification_course_hint'})}>
                                <i className='fal fa-question-circle header-item'/>
                            </Tooltip>
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({id: 'program.term_limit_value'})}
                    name={['form', 'termLimitValue']}
                    rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                >
                    <InputNumber disabled={disabled.permission} min={0} max={100}/>
                </Form.Item>

                <Form.Item
                    label={
                        <span className={'word-break'}>
                            {intl.formatMessage({id: 'campaign.maximum_completion_term_allowed'})}
                        </span>}
                    name={['form', 'maxCompletionTerm']}
                    rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                >
                    <InputNumber disabled={disabled.permission} min={0}/>
                </Form.Item>

                <Form.Item
                    label={
                        <span className={'word-break'}>
                            {intl.formatMessage({id: 'campaign.allow_repeated_after_tests'})}
                        </span>}
                    name={['form', 'testRepeats']}
                    rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                >
                    <InputNumber disabled={disabled.permission} min={0} max={100}/>
                </Form.Item>
                <Form.Item
                    label={
                        <span className={'word-break'}>
                            {intl.formatMessage({id: 'program.reminder_frequency'})}
                        </span>}
                    name={['form', 'reminderFrequency']}
                >
                    <InputNumber disabled={disabled.permission} min={1} max={100}/>
                </Form.Item>

                <Form.Item
                    label={
                        <span className={'word-break'}>
                            {intl.formatMessage({id: 'campaign.additional_attempts'})}
                            <Tooltip
                                title={intl.formatMessage({id: 'campaign.additional_attempts'})}>
                                <i className='fal fa-question-circle header-item'/>
                            </Tooltip>
                        </span>
                    }
                    name={['form', 'additionalAttempts']}
                >
                    <Radio.Group disabled={disabled.permission}>
                        <Radio value={true}><FormattedMessage id='general.yes'/></Radio>
                        <Radio value={false}><FormattedMessage id='general.no'/></Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={
                        <span className={'word-break'}>
                                {intl.formatMessage({id: 'campaign.certification_validity_term'})}
                            <Tooltip
                                title={intl.formatMessage({id: 'campaign.certification_validity_term_hint'})}>
                                    <i className='fal fa-question-circle header-item'/>
                                </Tooltip>
                            </span>
                    }
                    name={['form', 'certificationValidityTerm']}
                    rules={[{
                        required: !indefiniteEndDate || campaignType === 'CERTIFICATION',
                        message: intl.formatMessage({id: 'validation.field_required'})
                    }]}
                >
                    <InputNumber disabled={disabled.permission} min={campaignType === 'CERTIFICATION' ? 1 : 0} max={3652}/>
                </Form.Item>

                {campaignType === 'CERTIFICATION' &&
                    <>
                        <Form.Item
                            label={
                                <span className={'word-break'}>
                                {intl.formatMessage({id: 'campaign.avoid_certificate_invalidity_period'})}
                                    <Tooltip
                                        // todo: tooltip message
                                        title={intl.formatMessage({id: 'campaign.avoid_certificate_invalidity_period'})}>
                                    <i className='fal fa-question-circle header-item'/>
                                </Tooltip>
                            </span>
                            }
                            name={['form', 'avoidCertificateInvalidity']}
                        >
                            <Radio.Group disabled={disabled.permission}>
                                <Radio value={true}><FormattedMessage id='general.yes'/></Radio>
                                <Radio value={false}><FormattedMessage id='general.no'/></Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label={
                                <span className={'word-break'}>
                                {intl.formatMessage({id: 'campaign.certificate_effective_date'})}
                                    <Tooltip
                                        // todo: tooltip message
                                        title={intl.formatMessage({id: 'campaign.certificate_effective_date'})}>
                                    <i className='fal fa-question-circle header-item'/>
                                </Tooltip>
                            </span>
                            }
                            name={['form', 'certValidityType']}
                        >
                            <Radio.Group disabled={disabled.permission}>
                                <Radio value={'CERT_OBTAIN'}><FormattedMessage
                                    id='program.cert_type_CERT_OBTAIN'/></Radio>
                                <Radio value={'CERT_END'}><FormattedMessage id='program.cert_type_CERT_END'/></Radio>
                            </Radio.Group>
                        </Form.Item>


                        <Form.Item
                            label={
                                <span className={'word-break'}>
                                {intl.formatMessage({id: 'campaign.include_pre_test_as_of_2nd_recertification'})}
                                    <Tooltip
                                        title={intl.formatMessage({id: 'campaign.include_pre_test_as_of_2nd_recertification_hint'})}>
                                    <i className='fal fa-question-circle header-item'/>
                                </Tooltip>
                            </span>
                            }
                            name={['form', 'pretest']}
                        >
                            <Radio.Group disabled={disabled.permission}>
                                <Radio value={true}><FormattedMessage id='general.yes'/></Radio>
                                <Radio value={false}><FormattedMessage id='general.no'/></Radio>
                            </Radio.Group>
                        </Form.Item>
                    </>
                }
                {!!responsibleUrl &&
                    <RequestSelect
                        allowClear
                        disabled={disabled.permission}
                        label={intl.formatMessage({id: 'program.responsible_admin'})}
                        isForm={true}
                        form={form}
                        name={['form', 'settings', 'responsible']}
                        style={{width: 200}}
                        url={responsibleUrl}
                        initialValue={program?.settings?.responsible}
                        column
                        showSearch
                    />
                }
                {/*TODO hide behind access component */}
                {/*<Switch isFormItem name={['form', 'academyCompliant']}*/}
                {/*        label={intl.formatMessage({id: 'program.academy_compliant'})}/>*/}
                {
                    customFields.length > 0 ?
                        <>
                            <div className='campaign-header-text'>
                                <h1 className='text-18'>{intl.formatMessage({id: 'general.custom_fields'})}</h1>
                            </div>
                            {
                                customFields.map((field: {
                                    uuid: string,
                                    field_type: 'TEXT' | 'INTEGER' | 'DATE' | 'SWITCH' | 'MULTIPLE_CHOICE' | 'HYPERLINK',
                                    title: string,
                                    multiple_choice_options: string[]
                                }) => {

                                    switch (field.field_type) {
                                        case 'TEXT':
                                            return (
                                                <Input disabled={disabled.permission} name={['customFields', field.uuid]} label={field.title}></Input>
                                            )
                                        case 'INTEGER':
                                            return (
                                                <InputNumber disabled={disabled.permission} name={['customFields', field.uuid]}
                                                             label={field.title}></InputNumber>
                                            )
                                        case 'DATE':
                                            return (
                                                <DatePicker disabled={disabled.permission} name={['customFields', field.uuid]} label={field.title}
                                                            format={config.defaultDateFormat}></DatePicker>
                                            )
                                        case 'SWITCH':
                                            return (
                                                <Switch disabled={disabled.permission} name={['customFields', field.uuid]} isFormItem
                                                        label={field.title}></Switch>
                                            )
                                        case 'MULTIPLE_CHOICE':
                                            return (
                                                <div className={'multiple-choice-fields'}>
                                                    <Form.Item label={field.title} name={['customFields', field.uuid]}>
                                                        <Checkbox.Group disabled={disabled.permission}>
                                                            {
                                                                field.multiple_choice_options.map((el: string) => {
                                                                    return (
                                                                        <Checkbox value={el}>
                                                                            {el}
                                                                        </Checkbox>
                                                                    )
                                                                })
                                                            }
                                                        </Checkbox.Group>
                                                    </Form.Item>
                                                </div>
                                            )
                                        case 'HYPERLINK':
                                            return (
                                                <Form.Item label={field.title}
                                                           name={['customFields', field.uuid]}>
                                                    <AntdInput.Group compact>
                                                        <Form.Item
                                                            className={'vertical-form-item'}
                                                            label={intl.formatMessage({id: 'users.custom_filter_display_name'})}
                                                            name={['customFields', field.uuid, 'displayName']}
                                                        >
                                                            <AntdInput disabled={disabled.permission}/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            className={'vertical-form-item'}
                                                            label={intl.formatMessage({id: 'users.custom_filter_hyperlink'})}
                                                            name={['customFields', field.uuid, 'hyperlink']}
                                                        >
                                                            <AntdInput maxLength={1000} disabled={disabled.permission}/>
                                                        </Form.Item>
                                                    </AntdInput.Group>
                                                </Form.Item>
                                            );
                                        default:
                                            return;
                                    }
                                })
                            }
                        </>
                        :
                        null
                }
                <div className='campaign-header-text'>
                    <h1 className='text-18'>{intl.formatMessage({id: 'campaign.journal'})}</h1>
                </div>
                <Switch disabled={disabled.permission} checked={journalEnabled} isFormItem name={['journal', 'enabled']}
                        onChange={(bool: boolean) => setJournalEnabled(bool)}
                        label={intl.formatMessage({id: 'campaign.enable_electronic_journal'})}/>

                {journalEnabled &&
                    <>
                        <Form.Item
                            name={['journal', 'description']}
                            label={intl.formatMessage({id: 'campaign.electronic_journal_text'})}
                        >
                            <TextArea disabled={disabled.permission} maxLength={1000} showCount/>
                        </Form.Item>
                    </>
                }

                {programId &&
                    <>
                        <div className='campaign-header-text'>
                            <h1 className='text-18'>{intl.formatMessage({id: 'general.program_files'})}</h1>
                        </div>
                        <Form.Item
                            label={intl.formatMessage({id: 'general.upload_files'})}
                            name="files"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 10}}
                        >
                            <FileUploadList
                                disabled={disabled.permission}
                                multiple={true}
                                onAdd={handleAddFile}
                                onDelete={handleRemoveFile}
                                onDownload={handleDownload}
                                showUploadList={{
                                    showDownloadIcon: true,
                                    downloadIcon: intl.formatMessage({id: 'general.download'}),
                                    showRemoveIcon: true,
                                    removeIcon: intl.formatMessage({id: 'general.delete'})
                                }}
                            />
                        </Form.Item>
                    </>
                }

            </Form>
            <DefaultLayout.PageFooter
                right={
                    <>
                        <Button disabled={programId && !(program?.visualPermissions?.admin) } loading={loading} type='primary' onClick={form.submit}>
                            <FormattedMessage id='general.save'/>
                        </Button>
                        {launchButton}
                    </>
                }
            />
        </>
    )
};

export default connect(mapStateToProps)(Settings);
