import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {CheckOutlined, CloseOutlined, ExclamationCircleOutlined, MenuOutlined} from '@ant-design/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, InputNumber, message, Modal, Popover, Table, Tag} from 'antd';
import {Switch} from 'components/Form';
import {connect} from 'react-redux';
import { TreeSelect } from 'antd';
import debounce from "lodash/debounce";
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

interface TestQuestionBanksInterface {
    session?: any;
    learningTest: any;
    questionBanks: any[];
    setQuestionBanks: React.Dispatch<React.SetStateAction<any[]>>;
    treeData: any;
    isLoading: boolean;
}

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
));
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
));

const TestQuestionBanks: React.FC<TestQuestionBanksInterface> = ({learningTest, session, questionBanks, setQuestionBanks, treeData, isLoading}) => {
    const [selectedBankValue, setSelectedBankValue] = useState<string>()
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);

    const intl = useIntl();
    const {confirm} = Modal;

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const handleUpdate = debounce((record: any, value:any) => {
        record.includedQuestions = value;
        updateQuestionBank(record.questionBankId, record)
    }, 800)

    const columns = [
        {
            title: intl.formatMessage({id: 'question_pools.question_bank'}),
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: any) => {
              return (
                  <NavLink
                    to={
                        `/question-pools/question-banks/${record.questionBankId}/edit`
                    }
                    target="_blank"
                  >
                    <span>{record.title}</span>

                    {!record.isOnline ? <Tag color={record.isOnline ? 'green' : 'red'} style={{marginLeft: '5px'}}>
                        {record.isOnline ? 'ONLINE' : 'OFFLINE'}
                    </Tag> : null}
                  </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.id'}),
            dataIndex: 'id',
            render: (text: number|string) => Number(text) || '-'
        },
        {
            title: intl.formatMessage({id: 'question_pools.questions'}),
            dataIndex: 'questionsCount',
        },
        {
            title: intl.formatMessage({id: 'question_pools.critical'}),
            dataIndex: 'criticalQuestionsCount',
        },
        {
            title: intl.formatMessage({id: 'learning_tests.included_questions'}),
            render: (_text: string, record: any) => {

                return (
                    <InputNumber
                        defaultValue={record.includedQuestions}
                        disabled={isLoading || !record.isOnline}
                        onChange={(value: any) => handleUpdate(record, value)}
                        max={record.questionsCount}
                    />
                )
            }
        },
        {
            title: intl.formatMessage({id: 'learning_tests.shuffle'}),
            render: (_text: string, record: any) => {

                return (
                    <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        checked={!!record.shuffleQuestions}
                        loading={isLoading}
                        disabled={!record.isOnline}
                        onChange={() => {
                            record.shuffleQuestions = !record.shuffleQuestions;
                            updateQuestionBank(record.questionBankId, record)
                        }}
                    />
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.sort'}),
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: intl.formatMessage({id: 'general.actions'}),
            render: (text: string, record: any, index: number) => {
                const content = <>
                  <div onClick={() => warnDeletion(record)}>
                    <div className="popover-item">
                      <FormattedMessage id="general.delete"/>
                    </div>
                  </div>
                </>;
      
                return (
                  <Popover
                    open={index === visibleTooltip}
                    content={content}
                    trigger="click"
                    placement="bottomRight"
                    arrowPointAtCenter
                    onOpenChange={(visible) => handleVisibleChange(visible, index)}
                  >
                    <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                      <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
                    </div>
                  </Popover>
                )
            },
        },
    ];

    const warnDeletion = async (record: any) => {
        if (record.typeUuid === session.active_user_type_uuid) {
            message.warning(intl.formatMessage({id: 'laerning_tests.test_is_online'}));
            return;
        }

        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'learning_tests.delete_question_bank'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                if (learningTest.isOnline && Number(record.id)) {
                    message.error(intl.formatMessage({id: 'learning_tests.test_is_online'}))
                } else {
                    deleteQuestionBank(record.questionBankId);
                }
            }
        });
    }

    const deleteQuestionBank = async (questionBankId: number) => {
        const updatedList = questionBanks.reduce((res: any[], qb: any) => {
            if (qb.questionBankId === questionBankId) {
                if (qb.action === 'create') return res;

                res.push({ ...qb, action: 'delete' });
            } else {
                res.push(qb);
            }

            return res;
        }, []);

        setQuestionBanks(updatedList);
    }

    const addQuestionBank = async () => {
        if (selectedBankValue) {
            setSelectedBankValue('')

            const [questionPoolId, questionBankId] = selectedBankValue.split('-');

            if (questionBanks.some((qb: any) => qb.questionBankId == questionBankId)) {
                const updatedList = questionBanks.map((qb: any) => qb.questionBankId == questionBankId ? { 
                    ...qb,
                    sortingOrder: 0,
                    includedQuestions: 0,
                    shuffleQuestions: true,
                    action: 'update'
                } : qb);
                setQuestionBanks(updatedList)

                return;
            }

            const selectedBank = treeData.find((p: any) => p.value == questionPoolId).children.find((b: any) => b.value == selectedBankValue)

            const questionBank = {
                ...selectedBank,
                id: `temp-id-${questionPoolId}`,
                learningTestId: learningTest.id,
                sortingOrder: 0,
                includedQuestions: 0,
                shuffleQuestions: true,
                action: 'create'
            }

            let questionBanksArray = [...questionBanks, questionBank];
            setQuestionBanks(questionBanksArray)
        }
    }

    const updateQuestionBank = (questionBankId: number, updatedQuestionBank: any) => {
        const updatedList = questionBanks.map((qb: any) =>
            qb.questionBankId === questionBankId ? (qb.action === 'create' ? updatedQuestionBank : { ...updatedQuestionBank, action: 'update' }) : qb
        );

        setQuestionBanks(updatedList);
    };

    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (oldIndex !== newIndex) {
          const newData = arrayMove(questionBanks, oldIndex, newIndex)
          setQuestionBanks(newData)
        }
    };

    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody
          useDragHandle
          disableAutoscroll
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
        />
    );

    const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = questionBanks.findIndex((x: any) => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    
    return (
        <>
            <div className='flex-left gap-8'>
                <FormattedMessage id='learning_tests.add_question_bank_to_draw_questions'/>
                <TreeSelect
                    showSearch
                    showArrow
                    value={selectedBankValue}
                    disabled={isLoading}
                    style={{ width: '300px' }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                    onChange={(value: string) => setSelectedBankValue(value)}
                    treeData={treeData}
                    treeNodeFilterProp='title'
                />
                <Button type='default' onClick={() => addQuestionBank()} loading={isLoading}>
                    <FormattedMessage id='general.add'/>
                </Button>
            </div>
            <Table
                columns={columns}
                pagination={false}
                // url={`/api/v1/courses/learning-tests/${learningTest.id}/question-banks`}
                // setReload={setReload}
                // reload={reload}
                dataSource={questionBanks.reduce((res: any[], qb: any) => {
                    if (qb.action === 'delete') return res;
                    
                    res.push({ ...qb, key: qb.questionBankId });
                    return res;
                }, [])}
                rowKey={'id'}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />
        </>
    )
};

export default connect(mapStateToProps)(TestQuestionBanks);
