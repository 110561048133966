import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Checkbox} from '../../Form';
import './styles.scss';

interface QuestionItemListInterface {
    question: any;
    questionIndex: number;
    handlePanel: Function;
    openQuestionId: number | null;
    onSelectQuestion: Function;
    selectedQuestions: any;
    questionTypes: any;
    locale?: any;
    session?: any;
}

const QuestionItemList: React.FC<QuestionItemListInterface> = ({ 
    question, 
    questionIndex, 
    openQuestionId,
    handlePanel,
    onSelectQuestion,
    selectedQuestions,
    questionTypes,
}) => {
    const questionType = questionTypes[question.type];

    return (
        <div className={`question-item ${question.id === openQuestionId ? 'active' : ''}`}>
            <Checkbox 
                onChange={(e: any) => onSelectQuestion(e)}
                value={question.id}
                checked={selectedQuestions.includes(question.id)}
            />
            <div className='question-item-info'  onClick={() => handlePanel(question.id)}>
                <h4>{questionIndex}. {question.title}</h4>
                <p><FormattedMessage id={questionType.title} /></p>
            </div>
        </div>
    );
};

export default QuestionItemList;
    