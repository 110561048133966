import {Divider, Input as AntdInput, InputRef, Space} from 'antd';
import React, {useState, useEffect, useRef} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Select} from 'components/Form';
import {Button} from 'ui';
import generalHelpers from 'utils/generalHelpers';
import useQueryApiClient from 'utils/useApiClient';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
}

const Departments: React.FC<any> = ({
    session,
    organizationId,
    customerId,
    name = 'departmentId',
    isUuid,
    hideLabel,
    canCreate = true,
    setLoadedData = null,
    ...props
}) => {
    const [title, setTitle] = useState('');

    const inputRef = useRef<InputRef>(null);
    const intl = useIntl();

    const defaultParams = generalHelpers.isAdmin(session.active_user_type) ? {
        organizationId,
        customerId
    } : {}

    useEffect(() => {
        loadDepartments({isResponseArray: true, isUuid, ...defaultParams})
    }, [])

    const {data, appendData: loadDepartments, isLoading: listLoading} = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/departments',
            method: 'GET'
        },
        onSuccess: (response) => {
            if (response) {
                setLoadedData && setLoadedData(response.departments)
            }
        }
    });

    const {appendData: addDepartment, isLoading: addLoading} = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/departments/store',
            method: 'POST'
        },
        onSuccess: () => {
            loadDepartments({isResponseArray: true, isUuid, ...defaultParams})
            setTitle('')
        }
    });

    return (
        <Select 
            allowClear
            showSearch
            showArrow
            name={name}
            label={!hideLabel ? intl.formatMessage({ id: 'general.department' }) : undefined}
            className='default-select'
            manualOptions={data.departments}
            incrementKey={true}
            isResponseArray={true}
            dropdownRender={(menu: any) => (
                <>
                    {menu}
                    {canCreate && <>
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <AntdInput
                                ref={inputRef}
                                value={title}
                                onChange={(event: any) => setTitle(event.target.value)}
                            />
                            <Button 
                                type='outline'
                                onClick={() => addDepartment({title, ...defaultParams})}
                                loading={listLoading || addLoading}
                            >
                                <FormattedMessage id='general.add' />
                            </Button>
                        </Space>
                    </>}
                </>
            )}
            valueKey={'id'}
            labelKey={'title'}
            filterOption={(input: string, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            {...props}
        />
    )
}

export default connect(mapStateToProps)(Departments);
