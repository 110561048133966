import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form, Card, Checkbox } from 'antd';
import Input from 'components/Form/Input';
import { config } from 'config/config';
import apiClient from 'utils/apiClient';
import { PhoneNumberSelect, Button } from 'ui';
import DefaultUnauthorizedLayout from 'components/DefaultUnauthorizedLayout';
import styles from './styles.module.scss';
import { useLocaleContext } from 'context/LocaleContext';
import Logo from 'components/Logo';

const PasswordReset: React.FC = ({}: any) => {
    const { locale } = useLocaleContext();

    const [inProgress, setInProgress] = useState(false);
    const [phoneLogin, setPhoneLogin] = useState(false);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const submitForm = async (values: any) => {
        setInProgress(true);
        try {
            let parsedValues = {
                isEmail: !phoneLogin,
                userContact: phoneLogin ? `${values.code} ${values.phoneNumber}` : values.email,
                language: locale,
            };

            await apiClient.request('/api/v1/password-reset', parsedValues, 'POST');

            setInProgress(false);
            navigate('/login');
        } catch (error) {
            setInProgress(false);
        }
    };

    return (
        <DefaultUnauthorizedLayout className={styles.center} showLanguageChange={true}>
            <Card className={styles.card}>
                <div className={styles.logoContainer}>
                    <Logo />
                </div>
                <p className='please-login-text'>
                    <FormattedMessage id='login.forgot_your_password' />
                </p>
                <p className='reset-password-text'>
                    {phoneLogin ? (
                        <FormattedMessage id='login.enter_phone_number' />
                    ) : (
                        <FormattedMessage id='login.enter_email' />
                    )}
                </p>
                <Form form={form} onFinish={submitForm}>
                    {phoneLogin ? (
                        <PhoneNumberSelect
                            defaultCode={config.api.defaultCode}
                            form={form}
                            wrapperClassName={styles.phoneInputField}
                            name='phoneNumber'
                            placeholder={intl.formatMessage({ id: 'users.form.phone_number' })}
                            prefix={<i className='fa-solid fa-mobile-screen' />}
                        />
                    ) : (
                        <Input
                            name='email'
                            className={styles.inputField}
                            autoComplete='on'
                            customRules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'login.email.validation' }),
                                },
                            ]}
                            prefix={<i className={`fal fa-user ${styles.iconColor}`} />}
                            placeholder={intl.formatMessage({ id: 'login.email' })}
                            customLayout={true}
                        />
                    )}
                    <Checkbox className={styles.checkbox} onChange={(el: any) => setPhoneLogin(el.target.checked)}>
                        <FormattedMessage id='login.use_phone_to_login' />
                    </Checkbox>
                </Form>
                <div className={styles.centerButtons}>
                    <Button
                        onClick={form.submit}
                        type='primary'
                        className={styles.wideButton}
                        loading={inProgress}
                    >
                        <FormattedMessage id='login.reset_password' />
                    </Button>
                    <Button onClick={() => navigate('/login')} type='link'>
                        <FormattedMessage id='general.back' />
                    </Button>
                </div>
            </Card>
        </DefaultUnauthorizedLayout>
    );
};

export default PasswordReset;
