import React from 'react';
import {FormattedMessage} from 'react-intl';
import {UploadFile} from 'antd';
import Upload, {RcFile, UploadProps} from 'antd/lib/upload';
import {InboxOutlined} from '@ant-design/icons';

import Spinner from 'components/Spinner';

import {config} from 'config/config';

import generalHelper from 'utils/generalHelpers';

interface FileUploadListInterface {
    value?: UploadFile[],
    onAdd?(
        file: RcFile,
        callBack?: (id?: number) => void,
    ): void,
    onDelete?(id: number): Promise<boolean>,

    onDownload?(uid: number): any;
}

const FileUploadList: React.FC<FileUploadListInterface & UploadProps<any>> = ({ value, onAdd, onDelete, onDownload, ...restProps }) => {
    const { Dragger } = Upload;

    const handleAdd = ({ onError, onSuccess, file }: any) => {
        if (onAdd) {
            onAdd(file as RcFile, (id?: number) => {
                if (id && id > 0) {
                    onSuccess(id);
                } else {
                    onError();
                }
            });
        }
    }

    const handleRemove = async (file: UploadFile): Promise<boolean> => {
        if (!!file && file.status === 'error') {
            return true;
        }

        if (onDelete) {
            return await onDelete(file.response ?? +file.uid);
        }

        return true;
    }

    const handleDownload = async (file:any): Promise<any> => {
        if (onDownload) {
            return await onDownload(file);
        }

        return true;
    }

    return (<Spinner spinning={!value} >
        {!!value && <Dragger
            defaultFileList={value}
            listType="picture"
            isImageUrl={() => false}
            onDownload={handleDownload}
            customRequest={handleAdd}
            onRemove={handleRemove}
            {...restProps}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text"><FormattedMessage id='general.dragger_text' /></p>
            <p className="ant-upload-hint"><FormattedMessage id='general.dragger_description' /></p>
            <p className="ant-upload-hint"><FormattedMessage id='general.max_upload_file_size' values={{ size: generalHelper.getFileSize(config.maxUploadFileSize, 0) }} /></p>
        </Dragger>}
    </Spinner>)
};

export default FileUploadList;
