import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {
    Form,
    Button
} from 'antd';
import {Input} from 'components/Form';
import DefaultLayout from 'components/DefaultLayout';
import coursesApiClient from 'utils/coursesApiClient';
import Access from '../Access';

import './styles.scss';

import useHandleError from "../../../utils/useHandleError";
import generalHelpers from "../../../utils/generalHelpers";

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 6},
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

interface SettingsInterface {
    questionPool: any
    setQuestionPool: React.Dispatch<React.SetStateAction<any>>;
    session?: any;
}

const Settings: React.FC<SettingsInterface> = ({session, questionPool, setQuestionPool}) => {
    const [loading, setIsLoading] = useState(false);

    useEffect(() => {
        if (questionPool) {
            // setQuestionPoolLanguages
            form.setFieldsValue({
                ...questionPool,
            });

            if (generalHelpers.isPlatformAdmin(session.active_user_type)) {
                form.setFieldsValue({
                    ownerType: questionPool.ownerType,
                    ownerUuid: questionPool.ownerUuid,
                });
            }
        }


    }, [questionPool])

    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const params = useParams();

    const questionPoolId = params.id;

    const [form] = Form.useForm();
    const intl = useIntl();

    const submitForm = async (values: any) => {
        setIsLoading(true);

        const parsedValues = {
            ...values,
        };

        let response;

        try {
            if (questionPoolId) {
                response = await coursesApiClient.request(`/api/v1/courses/question-pools/${questionPoolId}/update`, parsedValues, 'PUT');
                setQuestionPool(response.question_pool)
            } else {
                response = await coursesApiClient.request('/api/v1/courses/question-pools/store', parsedValues, 'POST');
                navigate(`/question-pools/${response.question_pool.id}`)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Form form={form} onFinish={submitForm} {...formItemLayout}>
                <div className='campaign-header-text'>
                    <h1 className='text-30'>{intl.formatMessage({id: 'question_pools.question_pool_settings'})}</h1>
                </div>

                <Input
                    name="title"
                    label={intl.formatMessage({id: 'general.title'})}
                    validation={{required: true}}
                    customLayout={formItemLayout}
                />

                <div className='campaign-header-text' style={{margin: '30px 0px 20px'}}>
                    <h1 className='text-24'>{intl.formatMessage({id: 'question_pools.add_user_access_title'})}</h1>
                </div>

                <Access />
                <div style={{height: '200px'}}>

                </div>
            </Form>
            <DefaultLayout.PageFooter
                right={
                    <>
                        <Button loading={loading} type='primary' onClick={form.submit}>
                            <FormattedMessage id='general.save'/>
                        </Button>
                    </>
                }
            />
        </>
    )
};

export default connect(mapStateToProps)(Settings);
