import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Drawer } from 'antd';
import './styles.scss';

interface QuestionsSidebarInterface {
    openSidebar: boolean;
    setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    addNewQuestion: Function;
    questionTypes: any;
}

const QuestionsSidebar: React.FC<QuestionsSidebarInterface> = ({
    openSidebar,
    setOpenSidebar,
    addNewQuestion,
    questionTypes,
}) => {
    const intl = useIntl();

    const addNewQuestionByType = (questionType: string) => {
        addNewQuestion(questionType);
    };

    return (
        <Drawer
            title={intl.formatMessage({ id: 'question_pools.choose_question_type' })}
            placement='right'
            onClose={() => setOpenSidebar(false)}
            open={openSidebar}
        >
            <div className='sidebar-questions'>
                {Object.keys(questionTypes).map((key: string) => {
                    return (
                        <div
                            key={key}
                            className='question-type'
                            onClick={() => addNewQuestionByType(key)}
                        >
                            <span className='anticon'>
                                <i className={questionTypes[key].icon} />
                            </span>
                            <span className='title'>
                                <FormattedMessage id={questionTypes[key].title} />
                            </span>
                        </div>
                    );
                })}
            </div>
        </Drawer>
    );
};

export default QuestionsSidebar;
