import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Button, Space } from 'antd';
import { Search } from 'ui';
import { Select } from 'components/Form';
import LanguageImportModal from 'components/LanguageImportModal';
import LanguageExportModal from 'components/Form/LanguageExportModal';
import TranslationFormModal from './modals/TranslationFormModal';
import { TranslationsToolbarInterface } from '../types';

const TranslationsToolbar: React.FC<TranslationsToolbarInterface> = ({ reload, setFilter, filter }) => {
    const intl = useIntl();
    const [showLanguageImportModal, setShowLanguageImportModal] = useState(false);
    const [showLanguageExportModal, setShowLanguageExportModal] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const handleChanged = (value: any, key?: string) => {
        setFilter((filter: any) => ({ ...filter, [key ? key : 'search']: value }));
        reload(true);
    }

    const reloadWindow = () => {
        sessionStorage.removeItem('translations');
        window.location.reload();
    }

    return (
        <Row>
            <Space size={10}>
                <Col>
                    <Select
                        showSearch
                        className={'select-width-100 mb-0'}
                        customLayout
                        name='firstLanguageId'
                        label={intl.formatMessage({ id: 'system.languages.first_language' })}
                        url={`/api/v1/languages`}
                        onChange={(value: string) => { handleChanged(value, 'firstLanguageId') }}
                    />
                </Col>
                <Col>
                    <Select
                        showSearch
                        customLayout
                        className={'select-width-100 mb-0'}
                        name='secondLanguageId'
                        label={intl.formatMessage({ id: 'system.languages.second_language' })}
                        url={`/api/v1/languages`}
                        onChange={(value: string) => { handleChanged(value, 'secondLanguageId') }}
                    />
                </Col>
                <Col>
                    <Select
                        name='module'
                        label={intl.formatMessage({ id: 'system.module' })}
                        customLayout
                        className={'select-width-200 mb-0'}
                        url={'/api/v1/enum/language-module-type'}
                        showSearch
                        allowClear
                        onChange={(value: string) => { handleChanged(value, 'module') }}
                    />
                </Col>
                <Col>
                    <Search
                        setSearchString={handleChanged}
                    />
                </Col>
                <Col>
                    <Button icon={<i className='fal fa-file-import' />}
                        onClick={() => setShowLanguageExportModal(true)}>
                        <span><FormattedMessage id='system.language.export' /></span>
                    </Button>
                </Col>
                <Col>
                    <Button icon={<i className='fal fa-file-export' />}
                        onClick={() => setShowLanguageImportModal(true)}>
                        <span><FormattedMessage id='system.language.import' /></span>
                    </Button>
                </Col>
                <Col>
                    <Button icon={<i className='fal fa-plus' />}
                        onClick={() => setShowForm(true)}>
                        <span><FormattedMessage id='general.create' /></span>
                    </Button>
                </Col>
                <Col>
                    <Button icon={<i className='fa-regular fa-rotate-right' />}
                        onClick={() => reloadWindow()}>
                    </Button>
                </Col>
            </Space>
            <LanguageImportModal
                visible={showLanguageImportModal}
                onCancel={() => setShowLanguageImportModal(false)}
            />
            <LanguageExportModal
                visible={showLanguageExportModal}
                onCancel={() => setShowLanguageExportModal(false)}
            />
            <TranslationFormModal
                visible={showForm}
                onCancel={setShowForm}
            />
        </Row>
    )
}
export default TranslationsToolbar;
