import {AudienceRuleData, AudienceRuleFunction} from 'components/Audiences/types';
import React, {useEffect, useState} from 'react';
import {Space} from 'antd';
import {useIntl} from 'react-intl';
import {Select, Input, Departments, OrganizationalUnits} from 'components/Form';
import {config} from 'config/config';
import {connect} from 'react-redux';


const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
}

const AudienceRuleFormItems: React.FC<any> = ({
                                                  audience,
                                                  customFields,
                                                  selectedCustomerId,
                                                  setSelectedCustomerId,
                                                  session,
                                                  ruleSetId,
                                                  fields,
                                                  form,
                                                  remove,
                                                  key,
                                                  name,
                                                  ...restField
                                              }) => {
    const [data, setData] = useState<string>();
    const [orgUnits, setOrgUnits] = useState<any[]|undefined>(undefined)
    const [departments, setDepartments] = useState<any[]|undefined>(undefined)
    const intl = useIntl();

    const [dataOptions, setDataOptions] = useState({
        [AudienceRuleData.UserPosition]: intl.formatMessage({id: 'general.position'}),
        [AudienceRuleData.UserCustomer]: intl.formatMessage({id: 'general.customer'}),
        [AudienceRuleData.UserOrganizationalUnit]: intl.formatMessage({id: 'general.unit'}),
        [AudienceRuleData.Department]: intl.formatMessage({id: 'general.department'}),
    })

    useEffect(() => {
        if (customFields.length) {
            Object.values(customFields).map((el: any) => {
                setDataOptions(dataOptions => ({...dataOptions, [el.uuid]: el.title}));
            })
        }

    }, [customFields]);

    useEffect(() => {
        const currentRules = form.getFieldValue(['rulesGroup', ruleSetId, 'rules'])
        const groupValues = currentRules[restField.fieldKey];

        let newValue: number|undefined;

        switch (groupValues?.data) {
            case 'DEPARTMENT':
                if (departments) {
                    if (departments.some(d => d.id == groupValues.value)) {
                        newValue = Number(groupValues.value)
                    } else {
                        newValue = 0
                    }
                }
                break

            case 'USER_ORGANIZATIONAL_UNIT':
                if (orgUnits) {
                    if (orgUnits.some(o => o.id == groupValues.value)) {
                        newValue = Number(groupValues.value)
                    } else {
                        newValue = 0
                    }
                }
                break

            default:
                break
        }

        if (newValue !== undefined) {
            currentRules[restField.fieldKey].value = newValue;

            form?.setFieldsValue({
                rulesGroup: {
                    [ruleSetId]: {
                        rules: [...currentRules]
                    }
                }
            });
        }

    }, [departments, orgUnits]);

    useEffect(() => {
        const groupValues = form.getFieldValue(['rulesGroup', ruleSetId, 'rules'])[restField.fieldKey];

        if (groupValues) {
            const {data: groupValueData, value} = groupValues;

            setData(groupValueData);

            if (groupValueData === AudienceRuleData.UserCustomer) {
                setSelectedCustomerId(value);
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const functionOptions = {
        [AudienceRuleFunction.Contains]: intl.formatMessage({id: 'audience.contains'}),
        [AudienceRuleFunction.DoesNotContain]: intl.formatMessage({id: 'audience.does_not_contain'}),
        [AudienceRuleFunction.EqualTo]: intl.formatMessage({id: 'audience.equal_to'}),
        [AudienceRuleFunction.NotEqualTo]: intl.formatMessage({id: 'audience.not_equal_to'}),
        [AudienceRuleFunction.StartsWith]: intl.formatMessage({id: 'audience.starts_with'}),
        [AudienceRuleFunction.EndsWith]: intl.formatMessage({id: 'audience.ends_with'}),
        [AudienceRuleFunction.Before]: intl.formatMessage({id: 'audience.before'}),
        [AudienceRuleFunction.After]: intl.formatMessage({id: 'audience.after'}),
        [AudienceRuleFunction.Boolean]: intl.formatMessage({id: 'audience.boolean'}),
    };

    const filterOperators = () => {
        if (data) {
            return Object.fromEntries(Object.entries(functionOptions).filter(([key]) => {
                switch (data) {
                    case AudienceRuleData.UserPosition:
                        return [
                            AudienceRuleFunction.Contains.toString(),
                            AudienceRuleFunction.DoesNotContain.toString(),
                            AudienceRuleFunction.EqualTo.toString(),
                            AudienceRuleFunction.NotEqualTo.toString(),
                            AudienceRuleFunction.Empty.toString(),
                            AudienceRuleFunction.StartsWith.toString(),
                            AudienceRuleFunction.EndsWith.toString(),
                        ].includes(key);
                    case AudienceRuleData.UserCustomer:
                    case AudienceRuleData.UserOrganizationalUnit:
                    case AudienceRuleData.Department:
                        return [
                            AudienceRuleFunction.EqualTo.toString(),
                            AudienceRuleFunction.NotEqualTo.toString(),
                        ].includes(key);
                    default:
                        return [
                            AudienceRuleFunction.Contains.toString(),
                            AudienceRuleFunction.DoesNotContain.toString(),
                            AudienceRuleFunction.EqualTo.toString(),
                            AudienceRuleFunction.NotEqualTo.toString(),
                            AudienceRuleFunction.Empty.toString(),
                            AudienceRuleFunction.StartsWith.toString(),
                            AudienceRuleFunction.EndsWith.toString(),
                        ].includes(key);
                }
            }));
        }

        return functionOptions;
    }

    const operators = filterOperators();

    const handleDataChange = (value: any, options: any) => {
        setData(value);
        findAndSetCustomerId();

        const currentRules = form.getFieldValue(['rulesGroup', ruleSetId, 'rules']);

        currentRules[restField.fieldKey].value = undefined;

        form?.setFieldsValue({
            rulesGroup: {
                [ruleSetId]: {
                    rules: [...currentRules]
                }
            }
        });
    }

    const findAndSetCustomerId = () => {
        const addRulSetRules = form.getFieldValue(['rulesGroup', ruleSetId, 'rules']);

        const ruleSetCustomer = addRulSetRules.map((rule: any) => {
            if (rule.data === AudienceRuleData.UserCustomer) {
                return rule.value;
            }

            return undefined;
        }).filter((element: any) => {
            return element !== undefined;
        })[0];

        setSelectedCustomerId(ruleSetCustomer);
    }

    function checkIfValidUUID(str?: string) {
        if (!str) {
            return false
        }
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

        return regexExp.test(str);
    }

    return (
        <Space key={key} className="audience-rule-set" align="baseline">
            <div>#{restField.fieldKey + 1}</div>
            <Space className="audience-rule-set-item">
                <Select customLayout={{wrapperCol: {span: 24}}}
                        name={[name, 'data']}
                        manualOptions={dataOptions}
                        onChange={(value: any, options: any) => handleDataChange(value, options)}
                        disabled={!audience.isEditable()}
                        {...restField}
                />

                <Select customLayout={{wrapperCol: {span: 24}}}
                        name={[name, 'function']}
                        manualOptions={operators}
                        disabled={!audience.isEditable()}
                        {...restField}
                />

                {checkIfValidUUID(data) &&
                    <Input customLayout={{wrapperCol: {span: 24}}}
                           name={[name, 'value']}
                           disabled={!audience.isEditable()}
                           {...restField}
                    />}

                {(!data || data === AudienceRuleData.UserPosition) &&
                    <Input customLayout={{wrapperCol: {span: 24}}}
                           name={[name, 'value']}
                           disabled={!audience.isEditable()}
                           {...restField}
                    />}

                {data === AudienceRuleData.UserCustomer && <Select
                    name={[name, 'value']}
                    allowClear
                    showSearch
                    dataKey='customers'
                    url={config.api.routes.backend.selectCustomersUuid}
                    customLayout={{wrapperCol: {span: 24}}}
                    dropdownStyle={{minWidth: "385px"}}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={(value: any, options: any) => setSelectedCustomerId(options.value)}
                    disabled={!audience.isEditable()}
                    {...restField}
                />}

                {data === AudienceRuleData.UserOrganizationalUnit && (
                    <OrganizationalUnits
                        name={[name, 'value']}
                        isUuid
                        setLoadedData={setOrgUnits}
                        hideLabel
                        canCreate={false}
                        customerId={selectedCustomerId}
                        customLayout={{wrapperCol: {span: 24}}}
                        dropdownStyle={{minWidth: "385px"}}
                        disabled={!audience.isEditable()}
                        {...restField}
                    />
                )}
                {data === AudienceRuleData.Department && (
                    <Departments
                        name={[name, 'value']}
                        isUuid
                        setLoadedData={setDepartments}
                        hideLabel
                        canCreate={false}
                        customerId={selectedCustomerId}
                        customLayout={{wrapperCol: {span: 24}}}
                        dropdownStyle={{minWidth: "385px"}}
                        disabled={!audience.isEditable()}
                        {...restField}
                    />
                )}
            </Space>

            {audience.isEditable() ? <i onClick={() => remove(name)} style={{opacity: .5, cursor: 'pointer'}}
                                        className="fa-regular fa-trash"></i> : <div></div>}

        </Space>
    );
};

export default connect(mapStateToProps)(AudienceRuleFormItems);
