import React, {useState, useEffect} from 'react';
import {Radio, Form} from 'antd';
import apiClient from 'utils/apiClient';
import {config} from 'config/config';

interface LanguageRadioButtonsInterface {
    languages?: string[];
    onClickFunction?: Function;
    className?: string;
}

const LanguageRadioButtons: React.FC<LanguageRadioButtonsInterface> = ({
                                                                           languages = null,
                                                                           onClickFunction,
                                                                           className = ''
                                                                       }) => {
    const [languagesList, setLanguages] = useState<string[]>([]);

    useEffect(() => {
        if (!languages) {
            loadLanguages()
        }
    }, [])

    useEffect(() => {
        if (languages && languages.length) {
            setLanguages(languages)
        }
    }, [languages])

    const loadLanguages = async () => {
        let languages = await apiClient.request(config.api.routes.backend.languages, {}, 'GET');
        let parsedLanguages: any = {};
        Object.values(languages.languages).map((value: any) => {
            return parsedLanguages[value['code']] = value['name'];
        });
        setLanguages(parsedLanguages);
    };

    const getFunction = () => {
        if (languages) {
            return Object.values
        } else {
            return Object.keys
        }
    }

    return (
        <Form.Item className='noColon' label={' '} name='language'>
            <Radio.Group className={className}>
                {
                    getFunction()(languagesList).map((language: string) => {
                        return (
                            <>
                                {language &&
                                  <Radio.Button className='f-up' value={language}
                                                onClick={() => !!onClickFunction ? onClickFunction(language) : undefined}>
                                      {language}
                                  </Radio.Button>
                                }
                            </>
                        )
                    })
                }
            </Radio.Group>
        </Form.Item>
    )
}

export default LanguageRadioButtons;
