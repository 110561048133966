import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal, Checkbox, Form, Select as AntDSelect, Radio, DatePicker, Alert} from 'antd';
import {Input, Select} from 'components/Form';
import useHandleError from 'utils/useHandleError';
import apiClient from "utils/apiClient";
import moment from "moment/moment";
import CRONBuilder from "components/CRONBuilder";
import datepickerTranslations from "../../../shared/datepickerTranslations";
import generalHelpers from "../../../utils/generalHelpers";
import { useLocaleContext } from 'context/LocaleContext';

interface SendEmailModalInterface {
    visible: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    organizationId: any;
    organizationType?: 'CUSTOMER' | 'RESELLER' | 'DISTRIBUTOR' | 'ORGANIZATION';
    users: [];
    defaultCategory?: string;
    programId?: number;
    programData?: any[];
}

const formItemLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 14},
};

const SendEmailModal: React.FC<SendEmailModalInterface> = ({
                                                               visible,
                                                               onCancel,
                                                               organizationId,
                                                               organizationType,
                                                               users,
                                                               defaultCategory,
                                                               programId,
                                                               programData
                                                           }) => {
    const { locale } = useLocaleContext();

    const intl = useIntl();
    const [form] = Form.useForm();
    const [handleError] = useHandleError();

    const [isTemplateLoading, setIsTemplateLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [sendingType, setSendingType] = useState('NOW')
    const [CRONExpression, setCRONExpression] = useState<string | null>(null);
    const [categories, setCategories] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        form.setFieldsValue({cc: false, iniPasswordReset: false})
    }, [])

    useEffect(() => {
        if (defaultCategory && !generalHelpers.isEmpty(categories)) {
            loadEmailTemplates(getCategoryId())
        }
    }, [categories])


    const submitForm = async (values: any) => {
        setIsLoading(true);

        let parsedValues: any = {
            category: values.category,
            templateId: values.templateId,
            cc: values.cc,
            iniPasswordReset: values.iniPasswordReset,
            ownerType: organizationType,
            ownerReferenceId: organizationId,
            status: 'ACTIVE',
            scenarioType: 'CUSTOM',
            toSpecificUsers: users,
        }

        if (!!programId) {
            parsedValues.programId = programId;
            parsedValues.programData = programData;
        }

        //     parsedValues = {
        //         ...parsedValues,
        //         status: campaignStatus === 'DRAFT' ? 'INACTIVE' : 'ACTIVE',
        //         campaignId: campaignId,
        //         learningCampaign: 1,
        //         to: {
        //             userTypes: ['STUDENT'],
        //             options: {
        //                 accountStatus: 'ACTIVE'
        //             },
        //             customFields: {},
        //             campaign: {
        //                 campaignId: campaignId,
        //                 progress: values.progress
        //             }
        //         }
        //     }

        switch (sendingType) {
            case 'NOW':
                parsedValues.sendEmailNow = true;
                parsedValues.startDate = moment().format('YYYY-MM-DD');
                parsedValues.endDate = moment().format('YYYY-MM-DD');

                break;
            case 'SPECIFIC_TIME':
                parsedValues.sendEmailNow = false;
                parsedValues.startDate = moment().format('YYYY-MM-DD');
                parsedValues.endDate = moment().format('YYYY-MM-DD');
                parsedValues.scheduleDate = values.scheduleDate.format('YYYY-MM-DD HH:mm');
                break;
            case 'RECURRING':
                parsedValues.sendEmailNow = false;
                parsedValues.startDate = values.period[0].format('YYYY-MM-DD');
                parsedValues.endDate = values.period[1].format('YYYY-MM-DD');
                parsedValues.cronExpression = CRONExpression;
                break;
        }

        try {
            await apiClient.request('/api/v1/mail-scenarios', parsedValues, 'POST');

            onCancel(false);
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const loadEmailTemplates = async (categoryId: number) => {
        setIsTemplateLoading(true)
        form.setFieldsValue({templateId: null})
        try {
            let url

            switch (organizationType) {
                case 'ORGANIZATION':
                case 'RESELLER':
                case 'DISTRIBUTOR':
                    url = `/api/v1/mail-templates/organization/${organizationId}/select/${categoryId}`
                    break;
                case 'CUSTOMER':
                    url = `/api/v1/mail-templates/customer/${organizationId}/select/${categoryId}`
                    break;
            }

            const response = await apiClient.request(url, 'GET');

            setTemplates(response.mailTemplates)
        } catch (error) {
            handleError(error);
        } finally {
            setIsTemplateLoading(false);
        }
    }

    const getCategoryId = () => {
        const value = Object.entries(categories).filter((el:any) => el[1] === defaultCategory)

        return parseInt(value[0][0])
    }

    return (
        <Modal
            width={'40%'}
            className="user-list-modal"
            open={visible}
            title={intl.formatMessage({id: 'general.mail_scenario'})}
            onOk={() =>
                form.submit()
            }
            onCancel={() =>
                onCancel(false)
            }
            okButtonProps={{ loading: isLoading }}
            okText={intl.formatMessage({id: 'general.send'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
        >
            {programData?.length ?
                <div className='mb-4'>
                    <Alert type='info' message={intl.formatMessage({ id: 'emailing.scenarios.info_count' }, { count: programData?.length })} showIcon />
                </div>
                : null
            }
            <Form form={form} onFinish={submitForm} {...formItemLayout}>
                {(defaultCategory && !generalHelpers.isEmpty(categories)) &&
                    <Form.Item
                        className='scenario-email-required'
                        name='categoryId'
                        label={intl.formatMessage({id: "general.category"})}
                    >
                        <AntDSelect
                            disabled={true}
                            className={'default-select'}
                            defaultValue={getCategoryId()}
                            options={[
                                {
                                    value: getCategoryId(),
                                    label: <FormattedMessage id={`email_templates.categories.${defaultCategory}`}/>
                                }
                            ]}
                        />
                    </Form.Item>
                }
                <div className={!!defaultCategory ? 'hidden' : ''}>
                    <Select
                        saveLoadedData={true}
                        customLayout={formItemLayout}
                        setLoadedData={setCategories}
                        name={!defaultCategory ? 'categoryId': 'hiddenCategory'}
                        url='/api/v1/mail-templates/options/categories'
                        label={intl.formatMessage({id: "general.category"})}
                        integerKey={true}
                        onChange={(value: number) => loadEmailTemplates(value)}
                        customRules={[{
                            required: !defaultCategory,
                            message: intl.formatMessage({id: "validation.field_required"})
                        }]}
                        customObjLabel={(label: string) => intl.formatMessage({id: `email_templates.categories.${label}`})}
                    />
                </div>

                <Form.Item
                    className='scenario-email-required'
                    name='templateId'
                    label={intl.formatMessage({id: 'emailing.scenarios.email_template'})}
                    rules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                >
                    <AntDSelect showArrow loading={isTemplateLoading} className='default-select'>
                        {templates.map((template: any) => {
                                const translations = template.translations;
                                let titleObject = translations.filter((el: any) => el.language === locale)[0];

                                if (!titleObject) {
                                    titleObject = translations[Object.keys(translations)[0]]
                                }
                                return <AntDSelect.Option
                                    value={parseInt(template.id)}>{titleObject.title}</AntDSelect.Option>
                            }
                        )}
                    </AntDSelect>
                </Form.Item>
                <Form.Item label={intl.formatMessage({id: 'emailing.scenarios.when_to_send'})}>
                    <Radio.Group onChange={(el) => setSendingType(el.target.value)} value={sendingType}>
                        <Radio value='NOW'>
                            <FormattedMessage id='emailing.scenarios.now'/>
                        </Radio>
                        <Radio value='SPECIFIC_TIME'>
                            <FormattedMessage id='emailing.scenarios.on_a_specific_time'/>
                        </Radio>
                        <Radio value='RECURRING'>
                            <FormattedMessage id='emailing.scenarios.recurring'/>
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                {
                    sendingType === 'SPECIFIC_TIME' &&
                    <Form.Item label={intl.formatMessage({id: 'emailing.scenarios.on_a_specific_time'})}
                               name='scheduleDate' rules={[{
                        required: true,
                        message: intl.formatMessage({id: "validation.field_required"})
                    }]}>
                        <DatePicker
                            className='scroll-bar-scenario-modal'
                            format="YYYY-MM-DD HH:mm"
                            disabledDate={(current: any) => current && current < moment().subtract(1, 'day').endOf('day')}
                            showTime={{defaultValue: moment('00:00:00', 'HH:mm')}}
                            placeholder={intl.formatMessage({id: 'users.select_date'})}
                        />
                    </Form.Item>
                }
                {
                    sendingType === 'RECURRING' &&

                    <>
                        <Form.Item
                            label={intl.formatMessage({id: 'emailing.scenarios.recurring'})}
                        >
                            <CRONBuilder
                                className='scroll-bar-scenario-modal'
                                onChange={(value: string | null) => setCRONExpression(value)}
                                initialValue={null}
                            />
                        </Form.Item>
                        <Form.Item name='period'
                                   label={intl.formatMessage({id: 'emailing.scenarios.period'})} rules={[{
                            required: true,
                            message: intl.formatMessage({id: "validation.field_required"})
                        }]}>
                            <DatePicker.RangePicker
                                format="YYYY-MM-DD"
                                disabledDate={(current: any) => current && current < moment().subtract(1, 'day').endOf('day')}
                                locale={datepickerTranslations(intl)}
                            />
                        </Form.Item>
                    </>
                }
                <Form.Item
                    name='cc'
                    label={intl.formatMessage({id: 'emailing.scenarios.copy_cc_direct_manager'})}
                >
                    <Checkbox/>
                </Form.Item>
                <Form.Item
                    name='iniPasswordReset'
                    label={intl.formatMessage({id: 'emailing.scenarios.initialize_password_reset'})}
                >
                    <Checkbox/>
                </Form.Item>
            </Form>
        </Modal>
    )
};


export default SendEmailModal;
