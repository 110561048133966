import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, Search } from "ui";
import FlexRow from "components/FlexRow";
import {Button, message, Popover, Space, Tooltip} from 'antd';
import { SessionTabProps } from 'components/Lectures/types';
import IncreasedStudentInterestModal from 'components/Lectures/Modals/IncreasedStudentInterestModal';
import DefaultLayout from "components/DefaultLayout";
import { useSession } from "hooks/Lectures/useSession";
import {Switch} from "../../../Form";
import ChangeStatusModal from "../../Modals/ChangeStatusModal";

const SessionWaitingList: React.FC<SessionTabProps> = ({ lecture, session, reloadSession }) => {
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState<string>('');
    const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false)
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [filter, setFilter] = useState('absence=0&mandatory=0');

    const { addSessionAttendance, sessionLoading } = useSession();

    const intl = useIntl();

    const capacityExceeded = (session.capacity ?? 0) < (session.attendees ?? 0) + selectedRowKeys.length;

    const handleRowActionVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const columns = [
        {
            key: 'user',
            title: intl.formatMessage({ id: 'audience.user' }),
            render: (_text: string, record: any) => {
                return `${record.name} ${record.surname}`;
            }
        },
        {
            key: 'email',
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({ id: 'general.position' }),
            dataIndex: 'position'
        },
        {
            title: intl.formatMessage({ id: 'general.organizational_unit' }),
            dataIndex: 'organizationalUnit'
        },
        {
            title: intl.formatMessage({ id: 'general.organization' }),
            dataIndex: 'organization'
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = !capacityExceeded ? (
                    <div className="popover-item"
                        onClick={() => handleAddAttendance(record.id)}>
                        <FormattedMessage id="general.lectures.plan_session_attendance" />
                    </div>
                ) : null;

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleRowActionVisibleChange(visible, record.id)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                )
            },
        },
    ];

    const getTableColmns = () => {
        if ((session.capacity ?? 0) <= (session.attendees ?? 0) + selectedRowKeys.length) {
            return columns.filter((column: any) => column.key !== 'actions')
        }

        return columns;
    }

    const handleAddAttendance = async (id: number | null = null) => {
        setVisibleTooltip(null);
        if (session && session.id && session.lecturesId) {
            if (await addSessionAttendance(session.lecturesId, session.id, id ? [id] : selectedRowKeys)) {
                setReload(!reload);
                setSelectedRowKeys([]);
                reloadSession();
            }
        }
    }

    const handleCancelMessageModal = () => {
        setShowMessageModal(false);
    }

    const handleSentMessageModal = () => {
        setShowMessageModal(false);
        message.success(intl.formatMessage({ id: 'users.email_sent' }), 15);
    }

    const handleSwitch = (value: any, key: string) => {
        let tempFilter;

        if (value) {
            tempFilter = filter.replace(key + '=0', key + '=1')
        } else {
            tempFilter = filter.replace(key + '=1', key + '=0')
        }

        setFilter(tempFilter)
        setReload(true)
    }

    return (
        <>
            <FlexRow
                right={
                    <div className={'flex flex-column align-end gap-16'}>
                        <Search setSearchString={setSearchString} setReload={setReload}/>
                        <div className={'flex gap-10 strict-order'}>
                            <FormattedMessage id="lectures.long_absence"/>
                            <Switch onClick={(value: boolean) => handleSwitch(value, 'absence')}/>
                        </div>
                        <div className={'flex gap-10 strict-order'}>
                            <FormattedMessage id="lectures.mandatory_lecture"/>
                            <Switch onClick={(value: boolean) => handleSwitch(value, 'mandatory')}/>
                        </div>
                    </div>
                }
            />
            <Table
                columns={getTableColmns()}
                url={`/api/v1/courses/lectures/${lecture.id}/sessions/${session.id}/waiting-list`}
                reload={reload}
                setReload={setReload}
                search={searchString}
                rowKey={'id'}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                setTotalRecords={setTotalRecords}
                filter={filter}
                isLoading={sessionLoading}
            />
            <DefaultLayout.PageFooter
                left={
                    <>
                        {selectedRowKeys.length}<FormattedMessage id='users.rows_selected' />
                        <Tooltip
                            title={<div>
                                <div>{intl.formatMessage({ id: 'general.lectures.session_capacity_exceeded' }, { capacity: session.capacity ?? 0 })}</div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 5
                                }}>
                                    <Button
                                        onClick={() => setShowMessageModal(true)}>
                                        <FormattedMessage id='general.lectures.request_capacity_upgrade' />
                                    </Button>
                                </div>
                            </div>}
                            defaultOpen={capacityExceeded && !showMessageModal}
                            open={capacityExceeded && !showMessageModal}>
                            <Space size={5}>
                                <Button
                                    disabled={!selectedRowKeys.length || capacityExceeded}
                                    onClick={() => handleAddAttendance()}>
                                    <i className='fa-solid fa-clock header-item' style={{ marginRight: 4, fontSize: 16, float: 'left' }} />
                                    <FormattedMessage id='general.lectures.plan_session_attendance' />
                                </Button>
                                <Button
                                    disabled={!selectedRowKeys.length || capacityExceeded}
                                    onClick={() => setShowStatusModal(true)}>
                                    <i className='fa-solid fa-clock header-item' style={{ marginRight: 4, fontSize: 16, float: 'left' }} />
                                    <FormattedMessage id='general.lectures.change_status' />
                                </Button>
                            </Space>
                        </Tooltip>
                    </>
                }
            />
            {session && <IncreasedStudentInterestModal 
                visible={showMessageModal}
                session={session} 
                onCancel={handleCancelMessageModal} 
                onSubmit={handleSentMessageModal} 
            />}
            <ChangeStatusModal visible={showStatusModal} onCancel={setShowStatusModal} selectedRowKeys={selectedRowKeys}  session={session}/>
        </>
    );
};

export default SessionWaitingList;
