import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Space, Tag } from 'antd';
import { config } from 'config/config';
import moment from 'moment';
import './styles.scss';

interface ActiveQuestionHeaderProps {
    activeQuestion: any;
    testAttempt: any;
}

const ActiveQuestionHeader: React.FC<ActiveQuestionHeaderProps> = ({
    activeQuestion,
    testAttempt,
}) => {
    const intl = useIntl();

    const getQuestionIdKey = (questionIdPrefix: string | null, id: number) => {
        if (questionIdPrefix) {
            return `${intl.formatMessage({ id: 'general.id' })}-${questionIdPrefix}-${id}`;
        } else {
            return `${intl.formatMessage({ id: 'general.id' })}${id}`;
        }
    };

    const handleDeleted = () => {
        if (!activeQuestion?.question?.deleted_at) {
            return '';
        }
        const date = moment(activeQuestion.question.deleted_at).format(config.defaultDateFormat);
        return (
            <Tag color="red">
                <FormattedMessage id="question_pools.deleted_question" values={{ date: date }} />
            </Tag>
        );
    };

    return (
        <Col>
            {activeQuestion.question ? (
                <span>
                    {activeQuestion.attemptQuestion.questionNumber} of {testAttempt.questionsCount}{' '}
                    <Space size={5}>
                        <span style={{ textTransform: 'uppercase', color: '#999' }}>
                            <span className="mx-2">|</span>
                            {getQuestionIdKey(
                                activeQuestion.question?.question_bank.id_key,
                                activeQuestion.poolQuestionId
                            )}
                        </span>
                        {handleDeleted()}
                    </Space>
                </span>
            ) : null}
        </Col>
    );
};

export default ActiveQuestionHeader;
