import React from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form } from 'antd';
import { Select, Input } from 'components/Form';
import useQueryApiClient from 'utils/useApiClient';
import { TranslationFormModalInterfaceInterface } from '../../types';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const TranslationFormModal: React.FC<TranslationFormModalInterfaceInterface> = ({ visible, onCancel }) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    const { appendData, isLoading } = useQueryApiClient({
        request: {
            disableOnMount: true,
            method: 'POST',
            url: '/api/v1/languages/translation'
        },
        onSuccess: () => {
            onCancel(false)
            form.resetFields();
        },
    });

    return (
        <Modal
            title={intl.formatMessage({ id: 'system.create_translatons' })}
            open={visible}
            onOk={form.submit}
            onCancel={() => onCancel(false)}
            okText={intl.formatMessage({ id: 'general.submit' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            okButtonProps={{
                loading: isLoading,
            }}
        >
            <Form form={form} onFinish={appendData} {...formItemLayout}>
                <Select
                    customLayout={formItemLayout}
                    name='languageId'
                    label={intl.formatMessage({ id: 'system.language' })}
                    url={`/api/v1/languages`}
                    validation={{ required: true }}
                />
                <Select
                    name='module'
                    label={intl.formatMessage({ id: 'system.module' })}
                    customLayout
                    url={'/api/v1/enum/language-module-type'}
                    allowClear
                    validation={{ required: true }}
                    showSearch
                />
                <Input
                    validation={{ required: true }}
                    customLayout
                    label={intl.formatMessage({ id: 'system.languages.key' })}
                    name={'key'}
                />
                <Input
                    validation={{ required: true }}
                    customLayout
                    label={intl.formatMessage({ id: 'system.languages.translation' })}
                    name={'translation'}
                />
            </Form>
        </Modal>
    )
}

export default TranslationFormModal;
