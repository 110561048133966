import React from 'react';
import { useGlobalDesignContext } from 'context/GlobalDesignContext';

const Logo: React.FC<any> = ({ height = 50 }) => {
    const { logo } = useGlobalDesignContext();

    return <img src={logo} alt={'CloudStudy'} height={height} />;
};

export default Logo;
