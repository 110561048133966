// Workaround for TypeScript TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'
const windowObj = (window as { [key: string]: any });

export const routes = {
  api: {
    frontendUrl: windowObj['runConfig'].frontendUrl,
    baseUrl: windowObj['runConfig'].backendUrl,
  },
}

export const config = {
  defaultEnvironmentLocale: windowObj['runConfig'].defaultEnvironmentLocale,
  defaultDateFormat: windowObj['runConfig'].defaultDateFormat,
  DBDateFormat: windowObj['runConfig'].DBDateFormat,
  maxUploadFileSize: windowObj['runConfig'].maxUploadFileSize,
  api: {
    frontendUrl: windowObj['runConfig'].frontendUrl,
    baseUrl: windowObj['runConfig'].backendUrl,
    coursesUrl: windowObj['runConfig'].coursesUrl,
    serveUrl: windowObj['runConfig'].coursesServeUrl,
    previewUrl: windowObj['runConfig'].previewUrl,
    serveEmbeddedUrl: windowObj['runConfig'].coursesEmbeddedUrl,
    tinymceApiKey: windowObj['runConfig'].tinymceApiKey,
    supportInfoPhone: windowObj['runConfig'].supportInfoPhone,
    defaultCode: windowObj['runConfig'].defaultCode,
    supportInfoEmail: windowObj['runConfig'].supportInfoEmail,
    environment: windowObj['runConfig'].nodeEnv,
    appAppearance: windowObj['runConfig'].appAppearance,
    defaultAppLanguage: windowObj['runConfig'].defaultAppLanguage,
    routes: {
      auth: {
        login: '/api/v1/auth/login',
        refresh: '/api/v1/auth/refresh',
        me: '/api/v1/auth/me',
        logout: '/api/v1/auth/logout',
        switchUserRole: '/api/v1/auth/switch-user-role'
      },
      enums: {
        countries: '/api/v1/enum/country-types',
        organizationStatuses: '/api/v1/enum/organization-status-types',
        actionOnAgreementEnd: '/api/v1/enum/action-on-agreement-end-types',
        organizationalUnits: '/api/v1/organizational-unit-types',
        customerAdminAccountStatuses: '/api/v1/enum/customer-admin-account-status-types',
        emailTemplates: '/api/v1/enum/mail-template-types',
        serviceTypes: '/api/v1/enum/system-service-types',
        licenceServiceTypes: '/api/v1/enum/licence-service-types',
        actionAfterLicenceEndTermTypes: '/api/v1/enum/action-after-licence-end-term-types',
        contentStatus: '/api/v1/enum/content-status',
        learningCampaignNotificationTypes: '/api/v1/enum/learning-campaign-notification-types',
        campaignStatusTypes: '/api/v1/courses/enum/campaign-status',
        organizationalStatusTypes: '/api/v1/enum/organization-status-types',
        customerIndustryTypes: '/api/v1/enum/customer-industry-types',
        customFieldTypes: '/api/v1/courses/enum/custom-field-types',
        eventSubjectTypes: '/api/v1/courses/enum/event-subject-types',
        userRoleTypes: '/api/v1/enum/user-role-types'
      },
      backend: {
        appearanceSettings: {
          current: '/api/v1/appearance-settings/current',
          base: '/api/v1/appearance-settings/base',
          global: '/api/v1/appearance-settings/global',
          reseller: '/api/v1/appearance-settings/reseller/{id}',
          customer: '/api/v1/appearance-settings/customer/{id}',
          delete: '/api/v1/appearance-settings/{id}/delete-{type}',
        },
        selectCustomers:'/api/v1/customers/options/select',
        selectCustomersUuid:'/api/v1/customers/options/select-uuid',
        selectOrganizations:'/api/v1/organizations/options/select',
        selectOrganizationsUuid:'/api/v1/organizations/options/select-uuid',

        //depricate
        users: '/api/v1/auth/users',
        customers: '/api/v1/customers',
        activeCustomers: '/api/v1/customers?withoutDeleted=true',
        customerAgreements: '/api/v1/customer-agreements',
        organizations: '/api/v1/organizations',
        organizationsOptions: '/api/v1/organizations-options',
        organizationsCustomers: '/api/v1/organization-customers',
        standardMailables: '/api/v1/mail-templates/standard-mailables',
        mailCategories: '/api/v1/mail-templates/options/categories',
        standardTemplates: '/api/v1/mail-templates',
        mailVariables: '/api/v1/mail-templates/mailables-variables',
        emailHistory: '/api/v1/mail-history',
        mailScenarios: '/api/v1/mail-scenarios',
        languages: '/api/v1/language',
        instructions:{
          positions: '/api/v1/instruction/positions'
        }
      },
      courses: {
        program:{
          supportedLanguages: '/api/v1/courses/learning-program/{id}/supported-languages'
        }
      }
    }
  }
};

