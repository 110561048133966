import React from 'react';
import { Button } from 'ui';
import { useLocaleContext } from 'context/LocaleContext';
import styles from './styles.module.scss';

const LanguageButtons: React.FC<any> = ({}) => {
    const { locale, setLocale } = useLocaleContext();

    const dictionary = JSON.parse(sessionStorage.getItem('translations') ?? '');

    return (
        <>
            {dictionary &&
                Object.keys(dictionary).map((entries: any) => {
                    if (entries !== 'loading' && entries !== 'failed') {
                        return (
                            <Button
                                key={entries}
                                className={
                                    (locale === entries) ? styles.localeSwitchActive : styles.localeSwitch
                                }
                                onClick={() => setLocale(entries)}
                            >
                                {entries}
                            </Button>
                        );
                    }
                    return null;
                })}
        </>
    );
};
export default LanguageButtons;
