import axios, { AxiosRequestConfig } from 'axios';
import {config} from 'config/config';
import jwt from './jwt';
import { message } from 'antd';

interface InvalidRequestResponse {
  status_code: number,
  message: string
}

const parsedError = (response: InvalidRequestResponse, method: string) => {
  return ({
    status: response.status_code,
    message: response.message
  });
}

const request: any = async (url: string, data: any, method: any, mustRetry: boolean = true, multipart: boolean = false, postParams: any = {}, multipartResponseType: any = 'blob', responseType: any = 'json',) => {
  
  const requestConfig: AxiosRequestConfig = {
    url: url,
    method: method,
    baseURL: config.api.coursesUrl,
    responseType: multipart ? multipartResponseType : responseType,
    headers: {'Authorization': jwt.getHeader(), 'Content-Type': multipart ? '' : 'application/json'},
  };

  if (method === 'GET') {
    requestConfig.params = data;
  } else {
    requestConfig.data = data;

    if (Object.values(postParams).length > 0) {
      requestConfig.params = postParams;
    }
  }

  try {
    const response = await axios.request(requestConfig);

    let responseContent = response.data;
    
    if (response.headers['file-name']) {
      responseContent.fileName = response.headers['file-name'];
    }

      if (responseContent && responseContent.status_code > 299) {
          throw parsedError(responseContent, method);
      }
      if (responseContent?.code > 299 && responseContent?.error) {
          throw parsedError({status_code: responseContent.code, message: responseContent.error}, method);
      }

    return responseContent;
  } catch (e: any) {
    const response = e.response;

    if (isTokenExpired(response) && mustRetry) {
      try {
        await jwt.refresh();
        return await request(url, data, method, false);
      } catch (e) {
        message.error('Session expired');
        throw e;
      }
    }

    if (response?.data && response.data?.code === 401 && response.data?.error === 'Unauthorized') {
      window.location.assign('/login')
      jwt.remove();
    }

    throw e;
  }
};

const isTokenExpired = (response: any) => {
  return response && response.status === 401 && response.statusText === 'Unauthorized' && response.data.message === 'Token has expired';
};


const exportedObject = {
  request
};

export default exportedObject;
