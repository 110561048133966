import React from 'react';
import {useIntl} from 'react-intl';
import {Modal} from 'antd';
import {connect} from 'react-redux';
import LearningTestAttempt from 'components/LearningTest/TestAttempt';
import {clearLearningTestAttemptData} from 'store/actions/learningTestAttemptData';

import './styles.scss';

interface TestPreviewInterface {
    open: any;
    onCancel: any;
    learningTestId: null|number;
    clearLearningTestAttemptData: Function;
}
const mapStateToProps = (state: any) => {
    return {
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    clearLearningTestAttemptData: () => dispatch(clearLearningTestAttemptData()),
});

const TestPreview: React.FC<TestPreviewInterface> = ({
    open,
    onCancel,
    learningTestId,
    clearLearningTestAttemptData
}) => {
    const intl = useIntl();

    return (
        <Modal
            className="learning-test-preview-modal"
            title={intl.formatMessage({id: 'learning_tests.test_preview'})}
            open={open}
            onOk={() => {

            }}
            onCancel={() => {
                clearLearningTestAttemptData()
                onCancel()
            }}
            okText={intl.formatMessage({id: 'grading.grade'})}
            okButtonProps={{style: {display: 'none'}}}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            destroyOnClose
            width="84vw"
        >
            <LearningTestAttempt
                defaultLearningTestId={learningTestId}
                isPreview={true}
            />
        </Modal>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPreview);
