import React from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {message, Button, Form} from 'antd';
import {Select, InputNumber, TextArea, DatePicker} from 'components/Form';
import useQueryApiClient from 'utils/useApiClient';
import '../styles.scss';
import moment from 'moment';
import {config} from "../../../config/config";

const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 12},
};

interface AssessmentFinalResultInterface {
    activityFormData: any;
    assessmentSettings: any;
    submission: any;
    setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitLoading: boolean;
    onGraded: Function;
}

const AssessmentFinalResult: React.FC<AssessmentFinalResultInterface> = ({
    activityFormData,
    assessmentSettings,
    submission,
    setIsSubmitLoading,
    isSubmitLoading,
    onGraded
}) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    const {appendData: submitFinalAssessment} = useQueryApiClient({
        request: {
            url: `/api/v1/courses/assessment-results/submit-final-assessment/${submission.id}`,
            method: 'POST'
        },
        onSuccess: (): void => {
            message.success(intl.formatMessage({id: "courses.evaluation_submitted_success"}))
            onGraded()
        },
        onFinally: (): void => setIsSubmitLoading(false)
    });

    const submitForm = async (values: any) => {
        setIsSubmitLoading(true)
        submitFinalAssessment(values)
    };

    const renderGradeValue = (evaluationType: string | undefined) => {
        switch (evaluationType) {
            case 'EXAMINATION':
                return (
                    <Select
                        name='grade'
                        label={intl.formatMessage({id: 'grading.grade'})}
                        validation={{required: true}}
                        customLayout={formItemLayout}
                        defaultValue={submission.grade ?? 0}
                        manualOptions={{
                            PASSED: intl.formatMessage({id: 'general.passed'}),
                            FAILED: intl.formatMessage({id: 'general.failed'})
                        }}
                        disabled={!activityFormData.attemptData.finalAssessment.canSubmit}
                    />
                )

            case 'RANGE':
                return (
                    <InputNumber
                        name='grade'
                        label={intl.formatMessage({id: 'grading.grade'})}
                        customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                        customLayout={formItemLayout}
                        min={0}
                        max={100}
                        formatter={(value: number) => `${value}%`}
                        parser={(value: string) => value!.replace('%', '')}
                        disabled={!activityFormData.attemptData.finalAssessment.canSubmit}
                    />
                )
        }
    }

    return (
        <div className='assessment-final-result'>
            <Form className='assessment-final-result-form' form={form} onFinish={submitForm} initialValues={{
                grade: submission.grade,
                grade_comments: activityFormData.attemptData.finalAssessment.comments,
                grade_date: moment(activityFormData?.attemptData?.finalAssessment?.gradedDate ?? moment())
            }}>
                {renderGradeValue(assessmentSettings.evaluation_type)}
                <DatePicker
                    name={'grade_date'}
                    label={intl.formatMessage({id: 'grading.grade_date'})}
                    customLayout={formItemLayout}
                    format={config.defaultDateFormat}
                    disabled={!activityFormData.attemptData.finalAssessment.canSubmit}
                />
                <TextArea
                    name='grade_comments'
                    label={intl.formatMessage({id: 'general.comments'})}
                    customLayout={formItemLayout}
                    autoSize={{ minRows: 3 }}
                    showCount
                    disabled={!activityFormData.attemptData.finalAssessment.canSubmit}
                />
                {activityFormData.attemptData.finalAssessment.canSubmit && (
                    <div className='flex gap-10'>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={isSubmitLoading}
                        >
                            <FormattedMessage id='assessment.submit_assessment'/>
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    )
}
export default AssessmentFinalResult;