import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Card, Tag} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import Buttons from "./Buttons";
import './styles.scss';
import generalHelpers from "../../../utils/generalHelpers";
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
}

const AntDTagColor: any = {
    COMPLETED: 'green',
    PASSED: 'green',
    IN_PROGRESS: 'blue',
    RETURNED: 'blue',
    NOT_STARTED: 'gold'
}

const MaterialCard: React.FC<any> = ({
                                         material,
                                         adminView = false,
                                         multipleAttempts
                                     }) => {
    const { locale } = useLocaleContext();

    const intl = useIntl();

    const getTitle = (material: any) => {
        if (material?.id) {
            return material.title[generalHelpers.firstLower(locale)] ?? material.title[Object.keys(material.title)[0]]
        } else {
            return intl.formatMessage({id: material.title})
        }
    }

    const getMandatory = () => {
        if (multipleAttempts) {
            return (
                material.mandatoryRepeat ? <CheckOutlined style={{color: 'green'}}/> :
                    <CloseOutlined style={{color: 'red'}}/>
            )
        } else {
            return material.mandatory ? <CheckOutlined style={{color: 'green'}}/> :
                <CloseOutlined style={{color: 'red'}}/>
        }
    }

    const normalizePercent = (int?: number) => {
        if (int && 0 > int) {
            return 0;
        }
        return int ?? 0;
    }

    return (
        <Card className="card">
            <div className="program-data-container">
                <div className={'student-material title'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'general.activity'}/>
                    </span>
                    <span>
                        <div className={'flex gap-5 align-center'}>
                            <i className="fa-solid fa-book pr-1"></i>
                            <div className={'flex flex-column gap-5'}>
                                <div>
                                    {getTitle(material)}
                                </div>
                                <div className={'c-gray flex gap-5'}>
                                    <span>
                                    {!!material.hasLearning ?
                                        <div className={'c-gray flex gap-5'}>
                                            <FormattedMessage id={'student.e_learning_activity'}/>
                                        </div>
                                        : <div className={'c-gray flex gap-5'}>
                                            <FormattedMessage id={'student.e_learning_activity'}/>
                                        </div>
                                    }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
                <div className={'student-material time-spent'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'student.time_spent'}/>
                    </span>
                    <span>
                        {material?.minutes ? generalHelpers.secondsToHHmm(material.minutes * 60) : '-'}
                     </span>
                </div>
                <div className={'student-material pass-rate'}>
                   <span className={'student-material-title'}>
                        <FormattedMessage id={'general.pass_rate'}/>
                    </span>
                    <span>
                        {material.passRate ? material.passRate + (material.passingScoreType == 'PERCENTAGE' ? '%' : '') : '-'}
                    </span>
                </div>
                <div className={'student-material student-result'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'student.result'}/>
                    </span>
                    <span>
                    {material.result === null || !material?.result ?
                        '-'
                        : normalizePercent(Math.round(parseInt(material.result))) + (material.resultType == 'PERCENTAGE' ? '%' : '')
                    }
                    </span>
                </div>
                <div className={'student-material mandatory'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'general.mandatory'}/>
                    </span>
                    <span>
                    {getMandatory()}
                    </span>
                    {/**/}
                </div>
                <div className={'student-material student-status'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'general.status'}/>
                    </span>
                    <span>
                          {material?.skip ? '-'
                              :
                              <Tag
                                  color={material.status ? AntDTagColor[material.status] : AntDTagColor['NOT_STARTED']}>
                                  <FormattedMessage
                                      id={material.status === 'RETURNED' ? 'courses.topic_status.submission_returned' : `general.${material.status ? material.status.toLowerCase() : 'not_started'}`}/>
                              </Tag>
                          }
                    </span>
                </div>
                <div className={'student-material action'}>
                    <span className={'student-material-buttons'}>
                        {adminView || (material.status === "PASSED" && !material.accessAfterPass) ?
                            null
                            :
                            <Buttons material={material}/>
                        }
                    </span>
                </div>
            </div>
        </Card>
    )
}

export default connect(mapStateToProps)(MaterialCard);