import React, {useEffect, useState} from 'react';
import DefaultLayout from "components/DefaultLayout";
import FlexRow from "components/FlexRow";
import {NavLink} from "react-router-dom";
import {Button, Checkbox, Form, message, Modal, Popover, Space, Tag, UploadFile} from "antd";
import {FormattedMessage, useIntl} from "react-intl";
import {Search, Table} from "ui";
import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {DatePicker, Input, RequestSelect, Select} from "components/Form";
import {connect} from "react-redux";
import {config} from "config/config";
import FileUploadList from "ui/FileUploadList";
import {RcFile} from "antd/lib/upload/interface";
import {useCertificate} from "components/LearningProgram/hooks/useCertificate";
import FileDownload from "js-file-download";
import moment from "moment/moment";
import './styles.scss';
import CertificateImportModal from "components/CertificateImportModal";
import generalHelpers from "utils/generalHelpers";
import {FilterModal} from "components/Modals";
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
};

const Certificates: React.FC = ({ session }: any) => {
    const { locale } = useLocaleContext();
    const [loading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [handleError] = useHandleError();

    const [showCertificateModal, setShowCertificateModal] = useState(false);
    const [showCertificateImportModal, setShowCertificateImportModal] = useState(false);
    const [showCertificateFilterModal, setShowCertificateFilterModal] = useState(false);
    const [selectedCertificateId, setSelectedCertificateId] = useState<number>(0)
    const [selectedCustomerId, setSelectedCustomerId] = useState<string>()
    const [selectedStudentId, setSelectedStudentId] = useState<number>()
    const [activeOrganizationId, setActiveOrganizationId] = useState<number>(0);
    const [exportLoading, setExportLoading] = useState(false)
    const [currentUploadedFileId, setCurrentUploadedFileId] = useState<any>();
    const { getProgramFiles, storeProgramFile, deleteProgramFile, programLoading } = useCertificate();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [indefinete, setIndefinete] = useState(false)

    const intl = useIntl();
    const [form] = Form.useForm();

    const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);
    const { confirm } = Modal;

    const handleOk = () => {
        setIsLoading(true);
        form.submit()
    };

    const handleCancel = () => {
        form.resetFields();
        form.setFieldsValue({
            files: [],
        });
        setShowCertificateModal(false);
        if (currentUploadedFileId) {
            handleRemoveFile(currentUploadedFileId)
            setCurrentUploadedFileId(undefined)
        }
    };

    useEffect(() => {
        setSelectedCustomerId(session.active_user_type === 'CUSTOMER_ADMIN' ? session.organization.organization_uuid : undefined)
        setActiveOrganizationId(parseInt(session.organization.organization_id));
    }, [session])

    useEffect(() => {
        if (selectedCertificateId) {
            fetchFiles();
        }
        else {
            form.setFieldsValue({
                files: [],
            });
        }
    }, [selectedCertificateId])

    const fetchFiles = async () => {
        setFileList(await getProgramFiles(selectedCertificateId));
    }

    useEffect(() => {
        form.setFieldsValue({
            issueDate: moment(),
            expirationDate: moment(),
            files: [],
        })
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            files: fileList,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList])

    const handleAddFile = async (file: RcFile, callBack: (id?: number) => void) => {
        let fileId: number | undefined

        callBack(fileId = await storeProgramFile(file));

        if (fileId) {
            if (currentUploadedFileId) {
                await handleRemoveFile(currentUploadedFileId)
            }
        }
        setCurrentUploadedFileId(fileId)
    }

    const handleRemoveFile = async (id: number): Promise<boolean> => {
        form.setFieldsValue({
            files: [],
        });
        setCurrentUploadedFileId(undefined)
        return await deleteProgramFile(id);
    }

    const handleDownload = async (file: any) => {
        setIsLoading(true);
        try {
            if (file?.originFileObj) {
                return FileDownload(file.originFileObj, file.name);
            }
            const response = await coursesApiClient.request(`/api/v1/courses/storage-file/${file.uid}`, [], 'GET', true, true);

            FileDownload(response, file.name);
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const submitForm = async (values: any) => {

        const parsedValues = {
            customerUuid: values.customer,
            programId: values.learningProgramId ? values.learningProgramId : null,
            userTypeId: values.student,
            issueDate: values.issueDate.format(config.DBDateFormat),
            expirationDate: values?.expirationDate ? values.expirationDate.format(config.DBDateFormat) : null,
            validIndefinite: values.validIndefinite ? (values.validIndefinite[0] === true ? 1 : 0) : 0,
            certificateId: values.outerCertificateId ? values.outerCertificateId : null,
            storageId: currentUploadedFileId ? currentUploadedFileId : null,
            type: 'MANUAL',
            id: values.certificateId ? values.certificateId : null
        };

        if (parsedValues.customerUuid === undefined) {
            parsedValues.customerUuid = selectedCustomerId;
        }

        if (parsedValues.id) {
            if (values.files && values.files.length > 0) {
                parsedValues.storageId = values.files[0].uid;
            }
        }

        try {
            if (!parsedValues.id) {
                await coursesApiClient.request('/api/v1/courses/certificates', parsedValues, 'POST');
            } else if (parsedValues.type !== 'SYSTEM') {
                await coursesApiClient.request(`/api/v1/courses/certificates/${parsedValues.id}`, parsedValues, 'PUT');
            }

        } catch (error) {
            handleError(error)
        } finally {
            setCurrentUploadedFileId(undefined);
            setShowCertificateModal(false);
            form.resetFields();
            form.setFieldsValue({
                files: [],
            });
            setReload(true);
            setIsLoading(false);
        }
    };

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    const setEditValues = (data: any) => {
        setSelectedCertificateId(data.certificateId)
        setSelectedCustomerId(data.customerUuid)
        setSelectedStudentId(data.userTypeId)
        setCurrentUploadedFileId(form.getFieldValue('file'))
        setIndefinete(!!data.validIndefinite)
        form.setFieldsValue({
            certificateId: data.certificateId,
            title: data.title ?
                data.title.locale ? data.title.locale : data.title[Object.keys(data.title)[0]]
                : data.outerCertificateId ? data.outerCertificateId : '-',
            programName: data.title ?
                (data.title.locale ? data.title.locale : data.title[Object.keys(data.title)[0]])
                : null,
            customer: data.customerUuid,
            customerName: data.customerName,
            learningProgramId: data.programId ? data.programId.toString() : null,
            student: data.userTypeId.toString(),
            name: data.name,
            surname: data.surname,
            issueDate: moment(data.issueDate),
            expirationDate: data?.expirationDate ? moment(data.expirationDate) : null,
            validIndefinite: [data.validIndefinite === 1],
            outerCertificateId: data.outerCertificateId,
            files: form.getFieldValue('file'),
            createdNameSurname: data.createdNameSurname,
            createdAt: moment(data.createdAt).format('DD/MM/YYYY h:mm'),
            editedNameSurname: data.editedNameSurname ? data.editedNameSurname : null,
            updatedAt: data.updatedAt ? moment(data.updatedAt).format('DD/MM/YYYY h:mm') : null,
        })
    }

    const PopoverContent = (certificate: any) => {
        return (
            <div className="custom-field-popover">
                <div className="popover-button pointer" key='edit' onClick={() => {
                    setVisibleTooltip(null);
                    setEditValues(certificate.data);
                    setShowCertificateModal(true);
                }}><FormattedMessage id={'general.view'} /></div>
                <div className="popover-button pointer" key='delete' onClick={() => {
                    setVisibleTooltip(null);
                    showConfirmDelete(certificate.data.certificateId);
                }}><FormattedMessage id={'general.delete'} /></div>
            </div>
        )
    }

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.learning_program' }),
            dataIndex: 'title',
            render: (_text: string, record: any) => {
                let title

                if (record.title !== null) {
                    title = record.title && record.title[locale] ? record.title[locale] : record.title[Object.keys(record.title)[0]]
                } else {
                    title = '-'
                }

                if (!record.programId) {
                    return (
                        <div className='link-button'>
                            {title}
                        </div>
                    )
                }

                return (
                    <NavLink to={`/learning/programs/${record.programId}`}>
                        {title}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({ id: 'general.user' }),
            dataIndex: 'student',
            render: (text: string, certificate: any) => (
                <NavLink to={`../customer/${activeOrganizationId}/user/${certificate.typeUuid}/view/users`}>
                    {certificate.name} {certificate.surname}
                </NavLink>
            )
        },
        {
            title: intl.formatMessage({ id: 'general.organization' }),
            dataIndex: 'customerName',
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'status',
            render: (text: string, certificate: any) => {
                if (certificate.validIndefinite === 1) {
                    return (
                        <Tag color={'green'}>
                            <FormattedMessage id={'general.valid'} />
                        </Tag>
                    )
                }
                else {
                    let today = moment();
                    let expDate = moment(certificate.expirationDate);

                    if (expDate.diff(today, 'seconds') > 0) {
                        if (expDate.diff(today, 'days') > certificate.termLimitValue) {
                            return (
                                <Tag color={'green'}>
                                    <FormattedMessage id={'general.valid'} />
                                </Tag>
                            )
                        } else {
                            return (
                                <Tag color={'orange'}>
                                    <FormattedMessage id={'general.expires_soon'} />
                                </Tag>
                            )
                        }
                    } else if (expDate.diff(today, 'seconds') < 0) {
                        return (
                            <Tag color={'red'}>
                                <FormattedMessage id={'general.expired'} />
                            </Tag>
                        )
                    }
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'general.issue_date' }),
            dataIndex: 'issueDate',
        },
        {
            title: intl.formatMessage({ id: 'general.expiring_date' }),
            dataIndex: 'expirationDate',
            render: (text: string, certificate: any) => (
                <div>
                    {!certificate.validIndefinite ? text : '-'}
                </div>
            )
        },
        {
            title: intl.formatMessage({ id: 'general.certificate_id' }),
            dataIndex: 'outerCertificateId',
            render: (certId: string, certificate: any) => {
                return certId ? certId : '# ' + certificate.certificateId
            }
        },
        {
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({ id: 'general.type' }),
            dataIndex: 'type',
            render: (text: string, certificate: any) => (
                <FormattedMessage id={`general.certificate_type_${certificate.type.toLowerCase()}`} />
            )
        },
        {
            title: intl.formatMessage({ id: 'system.action' }),
            dataIndex: 'actions',
            render: (text: string, certificate: any) => {
                if (certificate.type === 'MANUAL') {
                    return (
                        <Popover
                            open={certificate.certificateId === visibleTooltip}
                            onOpenChange={(visible) => handleVisibleChange(visible, certificate.certificateId)}
                            placement='bottom'
                            content={<PopoverContent data={certificate} />}
                            arrowContent={false}
                            trigger='click'
                        >
                            <i className="pointer fa-solid fa-ellipsis"></i>
                        </Popover>
                    )
                }
            }
        },
    ];

    const deleteCertificates = async (ids: any) => {
        if (ids) {
            try {
                await coursesApiClient.request(`/api/v1/courses/certificates/delete`, ids, 'DELETE');
            } catch (error) {
                handleError(error)
            } finally {
                setSelectedRowKeys([]);
                setReload(true);
            }
        }
    }
    const showConfirmDelete = (id: number | null) => {
        confirm({
            title: intl.formatMessage({ id: "general.attention" }),
            content: intl.formatMessage({ id: "general.certificates_delete" }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.delete' }),
            cancelText: intl.formatMessage({ id: 'general.cancel' }),
            onOk() {
                id ? deleteCertificates([id]) : deleteCertificates(selectedRowKeys);
            },
        });
    };

    const exportCertificates = async () => {
        setExportLoading(true)
        try {
            const url = '/api/v1/courses/certificates/export' + (filter ? ('?' + filter) : '');

            const response = await coursesApiClient.request(url, [], 'GET', true, true);

            if (response.fileName) {
                FileDownload(response, response.fileName);
            } else {
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setExportLoading(false)
        }
    };

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                title={intl.formatMessage({ id: 'general.certificates' })}
            />
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                        <>
                            <Button type="primary" onClick={() => { setShowCertificateModal(true) }}>
                                <PlusOutlined /> <FormattedMessage id="general.add_new_certificate" />
                            </Button>
                            <Button icon={<i className="fal fa-file-import" />} onClick={() => setShowCertificateImportModal(true)}>
                                <span><FormattedMessage id={intl.formatMessage({ id: 'users.import.import' })} /></span>
                            </Button>
                            <Button loading={exportLoading} icon={<i className="fal fa-file-export" />} onClick={() => exportCertificates()}>
                                <span><FormattedMessage id={intl.formatMessage({ id: 'users.export.export' })} /></span>
                            </Button>
                        </>
                    }
                    right={
                        <>
                            <Button type="default" onClick={() => setShowCertificateFilterModal(true)}>
                                <FormattedMessage id="general.filter" />
                            </Button>
                            <Search
                                setReload={setReload}
                                setSearchString={setSearch}
                            />
                        </>
                    }

                />
                <div className={'table-filter-modal'}>
                    <FilterModal
                        visible={showCertificateFilterModal}
                        onCancel={setShowCertificateFilterModal}
                        title={'general.filter_by'}
                        okProp={'general.filter_certificates'}
                        modules={{
                            students: { mode: 'multiple', visible: true, params: '&accountStatus=ACTIVE' },
                            customerSelect: { mode: 'multiple', visible: generalHelpers.isAdmin(session.active_user_type) },
                            studentPosition: { mode: 'multiple', visible: true },
                            organizationalUnitId: { mode: 'multiple', visible: true },
                            departmentId: { mode: 'multiple', visible: true },
                            directManager: { mode: 'multiple', visible: true },
                            accountStatus: { visible: true },
                            programId: { mode: 'multiple', visible: true },
                            programName: { mode: 'multiple', visible: true },
                            nullProgram: { visible: true },
                            certificateType: { visible: true },
                            certificateId: { visible: true },
                            certificateIssueDate: { visible: true },
                            certificateExpirationDate: { visible: true },
                        }}
                        setFilter={setFilter}
                        filter={filter}
                        load={setReload}
                        locale={locale}
                    />
                </div>

                <div className={'certificates-table'}>
                    <Table
                        columns={columns}
                        url='/api/v1/courses/certificates/list'
                        setReload={setReload}
                        reload={reload}
                        selectedRowKeys={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                        rowKey='certificateId'
                        filter={filter}
                        search={search}
                        scroll={{ x: 800 }}
                    />
                </div>

                <Modal
                    className="certificate"
                    open={showCertificateModal}
                    title={
                        form.getFieldValue('certificateId') ?
                            form.getFieldValue('title')
                            : intl.formatMessage({ id: 'general.add_new_certificate' })
                    }
                    okText={
                        intl.formatMessage({ id: form.getFieldValue('certificateId') ? 'general.edit' : 'general.add' })
                    }
                    afterClose={() => setSelectedCertificateId(0)}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button onClick={handleCancel}>
                            <FormattedMessage id={'general.cancel'} />
                        </Button>,
                        <Button type="primary" loading={loading} onClick={handleOk}>
                            <FormattedMessage id={form.getFieldValue('certificateId') ? 'general.edit' : 'general.add'} />
                        </Button>,
                    ]}
                >
                    <Form form={form} onFinish={submitForm} {...formLayout}>
                        <Input name={'certificateId'} />
                        {
                            form.getFieldValue('certificateId') ?
                                session.active_user_type !== 'CUSTOMER_ADMIN' ?
                                    <Input
                                        label={intl.formatMessage({ id: 'general.organization' })}
                                        value={form.getFieldValue('customerName')}
                                        disabled
                                        customLayout={formLayout} />
                                    :
                                    <></>
                                :
                                session.active_user_type !== 'CUSTOMER_ADMIN' ?
                                    <Select
                                        name={'customer'}
                                        label={intl.formatMessage({ id: 'general.organization' })}
                                        allowClear
                                        dataKey='customers'
                                        url={config.api.routes.backend.selectCustomersUuid}
                                        validation={{ required: true }}
                                        onChange={(id: any) => { setSelectedCustomerId(id) }}
                                        customLayout={formLayout}
                                    />
                                    : null
                        }
                        {
                            form.getFieldValue('certificateId') ?
                                <>
                                    <Input
                                        label={intl.formatMessage({ id: 'general.student' })}
                                        value={form.getFieldValue('name').concat(' ', form.getFieldValue('surname'))}
                                        disabled
                                        customLayout={formLayout} />
                                    {form.getFieldValue('programName') ?
                                        <Input
                                            label={intl.formatMessage({ id: 'general.learning_program' })}
                                            value={form.getFieldValue('programName')}
                                            customLayout={formLayout}
                                            disabled />
                                        :
                                        <Select
                                            name={'learningProgramId'}
                                            label={intl.formatMessage({ id: 'general.learning_program' })}
                                            allowClear
                                            dataKey='programs'
                                            url={`/api/v1/courses/learning-program/student/${selectedStudentId}`}
                                            customObjLabel={(el: any) => el[locale] ? el[locale] : el[Object.keys(el)[0]]}
                                            customLayout={formLayout}
                                        />}
                                </>
                                :
                                !!selectedCustomerId &&
                                <>
                                    <RequestSelect
                                        name={'student'}
                                        label={intl.formatMessage({ id: 'general.student' })}
                                        allowClear
                                        showSearch
                                        url={`api/v1/edu/users/search-student`}
                                        param={[`owner=${selectedCustomerId}&key=id&accountStatus=ACTIVE`]}
                                        validation={{ required: true }}
                                        isForm
                                        onChange={(id: any) => { setSelectedStudentId(id) }}
                                        customLayout={formLayout}
                                    />
                                    {
                                        !!selectedStudentId &&
                                        <Select
                                            name={'learningProgramId'}
                                            label={intl.formatMessage({ id: 'general.learning_program' })}
                                            showSearch
                                            allowClear
                                            dataKey='programs'
                                            url={`/api/v1/courses/learning-program/student/${selectedStudentId}`}
                                            customObjLabel={(el: any) => el[locale] ? el[locale] : el[Object.keys(el)[0]]}
                                            customLayout={formLayout}
                                            filterOption={(input: string, option: any) => {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }}
                                        />
                                    }
                                </>
                        }
                        <DatePicker name={'issueDate'}
                            label={intl.formatMessage({ id: 'general.certificate_issue_date' })}
                            format={config.defaultDateFormat}
                            customRules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                            customLayout={formLayout}>
                        </DatePicker>

                        <DatePicker name={'expirationDate'}
                            label={intl.formatMessage({ id: 'general.certificate_expiration_date' })}
                            format={config.defaultDateFormat}
                            customRules={[{ required: !indefinete, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                            customLayout={formLayout}>
                        </DatePicker>

                        <Form.Item name={'validIndefinite'}
                            className={'check-box'}
                        >
                            <Checkbox.Group>
                                <Checkbox value={true} onChange={(el: any) => setIndefinete(el.target.checked)}>
                                    {intl.formatMessage({ id: 'general.valid_indefinitely' })}
                                </Checkbox>
                            </Checkbox.Group>
                        </Form.Item>

                        <Input name={'outerCertificateId'}
                            label={intl.formatMessage({ id: 'general.certificate_id' })}
                            customLayout={formLayout}
                        />

                        <Form.Item
                            label={intl.formatMessage({ id: 'general.upload_files' })}
                            name="files"
                        >
                            <FileUploadList
                                maxCount={1}
                                onAdd={handleAddFile}
                                onDelete={handleRemoveFile}
                                onDownload={handleDownload}
                                showUploadList={{
                                    showDownloadIcon: true,
                                    downloadIcon: intl.formatMessage({ id: 'general.download' }),
                                    showRemoveIcon: true,
                                    removeIcon: intl.formatMessage({ id: 'general.delete' })
                                }}
                            />
                        </Form.Item>
                    </Form>
                    <div>
                        {
                            form.getFieldValue('createdNameSurname') ?
                                <div><FormattedMessage id='general.created' />: {form.getFieldValue('createdNameSurname')}; {form.getFieldValue('createdAt')}</div>
                                : null
                        }
                        {
                            form.getFieldValue('editedNameSurname') ?
                                <div><FormattedMessage id='general.last_edited' />: {form.getFieldValue('editedNameSurname')}; {form.getFieldValue('updatedAt')}</div>
                                : null
                        }
                    </div>
                </Modal>

                <CertificateImportModal
                    visible={showCertificateImportModal}
                    onCancel={() => setShowCertificateImportModal(false)}
                    afterSubmit={() => setShowCertificateImportModal(false)}
                />

                <DefaultLayout.PageFooter
                    left={
                        <span className={'certificate-delete'}>
                            <div>
                                {selectedRowKeys.length ? selectedRowKeys.length : 0}
                                <FormattedMessage id='users.rows_selected' />
                            </div>
                            <Button onClick={() => selectedRowKeys.length > 0 ? showConfirmDelete(null) : null}>
                                <i style={{ color: '#F5222D' }} className="fal fa-trash" />
                                <span><FormattedMessage id='general.delete' /></span>
                            </Button>
                        </span>
                    }
                />

            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
};

export default connect(mapStateToProps)(Certificates);