import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import {Popover, Tag, Progress} from 'antd';
import useHandleError from "utils/useHandleError";
import {Button, LocaleText, Search, Table} from "ui";
import moment from 'moment'
import {config} from "../../../config/config";
import './styles.scss';
import {connect} from "react-redux";
import generalHelpers from "../../../utils/generalHelpers";
import {FilterModal} from "../../Modals";

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
}
const CompetencesStudents: React.FC = ({session}: any) => {
    const [reload, setReload] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [searchString, setSearchString] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState('')

    const intl = useIntl();
    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const params = useParams();
    const competenceId = params.id;

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const AntDTagColor: any = {
        completed: 'green',
        in_progress: 'blue',
        not_started: 'orange'
    }

    const defaultExpandable = {
        expandedRowRender: (record: any) => {
            return (
                <div className={'expandable-program-row'}>
                    {Object.values(record.programs).map((program: any) => {
                        let status = null;

                        switch (program.status) {
                            case 'COMPLETED':
                                status = 'completed';
                                break;
                            case 'IN_PROGRESS':
                                status = 'in_progress';
                                break;
                            case 'NOT_STARTED':
                                status = 'not_started';
                                break;
                            default:
                                status = 'not_started';
                                break;
                        }

                        let title = program.title;
                        return <div>
                            <div className={'expandable-title-wrapper'}>
                                <div className={'title'}>
                                    <LocaleText text={title} />
                                </div>
                                <Progress percent={program?.result * 100} size="small" showInfo={false}
                                          strokeColor={'#87d068'} style={{width: 100}}/>
                            </div>
                            <div>
                                <Tag color={AntDTagColor[status]}>
                                    <FormattedMessage id={'student.' + status}/>
                                </Tag>
                            </div>
                        </div>
                    })}
                </div>
            );
        }
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            render: (_text: string, record: any) => `${record.name} ${record.surname}`,
        },
        {
            title: intl.formatMessage({id: 'general.position'}),
            dataIndex: 'position'
        },
        {
            title: intl.formatMessage({id: 'general.organizational_unit'}),
            dataIndex: 'organizationalUnit'
        },
        {
            title: intl.formatMessage({id: 'general.organization'}),
            dataIndex: 'organization'
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            dataIndex: 'status',
            render: (_text: string, record: any) => {
                let color = 'red';
                let status = 'competences.status_not_valid';

                if (!!record.isValid && moment() < moment(record.validTo) && moment() < moment(record.recertification)) {
                    color = 'green';
                    status = 'competences.status_valid';
                }

                if (!!record.isValid && moment() < moment(record.validTo) && moment() > moment(record.recertification)) {
                    color = 'gold';
                    status = 'competences.status_expiring';
                }

                if (!!record.compliant && (moment() < moment(record.validTo) || !record.validTo)) {
                    color = 'lime';
                    status = 'competences.status_compliant';
                }

                return (
                    <Tag color={color}>
                        <FormattedMessage id={status}/>
                    </Tag>

                )
            },
        },
        {
            title: intl.formatMessage({id: 'general.expires'}),
            render: (record: any) => record.validTo && moment(record.validTo).format(config.defaultDateFormat)
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <>
                        <div className="popover-item">
                            <FormattedMessage id="general.edit"/>
                        </div>
                    </>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        }
    ];

    return (
        <div className={'table-with-expandable-rows-for-programs'}>
            <div className={'flex justify-between'}>
                <Button type={'primary'} onClick={() => setShowFilter(true)}>
                    <FormattedMessage id={'general.filter'}/>
                </Button>
                <div className={'w-25'}>
                    <Search setSearchString={setSearchString} setReload={setReload}/>
                </div>
            </div>
            <FilterModal
                visible={showFilter}
                onCancel={setShowFilter}
                modules={{
                    customerSelect: {mode: 'multiple', visible: generalHelpers.isAdmin(session.active_user_type)},
                    organizationalUnitId: {mode: 'multiple', visible: true},
                    departmentId: {mode: 'multiple', visible: true},
                    competenceStatus: {mode: 'multiple', visible: true},
                    competenceValidity: {visible: true},
                }}
                setFilter={setFilter}
                load={setReload}
                filter={filter}
            />
            <Table
                columns={columns}
                url={`/api/v1/competences/options/${competenceId}/students`}
                setReload={setReload}
                search={searchString}
                reload={reload}
                filter={filter}
                rowSelection={false}
                rowKey={'id'}
                expandable={defaultExpandable}
            />
        </div>
    )
};

export default connect(mapStateToProps)(CompetencesStudents);