import React from 'react';
import { Spin } from 'antd';
import './styles.scss';

const Spinner: React.FC<any> = ({opaque, opaqueH, center, ...props}) => {
  let className = 'spinner-wrapper';
  let centerClassName = '';

  if (opaque) {
    className += ' opaque';
  }

  if (opaqueH) {
      className += ' opaqueH';
  }

  if (center) {
    centerClassName = 'spinner-wrapper center';
 }

  return (
    <Spin wrapperClassName={className} className={centerClassName} {...props} />
  )
}

export default Spinner;
