import React from 'react';
import {Modal} from 'antd';
import {PreviewCertificateModalInterface} from '../types';

const PreviewCertificateModal: React.FC<PreviewCertificateModalInterface> = ({
    visible,
    onCancel,
    previewPath,
}) => {
    return (
        <Modal
            className="campaign-certificate-modal"
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={920}
        >
            <iframe
                key={'pdf-cert-preview'}
                className='manual-player'
                title='PDF_PREVIEW'
                src={`${previewPath}#toolbar=0`}
                style={{
                    marginTop: 24,
                    aspectRatio: '1.4 / 1',
                    height: 'auto',
                    maxHeight: '75vw',
                    border: 'none'
                }}
            />
        </Modal>
    )
}
export default PreviewCertificateModal;
