import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Popover, Tag, Input, Button} from "antd";
import {LocaleText, Table} from "ui";
import moment from "moment";
import GradingModal from "components/Grading/GradingModal";
import FilterModal from "components/Grading/FilterModal";
import debounce from "lodash/debounce";
import qs from 'qs';

const GradedGrading: React.FC<any> = ({ }) => {
    const [visibleTooltip, setVisibleTooltip] = useState<any>();
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [selectedAttemptData, setSelectedAttemptData] = useState<any>(null);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [filters, setFilters] = useState(null);

    const intl = useIntl();
    const {Search} = Input;

    const columns = [
        {
            title: intl.formatMessage({id: 'courses.assignment_title'}),
            dataIndex: 'title',
        },
        {
            title: intl.formatMessage({id: 'general.learning_programs'}),
            key: 'programTitle',
            render: (value: string, record: any) => {
                return <LocaleText text={record.programTitle} />
            }
        },
        {
            title: intl.formatMessage({id: 'general.type'}),
            dataIndex: 'type',
        },
        {
            title: intl.formatMessage({id: 'general.student'}),
            dataIndex: 'studentName',
        },
        {
            title: intl.formatMessage({id: 'general.email'}),
            dataIndex: 'studentEmail',
        },
        {
            title: intl.formatMessage({id: 'general.organization'}),
            dataIndex: 'organization',
        },
        {
            title: intl.formatMessage({id: 'courses.submission_date'}),
            key: 'submissionDate',
            render: (text: string, record: any) => {
                return record.submissionDate ? moment(record.submissionDate).format('DD/MM/YYYY HH:mm:ss') : '-'
            }
        },
        {
            title: intl.formatMessage({id: 'assessment.assessment_instructor'}),
            dataIndex: 'gradedBy',
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            key: 'status',
            render: (text: string, record: any) => {
                return getStatusTag(record)
            }
        },
        {
            key: 'actions',
            render: (text: string, record: any, index: number) => {
                const content = <>
                    <div className="popover-item" onClick={() => {
                        setSelectedAttemptData(record)
                        setVisibleTooltip(null)
                    }}>
                        <FormattedMessage id="grading.view"/>
                    </div>
                </>;

                return (
                    <Popover
                        open={index === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => setVisibleTooltip(visible ? index : null)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const getStatusTag = (record: any) => {
        let status: string = record?.status
        let color = ''
        let messageId = ''

        switch (status) {
            case 'NOT_STARTED':
            case 'IN_PROGRESS':
                color = 'blue'
                messageId = 'courses.topic_status.pending_grading'
                break;
            case 'FINAL_ASSESSMENT':
                color = 'blue'
                messageId = 'courses.topic_status.final_assessment'
                break;
            case 'SUBMITTED':
                color = 'blue'
                messageId = 'courses.topic_status.pending_grading'
                break;
            case 'PASSED':
                color = 'green'
                messageId = 'courses.topic_status.passed'
                break;
            case 'FAILED':
                color = 'red'
                messageId = 'courses.topic_status.failed'
                break;
            case 'MISSED_DEADLINE':
                color = 'red'
                messageId = 'courses.topic_status.missed_deadline'
                break;
            case 'RETURNED':
                color = 'red'
                messageId = 'courses.topic_status.submission_returned'
                break;
            default:
                color = 'gold'
                messageId = 'courses.topic_status.not_submitted'
                break;
        }

        return (
            <Tag color={color}>
                <FormattedMessage id={messageId} />
            </Tag>
        )
    }

    const onSearch = debounce((value: string) => {
        setSearchString(value);
        setReload(true)
    }, 800);

    return (
        <>
            <GradingModal 
                onCancel={() => {
                    setSelectedAttemptData(null)
                }}
                attemptData={selectedAttemptData}
                onGraded={() => {
                    setSelectedAttemptData(null);
                    setReload(true);
                }}
            />

            <FilterModal 
                open={openFilterModal}
                onCancel={() => {
                    setOpenFilterModal(false)
                }}
                onFilter={(filterValues: any) => {
                    setOpenFilterModal(false);
                    setFilters(filterValues);
                    setReload(true);
                }}
            />

            <div className='flex justify-end'>
                <div className='flex justify-end gap-5' style={{maxWidth: '400px'}}>
                    <Button type='primary' onClick={() => setOpenFilterModal(true)}><FormattedMessage id='general.filter' /></Button>
                    <Search 
                        allowClear
                        placeholder={intl.formatMessage({id: 'grading.search_placeholder'})} 
                        onSearch={onSearch} 
                        onChange={(e: any) => onSearch(e.target.value)} 
                        style={{ width: '100%', marginBottom: '20px' }} 
                    />
                </div>
            </div>

            <Table
                columns={columns}
                url={'/api/v1/courses/grading/get-gradings/graded'}
                setReload={setReload}
                reload={reload}
                rowSelection={false}
                rowKey={'id'}
                search={searchString}
                filter={filters ? qs.stringify(filters) : ''}
                scroll={{x: true}}
            />
        </>
    );
};

export default GradedGrading;
