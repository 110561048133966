import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import DefaultLayout from "components/DefaultLayout";
import useHandleError from "utils/useHandleError";
import {Tabs} from "antd";
import {QuestionPoolSettings, QuestionBanks, QuestionPoolReview} from "components/QuestionPool/index";
import coursesApiClient from "utils/coursesApiClient";
import Spinner from "components/Spinner";

const QuestionPoolEdit: React.FC<any> = ({ }) => {
    const params = useParams();
    const questionPoolId = params.id;
    const intl = useIntl();

    const [questionPool, setQuestionPool] = useState<any>();
    const [handleError] = useHandleError();
    const [isLoading, setIsLoading] = useState(!!questionPoolId);    

    useEffect(() => {
        if (questionPoolId) {
            loadQuestionPool()
        }
    }, [questionPoolId])

    const loadQuestionPool = async () => {

        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/${questionPoolId}`, [], 'GET');
            setQuestionPool(response.question_pool)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                loading={isLoading}
                breadcrumb={[
                    {
                        name: intl.formatMessage({id: 'question_pools.question_pools'}), 
                        path: '/question-pools/manage-content'
                    }
                ]}
                title={questionPool ? questionPool.title : intl.formatMessage({id: 'question_pools.question_pools'})}
                />
            <DefaultLayout.PageContent withBottomMargin>
                <Spinner spinning={isLoading}>
                    {questionPool && <Tabs defaultActiveKey='question_pool'>
                        <Tabs.TabPane
                            key='question_pool'
                            tab={intl.formatMessage({id: 'question_pools.question_pool'})}
                            >
                            <QuestionBanks questionPool={questionPool}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane key='settings' tab={intl.formatMessage({id: 'general.settings'})}>
                            <QuestionPoolSettings questionPool={questionPool} setQuestionPool={setQuestionPool}/>
                        </Tabs.TabPane>
                      <Tabs.TabPane key='review' tab={intl.formatMessage({id: 'general.review'})}>
                        <QuestionPoolReview/>
                      </Tabs.TabPane>
                    </Tabs>}
                </Spinner>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    );
};

export default QuestionPoolEdit;
