import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'utils/momentWithLocales';
import {List} from 'antd';
import {LectureSessionType, SessionLectorProps} from 'components/Lectures/types';
import SessionStatusBadge from 'components/Lectures/SessionStatusBadge/SessionStatusBadge';
import {Button, Title} from 'ui';
import coursesApiClient from 'utils/coursesApiClient';
import generalHelper from 'utils/generalHelpers';
import useHandleError from 'utils/useHandleError';

const UpcomingLectureSessions: React.FC<any> = ({lecture, reload}) => {
    const [disableSignUp, setDisableSignUp] = useState(false)

    const [handleError] = useHandleError();

    const ListItem: React.FC<{ session: any }> = ({session}) => {
        const date = moment(session.date);

        if (date < moment()) {
            return null
        }

        if (session.attendanceStatus) {
            setDisableSignUp(true)
        }

        const renderType = (type?: LectureSessionType) => {
            if (!type) {
                return '';
            }

            switch (type) {
                case LectureSessionType.Online:
                    return <FormattedMessage id='general.lectures.online'/>;
                case LectureSessionType.ClassRoom:
                    return <FormattedMessage id='general.lectures.classroom'/>
            }
        }

        return (
            <div className='my-session-list-item'>
                <div className='my-session-list-item--head'>
                    <div>
                        <span>{date.format('ddd')}</span>
                        <span>{date.format('DD')}</span>
                    </div>
                    <div><SessionStatusBadge status={session.status}/></div>
                </div>
                <div className='my-session-list-item--info'>
                    <div>
                        {session.title}
                    </div>
                    <div>
                        <span>{session.start?.slice(0, -3)} - {session.end?.slice(0, -3)}</span>
                        <span>{renderType(session.type)}</span>
                        <span>{session.lectors?.map((lector: SessionLectorProps) => !!lector.name ? lector.name : '---').join(', ')}</span>
                    </div>
                </div>
                <div className='my-session-list-item--status'>
                </div>
                <div className='my-session-list-item--cta'>
                    {session.registeredBy === 'SELF_REGISTRATION' &&
                        <>
                            {session.attendanceStatus === 'PLANNED' ?
                                <Button type={'outline'} danger block onClick={() => cancelAttendance(session)}>
                                    <FormattedMessage id='general.cancel_attendance'/>
                                </Button>
                                :
                                <Button disabled={disableSignUp} block onClick={() => applyForSession(session)}>
                                    <FormattedMessage id='general.sing_up'/>
                                </Button>
                            }
                        </>
                    }
                </div>
            </div>
        );
    };

    const cancelAttendance = async (session: any) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/student/learning-program/${session.attendanceId}/sessions-cancel`, [], 'POST');
            await reload();
            setDisableSignUp(false)
        } catch (error) {
            handleError(error)
        }
    }

    const applyForSession = async (session: any) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/student/learning-program/${session.id}/sessions-apply`, [], 'POST');
            reload();
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <div>
            {!generalHelper.isEmpty(lecture) && lecture.map((data: any) => {
                const date = moment(`${data.name}`, "YYYYMM")
                const title = `${date.format('MMMM').charAt(0).toUpperCase() + date.format('MMMM').slice(1)} ${date.year()}`
                return (
                    <List
                        header={<Title withLine title={title}/>}
                        dataSource={data.sessions}
                        renderItem={(item) => <ListItem session={item}/>}
                    />
                )
            })}
        </div>
    )
}
export default UpcomingLectureSessions;