import {Divider, Input as AntdInput, InputRef, Space} from 'antd';
import React, {useState, useEffect, useRef} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Select} from 'components/Form';
import {Button} from 'ui';
import generalHelpers from 'utils/generalHelpers';
import useQueryApiClient from 'utils/useApiClient';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
}

const OrganizationalUnits: React.FC<any> = ({
    session,
    organizationId,
    customerId,
    name = 'organizationalUnitId',
    isUuid,
    hideLabel,
    canCreate = true,
    setLoadedData = null,
    ...props
}) => {
    const [title, setTitle] = useState('');
    const [organizationalUnits, setOrganizationalUnits] = useState<any>();

    const inputRef = useRef<InputRef>(null);
    const intl = useIntl();

    const defaultParams = generalHelpers.isAdmin(session.active_user_type) || (session.special_rights && session.special_rights.includes('can_manage_customers')) ? {
        organizationId,
        customerId
    } : {}

    useEffect(() => {
        loadOrganizationalUnits({isResponseArray: true, isUuid, ...defaultParams})
    }, [])

    const {appendData: loadOrganizationalUnits, isLoading: listLoading} = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/customers/options/organizational-unit-types',
            method: 'GET'
        },
        onSuccess: (response) => {
            if (response) {
                setOrganizationalUnits(response.organizationalUnits)
                setLoadedData && setLoadedData(response.organizationalUnits)
            }
        }
    });

    const {appendData: addOrganizationalUnit, isLoading: addLoading} = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/customers/options/organizational-unit-types/store',
            method: 'POST'
        },
        onSuccess: (response) => {
            if (response && response.organizationalUnits) {
                const newOrganizationalUnit = {
                    id: response.organizationalUnits.id,
                    title: response.organizationalUnits.title
                };

                setOrganizationalUnits([...organizationalUnits, newOrganizationalUnit])
            }
            setTitle('')
        }
    });

    return (
        <Select 
            allowClear
            showSearch
            showArrow
            name={name}
            label={!hideLabel ? intl.formatMessage({ id: 'general.unit' }) : undefined}
            className='default-select'
            manualOptions={organizationalUnits}
            incrementKey={true}
            isResponseArray={true}
            dropdownRender={(menu: any) => (
                <>
                    {menu}
                    {canCreate && <>
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <AntdInput
                                ref={inputRef}
                                value={title}
                                onChange={(event: any) => setTitle(event.target.value)}
                            />
                            <Button 
                                type='outline'
                                disabled={!(organizationId || customerId)}
                                onClick={() => {
                                    const instanceType = organizationId ? 'ORGANIZATION' : 'CUSTOMER';

                                    addOrganizationalUnit({
                                        title,
                                        instanceType,
                                        instanceReferenceId: organizationId || customerId
                                    })
                                }}
                                loading={listLoading || addLoading}
                            >
                                <FormattedMessage id='general.add' />
                            </Button>
                        </Space>
                    </>}
                </>
            )}
            valueKey={'id'}
            labelKey={'title'}
            filterOption={(input: string, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            {...props}
        />
    )
}

export default connect(mapStateToProps)(OrganizationalUnits);
