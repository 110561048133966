import React, {useEffect} from "react";
import {useParams } from 'react-router-dom';
import jwt from 'utils/jwt';

const SamlTokenRedirect: React.FC = ({}: any) => {
    const params = useParams();
    const token: any = params.token;

    useEffect(() => {
        if (token) {
            handleToken();
        }
    }, []);

    const handleToken = async () => {
        const expires = 86400;
        await jwt.set(token, expires)
        window.location.assign('/');
    };

    return <></>;
};

export default SamlTokenRedirect;
