export const navigation = [
  {key: "dashboard", home: true, icon: "fal fa-home-alt", title: "dashboard.dashboard", permissionSlug: "view_dashboard"},
  {key: "content", home: true, icon: "fa-light fa-folder-open", title: "general.content", permissionSlug: "view_courses", subNav: [
      {key: "courses", url: "/courses/manage-content", title: "courses.content", permissionSlug: ""},
      {key: "lectures", url: "/lectures", title: "general.lectures", permissionSlug: "" /* lectures */},
      {key: "question_pools", url: "/question-pools/manage-content", title: "question_pools.manage_question_pools", permissionSlug: ""}, // TODO add permissions view_question_pools
      {key: "learning_tests", url: "/learning/tests", title: "learning_tests.learning_tests"}, // TODO add permissions view_learning_tests
    ]},
  {key: "e-learning", icon: "fal fa-book", title: "courses.e_learning", permissionSlug: "", subNav: [
    {key: "learning_programs", url: "/learning/programs", title: "general.learning_programs", permissionSlug: ""}, // TODO add permissions view_learning_programs
    {key: "competences", url: "/competences", icon: "fal fa-users", title: "general.competences", permissionSlug: "" /* view_competences */},//TODO add permissions to backend
    {key: "audiences", url: "/audiences", icon: "fal fa-users", title: "general.audiences", permissionSlug: "" /* view_audiences */},//TODO add permissions to backend
    // {key: "academy", url: "/academy", icon: "fa-light fa-school", title: "general.academy", permissionSlug: "" /* view_audiences */},//TODO add permissions to backend
    {key: "certificates", url: "/certificates", /*icon: "fal fa-users",*/ title: "general.certificates", permissionSlug: "" /* view_certificates */},
    {key: "grading", url: "/learning/grading", title: "grading.grading"}, // TODO add permissions view_grading
  ]},
  {key: "organization", icon: "fal fa-building", title: "general.organizations", permissionSlug: "view_organizations,view_customers", subNav: [
    {key: "resellers", url: "/organizations", permissionSlug: "view_organizations", title: "general.resellers"},
    {key: "customers", url: "/customers", permissionSlug: "view_customers", title: "general.customers"}
  ]},
  {key: "users-management", icon: "fal fa-users", title: "general.users_management", permissionSlug: "view_users", subNav: [
      {key: "users", url: "/users", icon: "fal fa-users", title: "general.users", permissionSlug: "view_users"},
  ]},

  {key: "general-messaging", icon: "fal fa-envelope", url: "/general-messaging", title: "general_messaging.notifications", permissionSlug: "",specRights: 'can_manage_general_messaging'}, // TODO add permissions view_learning_tests

  {key: "phishing", icon: "fal fa-fish-cooked", title: "phishing.phishing", permissionSlug: "view_phishing", subNav: [
    {key: "phishing-campaign", url: "/phishing", title: "campaigns.manage_campaigns", permissionSlug: 'view_phishing'},
    {key: "phishing-templates", url: "/phishing/templates", title: "emailing.templates_and_scenarios", permissionSlug: 'create_phishing_templates'},
    {key: "phishing-analytics", url: "/phishing/analytics", title: "general.analytics", permissionSlug: 'view_phishing_analytics'},

    ]},

{key: "sub4", icon: "fal fa-envelope-open-text", title: "general.e_mailing", permissionSlug: "view_mail_scenarios", subNav: [
    {key: "11", url: "/e-mailing/email-scenarios", title: "general.send_emails", permissionSlug: "view_mail_scenarios"},
    {key: "12", url: "/e-mailing/manage-templates", title: "emailing.templates.manage_templates", permissionSlug: "view_mail_templates"},
    {key: "13", url: "/e-mailing/history", title: "emailing.history.e_mailing_history", permissionSlug: "view_mail_history"}
  ]},
  {key: "rules", icon: "fa-sharp fa-light fa-file-contract", url: "/rules", title: "general.rules", permissionSlug: "view_rules"},
  {key: "reports", icon: "fa-light fa-chart-waterfall", url: "/reports", title: "analytics.reports", permissionSlug: ""},
  {key: "sub5", icon: "fal fa-analytics", title: "analytics.analytics", permissionSlug: 'view_analytics' , subNav: [
    {key: "14", url: "/analytics/e-learning", title: "courses.e_learning"},
    {key: "15", url: "/analytics/phishing", title: "phishing.phishing"},
    {key: "16", url: "/analytics/gamification", title: "analytics.gamification"},
    {key: "17", url: "/analytics/reports", title: "analytics.reports"}
  ]},
  {key: "sub6", icon: "fal fa-file-certificate", title: "licensing.licensing", permissionSlug: "view_services,view_licences", subNav: [
    {key: "18", url: "/licensing/manage-services", title: "licensing.manage_services", permissionSlug: "view_services"},
    {key: "19", url: "/licensing/manage-licences", title: "licensing.manage_licences", permissionSlug: "view_licences"}
  ]},
  {key: "sub7", icon: "fal fa-check-double", title: "subscriptions.subscriptions", permissionSlug: 'view_subscriptions', subNav: [
    {key: "27", url: "/content", title: "subscriptions.content.apply_for_content", permissionSlug: 'view_content'},
    {key: "20", url: "/subscriptions/assign-licences", title: "subscriptions.assign_licences",  permissionSlug: 'view_assigned_licences'},
    {key: "21", url: "/subscriptions/my-subscription-licences", title: "subscriptions.my_subscription_licences",  permissionSlug: 'view_customer_subscriptions'},
    {key: "4", url: "/e-learning/apply-for-new-content", title: "new_content.apply_for_new_content", permissionSlug: "view_apply_for_new_content"}
  ]},
  {key: "sub8", icon: "fal fa-cloud", title: "system.system", permissionSlug: "view_permissions,create_users_filters,view_managa_languages,can_edit_environment,view_global_logs,view_organization_logs,view_customer_logs,edit_connection", subNav: [
    {key: "22", url: "/system/languages", title: "system.manage_languages", permissionSlug: "view_managa_languages"},
    {key: "23", url: "/system/auditing", title: "system.auditing", permissionSlug: "view_global_logs,view_organization_logs,view_customer_logs"},
    {key: "24", url: "/system/permissions", title: "system.permissions.permissions", permissionSlug: "view_permissions"},
    {key: "25", url: "/system/manage-filters", title: "system.filter.manage_filters", permissionSlug: "create_users_filters"},
    {key: "26", url: "/system/customize-environment", title: "system.customize_environment", permissionSlug: "can_edit_environment"},
    {key: "806", url: "/system/connection", title: "system.connection", permissionSlug: "edit_connection", specRights: 'can_manage_customer_connection'},
    {key: "resource-classifiers", url: "/system/resource-classifiers", title: "system.resource.classifiers", permissionSlug: ""},
    {key: "resource-busyness", url: "/system/resource-busyness", title: "system.resource_busyness", permissionSlug: ""},
    {key: "resource-calendar", url: "/system/resource-calendar", title: "system.resource.calendar", permissionSlug: ""},
  ]},
];

const menuItems = navigation.map(menuItem => {
  if (menuItem.subNav) {
    return menuItem.subNav;
  }

  return menuItem;
}).flat();

export const openedMenuItem = menuItems.find((el: any) => el.url && window.location.pathname.includes(el.url)) || null;

export const openedSubMenu = openedMenuItem ?
  navigation.find(navItem =>
    navItem.subNav && navItem.subNav.find(subNavItem => subNavItem.key === openedMenuItem.key)
  ) : null;

const exportedObject = {
  navigation,
  openedMenuItem,
  openedSubMenu
};

export default exportedObject;