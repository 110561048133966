import 'tslib';

import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { store, persistor } from 'store/configureStore';
import { createRoot } from 'react-dom/client';

import GlobalDesignContextProvider from 'context/GlobalDesignContext';
import LocaleContextProvider from 'context/LocaleContext';

// Import Font Awesome Icons Set
import 'assets/fonts/FA/css/all.css';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <GlobalDesignContextProvider>
                <LocaleContextProvider>
                    <App />
                </LocaleContextProvider>
            </GlobalDesignContextProvider>
        </PersistGate>
    </Provider>
);

serviceWorker.unregister();
