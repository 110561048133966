import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, message, Radio} from 'antd';
import {LocaleText, Modal} from 'ui';
import {Select, Input} from 'components/Form';
import {connect} from 'react-redux';
import coursesApiClient from 'utils/coursesApiClient';
import generalHelper from "utils/generalHelpers";
import {useNavigate} from "react-router-dom";
import {config} from "config/config";

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6},
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        campaignCustomerFilter: state.campaignCustomerFilter,
    };
};

const CampaignCreationModal = ({visible, onCancel, session}: any) => {
    const [isCourseListLoading, setIsCourseListLoading] = useState(false);
    const [courseList, setCourseList] = useState([]);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterBy, setFilterBy] = useState('CUSTOMER');

    const isCustomerAdmin = session.active_user_type === 'CUSTOMER_ADMIN';
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        form.setFieldsValue({organizationType: 'CUSTOMER'})
    }, [])

    useEffect(() => {
        if (session.active_user_type === 'CUSTOMER_ADMIN') {
            loadCourses(session.organization.organization_id, 'CUSTOMER')
        }
    }, [])

    const loadCourses = async (id: string, type: 'CUSTOMER' | 'ORGANIZATION') => {
        setCourseList([]);
        form.setFieldsValue({includedCourses: []});
        if (!id) {
            return;
        }
        try {
            setIsCourseListLoading(true);

            let url = '/api/v1/courses/courses/valid';

            if (!isCustomerAdmin) {
                switch (type) {
                    case "CUSTOMER":
                        url += '?customerId=' + id
                        break;

                    case "ORGANIZATION":
                        url += '?organizationId=' + id
                        break;
                }
            }

            const response = await coursesApiClient.request(url, {}, 'POST');
            const courses = response.courses;

            setCourses(courses)
            const courseListObject: any = {};

            Object.values(courses).map((course: any) => {
                return courseListObject[course.id] = <LocaleText text={course.name_translations} defaultText={course.name} />;
            });

            setCourseList(courseListObject);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setIsCourseListLoading(false);
        }
    };

    const SubmitForm = async (values: any) => {
        setLoading(true)
        let selectedCourses = values.includedCourses;

        let phishing = false

        Object.values(courses).map((course: any) => {
            if (selectedCourses.includes(course.id + '') && !!course.smart_dephish_enabled && !phishing) {
                phishing = true;
            }
            return phishing;
        })

        const stateData: {
            title: any;
            phish: boolean;
            courses: any;
            owner?: { id: number, type: string, uuid: string }
        } = {
            title: values.campaignTitle,
            phish: phishing,
            courses: values.includedCourses.join('&'),
        }

        if (session.active_user_type !== 'CUSTOMER_ADMIN') {
            stateData.owner = {
                id: 0,
                type: values.organizationType,
                uuid: values.customerId
            }
        }

        navigate('/campaign/create/settings', {state: stateData})
        setLoading(false)
    };

    const displayOrganizationSelect = (type: 'CUSTOMER' | 'ORGANIZATION') => {

        let display = false;
        switch (type) {
            case 'CUSTOMER':
                if (filterBy === 'CUSTOMER') {
                    display = true;
                }
                break;
            case 'ORGANIZATION':
                if (filterBy === 'ORGANIZATION') {
                    display = true;
                }
                break;
        }

        return display;
    }

    return (
        <Modal
            className="create_elearning_campaign"
            open={visible}
            isLoading={loading}
            onCancel={() => onCancel(false)}
            title={intl.formatMessage({id: 'campaign.create_elearning_campaign'})}
            okText={intl.formatMessage({id: 'general.create'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            onOk={form.submit}
        >
            <Form form={form} onFinish={SubmitForm} {...formItemLayout}>
                {!generalHelper.isCustomerAdmin(session.active_user_type) &&
                  <Form.Item
                    name="organizationType"
                    label={intl.formatMessage({id: 'general.filter_by'})}
                  >
                    <Radio.Group onChange={(el: any) => setFilterBy(el.target.value)}>
                      <Radio value="CUSTOMER"><FormattedMessage id='general.customer'/></Radio>
                      <Radio value="ORGANIZATION"><FormattedMessage id='general.reseller'/></Radio>
                    </Radio.Group>
                  </Form.Item>
                }
                <Input customLayout={formItemLayout} name="campaignTitle"
                       label={intl.formatMessage({id: 'campaign.campaign_title'})}
                       validation={{required: true}}/>
                {!generalHelper.isCustomerAdmin(session.active_user_type) && displayOrganizationSelect('CUSTOMER') &&
                  <Select
                    allowClear
                    showSearch
                    name='customerId'
                    dataKey='customers'
                    customLayout={formItemLayout}
                    onChange={(id: any) => loadCourses(id, 'CUSTOMER')}
                    label={intl.formatMessage({id: "general.customer"})}
                    url={config.api.routes.backend.selectCustomersUuid}
                    dropdownStyle={{minWidth: "385px"}}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  />
                }
                {!generalHelper.isCustomerAdmin(session.active_user_type) && displayOrganizationSelect('ORGANIZATION') &&
                  <Select
                    allowClear
                    showSearch
                    name='customerId'
                    dataKey='organizations'
                    customLayout={formItemLayout}
                    onChange={(id: any) => loadCourses(id, 'ORGANIZATION')}
                    label={intl.formatMessage({id: "general.reseller"})}
                    url={config.api.routes.backend.selectOrganizationsUuid}
                    dropdownStyle={{minWidth: "385px"}}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  />
                }
                <Select
                    customLayout={formItemLayout}
                    name="includedCourses"
                    label={intl.formatMessage({id: 'campaign.included_courses'})}
                    onChange={(value: any) => {
                        form.setFieldsValue({value})
                    }}
                    valueKey="value"
                    labelKey="label"
                    manualOptions={courseList}
                    customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                    showSearch
                    loading={isCourseListLoading}
                    mode="multiple"
                    disabled={generalHelper.isEmpty(courseList)}
                    showArrow
                />
            </Form>
        </Modal>
    )
};
export default connect(mapStateToProps)(CampaignCreationModal);
