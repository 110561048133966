import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {message, Modal, Upload, Select as AntDSelect, Select, Form} from 'antd';
import Switch from 'components/Form/Switch'
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import {config} from 'config/config';
import './styles.scss';

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const ImportModal: React.FC<any> = ({visible, onCancel, refresh, customerId, afterSubmit, userRole, setActiveOrganizationId}) => {
    const [uploadTypesLoading, setUploadTypesLoading] = useState(false);
    const [userUploadTypes, setUserUploadTypes] = useState<any>({});
    const [isCustomersLoading, setIsCustomersLoading] = useState(false);
    const [customers, setCustomers] = useState<any>({});
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [importFileList, setImportFileList] = useState<any>([]);
    const {Dragger} = Upload;
    const {Option} = Select;

    const intl = useIntl();
    const [importForm] = Form.useForm();
    const messageTime = 4;

    useEffect(() => {
        const loadClassifiers = async () => {
            try {
                setIsCustomersLoading(true);
                const customersOptionsResponse = await apiClient.request('/api/v1/customers-options?withoutDeleted=true', {}, 'GET');
                setCustomers(customersOptionsResponse.customers || {});
                setIsCustomersLoading(false);
            } catch (err) {
                console.error(err);
            } finally {
                setIsCustomersLoading(false);
            }
        };

        const loadUploadTypes = async () => {
            try {
                setUploadTypesLoading(true);
                const uploadTypes = await apiClient.request('/api/v1/enum/user-upload-types', {}, 'GET');

                importForm.setFieldsValue({
                    options: 'ADD_NEW_AND_UPDATE_EXISTING_USERS'
                });

                setUserUploadTypes(uploadTypes);
                setUploadTypesLoading(false);
            } catch (err) {
                console.error(err);
            } finally {
                setUploadTypesLoading(false);
            }
        };

        if (userRole !== 'CUSTOMER_ADMIN') {
            loadClassifiers();
        }
        loadUploadTypes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (customerId) {
            importForm.setFieldsValue({
                customerId: customerId
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    const validatePlatformAccess = async (customerId: any, file: any) => {

        return true;
        try {
            if (customerId) {
                let bodyFormData = new FormData();

                bodyFormData.append('file', file);
                bodyFormData.append('customerId', customerId);

                let response = await apiClient.request(`/api/v1/auth/users/validate-import`, bodyFormData, 'POST');

                return response.valid;
            }
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: "error.data_load"}));

            return false;
        }
    };

    const normFile = (e: any) => {
        let fileList = [...e.fileList];

        fileList = fileList.slice(-1);
        setImportFileList(fileList);
        if (Array.isArray(e)) {
            return e;
        }
        return e && fileList;
    };

    const submitForm = async (values: any) => {
        let file: any;

        if (values && values.dragger) {
            file = values.dragger[0];
        }

        if (!file) {
            return message.error(intl.formatMessage({id: "users.import.unsuccessful_import_in_message"}), messageTime);
        }

        let bodyFormData = new FormData();

        values.sendPassword = !!values.sendPassword;
        values.allowNameChange = !!values.allowNameChange;
        bodyFormData.append('file', file.originFileObj);
        bodyFormData.append('customerId', values.customerId ? values.customerId : customerId);
        bodyFormData.append('sendPassword', values.sendPassword);
        bodyFormData.append('allowNameChange', values.allowNameChange);
        bodyFormData.append('options', values.options);

        try {
            setSubmitInProgress(true);

            let response = await validatePlatformAccess(values.customerId, file.originFileObj);

            if (response.error && !response.valid) {
                const errors = response.error;
                Modal.error({
                    title: intl.formatMessage({id: "general.import_errors"}),
                    content: Object.values(errors).map((el: any) => {
                        return (
                            <div>
                                <span style={{marginRight: 5}}>
                                    {intl.formatMessage({id: el.error})}
                                </span>
                                <span>
                                    {intl.formatMessage({id: "general.row"})}: {el.row}
                                </span>
                            </div>
                        );
                    })
                });
                return;
            }

            let importResponse = await apiClient.request('/api/v1/users/import', bodyFormData, 'POST', true, true);

            if (!importResponse) {
                message.error(intl.formatMessage({id: "users.import.unsuccessful_import_in_message"}), messageTime);
            } else {
                Modal.info({
                    title: intl.formatMessage({id: "general.attention"}),
                    content: intl.formatMessage({id: "users.import_message"}),
                });
            }
            setSubmitInProgress(false);
        } catch (err) {

            console.error(err);
            message.error(intl.formatMessage({id: "users.import.unsuccessful_import_in_message"}), messageTime);

            setImportFileList([]);
        } finally {
            setImportFileList([]);
            setSubmitInProgress(false);
            importForm.setFieldsValue({
                dragger: null
            });
            afterSubmit();
        }
    };

    //default dragger upload request prevention
    const customerDraggerRequest = ({onSuccess}: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <Form form={importForm} onFinish={submitForm}>
            <Modal
                className="import-modal"
                title={intl.formatMessage({id: 'users.import.user_import'})}
                open={visible}
                onOk={() => {
                    importForm.submit();
                }}
                onCancel={onCancel}
                okText={intl.formatMessage({id: 'users.import.import'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
            >
                <Spinner spinning={submitInProgress || uploadTypesLoading} opaque={uploadTypesLoading}>
                    <p>
                        <FormattedMessage id="users.import.first_paragraph"/>
                    </p>
                    <p>
                        <FormattedMessage id="users.import.second_paragraph"/>
                    </p>
                    <p>
                        <FormattedMessage id="users.import.upload_file_template"/>
                        <a
                            className='ml-2'
                            href={config.api.frontendUrl + '/User_Import_template.xlsx'}
                            download
                        >
                            User Import template.xlsx
                        </a>
                    </p>

                    <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile}>
                        <Dragger
                            className={importFileList.length ? 'hide-dragger' : ''}
                            customRequest={({onSuccess, file}) => customerDraggerRequest({onSuccess, file})}
                            name="file"
                            style={{
                                backgroundColor: 'white',
                                borderStyle: 'none',
                                padding: '0',
                                margin: 0,
                                cursor: 'initial'
                            }}
                            multiple={false} accept=".xlsx, .xls"
                        >
                            <div style={{textAlign: 'left'}}>
                                <FormattedMessage id="general.upload"/>
                                <div className="upload-file user-name-style" style={{border: 0, paddingLeft: '3px'}}>
                                    <FormattedMessage id="users.choose_file"/>
                                </div>
                            </div>
                            <div
                                style={{
                                    border: 1,
                                    borderStyle: 'dashed',
                                    padding: '40px',
                                    cursor: 'pointer',
                                    backgroundColor: '#fafafa',
                                    borderColor: '#DCDCDC'
                                }}
                            >
                                <i style={{fontSize: 50, color: '#D9D9D9', padding: 10}}
                                   className='fal fa-file-upload'/>
                                <p className='ant-upload-text'>
                                    <FormattedMessage id='users.import.click_or_drag_file_to_this_area_to_upload'/>
                                </p>
                            </div>
                        </Dragger>
                    </Form.Item>

                    <br/>
                    <Form.Item
                        {...formItemLayout}
                        name='customerId'
                        style={customerId ? {display: 'none'} : {display: ''}}
                        label={intl.formatMessage({id: 'general.customer'})}
                        rules={[{
                            required: true,
                            message: intl.formatMessage({id: 'validation.field_required'})
                        }]}
                    >
                        <AntDSelect loading={isCustomersLoading} onChange={(el: any) => setActiveOrganizationId(el)}>
                            {Object.keys(customers).map(key =>
                                <Option key={parseInt(key)} value={parseInt(key)}>{customers[key]}</Option>
                            )}
                        </AntDSelect>
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        name='options'
                        label={intl.formatMessage({id: 'general.options'})}
                    >
                        <AntDSelect loading={uploadTypesLoading}>
                            {Object.keys(userUploadTypes).map(key =>
                                <Option key={key} value={key}>{intl.formatMessage({id: `users.import.${key}`})}</Option>
                            )}
                        </AntDSelect>
                    </Form.Item>

                    <Form.Item
                        name='sendPassword'
                        label={intl.formatMessage({id: 'users.import.send_password_reset_email'})}
                    >
                        <Switch style={{marginLeft: 20}} defaultChecked={false}/>
                    </Form.Item>

                    <Form.Item
                        name='allowNameChange'
                        label={intl.formatMessage({id: 'users.import.allow_name_changes'})}
                    >
                        <Switch style={{marginLeft: 20}} defaultChecked={false}/>
                    </Form.Item>

                </Spinner>
            </Modal>
        </Form>
    )
};

export default ImportModal;
