import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Space, Upload } from 'antd';
import { config } from 'config/config';
import './styles.scss';
import { UploadOutlined } from '@ant-design/icons';
import coursesApiClient from 'utils/coursesApiClient';
import useHandleError from 'utils/useHandleError';
import { connect } from 'react-redux';
import { ComponentAccessCheck } from 'ui';
import jwt from 'utils/jwt';

const mapStateToProps = (state: any) => ({
    environment: state.environment.environment,
});

const StudentManual: React.FC<any> = ({ environment }) => {
    const [iframeKey, setIframeKey] = useState(0);
    const [handleError] = useHandleError();

    const uploadAttachment = async (file: any) => {
        try {
            let bodyFormData = new FormData();
            bodyFormData.append('file', file.file);
            await coursesApiClient.request('/api/v1/courses/student-manual', bodyFormData, 'POST');
            setIframeKey(iframeKey + 1);
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <>
            {environment === 'ADMINISTRATION' && (
                <>
                    <ComponentAccessCheck permission={'create_student_manual'}>
                        <hr className="form-group-separator" />
                        <Upload
                            customRequest={(file: any) => uploadAttachment(file)}
                            multiple
                            accept={'.pdf'}
                            fileList={[]}
                        >
                            <Button className={'ml-5'}>
                                <Space size={8}>
                                    <UploadOutlined />
                                    <FormattedMessage id="general.upload" />
                                </Space>
                            </Button>
                        </Upload>
                    </ComponentAccessCheck>

                    <hr className="form-group-separator" />
                </>
            )}
            <iframe
                key={iframeKey}
                className="manual-player"
                title="MANUAL_PLAYER"
                src={`${config.api.serveUrl}/manual?token=${jwt.get()}`}
            />
        </>
    );
};

export default connect(mapStateToProps)(StudentManual);
