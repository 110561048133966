import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, message, Space, Button } from 'antd';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import apiClient from "utils/apiClient";
import { UsersListFooterInterface } from '../types';
import './styles.scss';
import { SendEmailModal } from "components/Modals";
import useHandleError from 'utils/useHandleError';

const UsersListFooter: React.FC<UsersListFooterInterface> = ({
    activeOrganizationType,
    activeOrganizationId,
    activeOrganizationUuid,
    rowSelection,
    setReload,
    selectedRowKeys,
}) => {
    const [showMailModal, setShowMailModal] = useState(false);

    const [handleError] = useHandleError();
    const intl = useIntl();
    const { confirm, info } = Modal;

    const showConfirm = (value: boolean) => {
        if (value) {
            confirm({
                content: intl.formatMessage({ id: 'users.activate_users' }, { amount: selectedRowKeys.length }),
                icon: <ExclamationCircleOutlined />,
                okText: intl.formatMessage({ id: 'general.continue' }),
                cancelText: intl.formatMessage({ id: 'general.cancel' }),
                onOk() {
                    activateUsers();
                },
            });
        } else {
            confirm({
                content: intl.formatMessage({ id: 'users.block_users' }, { amount: selectedRowKeys.length }),
                icon: <ExclamationCircleOutlined />,
                okText: intl.formatMessage({ id: 'general.continue' }),
                cancelText: intl.formatMessage({ id: 'general.cancel' }),
                onOk() {
                    blockUsers();
                },
            });
        }
    };

    const activateUsers = async () => {
        const parsedValues = {
            organizationType: activeOrganizationType,
            organizationId: activeOrganizationId,
            users: selectedRowKeys
        };

        try {
            await apiClient.request('/api/v1/users/mass-activate', parsedValues, 'POST');

            info({
                content: intl.formatMessage({ id: 'users.activate_users_info' }),
                icon: <InfoCircleOutlined />,
                cancelText: intl.formatMessage({ id: 'general.close' })
            });
            setReload(true);
        } catch (error) {
            handleError(error);
        }
    };

    const blockUsers = async () => {
        const parsedValues = {
            organizationType: activeOrganizationType,
            organizationId: activeOrganizationId,
            users: selectedRowKeys
        };

        try {
            await apiClient.request('/api/v1/users/mass-block', parsedValues, 'POST');

            info({
                content: intl.formatMessage({ id: 'users.block_users_info' }),
                icon: <InfoCircleOutlined />,
                cancelText: intl.formatMessage({ id: 'general.close' })
            });
            setReload(true);
        } catch (error) {
            handleError(error);
        }
    };

    const showConfirmDelete = () => {
        confirm({
            title: intl.formatMessage({ id: "general.attention" }),
            content: intl.formatMessage({ id: "users.confirm_delete" }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.continue' }),
            cancelText: intl.formatMessage({ id: 'general.cancel' }),

            onOk() {
                initiateUsersDelete();
            },
        });
    };

    const initiateUsersDelete = async () => {
        try {
            let massDeleteRequestUrl = activeOrganizationType === 'CUSTOMER'
                ? '/api/v1/customer-users/mass-delete'
                : '/api/v1/admins/mass-delete';

            await apiClient.request(massDeleteRequestUrl, selectedRowKeys, 'POST');
            Modal.success({
                content: <>
                    <p><FormattedMessage id='users.after_initiate_delete_info_1' /></p>
                    <p><FormattedMessage id='users.after_initiate_delete_info_2' /></p>
                </>
            });
            setReload(true);
        } catch (error) {
            handleError(error);
        } finally {
            setReload(true);
        }
    };

    const showConfirmUndelete = () => {
        if (!selectedRowKeys.length) {
            Modal.warning({
                title: intl.formatMessage({ id: "general.attention" }),
                content: intl.formatMessage({ id: 'users.no_users_selected' })
            });
        } else {
            undeleteUsers();
        }
    };

    const undeleteUsers = async () => {
        try {
            let massUndeleteRequestUrl = activeOrganizationType === 'CUSTOMER'
                ? `/api/v1/customer-users/mass-undelete`
                : `/api/v1/admins/mass-undelete`;

            await apiClient.request(massUndeleteRequestUrl, selectedRowKeys, 'POST');
        } catch (error) {
            handleError(error);
        } finally {
            setReload(true);
        }
    };

    const checkSelectedOrganization = () => {
        if (selectedRowKeys.length > 0) {
            setShowMailModal(true);
        } else {
            message.error(intl.formatMessage({ id: "error.select_users" }));
        }
    };

    return (
        <>
            <Space size='small' className='users-list-footer'>
                <div>
                    {Array.isArray(selectedRowKeys) ? selectedRowKeys.length : 0}
                    <FormattedMessage id='users.rows_selected' />
                </div>
                <Button onClick={() => checkSelectedOrganization()}>
                    <i className="fal fa-envelope" />
                    <FormattedMessage id='users.send_email_users' />
                </Button>
                <Button onClick={() => showConfirm(true)}>
                    <i style={{ color: '#52C419' }} className="fal fa-check-circle" />
                    <FormattedMessage id='general.activate' />
                </Button>
                <Button onClick={() => showConfirm(false)}>
                    <i style={{ color: '#F5222D' }} className="fal fa-times-circle" />
                    <FormattedMessage id='general.block' />
                </Button>
                <Button onClick={() => showConfirmDelete()}>
                    <i style={{ color: '#F5222D' }} className="fal fa-trash" />
                    <FormattedMessage id='users.delete' />
                </Button>
                <Button onClick={() => showConfirmUndelete()}>
                    <i style={{ color: '#F5222D' }} className="fal fa-trash-restore" />
                    <FormattedMessage id='users.undelete' />
                </Button>
            </Space>
            <SendEmailModal
                visible={showMailModal}
                onCancel={setShowMailModal}
                organizationId={activeOrganizationId}
                organizationType={activeOrganizationType}
                users={selectedRowKeys}
            />
        </>
    );
};

export default UsersListFooter;
