import styles from './styles.module.scss'; // THIS HAS TO BE ON TOP BECAUSE REASONS, just don't touch it, okay
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import Footer from './Footer';
import Header from './Header';
import { useGlobalDesignContext } from 'context/GlobalDesignContext';
import generalHelpers from 'utils/generalHelpers';
import LanguageButtons from 'components/LanguageButtons';
import { SpecialClientEnumsType, SpecialClientInterface } from './types';
const { Content } = Layout;

const DefaultUnauthorizedLayout: React.FC<any> = ({ children, className, showLanguageChange }) => {
    const { logo, setLogo, favicon, setFavicon } = useGlobalDesignContext();

    const hostname = window.location.hostname;

    const handleClient = (key: keyof SpecialClientInterface) => {

        if (hostname === 'rix.cloudstudy.lv') {
            window.location.replace(
                'https://saml2.cloudstudy.lv/saml2/1a7f1839-9398-4358-aefc-53db85d99681/login'
            );
            return;
        }

        const clientValues: SpecialClientInterface = generalHelpers.getClientValues(hostname, styles);

        if (!logo) {
            setLogo(
                SpecialClientEnumsType.logo in clientValues && clientValues.logo ? clientValues.logo : '/cs-logo.svg'
            );
        }

        if (!favicon) {
            setFavicon(
                SpecialClientEnumsType.favicon in clientValues && clientValues.favicon ? clientValues.favicon : '/favicon.ico'
            );
        }

        if (key in clientValues && !generalHelpers.isEmpty(clientValues)) {
            return (clientValues as SpecialClientInterface)[key];
        }

        return undefined;
    };

    return (
        <div className={styles.container}>
            <Header handleValues={handleClient} />
            <Content
                className={
                    handleClient(SpecialClientEnumsType.design) +
                    ' ' +
                    (className ?? '') +
                    ' ' +
                    styles.content
                }
            >
                {children}
                {showLanguageChange && (
                    <div className={styles.languageButtons}>
                        <LanguageButtons />
                    </div>
                )}
            </Content>
            <Footer handleValues={handleClient} />
        </div>
    );
};

export default DefaultUnauthorizedLayout; //;
