import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'ui';
import { Switch } from 'components/Form';
import useQueryApiClient from 'utils/useApiClient';
import FlexRow from 'components/FlexRow';
import LanguagForm from './LanguageForm';
import { LanguageInterface } from '../types';

const Languages: React.FC = () => {
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [language, setLanguage] = useState<LanguageInterface | {}| {id: string}>({})
    const [reload, setReload] = useState(false);
    const intl = useIntl();

    const handleVisibleChange = (visible: boolean, rowIndex: number) => {
        if (visible) {
            setVisibleTooltip(rowIndex);
        } else {
            setVisibleTooltip(null);
        }
    };

    const { appendData, isLoading } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/languages/:id',
            method: 'DELETE',
        }, onSuccess: () => {
            setReload(true);
        }
    });

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.name' }),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: intl.formatMessage({ id: 'system.languages.code' }),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: intl.formatMessage({ id: 'system.languages.published' }),
            dataIndex: 'published',
            key: 'published',
            render: (text: string, record: LanguageInterface) => {
                return (<Switch isFormItem={false} disabled key={`language-${record.id}`} checked={record.publish} />)
            }
        },
        {
            title: intl.formatMessage({ id: 'system.languages.language_family' }),
            dataIndex: 'languageFamily',
            key: 'languageFamily',
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            render: (text: string, record: LanguageInterface, index: number) => {
                const content = <>
                    <div className='popover-item' onClick={() => {
                        setVisibleTooltip(null);
                        setLanguage(record);
                    }}>
                        <FormattedMessage id='general.edit' />
                    </div>
                    <div className='popover-item' onClick={() => {
                        setVisibleTooltip(null);
                        appendData({}, { id: record.id });
                    }}>
                        <FormattedMessage id='general.delete' />
                    </div>
                </>;

                return (
                    <Popover
                        open={index === visibleTooltip}
                        content={content}
                        trigger='click'
                        placement='bottomRight'
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, index)}
                    >
                        <div className='w-100 pointer f-center'>
                            <i className='fal fa-ellipsis-v' />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    return (
        <>
            <FlexRow left={
                <Button
                    type='primary'
                    onClick={() => setLanguage({ id: 'create' })}
                >
                    <PlusOutlined /> <FormattedMessage id='general.add' />
                </Button>
            } />
            <Table
                columns={columns}
                url={'/api/v1/languages'}
                setReload={setReload}
                reload={reload}
                rowSelection={false}
                rowKey={'id'}
            />

            <LanguagForm item={language} onCancel={setLanguage} reload={setReload} />
        </>

    )
}
export default Languages;
