import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeUserTableFilter } from 'store/actions/saveUserTableFilter';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Alert, Tag, Popover, message, Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    OrganizationType,
    RowSelectionUsersInterface
} from 'components/UsersComponents/types';
import DefaultLayout from 'components/DefaultLayout';
import { Tooltip } from 'antd';
import useQueryApiClient from 'utils/useApiClient';
import apiClient from 'utils/apiClient';
import Jwt from 'utils/jwt';
import {config} from 'config/config';
import UsersListFooter from 'components/UsersComponents/UsersListFooter';
import UsersListToolbar from 'components/UsersComponents/UsersListToolbar';
import { Table } from 'ui'
import qs from 'qs';
import Switch from 'components/Form/Switch';
import Spinner from 'components/Spinner';
import useHandleError from 'utils/useHandleError';
import jwt from 'utils/jwt';
import coursesApiClient from 'utils/coursesApiClient';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        userTableFilter: state.userTableFilter,
        session: state.session
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    changeUserTableFilter: (userTableFilter: any) => dispatch(changeUserTableFilter(userTableFilter)),
});

const UserList: React.FC<any> = ({ session, changeUserTableFilter, userTableFilter, hideHeader = false }) => {
    const [reload, setReload] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);
    const [search, setSearch] = useState('');
    const [activeOrganizationId, setActiveOrganizationId] = useState<number>(0);
    const [activeOrganizationType, setActiveOrganizationType] = useState<OrganizationType>();
    const [activeOrganizationUuid, setActiveOrganizationUuid] = useState<string>(''); // remove this param
    const [filter, setFilter] = useState('');
    const [canAddLearningManagers, setCanAddLearningManagers] = useState<boolean>(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showFilter, setShowFilter] = useState(false);
    const [customer, setCustomer] = useState<any>();
    const [isCustomerLoading, setIsCustomerLoading] = useState(false);
    const [newUsers, setNewUsers] = useState(false);
    const [rowSelection, setRowSelection] = useState<RowSelectionUsersInterface>({
        filter: {},
        selectedAllUsers: false,
        selectedUsers: [],
        deSelectedUsers: [],
        searchText: ''
    });
    const [dataSource, setDataSource] = useState<any>([]);
    const [customFields, setCustomFields] = useState<any>([]);

    const intl = useIntl();
    const userRole = session.active_user_type;

    const params = useParams();
    const [handleError] = useHandleError();

    const customerId = params.id;
    const backPath = params.backPath;
    const navigate = useNavigate();

    useEffect(() => {
        if (!customerId) {
            if (userRole !== 'SUPER_ADMIN') {
                if (userTableFilter?.filter) {
                    const storedFilter: any = qs.parse(userTableFilter?.filter);

                    if (storedFilter.filter.filterBy === 'CUSTOMER') {
                        setActiveOrganizationId(parseInt(storedFilter.filter.customerId));
                        setActiveOrganizationType('CUSTOMER');
                    } else if (storedFilter.filter.filterBy === 'ORGANIZATION') {
                        setActiveOrganizationId(parseInt(storedFilter.filter.organizationId));
                        setActiveOrganizationType('ORGANIZATION');
                    }
                } else {
                    setActiveOrganizationId(parseInt(session.organization.organization_id));
                    setActiveOrganizationType(session.organization.organization_type);
                    setActiveOrganizationUuid(session.organization.organization_uuid);
                }
            } else {
                setShowFilter(true);
            }

            if (session.special_rights && session.special_rights.includes('can_manage_learning_managers')) {
                setCanAddLearningManagers(true);
            }

            if (userRole === 'SUPER_ADMIN') {
                setCanAddLearningManagers(true);
            }
        }

        const loadCustomer = async () => {
            setIsCustomerLoading(true);
            let url = '/api/v1/customers/options/values';

            if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN') {
                url = url + '?customerId=' + customerId;
            }

            if (session.special_rights && session.special_rights.includes('can_manage_customers') && customerId) {
                url = url + '?customerId=' + customerId;
            }

            try {
                let response = await apiClient.request(url, [], 'GET');

                setCustomer(response.customer);
                setActiveOrganizationType('CUSTOMER');
                // @ts-ignore ignores typescript next line
                setActiveOrganizationId(customerId);
                setActiveOrganizationUuid(response.customer.uuid);

            } catch (err) {
                console.error(err);
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
            setIsCustomerLoading(false);
        };

        if (customerId) {
            loadCustomer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl, customerId]);

    useEffect(() => {
        loadCustomFields();
    }, []);

    useEffect(() => {
        if (reload) {
            changeUserTableFilter({ filter: filter })
            checkForNewUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    useEffect(() => {
        if (userTableFilter?.filter) {
            setFilter(userTableFilter.filter)
        } else if (activeOrganizationId && activeOrganizationType) {
            if (userRole === 'CUSTOMER_ADMIN' || userRole === 'SUPERVISION') {
                setFilter(`&filter[filterBy]=CUSTOMER&filter[customerId]=${activeOrganizationId}`)
            } else {
                if (activeOrganizationType === 'CUSTOMER') {
                    setFilter(`&filter[filterBy]=CUSTOMER&filter[customerId]=${activeOrganizationId}`)
                } else {
                    setFilter(`&filter[filterBy]=ORGANIZATION&filter[organizationId]=${activeOrganizationId}`)
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrganizationId, activeOrganizationType]);

    const checkForNewUsers = async () => {
        try {
            let response = await apiClient.request(`/api/v1/users/${activeOrganizationId}/check-new-users`, {}, 'GET');

            setNewUsers(response.users);
        } catch (error) {
            console.error(error);
        }
    }

    const checkIfUserAvailable = async (record: any) => {
        let parsedValues = {
            userUuid: record.userUuid,
            customerId: activeOrganizationId
        };

        try {
            setVisibleTooltip(null);

            let response = await apiClient.request('/api/v1/manager/user', parsedValues, 'POST');

            if (response.allow) {
                navigate(`/customer/${activeOrganizationId}/user/${record.userUuid}/view/users`);
            } else {
                message.error(intl.formatMessage({ id: 'error.not_allowed' }));
            }
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    };

    const switchSuperVisionRole = async (record: any) => {
        let url;
        let activationMessage
        let key: any = Object.entries(dataSource).filter((el: any) => el[1].typeUuid === record.typeUuid);

        if (!!record.isActiveSuperVision) {
            url = `/api/v1/users/user/${record.typeUuid}/deactivate/super-vision`
            if (userRole !== 'CUSTOMER_ADMIN') {
                url = url + '?customerId=' + activeOrganizationId;
            }
            activationMessage = 'role_super_vision_disabled';

            dataSource[key[0][0]].isActiveSuperVision = 0
            setDataSource(dataSource);
        } else if (!record.isActiveSuperVision) {
            url = `/api/v1/users/user/${record.typeUuid}/activate/super-vision`
            if (userRole !== 'CUSTOMER_ADMIN') {
                url = url + '?customerId=' + activeOrganizationId;
            }
            activationMessage = 'role_super_vision_enabled';
            dataSource[key[0][0]].isActiveSuperVision = 1
            setDataSource(dataSource);
        }

        try {
            await apiClient.request(url, [], 'POST');

            if (record.email === session.email) {
                jwt.switchUserRole(session.active_user_type_id);
            }
            message.success(intl.formatMessage({ id: 'users.' + activationMessage }));
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: 'error.cant_change_role' }));
        }
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.name' }),
            dataIndex: 'name',
            key: 'name',
            render: (_text: string, record: any) => {
                if (userRole !== 'SUPERVISION') {
                    let to;

                    if (record.type === 'ADMIN' || record.type === 'LECTOR') {
                        to = `/organizations/${activeOrganizationId}/admins/${record.typeUuid}/edit`;
                    } else {
                        to = `/customer/${activeOrganizationId}/user/${record.typeUuid}/view`;

                        if (backPath === 'org') {
                            to = to + '/org'
                        } else {
                            to = to + '/users'
                        }
                    }

                    return (
                        <NavLink to={to}>
                            {record.name}
                        </NavLink>
                    )
                } else {
                    return (
                        <div className='user-name-style' onClick={() => checkIfUserAvailable(record)}>
                            {record.name}
                        </div>
                    )
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'general.surname' }),
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
            key: 'email',
            render: (text: string, record: any) => record.email ? record.email : record.phone_number
        },
        {
            title: intl.formatMessage({ id: 'general.organization' }),
            dataIndex: 'organizationName'
        },
        {
            title: intl.formatMessage({ id: 'general.departments' }),
            dataIndex: 'departments'
        },
        {
            title: intl.formatMessage({ id: 'users.organizational_unit' }),
            dataIndex: 'organizationalUnit'
        },
        {
            title: intl.formatMessage({ id: 'general.position' }),
            dataIndex: 'position'
        },
        {
            title: intl.formatMessage({ id: 'users.user_status' }),
            dataIndex: 'accountStatus',
            render: (accountStatus: string, _record: any, key: number) => (
                <>
                    <Tag color={accountStatus === 'ACTIVE' ? 'green' : 'red'} key={key}>
                        {intl.formatMessage({ id: `user.status.${accountStatus}` })}
                    </Tag>
                    {_record.deletionInitiatedAt != null ?
                        <Tag color={'red'}>
                            {intl.formatMessage({ id: `user.status.DELETION` })}
                        </Tag> : ''}
                </>
            )
        },
        {
            title:
                <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                    {intl.formatMessage({ id: 'users.registration_status' })}
                    <Tooltip title={intl.formatMessage({ id: 'users.registration_status_explanation' })}>
                        <i className='fal fa-question-circle header-item' />
                    </Tooltip>
                </div>,
            dataIndex: 'active',
            align: 'center' as 'center',
            render: (active: boolean) => (
                active
                    ? <CheckOutlined style={{ color: 'green' }} />
                    : <CloseOutlined style={{ color: 'red' }} />
            )
        },
        {
            title: intl.formatMessage({ id: 'users.user_type' }),
            dataIndex: 'roles',
            render: (roles: string, record: any) => {
                return (
                    <>
                        {record.type === 'ADMIN' &&
                            <Tag color='gold' key='gold'>
                                {intl.formatMessage({ id: `user.type.${record.organizationType}_ADMIN` })}
                            </Tag>
                        }
                        {!!record.hasLectorRole &&
                            <Tag color='blue' key='blue' style={{ marginTop: 10 }}>
                                {intl.formatMessage({ id: 'user.type.LECTOR' })}
                            </Tag>
                        }
                        {!!record.isActiveLearningManagerRole &&
                            <Tag color='blue' key='blue' style={{ marginTop: 10 }}>
                                {intl.formatMessage({ id: 'user.type.CUSTOMER_ADMIN' })}
                            </Tag>
                        }
                        {!!record.isActiveElearningAccount || record.type === 'STUDENT' ?
                            <Tag color='purple' key='purple' style={{ marginTop: 10 }}>
                                {intl.formatMessage({ id: 'user.type.STUDENT' })}
                            </Tag> : null
                        }
                    </>
                )
            }
        },
        {
            key: 'superVisionSwitch',
            title:
                <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                    {intl.formatMessage({ id: 'users.supervision' })}
                    <Tooltip title={intl.formatMessage({ id: 'users.supervision_hint' })}>
                        <i className='fal fa-question-circle header-item' />
                    </Tooltip>
                </div>,
            align: 'center' as 'center',
            render: (record: any) => {
                if (record.isDirectManager) {
                    return (
                        <Switch
                            name='supervision'
                            defaultChecked={record.isActiveSuperVision === 1}
                            onClick={() => switchSuperVisionRole(record)}
                        />
                    )
                }
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any, index: number) => {
                let editRoute;

                if (record.type === 'ADMIN' || record.type === 'LECTOR') {
                    editRoute = `/organizations/${record.organizationId}/admins/${record.typeUuid}/edit`;
                } else {
                    editRoute = `/customer/${activeOrganizationId}/user/${record.typeUuid}/view`;

                    if (backPath === 'org') {
                        editRoute = editRoute + '/org'
                    } else {
                        editRoute = editRoute + '/users'
                    }
                }

                const content = (
                    <>
                        <NavLink to={editRoute}>
                            <div className='popover-item'>
                                <FormattedMessage id='general.edit' />
                            </div>
                        </NavLink>
                        <div className='popover-item' onClick={() => {
                            sendEmail(record)
                        }}>
                            <FormattedMessage id='login.reset_password' />
                        </div>
                        {isUserSwitchAllowed() && (
                            <div className='popover-item' onClick={() => {
                                switchToUser(record.uuid, record.typeId)
                            }}>
                        
                                <FormattedMessage id='users.switch_user' />
                            </div>
                        )}
                        {record.run && (
                            <div className='popover-item' onClick={() => {
                                runAudience({}, { id: record.id })
                            }}>
                                <FormattedMessage id='audience.run' />
                            </div>
                        )}
                    </>
                );

                if (userRole !== 'SUPERVISION') {
                    return (
                        <Popover
                            open={index === visibleTooltip}
                            content={content}
                            trigger='click'
                            placement='bottomRight'
                            arrowPointAtCenter
                            onOpenChange={(visible) => handleVisibleChange(visible, index)}
                        >
                            <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                                <i className='fal fa-ellipsis-v' style={{ fontSize: '16px' }} />
                            </div>
                        </Popover>
                    )
                }
            },
        }
    ];

    const { appendData: runAudience } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/users/audience/:id/run',
            method: 'POST',
        }
    });

    const switchToUser = async (userId: number, userTypeId: number) => {
        let errorMessageCode = 'switch_to_user_fail';

        try {
            setVisibleTooltip(null);

            let response = await Jwt.switchUser(userId, userTypeId);

            if (response === true) {
                window.location.assign('/')
            } else {
                message.error(intl.formatMessage({ id: errorMessageCode }));
            }
        } catch (err: any) {
            if (err.message === 'user_switch_not_allowed') {
                errorMessageCode = err.message
            } else if (err.message === 'user_is_not_activated') {
                errorMessageCode = err.message
            }

            message.error(intl.formatMessage({ id: 'error.' + errorMessageCode }));
        }
    };

    const handleVisibleChange = (visible: boolean, rowIndex: number) => {
        if (visible) {
            setVisibleTooltip(rowIndex);
        } else {
            setVisibleTooltip(null);
        }
    };

    const isUserSwitchAllowed = () => {
        return (session.active_user_type === 'SUPER_ADMIN')
    };

    const sendEmail = async (record: any) => {
        try {
            let parsedValues = {
                userTypeUuid: record.typeUuid,
            };
            setVisibleTooltip(null); // hide tooltips
            await apiClient.request('/api/v1/password-reset', parsedValues, 'POST');
            message.success(intl.formatMessage({ id: `users.email_sent` }));

        } catch (error) {
            console.error(error);
        }
    };

    const getBreadcrumbs = () => {

        if (session.special_rights && session.special_rights.includes('can_manage_customers')) {
            return [
                {
                    name: intl.formatMessage({ id: 'general.my_organizations' }),
                    path: backPath === 'org' ? '/user/organizations' : '/users',
                }
            ]
        }
        if (customerId && customer) {
            return [
                {
                    name: intl.formatMessage({ id: 'general.customers' }),
                    path: '/customers',
                }
            ]
        }
    };

    const getColumns = () => {
        if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN') {
            return columns;
        }

        return columns.filter((el: any) => el.dataIndex !== 'organizationName');
    }

    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/users/custom-fields/list', [], 'GET');
            setCustomFields(customFields.fields);
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    const handleFilter = (filter: string) => {
        if (activeOrganizationType === 'CUSTOMER' && !filter.includes(`filter[customerId]=${activeOrganizationId}`)) {
            return filter + `&filter[customerId]=${activeOrganizationId}&filter[filterBy]=CUSTOMER`;
        }

        return filter;
    }

    return (
        <Spinner spinning={isCustomerLoading}>
            <DefaultLayout.PageLayout withoutPageLayout={hideHeader}>
                {!hideHeader &&
                    <>
                        {customerId ?
                            <>
                                {customer &&
                                    <DefaultLayout.PageHeader
                                        breadcrumb={getBreadcrumbs()}
                                        title={customerId ? customer.name : intl.formatMessage({ id: 'general.users' })}
                                    />
                                }
                            </>
                            :
                            <DefaultLayout.PageHeader
                                title={intl.formatMessage({ id: 'general.users' })}
                            />
                        }
                    </>
                }

                <div style={!hideHeader ? { backgroundColor: 'white', padding: 16, borderRadius: 8, marginBottom: 80 } : {}}>
                    <UsersListToolbar
                        setSearch={setSearch}
                        setFilter={setFilter}
                        filter={filter}
                        setReload={setReload}
                        canAddLearningManagers={canAddLearningManagers}
                        activeOrganizationId={activeOrganizationId}
                        setActiveOrganizationId={setActiveOrganizationId}
                        activeOrganizationType={activeOrganizationType}
                        setActiveOrganizationType={setActiveOrganizationType}
                        showFilter={showFilter}
                        userRole={userRole}
                        selectedRowKeys={selectedRowKeys}
                        setRowSelection={setRowSelection}
                        rowSelection={rowSelection}
                    />
                    {newUsers ?
                        <Alert style={{ marginTop: 5 }} closable message={
                            <div className='flex-between'>
                                <FormattedMessage id='general.new_users_added' values={{ users: newUsers }} />
                                <Button onClick={() => {
                                    setFilter('filter[newUsers]=true');
                                    setReload(true);
                                }}>
                                    <FormattedMessage id='general.filter_new_users' />
                                </Button>

                            </div>
                        } type='info'></Alert>
                        :
                        null
                    }
                    {!!filter &&
                        <Table
                            columns={getColumns()}
                            url={'api/v1/users/list'}
                            setReload={setReload}
                            reload={reload}
                            rowKey={'typeUuid'}
                            updateData={dataSource}
                            saveData={setDataSource}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            filter={handleFilter(filter)}
                            scroll={{ x: 800 }}
                            search={search}
                            customFields={customFields}
                            learningProgramCustomColumns={true}
                        />
                    }
                </div>
                {userRole !== 'SUPERVISION' &&
                    <DefaultLayout.PageFooter
                        left={
                            <UsersListFooter
                                activeOrganizationType={activeOrganizationType}
                                activeOrganizationId={activeOrganizationId}
                                activeOrganizationUuid={activeOrganizationUuid}
                                rowSelection={rowSelection}
                                setReload={setReload}
                                selectedRowKeys={selectedRowKeys}
                            />
                        }
                    />
                }
            </DefaultLayout.PageLayout>
        </Spinner>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);