import React from 'react';
import { Layout } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {SpecialClientEnumsType} from '../types'
import styles from '../styles.module.scss';

const { Footer } = Layout;

interface UnauthorizedFooterInterface {
    handleValues: Function;
}

const UnauthorizedFooter: React.FC<UnauthorizedFooterInterface> = ({ handleValues }) => {
    const navigate = useNavigate();

    const constTermsAndConditions = (url?: string) => {
        return (
            <div
                className={styles.pointer}
                onClick={() => url ? window.location.replace(url) : navigate('terms-and-conditions')}
            >
                <FormattedMessage id='login.terms_and_conditions' />
            </div>
        )
    }

    return (
        <Footer className={handleValues(SpecialClientEnumsType.design) + ' f-center ' + styles.footer}>
            <ul className={styles.footerList}>
                <li>{handleValues(SpecialClientEnumsType.env) ?? '© CloudStudy Ltd'}</li>
                <li>{constTermsAndConditions(handleValues(SpecialClientEnumsType.terms))}</li>
                <li><FormattedMessage id='login.cookies' /></li>
            </ul>
        </Footer>
    )
}

export default UnauthorizedFooter;
