import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from "components/DefaultLayout";
import useHandleError from "utils/useHandleError";
import { Button, Tabs } from "antd";
import {
    LearningProgramSettings,
    LearningProgramMaterials,
    LearningProgramCertificates,
    LearningProgramStudents,
    LearningProgramNotifications,
    LearningProgramAccess, LearningProgramHistory, LearningProgramOverview
} from "components/LearningProgram";
import coursesApiClient from "utils/coursesApiClient";
import Spinner from "components/Spinner";
import { clearActivityCreationSettings } from "store/actions/activityCreationModalActions";
import { useLanguage } from "hooks/Language/useLanguage";
import { LanguageInterface } from "hooks/Language/types";
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    clearActivityCreationSettings: () => dispatch(clearActivityCreationSettings()),
});

const LearningProgramList: React.FC<any> = ({ clearActivityCreationSettings }) => {
    const { locale } = useLocaleContext();
    const { getLanguages } = useLanguage();
    const [program, setProgram] = useState<any>();
    const [programLanguages, setProgramLanguages] = useState<LanguageInterface[] | []>([]);
    const [activeTabKey, setActiveTabKey] = useState('settings')
    const intl = useIntl();
    const params = useParams();
    const [handleError] = useHandleError();
    const programId = params.id;
    const navigate = useNavigate();
    const tab = params.tab;

    const [isLoading, setIsLoading] = useState(!!programId);
    const [isProgramStarting, setIsProgramStarting] = useState(false);

    useEffect(() => {
        if (programId) {
            loadProgram()
        }
    }, [programId])

    useEffect(() => {
        clearActivityCreationSettings();
        if (tab) {
            setActiveTabKey(tab)
        }
        loadLanguages()
    }, [])

    const loadLanguages = async () => {
        setProgramLanguages(await getLanguages());
    }

    const loadProgram = async () => {

        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}`, [], 'GET');
            
            if (!response.program.visualPermissions.edit || !response.program.visualPermissions.admin) {
                setActiveTabKey('students')
            }
            
            setProgram(response.program)

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const permissionCheck = (permissions: string[]) => {
        if (!programId) {
            return true;
        }

        if (permissions.includes('admin') && !!program.visualPermissions.admin) {
            return true;
        }

        if (permissions.includes('edit') && !!program.visualPermissions.edit) {
            return true;
        }

        if (permissions.includes('view') && !!program.visualPermissions.view) {
            return true;
        }

        return false;
    }

    const launchButton: any = (
        <>
            {!programId || (program?.id && permissionCheck(['edit', 'admin'])) ?
                <div className="form-buttons">
                    <Button
                        loading={isProgramStarting}
                        disabled={!programId || program?.status !== 'DRAFT'}
                        type="primary" onClick={() => launchProgram()}>
                        <FormattedMessage id="program.launch" />
                    </Button>
                </div>
                :
                <></>
            }
        </>
    )

    const launchProgram = async () => {
        try {
            setIsProgramStarting(true);
            const response = await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/launch`, [], 'POST');
            setProgram(response.program)
        } catch (error) {
            handleError(error)
        } finally {
            setIsProgramStarting(false);
        }
    }

    const tabChangeHandle = (tab: string) => {
        setActiveTabKey(tab)
        navigate(`/learning/programs/${programId}/${tab}`)
    }

    return (
        <Spinner spinning={isLoading}>
            <DefaultLayout.PageLayout>
                <DefaultLayout.PageHeader
                    title={programId && program ? program.title[locale] ? program.title[locale] : program.title[Object.keys(program.title)[0]] : intl.formatMessage({ id: 'general.learning_programs_form' })}
                    breadcrumb={[{
                        name: intl.formatMessage({ id: 'general.learning_programs' }),
                        path: '/learning/programs'
                    }]}
                />
                <DefaultLayout.PageContent withBottomMargin>
                    <Tabs onChange={tabChangeHandle} activeKey={activeTabKey}>
                        {programId && program && program?.status !== 'DRAFT' &&
                            <Tabs.TabPane key='overview' tab={intl.formatMessage({ id: 'campaign.overview' })}>
                                <LearningProgramOverview programId={program.id} />
                            </Tabs.TabPane>
                        }
                        {(!programId || (program?.id && permissionCheck(['edit', 'admin']))) &&
                            <Tabs.TabPane key='settings' tab={intl.formatMessage({ id: 'general.settings' })}>
                                <LearningProgramSettings programLanguages={programLanguages} program={program}
                                    launchButton={launchButton}
                                    setProgram={setProgram} />
                            </Tabs.TabPane>
                        }
                        <Tabs.TabPane
                            disabled={!programId}
                            key='materials'
                            tab={intl.formatMessage({ id: 'program.materials' })}
                        >
                            <LearningProgramMaterials program={program} launchButton={launchButton} />
                        </Tabs.TabPane>
                        <Tabs.TabPane disabled={!programId} key='students'
                            tab={intl.formatMessage({ id: 'program.students' })}>
                            <LearningProgramStudents program={program} launchButton={launchButton} />
                        </Tabs.TabPane>
                        {(!programId || (program?.id && permissionCheck(['edit', 'admin']))) &&
                            <Tabs.TabPane disabled={!programId} key='certificate'
                                tab={intl.formatMessage({ id: 'program.certificate' })}>
                                <LearningProgramCertificates program={program} launchButton={launchButton} />
                            </Tabs.TabPane>
                        }
                        {(!programId || (program?.id && permissionCheck(['edit', 'admin']))) &&
                            <Tabs.TabPane disabled={!programId} key='notifications'
                                tab={intl.formatMessage({ id: 'program.notifications' })}>
                                <LearningProgramNotifications program={program} launchButton={launchButton} />
                            </Tabs.TabPane>
                        }
                        {(!programId || (program?.id && permissionCheck(['edit', 'admin']))) &&
                            <Tabs.TabPane disabled={!programId} key='access'
                                tab={intl.formatMessage({ id: 'program.access' })}>
                                <LearningProgramAccess program={program} launchButton={launchButton} />
                            </Tabs.TabPane>
                        }
                        {(!programId || (program?.id && permissionCheck(['edit', 'admin']))) &&
                            <Tabs.TabPane disabled={!programId} key='logs' tab={intl.formatMessage({ id: 'program.logs' })}>
                                <LearningProgramHistory program={program} launchButton={launchButton} />
                            </Tabs.TabPane>
                        }
                    </Tabs>
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>
        </Spinner>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LearningProgramList);