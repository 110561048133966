import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Layout, Spin, message, Menu, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import apiClient from 'utils/apiClient';
import ContentCreateModal from 'components/Content/ContentCreateModal';
import { connect } from 'react-redux';
import FlexRow from 'components/FlexRow';
import { Switch } from 'components/Form';
import ContentFilterModal from 'components/Content/ContentFilterModal';
import Flag from 'components/Flags/FlagProps';

import './styles.scss'
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const ContentList: React.FC<any> = ({ props, session }: any) => {
    const { locale } = useLocaleContext();

    const [contentCategories, setContentCategories] = useState([]);
    const [content, setContent] = useState<any>();
    const [contentImages, setContentImages] = useState([]);
    const [showContentModal, setShowContentModal] = useState(false);
    const [validContent, setValidContent] = useState(true);
    const [showContentFilterModal, setShowContentFilterModal] = useState(false);
    const [selectedContentCategory, setSelectedContentCategory] = useState('');
    const [contentFilterCategories, setContentFilterCategories] = useState<any>([]);
    const [contentSelectedCategory, setContentSelectedCategory] = useState(1);

    // loaders
    const [viewLoading, setViewLoading] = useState(false);
    const [filterButtonLoading, setFilterButtonLoading] = useState(false);
    const [categoriesLoader, setCategoriesLoader] = useState(false);

    const { Header } = Layout;
    const { Sider } = Layout;
    const { Content } = Layout;
    const intl = useIntl();
    const userRole = session.active_user_type;

    useEffect(() => {
        const loadCategories = async () => {
            setCategoriesLoader(true);
            const response = await apiClient.request('/api/v1/content/categories', {}, 'GET');

            let array: any = [];

            Object.values(response.categories).map((category: any) => {
                if (category.name !== 'all_categories') {
                    array[category.id] = <FormattedMessage id={`licensing.content.` + category.name} />;
                }
                return array;
            });

            setContentFilterCategories(array);
            setSelectedContentCategory(response.categories[0].name);
            setContentCategories(response.categories);
            setCategoriesLoader(false);
        };

        const loadContent = async () => {
            setViewLoading(true);

            let response;
            try {
                response = await apiClient.request(`/api/v1/auth/content/${locale}`, { valid: true }, 'POST');
            } catch (error) {
                console.error(error);
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }

            if (response.content) {
                response.content.map((element: any) => {
                    getImgSrc(element.imgPath, element.id);
                    return null;
                });
            }

            setContent(response.content);
            setViewLoading(false);
        };

        loadContent();
        loadCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const getImgSrc = async (path: string, id: number) => {
        let image = null;

        if (path) {
            // requests image from backend path='/storage/... .png' no need for additional '/'
            image = await apiClient.request(`api/v1${path}`, {}, 'GET', true, true);
        }

        if (Promise.resolve(image) !== image && image !== null) {
            let url: string = URL.createObjectURL(image);
            if (typeof url === 'string') {
                setContentImages((contentImages: any) => ({ ...contentImages, [id]: url }));
            }
        } else {
            setContentImages((contentImages: any) => ({ ...contentImages, [id]: '' }));
        }
    };

    const loadContent = async (contentId: string) => {
        if (contentSelectedCategory === parseInt(contentId)) {
            return;
        }
        setViewLoading(true);

        let categoryName: any = contentCategories.filter((el: any) => el.id === parseInt(contentId));

        let response;
        let parsedValues;

        if (parseInt(contentId) === 1) {
            parsedValues = { valid: true };
        } else {
            parsedValues = { category: contentId };
        }

        try {
            response = await apiClient.request(`/api/v1/auth/content/${locale}`, parsedValues, 'POST');
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }

        response.content.map((element: any) => {
            getImgSrc(element.imgPath, element.id);
            return null;
        });

        setContentSelectedCategory(parseInt(contentId));
        setContent(response.content);
        setSelectedContentCategory(categoryName[0].name);
        setViewLoading(false);
    };

    const loadValidSwitch = async (boolean: boolean) => {
        let response = [];

        try {
            response = await apiClient.request(`/api/v1/auth/content/${locale}`, { valid: boolean }, 'POST');
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }

        response.content.map((element: any) => {
            getImgSrc(element.imgPath, element.id);
            return null;
        });

        setContent(response.content);
    };

    const filterContent = async (values: any) => {
        setFilterButtonLoading(true);
        setViewLoading(true);

        const parsedValues = {
            filterBy: values.filterBy,
            category: values.category,
            customers: values.customers,
            organization: values.organization,
            language: values.language
        };

        let response;

        try {
            setShowContentFilterModal(false);
            response = await apiClient.request(`/api/v1/auth/content/${locale}`, { filter: parsedValues }, 'POST');
            setFilterButtonLoading(false);
            setViewLoading(false);

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            setFilterButtonLoading(false);
            setViewLoading(false);
        }

        if (response.content) {
            response.content.map((element: any) => {
                getImgSrc(element.imgPath, element.id);
                return null;
            });
        }

        setContent(response.content);
    };

    return (
        <>
            <Spin spinning={categoriesLoader} >
                <Layout className='content-layout'>
                    <Sider
                        width={240}
                    >
                        <div className="content-sidebar">
                            {contentCategories &&
                                <Menu
                                    theme="light"
                                    defaultOpenKeys={['1']}
                                    defaultSelectedKeys={['1']}
                                >
                                    {
                                        userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'SUPER_ADMIN' ?
                                            <Button type="primary" style={{ margin: '122px 20px 15px 20px' }} onClick={() => setShowContentModal(true)}>
                                                <PlusOutlined /> <FormattedMessage id="licensing.content.add_new_content" />
                                            </Button>
                                            : null
                                    }
                                    {
                                        userRole === 'CUSTOMER_ADMIN' || userRole === 'STUDENT' ?
                                            null
                                            :
                                            <hr className="form-group-separator" />
                                    }
                                    {
                                        userRole === 'CUSTOMER_ADMIN' &&
                                            <hr style={{marginTop: 170}} className="form-group-separator" />
                                    }
                                    {contentCategories &&
                                    Object.values(contentCategories).map((el: any) => {
                                        let key = (el.id).toString();
                                        return (
                                            <Menu.Item key={key} onClick={() => loadContent(key)} >
                                                {contentCategories &&
                                                    <FormattedMessage id={`licensing.content.${el.name}`} />
                                                }
                                            </Menu.Item>
                                        )
                                    })
                                    }
                                </Menu>
                            }
                        </div>
                    </Sider>
                    <Layout>
                        <Header className='request-content-page-header'>
                            <div style={{width: '100%'}}>
                                <FormattedMessage id="general.category" /> / <FormattedMessage id={"licensing.content." + selectedContentCategory} />
                            </div>
                            <FlexRow
                                left={
                                    <span style={{ display: 'block' }}>

                                        <div className='request-content-left-page-header'>
                                            <FormattedMessage id={"licensing.content." + selectedContentCategory} />
                                        </div>
                                    </span>
                                }
                                right={
                                    <>
                                        {userRole !== 'CUSTOMER_ADMIN' && userRole !== 'STUDENT' &&
                                            (
                                                <>
                                                    <Button loading={filterButtonLoading} type="default" style={{ marginRight: 20 }} onClick={() => setShowContentFilterModal(true)}>
                                                        <i className="fal fa-filter"></i> <FormattedMessage id="general.filter" />
                                                    </Button>
													<label>{intl.formatMessage({ id: "licensing.content.show_valid_content" })}</label>
                                                    <Switch
                                                        name="showValidContent"
                                                        onChange={(boolean: boolean) => { loadValidSwitch(boolean); setValidContent(boolean) }}
                                                        defaultChecked={validContent}
                                                    />
                                                </>
                                            )
                                        }
                                    </>
                                }
                            />
                            <hr className="form-group-separator" />
                        </Header>
                        <Content className='content-card-container'>
                            <Spin spinning={viewLoading}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '35px', marginLeft: '35px' }}>
                                    {content &&
                                        content.map((element: any) => {
                                        let loading = true;
                                        let src: string = '';
                                        src = contentImages[element.id];
                                        if (!element.imgPath) {
                                            src = process.env.PUBLIC_URL + '/logo512.png';
                                            loading = false;
                                        } else if (element.imgPath && src) {
                                            loading = false
                                        }

                                        return (
                                            <NavLink to={`content/${element.uuid}/view`} style={{ margin: '0px 15px 30px 15px' }}>
                                                <Card
                                                    hoverable
                                                    className='content-card'
                                                >
                                                    {
                                                        loading ?
                                                            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', height: 180 }}>
                                                                <Spin size='default' />
                                                            </div>
                                                            :
                                                            <img className='content-image' src={src} alt='' />
                                                    }
                                                    <span className='content-title'>{element.name.substring(0, 65) }<span> {element.name.length > 65 ? '...' : null} </span></span>
                                                    <hr className="form-group-separator" style={{ marginBottom: 5 }} />
                                                    <FlexRow
                                                        size={4}
                                                        left={element.totalMinutes ?
                                                            <span className='content-settings'>
                                                                <i className="fal fa-clock"></i> {element.totalMinutes} <FormattedMessage id="general.minutes" />
                                                            </span>
                                                            :
                                                            <span className='content-settings'>
                                                                <i className="fal fa-user-plus"></i>
                                                            </span>
                                                        }
                                                        right={
                                                            <span className='content-settings-flags'>
                                                                {element.languages &&
                                                                Object.values(element.languages).map((el: any) => {
                                                                    return <Flag languageCode={el} />
                                                                })
                                                                }
                                                            </span>
                                                        }
                                                    />
                                                </Card>
                                            </NavLink>
                                        )
                                    })
                                    }
                                </div>
                            </Spin>
                        </Content>
                    </Layout>
                </Layout>
            </Spin>
            {userRole === 'CUSTOMER_ADMIN' || userRole === 'STUDENT' ?
                null
                :
                <>
                    <ContentCreateModal
                        visible={showContentModal}
                        onCancel={() => setShowContentModal(false)}
                        afterSubmit={() => setShowContentModal(false)}
                    />
                    <ContentFilterModal
                        visible={showContentFilterModal}
                        onCancel={() => setShowContentFilterModal(false)}
                        afterSubmit={() => setShowContentFilterModal(false)}
                        contentCategories={contentFilterCategories}
                        filterContent={filterContent}
                    />
                </>

            }

        </>
    );
}

export default connect(mapStateToProps)(ContentList);


