import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {message} from 'antd';

import HandoutsModal from './HandoutsModal';
import {config} from 'config/config';
import {useLocaleContext} from 'context/LocaleContext';
import {changeScormData} from 'store/actions/scromData';
import {Button} from 'ui';
import useQueryApiClient from 'utils/useApiClient';

const mapStateToProps = (state: any) => ({
    session: state.session,
    activityList: state.campaignActivityList
});

const mapDispatchToProps = (dispatch: any) => ({
    changeScormData: (formData: any) => dispatch(changeScormData(formData))
});

const Buttons: React.FC<any> = ({material, changeScormData}) => {
    const {locale} = useLocaleContext();
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);

    const intl = useIntl();

    const {appendData: beginActivity, isLoading} = useQueryApiClient({
        request: {
            url: `/api/v1/courses/student/learning-program/${material.id}/begin`,
            method: 'POST',
            baseUrl: config.api.coursesUrl
        },
        onSuccess: (response: any, passOnSuccess: any): void => {
            changeScormData({
                learningActivityId: response.learningActivityId,
                activityAttemptId: response.activityAttemptId,
                programActivityAttemptId: response.programActivityAttemptId,
                materialType: response.materialType,
                type: passOnSuccess.type,
                language: locale,
                learningType: 'PROGRAM'
            })

            if (response?.message) {
                message.error(intl.formatMessage({ id: response.message }));
                return;
            }

            setTimeout(function () {
                if (passOnSuccess.isSafari) {
                    passOnSuccess.newWindow.location.href = '/student/show-activity';
                } else {
                    window.open('/student/show-activity');
                }
            }, 100);
        }
    });

    const beginLearning = async (type: 'test' | 'learn') => {
        const userAgent = navigator.userAgent;
        const isSafari = userAgent.includes('Safari') && !userAgent.includes('Chrome');

        if (isSafari) {
            const newWindow = window.open('', '_blank');
            beginActivity({type, language: locale}, null, {type, isSafari, newWindow});
        } else {
            beginActivity({type, language: locale}, null, {type, isSafari});
        }
        
    }

    const getButtonTitle = (type: 'test' | 'learn') => {
        if (type === 'learn') {
            switch (material?.learningType) {
                case 'ASSESSMENT':
                    return 'general.view';

                case 'ASSIGNMENT':
                    if (!['NOT_SUBMITTED', 'RETURNED'].includes(material.status)) {
                        return 'general.overview'
                    }
                    return 'student.start_learning'

                default:
                    return 'student.start_learning'
            }
        }

        if (material?.testType === 'LEARNING_TEST' && material.status === 'PASSED') {
            return 'learning_tests.test_overview';
        }

        return 'student.start_test';
    }

    const getTestAccess = () => {
        if (material.testType === 'LEARNING_TEST') {

            if (material.status === 'PASSED' && !!material.allowTestReview && !!material.accessAfterPass) {
                return false
            }

            if (material.status === 'PASSED' && !material.allowTestReview) {
                return true
            }

            if (material.testRepeats < material.learningTestAttempts) {
                return true
            }
        }

        if (material.testType === 'SCORM_TEST') {
            if (material.status === 'PASSED' && !material.accessAfterPass) {
                return true
            }

            if (material.testRepeats + material.lastAttempt <= material.testAttempt) {
                return true
            }
        }

        return material.buttonTest === 'disabled'
    }

    const getLearningAccess = () => {
        if (material.learningStatus === 'PASSED' && !material.accessAfterPass) {
            return true;
        }

        return material.buttonLearn === 'disabled'
    }

    return (
        <>
            {!!material?.skip && (
                <Button
                    loading={isLoading} type={'primary'}
                    onClick={() => setIsDownloadModalVisible(true)}
                >
                    <FormattedMessage id={'courses.material'}/>
                </Button>
            )}
            {!!material.hasLearning && (
                <Button
                    className={'learning-button'}
                    disabled={getLearningAccess()}
                    loading={isLoading} type={'primary'}
                    onClick={() => beginLearning('learn')}
                >
                    <FormattedMessage id={getButtonTitle('learn')}/>
                </Button>
            )}
            {!!material.hasTest && (
                <Button
                    className={'test-button'}
                    disabled={getTestAccess()}
                    loading={isLoading} type={'primary'}
                    onClick={() => beginLearning('test')}
                >
                    <FormattedMessage id={getButtonTitle('test')}/>
                </Button>
            )}

            {material?.skip && (
                <HandoutsModal
                    visible={isDownloadModalVisible}
                    onCancel={setIsDownloadModalVisible}
                    files={material.files}
                />
            )}
        </>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)((Buttons));
