import {Spin, Table as AntDTable} from 'antd';
import type {TablePaginationConfig} from 'antd/es/table';
import type {SorterResult} from 'antd/es/table/interface';
import React, {useEffect, useState} from 'react';
import apiClient from 'utils/apiClient';
import qs from 'qs';
import moment from "moment";
import {config} from "config/config";
import Switch from "components/Form/Switch";
import {useIntl} from "react-intl";
import generalHelpers from "utils/generalHelpers";

interface Params {
    pagination?: TablePaginationConfig;
    sorter?: SorterResult<any> | SorterResult<any>[];
    total?: number;
    sortField?: string;
    sortOrder?: string;
}

const getData = (params: Params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
});

const Table: React.FC<any> = ({
                                  columns,
                                  url,
                                  setReload,
                                  reload,
                                  rowKey = 'id',
                                  selectedRowKeys,
                                  setSelectedRowKeys,
                                  saveData,
                                  rowSelection = true,
                                  setTotalRecords,
                                  silentReload = false,
                                  showTotalResults = false,
                                  isLoading = false,
                                  manualDataUpdate = false,
                                  updateData,
                                  selectionProps = {},
                                  onLoadFunction = false,
                                  search = '',
                                  searchKey = 'q',
                                  useFilters = false,
                                  filter = '',
                                  components,
                                  learningProgramCustomColumns,
                                  customFields,
                                  hasCustomSorter = false,
                                  hasCustomFields = false,
                                  defaultFields = [],
                                  setColumns,
                                  fetchOnLoad = true,
                                  additionalSelect,
                                  additionalSelectKey,
                                  wholeRow = false,
                                  ...restProps
                              }) => {
    const [data, setData] = useState<any>();
    const [tableColumns, setTableColumns] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [displayTable, setDisplayTable] = useState(false);
    const showTotal: any = (total: number, range: number[]) => `${range[0]}-${range[1]} of ${total}`;
    const intl = useIntl();

    const pageSizeArray = (config.api.environment === 'development') ? [1, 10, 30, 50, 100] : [10, 30]

    const defaultPageSize = 30;

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        showTotal: showTotalResults ? showTotal : null,
        current: 1,
        pageSize: defaultPageSize,
        pageSizeOptions: pageSizeArray,
        showSizeChanger: true,
    });

    useEffect(() => {
        if (manualDataUpdate) {
            setData(updateData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualDataUpdate])

    useEffect(() => {
        if (columns) {
            setTableColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns])

    useEffect(() => {
        handleCustomFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (reload) {
            fetchData({
                pagination: {
                    current: 1,
                    pageSize: defaultPageSize,
                    pageSizeOptions: pageSizeArray,
                    showSizeChanger: true,
                }
            });
            if (setSelectedRowKeys) {
                setSelectedRowKeys([])
            }
            setReload(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    useEffect(() => {
        if (fetchOnLoad) {
            fetchData({pagination});
        }

        if (!fetchOnLoad) {
            setDisplayTable(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
        if (additionalSelect && !wholeRow) {
            additionalSelect(generalHelpers.pluck(selectedRows, additionalSelectKey))
        }

        if (additionalSelect && wholeRow) {
            additionalSelect(selectedRows)
        }
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const fetchData = async (params: Params = {}) => {
        if (!silentReload) {
            setLoading(true);
        }

        let requestUrl = `${url}?${qs.stringify(getData(params))}`

        if (search) {
            requestUrl += `&${useFilters ? `filter[${searchKey}]` : [searchKey]}=${search}`;
        }

        if (typeof filter === 'string') {
            requestUrl += `&${filter}`;
        } else if (filter && !generalHelpers.isEmpty(filter)) {
            requestUrl += `&${generalHelpers.jsonToQueryString(filter)}`;
        }

        try {
            let response = await apiClient.request(requestUrl, [], 'GET');
            if (onLoadFunction) {
                onLoadFunction(response)
            }

            if (defaultFields.length && response.data) {
                await handleCustomColumns(response.data)
            }

            setData(response.data);

            if (saveData) {
                saveData(response.data)
            }

            if (response?.pagination) {
                setPagination({
                    ...params.pagination,
                    showTotal: showTotalResults ? showTotal : null,
                    total: response.pagination.total ?? 0,
                });
            }

            if (setTotalRecords) {
                setTotalRecords(response.pagination.total)
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setDisplayTable(true)
        }
    };

    const handleTableChange = (options: any, sortField: any, sortOrder: any) => {
        fetchData({
            pagination: options,
            sortOrder: sortOrder
        });
    };

    const handleCustomColumns = async (responseData: any) => {
        let customColumns: any = [];
        Object.values(responseData).map((el: any) => {
            return Object.entries(el).map((ff: any) => {
                if (!defaultFields.includes(ff[0])) {
                    return customColumns[ff[0]] = {
                        title: ff[0],
                        value: ff[1]
                    }
                }
            })
        })

        const parsedCustomColumns = Object.values(customColumns).map((element: any) => ({
            title: element.title,
            dataIndex: element.title,
            key: element.title,
            sorter: true,
            render: (_text: string, record: any) => {
                if (element.value?.type === 'DATE') {
                    return moment(record[element.title]).format(config.defaultDateFormat)
                } else if (record[element.title] && element.value?.type === 'SWITCH') {
                    return !!record[element.title].value ? 'TRUE' : 'FALSE'
                } else if (record[element.title]) {
                    return record[element.title].value
                } else {
                    return null
                }
            }
        }))

        setTableColumns([...columns, ...parsedCustomColumns])

        if (setColumns) {
            setColumns([...columns, ...parsedCustomColumns])
        }
    }

    const handleCustomFields = () => {
        if (learningProgramCustomColumns && data !== undefined) {
            let actions_tmp = {};
    
            if (customFields.length > 0 && Object.entries(data).length > 0) {
    
                const updatedColumns = [...columns];
    
                for (let k in updatedColumns) {
                    if (updatedColumns[k].key === 'actions') {
                        actions_tmp = updatedColumns[k];
                        delete updatedColumns[k];
                        break;
                    }
                }
    
                customFields.map((field: any) => {
                    return Object.entries(data[0]).map((props: any) => {
    
                        if (field.title !== props[0] && generalHelpers.camelize(field.title) !== props[0]) {
                            return;
                        }
    
                        let column = {
                            title: field.title,
                            sorter: hasCustomSorter,
                            key: field.uuid,
                            render: (record: any) => {
                                return (record[props[0]] !== null) ? record[props[0]] : '-';
                            },
                        };
    
                        switch (field.field_type) {
                            case 'SWITCH':
                                column.render = (record: any) => {
                                    return (<Switch disabled={true} defaultChecked={!!record[props[0]]}/>)
                                };
                                break;
                            case 'DATE':
                                column.render = (record: any) => {
                                    return record[props[0]] ? moment(record[props[0]]).format(config.defaultDateFormat) : '-'
                                };
                                break;
                            case 'MULTIPLE_CHOICE':
                                column.render = (record: any) => {
                                    if (record[props[0]]) {
                                        return JSON.parse(record[props[0]]).map((value: string) => <li>{value}</li>);
                                    }
                                    return '-'
                                };
                                break;
                            case 'HYPERLINK':
                                column.render = (record: any) => {
                                    if (record[props[0]]) {
                                        const params = JSON.parse(record[props[0]]);
    
                                        return (
                                            <div className={'hyperlink-tag pointer'}
                                                 onClick={() => window.open(params.hyperlink, "_blank")}>
                                                {params.displayName}
                                            </div>
                                        )
                                    }
                                    return '-'
                                };
                                break;
                        }
    
                        return updatedColumns.push(column);
                    });
                });
    
                if (actions_tmp) {
                    updatedColumns.push(actions_tmp);
                }
    
                setTableColumns(updatedColumns)
            }
        }
    }

    return (
        <>
            {displayTable ?
                <AntDTable
                    columns={tableColumns}
                    locale={{emptyText: intl.formatMessage({id: "general.found_no_data"})}}
                    rowKey={record => record[rowKey]}
                    dataSource={data}
                    onHeaderRow={() => {
                        return {
                            onClick: () => {
                                if (config.api.environment === 'development' && setReload) {
                                    setReload(true)
                                }
                            },
                        };
                    }}
                    rowSelection={
                        rowSelection
                            ? {
                                ...selectionProps,
                                selectedRowKeys,
                                onChange: onSelectChange,
                                preserveSelectedRowKeys: true,
                            }
                            : undefined // {} is not valid
                    }
                    pagination={pagination}
                    loading={isLoading ? isLoading : loading}
                    components={components ? components : undefined}
                    onChange={(options: any, sortField: any, sortOrder: any) => handleTableChange(options, sortField, sortOrder)}
                    {...restProps}
                />
                :
                <div className={'flex justify-center'}><Spin/></div>
            }
        </>
    );
};

export default Table;