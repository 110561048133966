import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {message, Modal, Upload, Form} from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import {config} from 'config/config';
import {Select} from "../Form";
import {connect} from "react-redux";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

const formLayout = {
    labelCol: {span: 7},
    wrapperCol: {span: 17},
};

const CertificateImportModal: React.FC<any> = ({session, visible, onCancel, afterSubmit}) => {
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [importFileList, setImportFileList] = useState<any>([]);
    const [customerId, setCustomerId] = useState<string>()

    const {Dragger} = Upload;

    const intl = useIntl();
    const [importForm] = Form.useForm();

    useEffect(() => {
        setCustomerId(session.active_user_type === 'CUSTOMER_ADMIN' ? session.organization.organization_uuid : undefined)
    }, [session])

    const normFile = (e: any) => {
        let fileList = [...e.fileList];

        fileList = fileList.slice(-1);
        setImportFileList(fileList);
        if (Array.isArray(e)) {
            return e;
        }
        return e && fileList;
    };

    const submitForm = async (values: any) => {
        let file: any;

        if (values && values.dragger) {
            file = values.dragger[0];
        }
        if (!file) {
            return message.error(intl.formatMessage({id: "general.certificates_no_file"}));
        }

        let customerUuid: any;
        if (session.active_user_type === 'CUSTOMER_ADMIN') {
            customerUuid = customerId;
        } else {
            customerUuid = values.customer;
        }

        let bodyFormData = new FormData();
        bodyFormData.append('file', file.originFileObj);
        bodyFormData.append('customerUuid', customerUuid);

        try {
            setSubmitInProgress(true);

            let response = await coursesApiClient.request('/api/v1/courses/certificates/import-validate', bodyFormData, 'POST');

            if (!response) {
                message.error(intl.formatMessage({id: "general.certificates_upload_unsuccessful"}));
            }

            if (response.message !== 'import_failed') {
                let response = await coursesApiClient.request('/api/v1/courses/certificates/import', bodyFormData, 'POST');
            }

            if (response.message === 'import_failed') {
                Modal.info({
                    title: intl.formatMessage({id: "general.attention"}),
                    content: (
                        <>
                            <p>{intl.formatMessage({id: "general.certificates_upload_unsuccessful"})}</p>

                            {Object.values(response.errors).map((error: any) => (
                                <>
                                    <p>{intl.formatMessage({id: "general.certificate_error_row"}, values = {
                                        row: error.row,
                                        column: parseInt(error.column) + 1
                                    })}</p>
                                    {Object.values(error.errors).map((msg: any) => (
                                        msg.startsWith('custom.') && <p>{intl.formatMessage({id: msg.replace('custom.', '')})}</p>
                                    ))}
                                </>
                            ))}

                            
                        </>
                    ),
                });
            } else {
                Modal.info({
                    title: intl.formatMessage({id: "general.attention"}),
                    content: intl.formatMessage({id: "general.certificates_import_message"}),
                });
            }
            setSubmitInProgress(false);
        } catch (err: any) {
            if (err.message && err.message === 'dont_have_permission_to_access') {
                Modal.info({
                    title: intl.formatMessage({id: "general.attention"}),
                    content: intl.formatMessage({id: "general.certificates_no_rights"}),
                });
            } else {
                message.error(intl.formatMessage({id: "general.certificates_upload_unsuccessful"}));
            }
            setImportFileList([]);
        } finally {
            setImportFileList([]);
            setSubmitInProgress(false);
            importForm.setFieldsValue({
                dragger: null
            });
            afterSubmit();
        }
    };

    //default dragger upload request prevention
    const customerDraggerRequest = ({onSuccess}: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <Form form={importForm} onFinish={submitForm}>
            <Modal
                title={intl.formatMessage({id: 'general.certificates_import'})}
                open={visible}
                onOk={() => {
                    importForm.submit();
                }}
                onCancel={onCancel}
                okText={intl.formatMessage({id: 'users.import.import'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
            >
                <Spinner spinning={submitInProgress}>
                    <p><FormattedMessage id="general.certificates_upload_description_p1"/></p>
                    <p><FormattedMessage id="general.certificates_upload_description_p2"/></p>

                    <p>
                        <FormattedMessage id="general.certificates_upload_template"/>
                        <a
                            href={config.api.frontendUrl + '/CloudStudy-certificate-import.xlsx'}
                            download
                        >
                            CloudStudy-certificate-import.xlsx
                        </a>
                    </p>

                    {
                        session.active_user_type !== 'CUSTOMER_ADMIN' ?
                            <Select
                                name={'customer'}
                                label={intl.formatMessage({id: 'general.organization'})}
                                allowClear
                                dataKey='customers'
                                url={config.api.routes.backend.selectCustomersUuid}
                                validation={{required: true}}
                                onChange={(id: any) => {
                                    setCustomerId(id)
                                }}
                                customLayout={formLayout}
                            />
                            : null
                    }

                    <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile}
                               className={'dragger-style'}>
                        <Dragger
                            customRequest={({onSuccess, file}) => customerDraggerRequest({onSuccess, file})}
                            name="file"
                            multiple={false} accept=".xlsx, .xls"
                        >
                            <div>
                                <FormattedMessage id="general.certificates_upload"/>
                                <div className="upload-file user-name-style">
                                    <FormattedMessage id="users.choose_file"/>
                                </div>
                            </div>
                            <div>
                                <i className='fal fa-file-upload'/>
                                <p className='ant-upload-text'>
                                    <FormattedMessage id='users.import.click_or_drag_file_to_this_area_to_upload'/>
                                </p>
                            </div>
                        </Dragger>
                    </Form.Item>
                </Spinner>
            </Modal>
        </Form>
    )
};

export default connect(mapStateToProps)(CertificateImportModal);