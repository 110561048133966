import React, { useState } from 'react';
import { Dropdown, Input, message, Menu, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import coursesApiClient from 'utils/coursesApiClient';
import debounce from 'lodash/debounce';
import FileDownload from 'js-file-download';
import styles from './styles.module.scss';

interface FileTableInterface {
    date?: boolean;
    fileArray: any;
    reload?: Function;
    description?: boolean;
    isAssignment?: boolean;
    assignmentId?: number;
    allowAction?: boolean;
    dateFormatter?: any;
    student?: number;
    allowDelete?: boolean;
    setActivityFormData?: any;
    activityFormData?: any;
    courseId?: number;
    activityId?: number;
}

const FileTable: React.FC<FileTableInterface> = ({ 
    fileArray, reload, description = false, isAssignment = false,
    assignmentId, allowAction = false, dateFormatter,
    date = false, student, allowDelete = true,
    setActivityFormData = null, activityFormData = null,
    courseId, activityId,
}) => {
    const [selectedFile, setSelectedFile] = useState<any>();

    const intl = useIntl();
    const { confirm } = Modal;

    const getFileType = (fileName: string) => {
        const fileNameArray = fileName.split('.');

        return fileNameArray[fileNameArray.length - 1].toUpperCase();
    }

    function getFileSize(size: number) {
        var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var i = 0;
        while (size >= 1024) {
            size /= 1024;
            ++i;
        }
        return size.toFixed(1) + ' ' + units[i];
    };

    const updateSettings = async (settings: any, id: number) => {
        if (!courseId || !activityId) {
            return;
        }
        try {
            await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/${id}`, settings, 'PUT');
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    const storeOptions = debounce(function (field: string, value: string, id: number) {
        updateSettings({ settings: { [field]: value }, type: 'FILE' }, id);
    }, 800);


    const content = (
        <Menu>
            <Menu.Item key="download" onClick={() => downloadFile()}>
                <FormattedMessage id="general.download" />
            </Menu.Item>
            {allowDelete &&
                <>
                    <Menu.Divider />
                    <Menu.Item key="delete" onClick={() => warnDeletion()}>
                        <FormattedMessage id="general.delete" />
                    </Menu.Item>
                </>
            }
        </Menu >
    );

    const downloadFile = async (path: string = '', title: string = '') => {
        if (!selectedFile && !path) {
            return;
        }
        try {
            let fileSettings

            if (!path) {
                if (isAssignment) {
                    fileSettings = selectedFile
                } else {
                    fileSettings = JSON.parse(selectedFile.fileSettings);
                }
            }

            let response = await coursesApiClient.request(`api/v1/courses/material/storage/${path ? path : fileSettings.file_path}`, {}, 'GET', true, true);
            FileDownload(response, title ? title : fileSettings.title);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    const warnDeletion = async () => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'courses.deletion_resource' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                deleteResource();
            }
        });
    }

    const deleteResource = async () => {

        if (student) {
            const response = await coursesApiClient.request(`/api/v1/courses/student/${student}/delete-file`, { filePath: selectedFile.file_path }, 'DELETE');
            setActivityFormData({ ...activityFormData, activityAttempt: response.activityAttempt })
            
        } else {
            if (isAssignment) {
                await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/${assignmentId}/delete-assignment-file`, { filePath: selectedFile.file_path }, 'DELETE');
            } else {
                await coursesApiClient.request(`/api/v1/courses/${courseId}/activities/${activityId}/material/${selectedFile.id}/delete`, {}, 'DELETE');
            }
        }

        if (reload) {
            reload(selectedFile.activityId);
        }
    }
    return (
        <>
            {fileArray &&
                <div className={styles.file}>
                    {Object.values(fileArray).map((file: any) => {

                        let fileSettings: any

                        if (isAssignment) {
                            fileSettings = file
                        } else {
                            fileSettings = JSON.parse(file.fileSettings);
                        }

                        let settings
                        if (file.settings) {
                            settings = JSON.parse(file.settings);
                        }

                        return (
                            <div className={styles.fileRow}>
                                <div className={styles.fileRowElement}>
                                    <div className={styles.fileRowLabel}>
                                        <FormattedMessage id='courses.material_title' />
                                    </div>
                                    <div className={styles.fileContentTitle}>
                                        <Tooltip placement="topLeft" title={fileSettings.title}>
                                            <i className={`${styles.fileContentIcon} fa-solid fa-file-lines`} />{fileSettings.title.split(150)}
                                        </Tooltip>
                                    </div>
                                </div>

                                {description &&
                                    <div className={styles.fileRowElement}>
                                        <div className={styles.fileRowLabel}>
                                            <FormattedMessage id='general.description' />
                                        </div>
                                        <div className={styles.fileContent}>
                                            {reload ?
                                                <Input maxLength={64} defaultValue={file.settings ? settings.description : ''} onBlur={(event: any) => reload ? storeOptions('description', event.target.value, file.id) : null} placeholder={intl.formatMessage({ id: 'courses.file_description' })} />
                                                :
                                                <div style={{ fontSize: 16, overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: settings && settings.description ? settings.description : '' }}></div>
                                            }
                                        </div>
                                    </div>
                                }
                                {date &&
                                    <div className={styles.fileRowElement}>
                                        <div className={styles.fileRowLabel}>
                                            <FormattedMessage id='general.upload_date' />
                                        </div>
                                        <div className={styles.fileContent}>
                                            {dateFormatter(file.date)}
                                        </div>
                                    </div>
                                }

                                <div className={styles.fileRowDetailsElement}>
                                    <div className={styles.fileRowLabel}>
                                        <FormattedMessage id='general.type' />
                                    </div>
                                    <div className={styles.fileDetails}>
                                        {getFileType(fileSettings.title)}
                                    </div>
                                </div>

                                <div className={styles.fileRowDetailsElement}>
                                    <div className={styles.fileRowLabel}>
                                        <FormattedMessage id='general.file_size' />
                                    </div>
                                    <div className={styles.fileDetails}>
                                        {getFileSize(fileSettings.file_size)}
                                    </div>
                                </div>

                                <div className={styles.fileRowElement}>
                                    <div className={styles.fileRowLabel}>
                                    ‎
                                    </div>
                                    <div className={styles.fileAction} onClick={() => setSelectedFile(file)}>
                                        {allowAction ?
                                            <Dropdown overlay={content} trigger={['click']}>
                                                <i className={`${styles.fileActionIcon} fa-solid fa-ellipsis-vertical`} />
                                            </Dropdown>
                                            :
                                            <div className={styles.fileActionDownload} onClick={() => downloadFile(fileSettings.file_path, fileSettings.title)}>
                                                <FormattedMessage id='general.download' />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </>
    )
};

export default FileTable;
