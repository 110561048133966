import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { message, Button, Form, Table } from 'antd';
import {TextArea} from 'components/Form';
import useQueryApiClient from 'utils/useApiClient';
import '../styles.scss';
import moment from 'moment';


interface AssessmentOverviewInterface {
    activityFormData: any;
    submission: any;
    loadAssignment: Function;
    setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitLoading: boolean;
    getStatusTag: Function;
}

const AssessmentOverview: React.FC<AssessmentOverviewInterface> = ({
    activityFormData,
    submission,
    loadAssignment,
    setIsSubmitLoading,
    isSubmitLoading,
    getStatusTag
}) => {
    const [overviewRows, setOverviewRows] = useState<any[]>([]);
    const [selectedInstructor, setSelectedInstructor] = useState<any>(null);

    const intl = useIntl();
    const [form] = Form.useForm();

    const parseInitiateData = (initiateData: any) => ({
        status: initiateData.isInitiated,
        title: initiateData.isInitiated ? intl.formatMessage({id: 'assessment.assessment_initiated'}) : intl.formatMessage({id: 'assessment.waiting_initiate'}),
        tagline: initiateData.initiatedDate,
        initiator: initiateData.initiatedByUser && `${initiateData.initiatedByUser.name} ${initiateData.initiatedByUser.surname} `,
        comments: null,
        actions: initiateData.canInitiate && {
            message: intl.formatMessage({id: 'assessment.initiate_assessment'}),
            onClick: () => {
                setIsSubmitLoading(true)
                initiateAssessment()
            }
        }
    })

    const parseInstructorData = (instructorData: any) => ({
        status: instructorData.isSubmitted,
        title: instructorData.instructorUser && `${instructorData.instructorUser.name} ${instructorData.instructorUser.surname}`,
        tagline: instructorData.submittedDate,
        initiator: null,
        comments: instructorData.comments,
        actions: instructorData.canSubmit && !instructorData.isSubmitted && {
            message: intl.formatMessage({id: 'assessment.submit_comment'}),
            onClick: () => setSelectedInstructor(instructorData)
        }
    })

    const parseSubmitData = (finalAssessment: any) => ({
        status: finalAssessment.isSubmitted,
        title: finalAssessment.isSubmitted ? intl.formatMessage({id: 'assessment.final_assessment'}) : intl.formatMessage({id: 'courses.topic_status.final_assessment'}),
        tagline: finalAssessment.gradedDate,
        initiator: finalAssessment.gradedByUser && `${finalAssessment.gradedByUser.name} ${finalAssessment.gradedByUser.surname} `,
        comments: null,
        actions: null
    })

    useEffect(() => {
        if(submission && activityFormData.attemptData) {
            const initiateData = parseInitiateData(activityFormData.attemptData.initiateData)
            const instructorsData = activityFormData.attemptData.instructorsData.map((instructorData: any) => parseInstructorData(instructorData))
            const submitData = parseSubmitData(activityFormData.attemptData.finalAssessment)

            setOverviewRows([initiateData, ...instructorsData, submitData])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activityFormData])

    const {appendData: initiateAssessment} = useQueryApiClient({
        request: {
            url: `/api/v1/courses/assessment-results/initiate/${submission.id}`,
            method: 'POST'
        },
        onSuccess: (): void => {
            message.success(intl.formatMessage({id: 'assessment.initiated_successfully'}))
            loadAssignment()
        },
        onFinally: (): void => setIsSubmitLoading(false)
    });

    const {appendData: submitInstructor} = useQueryApiClient({
        request: {
            url: `/api/v1/courses/assessment-results/submit-instructor/${submission.id}`,
            method: 'POST'
        },
        onSuccess: (): void => {
            message.success(intl.formatMessage({id: 'courses.evaluation_submitted_success'}))
            loadAssignment()
        },
        onFinally: (): void => {
            setIsSubmitLoading(false)
            setSelectedInstructor(null)
        }
    });

    const submitForm = async (values: any) => {
        setIsSubmitLoading(true)
        submitInstructor({
            instructorId: selectedInstructor.instructorUser.typeId,
            comments: values?.instructorComments
        })
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'assessment.final_assessment'}),
            key: 'status',
            render: () => getStatusTag()
        },
        {
            title: intl.formatMessage({id: 'grading.grade'}),
            dataIndex: 'grade'
        },
        {
            title: intl.formatMessage({id: 'general.comments'}),
            dataIndex: 'comments'
        }
    ];

    return (
        <div className='assessment-overview'>
            {selectedInstructor === null && overviewRows.map((item: any, key: number) => (
                <div 
                    key={key} 
                    className={`assessment-row flex flex-wrap flex-align-center w-100 ${item.status ? 'is-submitted' : ''}`}
                >
                    <div className='row-icon'>
                        <i className="fa-light fa-circle-check"></i>
                    </div>
                    <div className='row-title'>
                        <h4>{item.title}</h4>
                        <div className='flex gap-10'>
                            {item.tagline && moment(item.tagline).format('DD/MM/YYYY HH:mm')}
                            {item.initiator && (
                                <span className='bold'>{item.initiator}</span>
                            )}
                        </div>
                    </div>
                    <div className='row-comments'>
                        {item.comments && <>
                            <h4><FormattedMessage id='general.comments' /></h4>
                            <div>{item.comments}</div>
                        </>}
                    </div>
                    <div className='row-actions'>
                        {item.actions && (
                            <Button
                                type='primary'
                                loading={isSubmitLoading}
                                onClick={item.actions.onClick}
                            >
                                {item.actions.message}
                            </Button>
                        )}
                    </div>
                </div>
                )
            )}
            {selectedInstructor !== null && (
                <Form className='assessment-instructor-comment-form' form={form} onFinish={submitForm}>
                    <TextArea
                        name='instructorComments'
                        label={intl.formatMessage({id: 'general.comments'})}
                        validation={{required: true}}
                        customLayout={{labelCol: 0, wrapperCol: 24}}
                        autoSize={{ minRows: 3 }}
                        showCount
                        maxLength={100}
                    />
                    <div className='flex gap-10 justify-end' style={{paddingTop: 12}}>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={isSubmitLoading}
                        >
                            <FormattedMessage id='assessment.submit_comment' />
                        </Button>
                        <Button
                            type='ghost'
                            htmlType='button'
                            onClick={() => setSelectedInstructor(null)}
                        >
                            <FormattedMessage id='general.cancel' />
                        </Button>
                    </div>
                </Form>
            )}
            {activityFormData.attemptData.finalAssessment.isSubmitted && (
                <Table
                    columns={columns}
                    pagination={false}
                    dataSource={[activityFormData.attemptData.finalAssessment]}
                />
            )}
        </div >
    )
}
export default AssessmentOverview;