import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { message, Button, Form, Input, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import useHandleError from "utils/useHandleError";
import apiClient from 'utils/apiClient';
import '../styles.scss';


interface AssessmentDescriptionInterface {
    activityFormData: any;
    assessmentSettings: any;
    submission: any;
    loadAssignment: Function;
    setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitLoading: boolean;
    session?: any;
}
const mapStateToProps = (state: any) => ({
    session: state.session,
});

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const AssessmentDescription: React.FC<AssessmentDescriptionInterface> = ({
    activityFormData,
    assessmentSettings,
    submission,
    loadAssignment,
    setIsSubmitLoading,
    isSubmitLoading,
    session
}) => {
    const [isEditDocuments, setIsEditDocuments] = useState<boolean>(false);
    const [documents, setDocuments] = useState<any>([]);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();

    useEffect(() => {
        if (activityFormData) {
            const documentList = activityFormData.attemptData?.documents.map((item: string) => {
                return {
                    link: item
                }
            }) 

            setDocuments(documentList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityFormData]);

    const submitDocumentsForm = async (values: any) => {
        const parsedValues = {
            documents: values?.documents,
        }

        try {
            setIsSubmitLoading(true);
            let response = await apiClient.request(`/api/v1/courses/assessment-results/save-documents/${submission.id}`, parsedValues, 'POST');
            message.success(intl.formatMessage({id: "courses.evaluation_submitted_success"}));
            setIsSubmitLoading(false);
            loadAssignment()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false);
        }
    };

    return (
        <div className='assessment-description'>
            <div className='assessment-description-content' dangerouslySetInnerHTML={{ __html: assessmentSettings.description }}></div>
            <h4><FormattedMessage id='assessment.assessment_documents' /></h4>
            <div className='separator' />
            {!isEditDocuments && <ul className='assessment-documents-list'>
                {activityFormData.attemptData.documents.map((item: string, key: number) => {
                    return (<li key={key}>
                        <a target='_blank' href={item}>{item}</a>
                        {activityFormData.attemptData.canUpdateDocuments && <Button type='link' className='p-0' onClick={() => setIsEditDocuments(true)}><FormattedMessage id='general.edit' /></Button>}
                    </li>)
                })}
            </ul>}
            {isEditDocuments && (
                <Form form={form} onFinish={submitDocumentsForm} initialValues={{
                    documents: documents
                }}>
                    <Form.List 
                        name="documents"
                        initialValue={documents}
                        >
                        {(fields, { add, remove }) => {
                        return (
                            <div className='mb-5'>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div className='flex gap-10' key={key}>
                                        <Form.Item {...formItemLayout} name={[name, 'link']} label={intl.formatMessage({ id: 'assessment.link_to_document' })} className='w-100' >
                                            <Input />
                                        </Form.Item>
                                        {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} className='mt-2' />}
                                    </div>
                                ))}
                                <Row>
                                    <Col span={4}></Col>
                                    <Col span={20}>
                                        <Button type="ghost" icon={<PlusOutlined/>} onClick={() => add()}>
                                            <FormattedMessage id="general.add" />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        );
                        }}
                    </Form.List>
                    <div className='flex justify-end gap-10 mt-3'><Button type='primary' loading={isSubmitLoading} onClick={() => form.submit()}><FormattedMessage id='general.save' /></Button> <Button type='ghost' htmlType='button' onClick={() => setIsEditDocuments(false)} className='mb-3'><FormattedMessage id='general.cancel' /></Button></div>
                </Form>
            )}
        </div >
    )
}
export default connect(mapStateToProps)(AssessmentDescription);