import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import Spinner from "../../../components/Spinner";
import useHandleError from "utils/useHandleError";
import QuestionEditView from "./QuestionEditView";
import QuestionVersionListModal from "./QuestionVersions/QuestionVersionListModal";
import QuestionVersionDetailModal from "./QuestionVersions/QuestionVersionDetailModal";

import './styles.scss';

interface QuestionEditInterface {
    questionId: number;
    saveQuestion: Function;
    isParentLoading: boolean;
    isSaveQuestionLoading: boolean;
    questionTypes: any;
    setOpenQuestionId: Function;
    setHasFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
    saveEvent: boolean;
    setSaveEvent: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentQuestionAnswers: any;
    loadQuestions: Function;
    questionIdPrefix: string|null;
}

const QuestionEdit: React.FC<QuestionEditInterface> = ({
    questionId,
    saveQuestion,
    isParentLoading,
    isSaveQuestionLoading,
    questionTypes,
    setOpenQuestionId,
    setHasFormChanged,
    saveEvent,
    setSaveEvent,
    loadQuestions,
    setCurrentQuestionAnswers,
    questionIdPrefix
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showVersionsModal, setShowVersionsModal] = useState<boolean>(false);
    const [versionDetailId, setVersionDetailId] = useState<number|null>(null);
 
    const showVersionListPopup = () => {
        setShowVersionsModal(true)
        setVersionDetailId(null)
    };

    const showVersionDetailPopup = (versionId: number) => {
        setShowVersionsModal(false)
        setVersionDetailId(versionId)
    };

    return (
        <Spinner spinning={isLoading || isParentLoading || isSaveQuestionLoading}>
            {questionId && <>
                <QuestionVersionListModal
                    open={showVersionsModal}
                    questionId={questionId}
                    onCancel={() => {
                        setShowVersionsModal(false)
                    }}
                    showVersionDetailPopup={showVersionDetailPopup}
                />
                <QuestionVersionDetailModal
                    open={versionDetailId !== null}
                    onCancel={() => {
                        setVersionDetailId(null)
                    }}
                    questionId={versionDetailId}
                    saveQuestion={saveQuestion}
                    isParentLoading={isParentLoading}
                    setHasFormChanged={setHasFormChanged}
                    saveEvent={saveEvent}
                    setSaveEvent={setSaveEvent}
                    isSaveQuestionLoading={isSaveQuestionLoading}
                    questionTypes={questionTypes}
                    showVersionListPopup={showVersionListPopup}
                    setOpenQuestionId={setOpenQuestionId}
                    loadQuestions={loadQuestions}
                    questionIdPrefix={questionIdPrefix}
                />
                <QuestionEditView
                    questionId={questionId}
                    saveQuestion={saveQuestion}
                    isParentLoading={isParentLoading}
                    isSaveQuestionLoading={isSaveQuestionLoading}
                    setHasFormChanged={setHasFormChanged}
                    saveEvent={saveEvent}
                    setCurrentQuestionAnswers={setCurrentQuestionAnswers}
                    setSaveEvent={setSaveEvent}
                    questionTypes={questionTypes}
                    showVersionListPopup={showVersionListPopup}
                    isVersionView={false}
                    questionIdPrefix={questionIdPrefix}
                />
            </>}
        </Spinner>
    );
};

export default QuestionEdit;
    