import React, {useState, useEffect} from 'react';
import { useIntl} from 'react-intl';
import { Modal} from 'antd';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import useHandleError from "utils/useHandleError";
import {Select} from "components/Form";
import {config} from 'config/config';
import { LocaleText } from 'ui';
import './styles.scss'

interface FilterModalInterface {
    open: boolean;
    onCancel: any;
    onFilter: Function;
}

const FilterModal: React.FC<FilterModalInterface> = ({
    open,
    onCancel,
    onFilter
}) => {
    const statusOptions = {SUBMITTED: 'SUBMITTED', 
        RETURNED: 'RETURNED',
        NOT_SUBMITTED: 'NOT_SUBMITTED',
        PASSED: 'PASSED',
        FAILED: 'FAILED',
        MISSED_DEADLINE: 'MISSED_DEADLINE',
        NOT_STARTED: 'NOT_STARTED',
        IN_PROGRESS: 'IN_PROGRESS',
        FINAL_ASSESSMENT: 'FINAL_ASSESSMENT'
        };
    const typeOptions = {ASSIGNMENT: 'ASSIGNMENT', ASSESSMENT: 'ASSESSMENT', CERTIFICATE: 'CERTIFICATE'};
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState(0);
    const [selectedOrganization, setSelectedOrganization] = useState(0);
    const [selectedType, setSelectedType] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(0);

    const intl = useIntl();
    const [handleError] = useHandleError();

    useEffect(() => {
        if(open) {
            // loadFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const loadFilters = async () => {
        try {
            setIsLoading(true);
            const response = await apiClient.request(`/api/v1/courses/grading/get-filters`, {}, 'GET');
            setIsLoading(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            className="filter-modal"
            title={intl.formatMessage({id: 'general.filter'})}
            open={open}
            onOk={() => {
                onFilter({
                    program_id: selectedProgram,
                    organization_uuid: selectedOrganization,
                    type: selectedType,
                    status: selectedStatus,
                })
            }}
            onCancel={onCancel}
            okText={intl.formatMessage({id: 'general.filter'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
        >
            <Spinner spinning={isLoading}>
                <div className='grading-filters'>
                    <Select
                        label={intl.formatMessage({id: 'general.learning_program'})}
                        allowClear
                        showSearch
                        isFormItem={true}
                        dataKey='programs'
                        url={'/api/v1/courses/learning-program/options/list'}
                        integerKey={true}
                        customLayout={{labelCol: 10, wrapperCol: 14}}
                        customObjLabel={(el: any) => <LocaleText text={el} />}
                        dropdownStyle={{width: "100%"}}
                        onChange={(programId: number) => setSelectedProgram(programId)}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                    />
                    <Select
                        label={intl.formatMessage({id: 'general.organization'})}
                        allowClear
                        showSearch
                        dataKey='customers'
                        url={config.api.routes.backend.selectCustomersUuid}
                        customLayout={{labelCol: 10, wrapperCol: 14}}
                        dropdownStyle={{ width: "100%" }}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        onChange={(value: any, options: any) => setSelectedOrganization(options.value)}
                    />
                    <Select
                        label={intl.formatMessage({id: 'general.type'})}
                        allowClear
                        showSearch
                        customLayout={{labelCol: 10, wrapperCol: 14}}
                        dropdownStyle={{ width: "100%" }}
                        manualOptions={typeOptions}
                        onChange={(value: any, options: any) => setSelectedType(value)}
                    />
                    <Select
                        label={intl.formatMessage({id: 'general.status'})}
                        allowClear
                        showSearch
                        customLayout={{labelCol: 10, wrapperCol: 14}}
                        dropdownStyle={{ width: "100%" }}
                        manualOptions={statusOptions}
                        onChange={(value: any, options: any) => setSelectedStatus(value)}
                    />
                </div>
            </Spinner>
        </Modal>
    )
};

export default FilterModal;
