import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Form} from 'antd';
import useHandleError from 'utils/useHandleError';
import {config} from 'config/config';
import {Modal,} from 'ui'
import {connect} from "react-redux";
import coursesApiClient from "utils/coursesApiClient";
import {useNavigate} from "react-router-dom";
import apiClient from "utils/apiClient";
import {Select, Input} from "components/Form";
import {changeActivityCreationSettings} from "store/actions/activityCreationModalActions";
import { useLocaleContext } from 'context/LocaleContext';

interface ActivityCreationModalInterface {
    visible: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    program: any;
    changeActivityCreationSettings: any
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    changeActivityCreationSettings: (data: any) => dispatch(changeActivityCreationSettings(data)),
});

const ActivityCreationModal: React.FC<ActivityCreationModalInterface> = ({
                                                                             visible,
                                                                             onCancel,
                                                                             program,
                                                                             changeActivityCreationSettings
                                                                         }) => {
    const { locale } = useLocaleContext();

    const intl = useIntl();
    const [form] = Form.useForm();
    const [handleError] = useHandleError();
    const [isLoading, setIsLoading] = useState(false);
    const [languageList, setLanguageList] = useState<any>({});

    const navigate = useNavigate();

    useEffect(() => {
        loadLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadLanguages = async () => {
        let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
        let languageListForSelect: any = {};
        let languageListNameForSelect: any = {};
        Object.values(languages.languages).map((value: any) => {
            if (value['publish']) {
                languageListForSelect[value['code']] = value['code']
                languageListNameForSelect[value['code']] = value['name']
            }
            return languageListNameForSelect;
        });
        setLanguageList(languageListNameForSelect);
    };

    const submitForm = async (values: any) => {
        setIsLoading(true)

        let parsedValues = {
            active: 1,
            name: program.title[locale] ? program.title[locale] : program.title[Object.keys(program.title)[0]],
            firstLanguage: values.language,
            programId: program.id
        }

        try {
            const courseResponse = await coursesApiClient.request(`/api/v1/courses/courses`, parsedValues, 'POST');

            const activityParsedValues = {
                name: values.name,
                language: values.language,
                status: 'ONLINE',
                courseId: courseResponse.course.id,
                programId: program.id
            }

            const response = await coursesApiClient.request(`/api/v1/courses/${courseResponse.course.id}/activities/store`, activityParsedValues, 'POST');

            changeActivityCreationSettings(
                {
                    type: 'PROGRAM',
                    path: window.location.pathname,
                    title: program.title[locale] ? program.title[locale] : program.title[Object.keys(program.title)[0]],
                }
            )

            navigate(`/courses/${courseResponse.course.id}/material/${response.activity.id}`);

            onCancel(false)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal
            width={700}
            isLoading={isLoading}
            open={visible}
            title={intl.formatMessage({id: 'program.activity_append_modal'})}
            onOk={() => form.submit()}
            onCancel={() => onCancel(false)}
            okText={intl.formatMessage({id: 'general.add'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
        >
            <Form form={form} onFinish={submitForm}>
                <Input
                    name='name'
                    label={'courses.activity_name'}
                    customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                />
                <Select
                    name='language'
                    label={intl.formatMessage({id: "general.language"})}
                    manualOptions={languageList}
                    customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                />
            </Form>
        </Modal>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityCreationModal);
