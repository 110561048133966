
import { AudienceTabProps } from 'components/Audiences/types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, Search } from "ui";
import { RequestSelect, Switch } from 'components/Form';
import FlexRow from "components/FlexRow";
import apiClient from 'utils/apiClient';
import useHandleError from 'utils/useHandleError';
import { Modal, message, Button } from 'antd';
import { connect } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const AccessRightsTab: React.FC<AudienceTabProps> = ({ audience, reloadAudience, session }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false);
    const [customerId, setCustomerId] = useState<number>();
    const [selectedUser, setSelectedUser] = useState<string>();
    const [searchString, setSearchString] = useState<string>('');
    const intl = useIntl();
    const [handleError] = useHandleError();
    const { confirm } = Modal;

    useEffect(() => {
        if (session.active_user_type === 'CUSTOMER_ADMIN') {
            setCustomerId(session.organization.organization_id)
        } else {
            setCustomerId(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            key: 'user',
            title: intl.formatMessage({ id: 'audience.user' }),
            render: (_text: string, record: any) => {
                return `${record.name} ${record.surname}`;
            }
        },
        {
            key: 'email',
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
        },
        {
            key: 'position',
            title: intl.formatMessage({ id: 'general.position' }),
            dataIndex: 'position',
        },
        {
            key: 'organization',
            title: intl.formatMessage({ id: 'general.organization' }),
            dataIndex: 'organization',
        },
        {
            key: 'organizationalUnit',
            title: intl.formatMessage({ id: 'general.unit' }),
            dataIndex: 'organizationalUnit',
        },
        {
            key: 'view',
            title: intl.formatMessage({ id: 'general.view' }),
            render: (_text: string, record: any) => {

                return (
                    <Switch
                        checked={!!record.view}
                        loading={isLoading}
                        onClick={() => updatePermissions(record.adminUuid, 'view', !record.view)}
                        disabled={!audience.isEditable()}
                    />
                )
            }
        },
        {
            key: 'edit',
            title: intl.formatMessage({ id: 'general.edit' }),
            render: (_text: string, record: any) => {
                return (
                    <Switch
                        checked={!!record.edit}
                        loading={isLoading}
                        onClick={() => updatePermissions(record.adminUuid, 'edit', !record.edit)}
                        disabled={!audience.isEditable()}
                    />
                )
            }
        },
        {
            key: 'actions',
            title: intl.formatMessage({ id: 'general.actions' }),
            render: (_text: string, record: any) => {
                return (
                    <NavLink onClick={event => warnDeletion(event, record)} to="#">
                        {intl.formatMessage({ id: 'general.remove' })}
                    </NavLink>
                )
            }
        },
    ];

    const getTableColumns = () => {
        if (!audience.isEditable()) {
            return columns.filter((column: any) => column.key !== 'actions')
        }

        return columns;
    }

    const updatePermissions = async (adminUuid: string, permission: string, status: boolean) => {
        if (adminUuid === session.active_user_type_uuid) {
            message.warning(intl.formatMessage({ id: 'warning.self_update' }));
            return;
        }

        setIsLoading(true)
        const putData: any = {
            adminUuids: [adminUuid],
        }

        putData[permission] = status

        try {
            const response = await apiClient.request(`api/v1/audiences/${audience.id}/admins`, putData, 'PUT');
            if ("error" in response) {
                throw response
            };
            setReload(true)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const warnDeletion = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, record: any) => {
        event.preventDefault();

        if (record.adminUuid === session.active_user_type_uuid) {
            message.warning(intl.formatMessage({ id: 'warning.self_remove' }));
            return;
        }

        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'audience.delete_user_confirm' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                removeAdmin(record.adminUuid);
            }
        });
    }

    const removeAdmin = async (adminUuid: string) => {
        setIsLoading(true)
        try {
            const response = await apiClient.request(`api/v1/audiences/${audience.id}/admins/delete`, {
                "adminUuids": [adminUuid]
            }, 'POST');
            if ("error" in response) {
                throw response
            };
            setReload(true)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const addAccessUser = async () => {
        setIsLoading(true)
        try {
            const response = await apiClient.request(`api/v1/audiences/${audience.id}/admins`, {
                "adminUuids": [
                    selectedUser
                ],
                "view": 1,
                "edit": 1
            }, 'POST');

            if ("error" in response) {
                throw response
            };
            
            setReload(true);
            reloadAudience();
            setSelectedUser(undefined);
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);

        }
    }

    return (
        <>
            {<FlexRow
                left={audience.isEditable()
                    ? <>
                        <FormattedMessage id='general.add_user' />
                        <RequestSelect
                            isForm={false}
                            customLayout={true}
                            style={{ minWidth: 200 }}
                            url={`/api/v1/edu/users/mass-search`}
                            param={[`customerId=${customerId}`]}
                            column
                            showSearch
                            onChange={(typeUuid: string) => setSelectedUser(typeUuid)}
                            value={selectedUser}
                        />
                        <Button type='primary' disabled={!selectedUser}
                            onClick={() => addAccessUser()}
                        >
                            <FormattedMessage id='general.add' />
                        </Button>
                    </>
                    : undefined
                }
                right={
                    <Search setSearchString={setSearchString} setReload={setReload} />
                }
            />}
            <Table
                columns={getTableColumns()}
                url={`/api/v1/audiences/${audience.id}/admins`}
                reload={reload}
                setReload={setReload}
                search={searchString}
                rowKey={'adminUuid'}
                isLoading={isLoading}
                rowSelection={false}
            />
        </>
    );
};

export default connect(mapStateToProps)(AccessRightsTab);
