import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DefaultLayout from 'components/DefaultLayout';
import { NavLink, useParams } from "react-router-dom";
import { Progress, Breadcrumb, Card } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Spinner from "components/Spinner";
import moment from "moment/moment";
import { config } from "config/config";
import MaterialCard from "./MaterialCard";
import LectureCard from "./LectureCard";
import coursesApiClient from "utils/coursesApiClient";
import { connect } from "react-redux";
import useHandleError from "utils/useHandleError";
import './styles.scss';
import generalHelpers from "../../../utils/generalHelpers";
import SignatureModal from "../../Campaign/StudentCampaignActivityList/SignatureModal";
import { ProgramDataInterface } from "../../../types/Student";
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
}

const Materials: React.FC<any> = ({
    userTypeId,
    programId,
    adminView,
    session,
    setView,
}) => {
    const { locale } = useLocaleContext();
    const [isLoading, setIsLoading] = useState(false);
    const [totalMinutes, setTotalMinutes] = useState<any>();
    const [totalActivities, setTotalActivities] = useState<any>(0);
    const [totalResult, setTotalResult] = useState<any>();
    const [programTitle, setProgramTitle] = useState<any>();
    const [programDescription, setProgramDescription] = useState<any>();
    const [program, setProgram] = useState<ProgramDataInterface | undefined>();
    const [programDeadline, setProgramDeadline] = useState<any>();
    const [reload, setReload] = useState(false);
    const [materials, setMaterials] = useState([])
    const [multipleAttempts, setMultipleAttempts] = useState(false)
    const [showSignatureModal, setShowSignatureModal] = useState(false)

    const [handleError] = useHandleError();
    const params = useParams();

    function onFocus() {
        document.body.className = 'focused';
        if (!showSignatureModal) {
            loadMaterials();
        }
    };

    const reloadActivities = useCallback(() => {
        if (document.visibilityState === 'visible') {
            if (reload) {
                onFocus();
                setReload(false);
                document.removeEventListener("visibilitychange", reloadActivities);
            }
        } else {
            setReload(true);
        }
    }, [reload])

    useEffect(() => {
        document.addEventListener("visibilitychange", reloadActivities);
    })

    useEffect(() => {
        loadMaterials();
    }, [])

    const loadMaterials = async () => {
        setIsLoading(true);
        try {

            let url

            if (session.active_user_type_uuid && session.active_user_type !== 'STUDENT') {
                url = `/api/v1/courses/student/learning-program/${programId}/materials?userTypeId=${userTypeId}`
            } else {
                url = `/api/v1/courses/student/learning-program/${params.id}/materials`;
            }

            const response = await coursesApiClient.request(url, [], 'GET');

            setMaterials(response.materials)

            setProgramDeadline(response.programData.programDeadline);

            let totalMinutes: any = 0;

            response.materials.forEach((element: any) => {
                if (element.type === 'LECTURE') {
                    if (element.status === 'ATTENDED') {
                        const timeDifferenceMinutes = generalHelpers.getLectureTimeSpent(element)
                        totalMinutes = totalMinutes + timeDifferenceMinutes;
                    }
                } else {
                    totalMinutes = totalMinutes + element.minutes;
                }
            });

            setMultipleAttempts(response.programData.hasMultipleAttempts)
            setTotalMinutes(totalMinutes);
            setTotalResult((response.programData.completed / response.programData.total) * 100);
            setTotalActivities(response.programData.total);
            setProgramTitle(response.programData.programTitle);
            setProgramDescription(response.programData.programDescription);
            if (response.programData?.hasJournal?.id) {
                if (!!response.programData?.readyToSign) {
                    setShowSignatureModal(true)
                }

                setProgram(response.programData)
            }

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const getProgramTitle = () => {
        return programTitle[generalHelpers.firstLower(locale)] ?? programTitle[Object.keys(programTitle)[0]]
    }

    return (
        <Spinner spinning={isLoading}>
            <DefaultLayout.PageLayout withoutPageLayout>
                <div className={'material-container'}>
                    <div className="material-breadcrumb">
                        {adminView ?
                            <div className='flex flex-column'>
                                <div onClick={() => setView('PROGRAM')} className="flex align-center student-program-title pointer gap-5">
                                    <div>
                                        <ArrowLeftOutlined className="arrow" />
                                    </div>
                                    <h1>{programTitle ? getProgramTitle() : null}</h1>

                                </div>
                                <div className={'my-2'}>
                                    {programDescription}
                                </div>
                            </div>
                            :
                            <>
                                <Breadcrumb className="breadcrumb">
                                    <Breadcrumb.Item>
                                        <NavLink to='../'>
                                            <FormattedMessage id={'student.my_courses'} />
                                        </NavLink>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>{programTitle ? getProgramTitle() : null}</Breadcrumb.Item>
                                </Breadcrumb>
                                <div className='flex flex-column'>
                                    <div className="flex align-center student-program-title">
                                        <NavLink to='../'>
                                            <ArrowLeftOutlined className="arrow" />
                                        </NavLink>
                                        <h1>{programTitle ? getProgramTitle() : null}</h1>
                                    </div>
                                    <div className={'my-2'}>
                                        {programDescription}
                                    </div>
                                </div>
                            </>
                        }
                        <div className="flex flex-between program-details">
                            <div>
                                <span>
                                    <i className="fa-solid fa-bars" />
                                    <FormattedMessage id={totalActivities === 1 ? 'student.activity' : 'student.activities'}
                                        values={{ amount: materials.length }} />
                                </span>
                                <span>
                                    <i className="fa-solid fa-clock" />
                                    {generalHelpers.secondsToHHmm(totalMinutes * 60)}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {program && program.hasJournal ?
                                        <span style={{ marginRight: 8 }}>
                                            <span className={(program.signed ? 'signature-style sign-green' : 'signature-style sign-red') + ' sign-button'} onClick={() => setShowSignatureModal(true)}>
                                                <i className='fal fa-user-edit'></i>
                                                <FormattedMessage id='student.signature' />
                                            </span>
                                        </span>
                                        :
                                        null
                                    }
                                    <Progress 
                                        percent={Math.round(totalResult)}
                                        steps={totalActivities}
                                        strokeColor={'#73D13D'}
                                        trailColor={'#FFFFFF'}
                                    />
                                </span>
                                {programDeadline ?
                                    <span>
                                        <i className="fa-solid fa-flag-checkered" />
                                        <FormattedMessage id={"student.deadline"}
                                            values={{ date: moment(programDeadline).format(config.defaultDateFormat) }} />
                                    </span>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>

                    <Card className="card lecture-card activity-card-body mobile-hide">
                        <div className="program-data-container">
                            <div className={'student-material title bold'}>
                                <FormattedMessage id={'general.activity'} />
                            </div>
                            <div className={'student-material time-spent bold'}>
                                <FormattedMessage id={'student.time_spent'} />
                            </div>
                            <div className={'student-material pass-rate bold'}>
                                <FormattedMessage id={'general.pass_rate'} />
                            </div>
                            <div className={'student-material student-result bold'}>
                                <FormattedMessage id={'student.result'} />
                            </div>
                            <div className={'student-material mandatory bold'}>
                                <FormattedMessage id={'general.mandatory'} />
                            </div>
                            <div className={'student-material student-status bold'}>
                                <FormattedMessage id={'general.status'} />
                            </div>
                            <div className={'student-material action'}>
                            </div>
                        </div>
                    </Card>

                    {Object.values(materials).map((el: any) => {
                        switch (el.type) {
                            case 'MATERIAL':
                                return (
                                    <MaterialCard
                                        material={el}
                                        adminView={adminView}
                                        multipleAttempts={multipleAttempts}
                                    />

                                );
                            case 'LECTURE':
                                return (
                                    <LectureCard
                                        lecture={el}
                                        adminView={adminView}
                                        multipleAttempts={multipleAttempts}
                                    />
                                );
                        }
                    })
                    }
                </div>
            </DefaultLayout.PageLayout>
            {program &&
                <SignatureModal
                    visible={showSignatureModal}
                    onCancel={setShowSignatureModal}
                    program={program}
                    reload={loadMaterials}
                />
            }

        </Spinner>
    )
}

export default connect(mapStateToProps)(Materials);