import React, { useEffect, useState } from 'react';
import FilterPopover from "../FilterPopover";
import FilterCheckBox from "../FilterCheckBox";
import { Checkbox, DatePicker, Divider, Form } from "antd";
import { FormattedMessage } from "react-intl";
import { useProgram } from "hooks/Program/useProgram";
import { Button } from "ui";

interface LearningProgramFilterInterface {
    tagBuilder: Function;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const LearningProgramFilter: React.FC<LearningProgramFilterInterface> = ({
    filterKey,
    setFilterKey,
    form,
    tagBuilder,
    setFilterValues,
    filterValues
}) => {
    const { searchProgram, loading } = useProgram();
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'learning-program'

    const onSearch = async (value: string = '') => {
        const response = await searchProgram(value);
        setData(response);

        setFilterValues(
            {
                ...filterValues,
                program: {
                    ...filterValues.program,
                    ...response
                }
            }
        )
    };

    const content = (
        <div className={'flex flex-column p-1 checkbox-wrapper'}>
            <FilterCheckBox data={data} name={'program'} onSearch={onSearch} />
            <div className={'p-1 checkbox-wrapper'}>
                <div className={'bold'}>
                    <FormattedMessage id={'general.status'} />
                </div>
                <Form.Item name={'programStatus'}>
                    <Checkbox.Group className={'flex'}>
                        <Checkbox value="COMPLETED"><FormattedMessage id='general.completed' /></Checkbox>
                        <Checkbox value="IN_PROGRESS"><FormattedMessage id='general.in_progress' /></Checkbox>
                        <Checkbox value="NOT_STARTED"><FormattedMessage id='general.not_started' /></Checkbox>
                        <Checkbox value="FAILED"><FormattedMessage id='general.failed' /></Checkbox>
                    </Checkbox.Group>
                </Form.Item>
                <Divider className={'m-2'} />
                <div className={'bold'}>
                    <FormattedMessage id={'general.is_cancelled'} />
                </div>
                <Form.Item name={'isCancelled'}>
                    <Checkbox.Group className={'flex'}>
                        <Checkbox value={1}><FormattedMessage id='general.yes' /></Checkbox>
                        <Checkbox value={0}><FormattedMessage id='general.no' /></Checkbox>
                    </Checkbox.Group>
                </Form.Item>
                <Divider className={'m-2'} />
                <div className={'bold'}>
                    <FormattedMessage id={'general.execution_status'} />
                </div>
                <Form.Item name={'completionStatus'}>
                    <Checkbox.Group className={'flex'}>
                        <Checkbox value='IN_PROGRESS'><FormattedMessage id='general.in_progress' /></Checkbox>
                        <Checkbox value='OVERDUE_DEADLINE'><FormattedMessage id='general.overdue_deadline' /></Checkbox>
                        <Checkbox value='EXECUTED_ON_TIME'><FormattedMessage id='general.executed_on_time' /></Checkbox>
                        <Checkbox value='EXECUTED_LATE'><FormattedMessage id='general.executed_late' /></Checkbox>
                    </Checkbox.Group>
                </Form.Item>
                <Divider className={'m-2'} />
                <div className={'bold'}>
                    <FormattedMessage id={'general.last_activity'} />
                </div>
                <div className={'flex flex-column checkbox-wrapper'}>
                    <Form.Item name={'lastActivityDate'}>
                        <DatePicker.RangePicker />
                    </Form.Item>
                </div>
                <Divider className={'m-2'} />
            </div>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'} />
                </Button>
                <Button type={'primary'} onClick={() => {
                    setFilterKey('')

                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'} />
                </Button>
            </div>
        </div>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
        setFilterKey('');
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    program: {
                        ...filterValues.program,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            placement={'bottomLeft'}
            handleOpen={handleOpen}
            content={content}
            loading={loading}
            title={'general.programs'}
            buttonTitle={'general.programs'}
        />
    )
}

export default LearningProgramFilter;