import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from "react-redux";
import {useNavigate} from 'react-router-dom';
import Spinner from "../../components/Spinner";
import DefaultLayout from 'components/DefaultLayout';
import Badge from "../../components/CampaignCard/components/Badge";
import {config} from "../../config/config";
import coursesApiClient from "../../utils/coursesApiClient";
import useHandleError from "../../utils/useHandleError";
import {Button} from "ui";
import {Card, Empty, Tag, Divider} from 'antd';
import moment from 'moment'
import './styles.scss';
import StudentProgramFilter from "../../components/StudentProgramFilter";
import generalHelpers from "../../utils/generalHelpers";
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
}

const StudentDashboard: React.FC<any> = ({ 
                                             session,
                                             userTypeId = null,
                                             adminView = false,
                                             setAdminProgramId,
                                             setView
}) => {
    const { locale } = useLocaleContext();
    const [isLoading, setIsLoading] = useState(false);
    const [programsList, setProgramsList] = useState<any>([]);
    const [programsListShow, setProgramsListShow] = useState<any>([]);
    const [searchString, setSearchString] = useState<string>('');
    const [reload, setReload] = useState<boolean>(false);
    const [filtersSelected, setFiltersSelected] = useState<any>({statuses: "ongoing"});
    const [handleError] = useHandleError();
    const intl = useIntl();
    const navigate = useNavigate();

    useEffect(() => {
        if (adminView && userTypeId){
            loadPrograms();
        } else if (!adminView && session.active_user_type === 'STUDENT') {
            loadPrograms();
        }
    }, [session]);

    useEffect(()=> {
        showPrograms();
    },[filtersSelected])

    // TODO: replace with competence colors when ready
    let colors = ['#531DAB', '#36CFC9', '#19A9CE', '#7F67BE'];

    const loadPrograms = async () => {
        setIsLoading(true);
        try {
            let url = '/api/v1/courses/student/learning-program'
            if (session.active_user_type_uuid && session.active_user_type !== 'STUDENT') {
                url += '?userTypeId=' + userTypeId
            }

            const response = await coursesApiClient.request(url, [], 'GET');
            if (!response.programs) {
                return;
            }
            setProgramsList(response.programs);
            showPrograms(response.programs)
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const showPrograms = (programs: any = null) => {
        let showCompetences:any

        if (programs) {
            showCompetences = programs.map((c:any) => {return {...c}}); // clone, not attach
        } else {
            showCompetences = programsList.map((c:any) => {return {...c}}); // clone, not attach
        }

        // if (filtersSelected.learning !== undefined) {
        //     if (filtersSelected.learning === 'programs') {
        //         // TODO: add program filter
        //     } else if (filtersSelected.learning === 'campaigns') {
        //         // TODO: add campaign filter
        //     }
        // }
        if (filtersSelected.statuses !== undefined) {
            showCompetences.map((c:any) => {
                if (filtersSelected.statuses === 'finished') {
                    c.programs = c.programs.filter((p:any) => {
                        return (p.status === 'COMPLETED');
                    });
                } else {
                    c.programs = c.programs.filter((p:any) => {
                        return (p.status === 'IN_PROGRESS' || p.status === null || p.status === 'NOT_STARTED' || p.status === 'FAILED');
                    });
                }
            });
        }
        if (filtersSelected.competences !== undefined) {
            showCompetences.map((c:any) => {
                if (c.id !== parseInt(filtersSelected.competences)) {
                    c.programs = [];
                }
            });
        }
        if (filtersSelected.SEARCH !== undefined) {
            showCompetences.map((c:any) => {
                c.programs = c.programs.filter((p:any) => {
                    return (p.title.lV.toLowerCase().indexOf(filtersSelected.SEARCH.toLowerCase()) !== -1);
                });
            });
        }

        showCompetences = showCompetences.filter((c:any) => {
            return c.programs.length !== 0;
        });

        setProgramsListShow(showCompetences);
    }

    if (reload) {
        setFiltersSelected((filtersSelected:any) => ({ ...filtersSelected, SEARCH: searchString}));
        setReload(false);
    }

    const renderCertificateData = (program: any) => {
        if (program.status !== 'COMPLETED') {
            return;
        }
        return (
            <>
                <FormattedMessage id={'program.program_validity'} />: {program.type === 'TIMED_EVENT' ? '-' : moment(program.expirationDate).format(config.defaultDateFormat)}
            </>
        )
    }

    const renderPrograms = (list:any) => {
        if (!Object.keys(list).length) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={intl.formatMessage({ id: 'general.found_no_data' })}
                />
            );
        }

        const getDeadline = (record: any) => {
            let enrollDate;

            switch (record.type) {
                case ('TIMED_EVENT'):
                    let deadline;

                    if (moment(record.createdAt) > moment(record.beginDate)) {
                        enrollDate = moment(record.createdAt);
                    } else {
                        enrollDate = moment(record.beginDate);
                    }

                    if (moment(enrollDate).add(record.maxCompletionTerm, 'days') > moment(record.endDate)) {
                        deadline = moment(enrollDate).add(record.termLimitValue, 'days');
                    } else {
                        deadline = moment(enrollDate).add(record.maxCompletionTerm, 'days');
                    }

                    return deadline.format('DD.MM.YYYY');
                case ('CERTIFICATION'):

                    if (moment(record.createdAt) > moment(record.beginDate)) {
                        enrollDate = moment(record.createdAt)
                    } else {
                        enrollDate = moment(record.beginDate)
                    }

                    return moment(enrollDate).add(record.maxCompletionTerm, 'days').format('DD.MM.YYYY');
            }
        }

        const handleProgramStartClick = (program: any) => {
            if (program.hasOwnProperty('access') && program.access) {
                navigate(`/materials/${program.id}`);
            } else {
                navigate(`/materials/${program.id}`);
            }
        }

        return Object.values(list).map((competence: any) => {

            const name = competence.title;

            return (
                <>
                    <Divider orientation="left" plain>
                        {/*TODO: add tag colors*/}
                        <Tag color={colors[Math.floor(Math.random() * colors.length)]}>{name !== 'Other' ? name : intl.formatMessage({id: 'organizations.OTHER'})}</Tag>
                    </Divider>
                    {
                        Object.values(competence.programs).map((program: any) => {
                            const color = (status: string, initiated: number) => {

                                if (status === 'FAILED') {
                                    return <Tag color={"red"}><FormattedMessage id={'general.failed'} /></Tag>
                                }
                                switch (status) {
                                    case 'COMPLETED':
                                        switch (initiated) {
                                            case 1: return <Tag color={"green"}><FormattedMessage id={'student.completed'} /></Tag>
                                            case 0: return <Tag color={"red"}><FormattedMessage id={'student.missed'} /></Tag>
                                        }
                                        break;
                                    case 'IN_PROGRESS':
                                        switch (initiated) {
                                            case 1: return <Tag color={"blue"}><FormattedMessage id={'student.in_progress'} /></Tag>
                                            case 0: return <Tag color={"orange"}><FormattedMessage id={'student.not_started'} /></Tag>
                                        }
                                        break;
                                    default:
                                         return <Tag color={"gold"}><FormattedMessage id={'student.not_started'} /></Tag>
                                }
                            }

                            const dueDate = moment(program.deadline);
                            const today = moment();
                            const diff = dueDate.diff(today, 'days');

                            let deadlineAlert = false;
                            if (diff >= 0 && diff <= program.termLimitValue) {
                                deadlineAlert = true;
                            }

                            let missed = false;
                            if (diff < 0) {
                                missed = true;
                            }

                            if (diff <= 0 && !dueDate.isSame(today, 'day')) {
                                deadlineAlert = false;
                                missed = true;
                            }

                            return (
                                <Card>
                                    <div className="program-container">
                                        <div className="button">
                                            {adminView ?
                                                <Button type={'primary'} onClick={()=> {
                                                    setAdminProgramId(program.id)
                                                    setView('MATERIAL')
                                                }}>
                                                    <FormattedMessage id={'student.view_materials'} />
                                                </Button>
                                                :
                                                    <Button disabled={program.hasOwnProperty('access') ? !program.access : false} type={'primary'} onClick={() => handleProgramStartClick(program)}>
                                                        <FormattedMessage id={program.initiated ? 'student.continue_learning' : ( program.finished ? 'student.view_content' : 'student.start_learning')} />
                                                    </Button>
                                            }
                                        </div>
                                        <div className="content">
                                            <span>{program.title[generalHelpers.firstLower(locale)] ? program.title[generalHelpers.firstLower(locale)] : program.title[Object.keys(program.title)[0]]}</span>
                                            <span>
                                                <i className="fa-solid fa-bars"></i>
                                                <FormattedMessage id={program.activitiesCount === 1 ? 'student.activity' : 'student.activities'}  values={{amount: program.activitiesCount}}/>
                                            </span>
                                            <span>
                                                <i className="fa-solid fa-clock"></i>
                                                <FormattedMessage id='student.activity_minutes' values={{amount: program.totalMinutes ?? 0}}/>
                                            </span>
                                            {program.status === 'COMPLETED' ?
                                                null
                                                :
                                                <>
                                                    <span style={{color: missed ? '#FF4D4F' : ''}}>
                                                        <i className="fa-solid fa-flag-checkered" style={{color: missed ? '#FF4D4F' : ''}}></i>
                                                        <FormattedMessage id={"student.deadline"} values={{date: program.deadline ? moment(program.deadline).format('DD.MM.YYYY') : getDeadline(program)}}/>
                                                    </span>
                                                    <span className="deadline" style={{display: !deadlineAlert ? 'none' : ''}}>
                                                        <i className="fa-solid fa-circle"></i>
                                                        <FormattedMessage id={"student.deadline_approaching"} />
                                                    </span>
                                                </>
                                            }
                                            {(program.status === 'COMPLETED') ?
                                                <>
                                                    <span>
                                                        <i className="fa-solid fa-flag-checkered" style={{color: '#52C41A'}}></i>
                                                        <FormattedMessage id={"student.completed_on"} values={{date: moment(program.isCompleted).format('DD.MM.YYYY')}}/>
                                                    </span>
                                                    <span>
                                                        {renderCertificateData(program)}
                                                    </span>
                                                </>
                                            : null}
                                        </div>
                                        <div className="badge">
                                            <Badge thumbnail={program.thumbnail} results={(program.result * 100) + ''}/>
                                        </div>
                                        <div className="status">
                                            {color(program.status, program.initiated)}
                                        </div>
                                    </div>
                                </Card>
                            )
                        })
                    }
                </>
            )}
        );
    };

    return (
        <Spinner spinning={isLoading}>
            <DefaultLayout.PageLayout withoutPageLayout>
                <StudentProgramFilter
                    setSearchString={setSearchString}
                    setReload={setReload}
                    filtersSelected={filtersSelected}
                    setFiltersSelected={setFiltersSelected}
                    programsList={programsList}
                    status={true}
                    competences={true}
                    validity={false}
                />
                <div className="program-list-wrapper program-list ">
                    {renderPrograms(programsListShow)}
                </div>
            </DefaultLayout.PageLayout>
        </Spinner>
    )
}
export default connect(mapStateToProps)(StudentDashboard);