// THIS HAS TO BE ON TOP BECAUSE REASONS, just don't touch it, okay
import 'assets/scss/styles.scss';

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { loadLanguageDictionary } from 'store/actions/languageDictionary';
import Login from 'pages/Public/Login';
import PasswordReset from 'pages/Public/PasswordReset';
import PasswordResetConfirm from 'pages/Public/PasswordResetConfirm';
import DefaultLayout from 'components/DefaultLayout';
import LoginRoute from 'components/LoginRoute';
import ShortRoute from 'components/ShortRoute';
import AdfsRedirect from 'components/RedirectRoutes/AdfsRedirect';

import ProtectedRoute from 'components/ProtectedRoute';
// import NoAuthCustomerForm from 'pages/Customers/NoAuthCustomerForm';
// import RegistrationCompletion from 'pages/HelperPages/RegistrationCompletion';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Spinner from 'components/Spinner';
import SamlTokenRedirect from './pages/SamlTokenRedirect';

import GlobalDesignContextProvider from 'context/GlobalDesignContext';
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => ({
    languageDictionary: state.languageDictionary,
    languageDictionaryLoading: state.languageDictionary.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
    loadLanguageDictionary: () => dispatch(loadLanguageDictionary()),
});

interface AppProps {
    languageDictionary: any;
    languageDictionaryLoading: boolean;
    loadLanguageDictionary: () => void;
}

const App: React.FC<AppProps> = ({ languageDictionary, loadLanguageDictionary }) => {
    const { locale } = useLocaleContext();
    useEffect(() => {
        loadLanguageDictionary();
    }, [loadLanguageDictionary]);

    return (
        <GlobalDesignContextProvider>
            {!languageDictionary.loading ? (
                <IntlProvider locale={locale} messages={languageDictionary[locale]}>
                    <BrowserRouter>
                        <Routes>
                            <Route
                                path="/login"
                                element={
                                    <LoginRoute>
                                        <Login />
                                    </LoginRoute>
                                }
                            />

                            <Route path="/short/:code" element={<ShortRoute />} />

                            <Route
                                path="/forgot-password"
                                element={
                                    <LoginRoute>
                                        <PasswordReset />
                                    </LoginRoute>
                                }
                            />

                            <Route
                                path="/reset-confirm-password/:token"
                                element={
                                    <LoginRoute>
                                        <PasswordResetConfirm />
                                    </LoginRoute>
                                }
                            />
                            {/* <Route path="/register-customer" element={<NoAuthCustomerForm />} />
                        <Route
                            path="/registration-complete"
                            element={<RegistrationCompletion />}
                        /> */}
                            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/token/:token" element={<SamlTokenRedirect />} />
                            <Route path="/login/:client" element={<AdfsRedirect />} />
                            <Route
                                path="*"
                                element={
                                    <ProtectedRoute>
                                        <DefaultLayout />
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                    </BrowserRouter>
                </IntlProvider>
            ) : (
                <Spinner center />
            )}
        </GlobalDesignContextProvider>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
