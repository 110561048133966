import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {Form, message, Table, Row, Switch, Input as AntDInput, Button, Tooltip} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Input } from 'components/Form';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/Spinner';
import LicenceServiceModal from 'components/Licences/LicenceServiceModal';
import apiClient from 'utils/apiClient';

import './styles.scss';
import { useParams } from 'react-router-dom';
import generalHelpers from "utils/generalHelpers";
import { useLocaleContext } from 'context/LocaleContext';

const mapStateToProps = (state: any) => {
  return { 
    session: state.session,
   };
}

const LicenceEdit: React.FC = ({ session }: any) => {
  const { locale } = useLocaleContext();
  const [licenceLoading, setLicenceLoading] = useState<boolean>(false);
  const [showAddNewService, setShowAddNewService] = useState<boolean>(false);
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [platformAccessLicence, setPlatformAccessLicence] = useState<boolean>(false);
  const [appearanceLicence, setAppearanceLicence] = useState<boolean>(false);
  const [learningLicence, setLearningLicence] = useState<boolean>(false);
  const [phishingLicence, setPhishingLicence] = useState<boolean>(false);
  const [serviceLicence, setServiceLicence] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [editService, setEditService] = useState<any|null>(null);
  const [commentSubmitInProgress, setCommentSubmitInProgress] = useState(false);
  const [searchString, setSearchString] = useState<string>('');
  const [responsibleOrganizations, setResponsibleOrganizations] = useState([]);
  const [responsibleAdmins, setResponsibleAdmins] = useState([]);
  const [consumptions, setConsumptions] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [licenseActivities, setLicenseActivities] = useState<any[]>([]);

  const intl = useIntl();
  const [form] = Form.useForm();
  const [commentForm] = Form.useForm();
  const params = useParams();

  const licenceId: any = params.id;

  const { Search, TextArea } = AntDInput;

  const organization = session.organization ?
      session.active_user_type === 'CUSTOMER_ADMIN' ?
          {
            id: session.organization.organization_id,
            name: session.user_types.customers[session.organization.organization_id].name
          }
          :
          {
              id: session.organization.organization_id,
              name: session.user_types.organizations[session.organization.organization_id].name
          }
      : null;

  const handleEditButtonClick = (record: object) => {
    setEdit(true);
    setShowAddNewService(true);
    setEditService(record);
  }

  const submitCommentForm = async (values: any) => {
    try {
      setCommentSubmitInProgress(true);

      const parsedValues = { comment: values.comment || '' };

      await apiClient.request(`/api/v1/licences/${licenceId}/update-comment`, parsedValues, 'PUT');
      message.success(intl.formatMessage({id: 'comment_successfully_updated'}));
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({id: 'error.data_load'}));
    } finally {
      setCommentSubmitInProgress(false);
    }
  }

  useEffect(() => {
    loadLicence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, form, commentForm, licenceId]);

  useEffect(() => {
    const searchTable = (): any => {
      const searchableColumns = ['code', 'name'];

      if (searchString) {
        const filterableServices = services;

        return filterableServices.filter((service: any) => {
          return Object.keys(service).some(
            key => searchableColumns.includes(key)
              ? service[key].toString().toLowerCase().includes(searchString.toLocaleLowerCase())
              : false
          );
        });
      }

      return services;
    }

    setFilteredServices(searchTable())
  }, [services, searchString]);

  const loadLicence = async () => {
    try {
      setLicenceLoading(true);
      const response = await apiClient.request(`/api/v1/licences/${licenceId}`, {}, 'GET');
      const licence = response.licence;
      setCustomerId(licence.customerId);
      setResponsibleOrganizations(licence.organizations);
      setResponsibleAdmins(licence.responsibleAdmins);
      setConsumptions(licence.consumptions)
      setLicenseActivities(licence.activities);

      setPlatformAccessLicence(licence.isPlatformAccessLicence);
      setAppearanceLicence(licence.isAppearanceLicence);
      setLearningLicence(licence.isLearningLicence);

      if (generalHelpers.hasPermissions(session, 'view_phishing')) {
        setPhishingLicence(licence.isPhishingLicence);
      }

      if (!licence.isPlatformAccessLicence && licence.services.length) {
        setServiceLicence(true);
      }

      form.setFieldsValue({
        id: licence.no,
        customer: licence.customerName
      });

      commentForm.setFieldsValue({ comment: licence.comment });

      setServices(licence.services);
    } catch(error) {
      console.error(error);
      message.error(intl.formatMessage({id: 'error.data_load'}));
    } finally {
      setLicenceLoading(false);
    }
  };

  const handleSwitchChange = async (checked: boolean, serviceId: any, hasPivot: boolean, record: any) => {

      if (session.active_user_type === 'CUSTOMER_ADMIN') {
        return;
      }
      const responsibleAdmin: any = hasPivot ? record.pivot.responsibleAdminId : record.responsibleAdminId;

      if (!responsibleAdmin) {
        return;
      }

      try {
          await apiClient.request(`/api/v1/licences/${licenceId}/services/${serviceId}/set-validity`, {active: checked}, 'PUT');

          //Clone (and get rid of references to) state so that it's not edited directly
          const currServices: any = JSON.parse(JSON.stringify(services));

          let serviceIndex: number = -1;

          if (hasPivot) {
              serviceIndex = currServices.findIndex((service: any) => service.pivot.id === serviceId);
              currServices[serviceIndex].pivot.active = checked;
          } else {
              serviceIndex = currServices.findIndex((service: any) => service.id === serviceId);
              currServices[serviceIndex].active = checked;
          }

          setServices(currServices);
      } catch (error) {
          message.error(intl.formatMessage({id: 'error.data_load'}));
          console.error(error);
      }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'service_code',
      render: (_value: string, record: any) => record.code
    },
    {
      title: intl.formatMessage({id: 'licensing.service_category'}),
      key: 'service_category',
      render: (_value: string, record: any) => intl.formatMessage({id: `services.${record.type.toLowerCase()}`}),
    },
    {
      title: intl.formatMessage({id: 'licensing.service_name'}),
      key: 'service_name',
      render: (_value: string, record: any) => record.name
    },
    {
      title: intl.formatMessage({id: 'licensing.e_learning_content'}),
      dataIndex: 'e_learning_content',
      render: (_value: string, record: any) => {
        if (record.type && record.type === 'E_LEARNING_CONTENT') {
          let activities: any = [];
          if (!record.isLicence) {
            let settings = JSON.parse(record.pivot.settings);
            settings.learning_activities.forEach((el: number) => {
              if (!!licenseActivities[el]) {
                const title = licenseActivities[el];
                activities.push(title[locale] ? title[locale] : title[Object.keys(title)[0]])
              }
            });
            return activities.map((el: any) => el + '; ');
          } else {
            return null;
          }
        }
      }
    },
    {
      title: intl.formatMessage({id: 'licensing.valid_from'}),
      key: 'valid_from',
      render: (_value: string, record: any) => moment(record.pivot ? record.pivot.begin_date : record.validFrom).format('DD/MM/YYYY')
    },
    {
      title: intl.formatMessage({id: 'licensing.valid_until'}),
      key: 'valid_until',
      render: (_value: string, record: any) => {
        let output: string = 'N/A';
        let className: string = '';

        if (record.validTo || record.pivot?.endDate) {
          const date = moment(record.pivot ? record.pivot.endDate : record.validTo);
          const diff = date.diff(moment(), 'days');

          if (diff < 0) {
            className = 'date-passed';
          } else if (diff <= 60) {
            className = 'date-incoming';
          }

          output = date.format('DD/MM/YYYY');
        }

        return <span className={className}>{output}</span>;
      }
    },
    {
      title: intl.formatMessage({id: platformAccessLicence ? 'licensing.max_users' : 'licensing.data_summary'}),
      key: 'extra_data',
      render: (_value: string, record: any) => {
        switch (record.type) {
          case 'E_LEARNING_CONTENT':
            const settings = JSON.parse(record.pivot.settings);
            const responsibleAdmin: any = responsibleAdmins[record.pivot.responsibleAdminId];

            let color = 'green';

            if (record.validTo) {
              const diff = moment(record.validTo).diff(moment(), 'days');

              if (diff < 0) {
                color = 'red';
              } else if (diff < 45) {
                color = 'orange';
              }
            }

            return (
              <>
                <div>
                  <strong>
                    <FormattedMessage id="licensing.responsible_reseller"/>:{' '}
                  </strong>
                    {responsibleAdmin ?
                        <>
                            {responsibleAdmin.name},{' '}
                                <a href={`mailto:${responsibleAdmin.email}`}>{responsibleAdmin.email}</a>,{' '}
                            {responsibleOrganizations[record.pivot.responsibleOrganizationId]}
                        </>
                    : null
                    }
                </div>
                <div>
                  <strong>
                    <FormattedMessage id="licensing.max_access_limit"/>:{' '}
                  </strong>
                  {settings.max_access === 'infinite' ?
                    <Tooltip title={intl.formatMessage({id: 'licensing.infinite_licence'})}>
                      <span>
                        <i className="fas fa-infinity"></i>
                      </span>
                    </Tooltip>
                    :
                    settings.max_access
                  }
                </div>
                <div>
                  <strong>
                    <FormattedMessage id="licensing.current_access"/>:{' '}
                  </strong>
                  {consumptions[record.pivot.id]}
                </div>
                <div>
                    {!record.active &&
                      <strong style={{fontWeight: 400}}>
                        <FormattedMessage id="licensing.service_not_active"/>:{' '} <span style={{color: color}}>{record.validTo ? moment(record.validTo).format('DD/MM/YYYY') : <FormattedMessage id="licensing.end_date_status"/>} </span>
                      </strong>
                    }
                </div>
              </>
            );
          case 'PLATFORM_ACCESS':
            return record.maxUsers;

          case 'PHISHING_SERVICE':
            if (generalHelpers.hasPermissions(session, 'view_phishing')) {
              return record.maxUsers;
            }
            return;

          default:
            return;
        }
      }
    },
    {
      title: intl.formatMessage({id: 'licensing.validity'}),
      key: 'validity',
      render: (_value: string, record: any) => {
        const serviceId: any = record.pivot ? record.pivot.id :  record.id;
        const isPivotId = !!record.pivot;

        let isDisabled = false;

        if (generalHelpers.hasPermissions(session, 'view_phishing') && record.type === 'PHISHING_SERVICE') {
          if (!record.responsibleAdminId) {
            isDisabled = true
          }
        } else if ( record.type === 'APPEARANCE_SERVICE' ) {
          if (!record.responsibleAdminId) {
            isDisabled = true
          }
        } else {
          isDisabled = isPivotId ? !record.pivot.responsibleAdminId : !record.responsibleAdminId
        }

        return <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          disabled={session.active_user_type === 'CUSTOMER_ADMIN' ? true : isDisabled}
          defaultChecked={!record.pivot ? record.active : record.pivot.active}
          onChange={(checked) => handleSwitchChange(checked, serviceId, isPivotId, record)} />
      }
    },
    {
      key: 'actions',
      render: (_value: string, record: any) => (
          <>
              {session.active_user_type !== 'CUSTOMER_ADMIN' &&
                  <Button
                      type="link"
                      onClick={() => handleEditButtonClick(record)}
                  >
                      <FormattedMessage id="general.edit"/>
                  </Button>
              }
          </>
      )
    }
  ];

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 }
  };

  return (
    <Spinner spinning={licenceLoading}>
      <DefaultLayout.PageLayout>
        <DefaultLayout.PageHeader
          title={intl.formatMessage({id: 'licensing.edit_licence'})}
          breadcrumb={[{
            name: intl.formatMessage({id: 'licensing.manage_licences'}),
            path: '/licensing/manage-licences'
          }]}
        />
        <Form form={form}>
          <Input
            name="id"
            label={intl.formatMessage({id: 'licensing.licence_id'})}
            readOnly
            customLayout={formItemLayout}
          />
          <Input
            name="customer"
            label={intl.formatMessage({id: 'general.customer'})}
            readOnly
            customLayout={formItemLayout}
          />
        </Form>
          {session.active_user_type !== 'CUSTOMER_ADMIN' &&
            <>
              <Form form={commentForm} onFinish={submitCommentForm}>
                <Form.Item
                    name="comment"
                    label={<span>{intl.formatMessage({id: 'licensing.comment'})}</span>}
                    labelCol={formItemLayout.labelCol}
                    wrapperCol={formItemLayout.wrapperCol}
                    style={{ marginBottom: 12 }}
                >
                    <TextArea />
                </Form.Item>
                <Row justify="center">
                    <Button
                        type="primary"
                        loading={commentSubmitInProgress}
                        onClick={commentForm.submit}
                    >
                        <FormattedMessage id="licensing.save_comment"/>
                    </Button>
                </Row>
              </Form>
              <Button
                type="primary"
                style={{marginLeft: 16}}
                onClick={() => setShowAddNewService(true)}
              >
                <FormattedMessage id="licensing.add_new_service"/>
              </Button>
            </>
          }
        <Search
          className="table-search-field"
          placeholder={intl.formatMessage({id: 'general.search'})}
          onChange={value => setSearchString(value.target.value)}
          style={{marginRight: 16, marginBottom: 24}}
        />
        <Table
          locale={{ emptyText: intl.formatMessage({id: "general.found_no_data"}) }}
          rowKey={(record) => record}
          style={{marginTop: 16}}
          columns={columns}
          size="middle"
          scroll={{ x: 800 }}
          dataSource={filteredServices}
        />
        <LicenceServiceModal
          edit={edit} setEdit={setEdit}
          editService={editService}
          userRole={session.active_user_type}
          user={{
            id: session.id,
            name: `${session.name} ${session.surname}`
          }}
          organization={organization}
          licenceServices={services}
          showAddNewService={showAddNewService}
          setShowAddNewService={setShowAddNewService}
          licenceId={licenceId}
          platformAccessLicence={platformAccessLicence}
          appearanceLicence={appearanceLicence}
          learningLicence={learningLicence}
          phishingLicence={phishingLicence}
          serviceLicence={serviceLicence}
          responsibleOrganizations={responsibleOrganizations}
          responsibleAdmins={responsibleAdmins}
          loadLicence={loadLicence}
          session={session}
          customerId={customerId}
        />
      </DefaultLayout.PageLayout>
    </Spinner>
  );
}
export default connect(mapStateToProps)(LicenceEdit);
