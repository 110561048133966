import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Input, Radio, Button, InputNumber, FormInstance, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { RequestSelect } from 'components/Form';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

interface GradingTabInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    viewMaterial?: any;
    onCancel: any;
    reload: Function;
    settings: any;
    setSettings: Function
}

const GradingTab: React.FC<GradingTabInterface> = ({
    language,
    form,
    activityId,
    viewMaterial,
    onCancel,
    reload,
    settings,
    setSettings
}) => {
    const [documents, setDocuments] = useState<any>([]);
    const [instructors, setInstructors] = useState<any>([]);

    const intl = useIntl();

    useEffect(() => {
        if (settings) {
            const documentList = settings?.documents.map((item: string) => {
                return {
                    link: item
                }
            })

            const instructorsList = settings?.instructors.map((item: string) => {
                return {
                    id: item
                }
            }) 

            setDocuments(documentList)
            setInstructors(instructorsList)

            form.setFieldsValue({
                documents: documentList,
                instructors: instructorsList,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    return (
        <>
            <h3><FormattedMessage id='assessment.grading_settings' /></h3>
            <Form.Item required={true} {...formItemLayout} name='evaluationType' label={intl.formatMessage({ id: 'courses.evaluation_type' })} >
                <Radio.Group onChange={(event: any) => setSettings({
                    ...settings,
                    evaluationType: event.target.value
                })}>
                    <Radio value='EXAMINATION'>
                        <FormattedMessage id='courses.examination' />
                    </Radio>
                    <Radio value='RANGE'>
                        <FormattedMessage id='courses.rating_range' />
                    </Radio>
                </Radio.Group>
            </Form.Item>
            {settings.evaluationType === 'RANGE' &&
                <Form.Item {...formItemLayout} name='passRate' label={intl.formatMessage({ id: 'courses.pass_rate' })} >
                    <InputNumber min={1} max={100} />
                </Form.Item>
            }
            <h3><FormattedMessage id='assessment.assessment_document' /></h3>
            <Form.List
                name="documents"
                initialValue={documents}
            >
                {(fields, { add, remove }) => {
                    return (
                        <div className='mb-5'>
                            {fields.map(({ key, name, ...restField }) => (
                                <div className='flex gap-10' key={key}>
                                    <Form.Item {...formItemLayout} name={[name, 'link']} label={intl.formatMessage({ id: 'assessment.link_to_document' })} className='w-100' >
                                        <Input />
                                    </Form.Item>
                                    {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} className='mt-2' />}
                                </div>
                            ))}
                            <Row>
                                <Col span={4}></Col>
                                <Col span={20}>
                                    <Button type="ghost" icon={<PlusOutlined />} onClick={() => add()}>
                                        <FormattedMessage id="general.add" />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    );
                }}
            </Form.List>
            <h3><FormattedMessage id='assessment.assessment_management' /></h3>
            <Form.Item required={true} {...formItemLayout} name='initiationType' label={intl.formatMessage({ id: 'assessment.initiation_type' })}>
                <Radio.Group>
                    <Radio value='AUTOMATIC'>
                        <FormattedMessage id='assessment.automatic' />
                    </Radio>
                    <Radio value='MANUAL'>
                        <FormattedMessage id='assessment.manual' />
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <h3><FormattedMessage id='assessment.assessment_instructors' /></h3>
                <Form.List
                    name="instructors"
                    initialValue={instructors}
                >
                    {(fields, { add, remove }) => {
                        return (
                            <div className='mb-5'>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div className='flex gap-10' key={key}>
                                        <RequestSelect
                                            className='w-100'
                                            name={[name, 'id']}
                                            isForm={true}
                                            customLayout={formItemLayout}
                                            selectClassName='request-select'
                                            url={`/api/v1/edu/users/mass-search`}
                                            param={['key=type_id']}
                                            initialValue={form.getFieldValue('instructors')}
                                            initialParam={key}
                                            column
                                            showSearch
                                            label={intl.formatMessage({ id: 'assessment.assessment_instructor' })}
                                        />
                                        {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} className='mt-2' />}
                                    </div>
                                ))}
                                <Row>
                                    <Col span={4}></Col>
                                    <Col span={20}>
                                        <Button type="ghost" icon={<PlusOutlined />} onClick={() => add()}>
                                            <FormattedMessage id="general.add" />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        );
                    }}
                </Form.List>
        </>
    )
}
export default GradingTab;
