import React from 'react';
import { Button } from 'antd';
import { Modal, Tag } from 'ui';
import { FormattedMessage } from 'react-intl';
import { config } from 'config/config';
import moment from 'moment';
import { TagStatus } from 'ui/Tag';

interface CommentInterface {
    fullName: string;
    comment: string;
    createdAt: string;
    prevStatus: TagStatus;
    currentStatus: TagStatus;
}

interface CommentModalInterface {
    open: [];
    onCancel: React.Dispatch<React.SetStateAction<CommentInterface[]>>;
}

const CommentModal: React.FC<CommentModalInterface> = ({
    open,
    onCancel,
}) => {

    const handleComments = (comment: CommentInterface) => {
        return (<div>
            <div className='flex mb-2 flex-column gap-5'>
                <span className='f-16'>{comment.comment}</span>
                {comment?.prevStatus &&
                    <div className='flex align-center gap-10 justify-center'>
                        <Tag status={comment.prevStatus} /> <i className='fa-solid fa-arrow-right theme-color mr-1'></i> <Tag status={comment.currentStatus} />
                    </div>
                }
                <div className='flex justify-end'> {comment.fullName} {comment?.fullName ? moment(comment.createdAt).format(config.defaultDateFormat + ' HH:mm') : null}</div>
            </div>
            <hr className='form-group-separator' />
        </div>)
    }

    return (
        <Modal
            open={open.length > 0}
            onCancel={() => onCancel([])}
            className='add_remove_activity'
            title={<div>
                <i className='fa-regular fa-circle-info pr-1 f-20 theme-color' />
                <FormattedMessage id='general.comment' />
            </div>}
            destroyOnClose={true}
            footer={
                <Button onClick={() => onCancel([])}>
                    <FormattedMessage id='general.close' />
                </Button>
            }
        >
            {open.map((comment: CommentInterface) => handleComments(comment))}
        </Modal>
    );
};

export default CommentModal;
