import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import DefaultLayout from 'components/DefaultLayout';
import {Input, Select} from 'components/Form';
import {Button, Form} from "antd";
import {useParams} from "react-router-dom";
import {usePhishing} from "hooks/Phishing/usePhishing";

const SimulationsForm: React.FC<any> = ({}) => {
    const {getSimulations, saveSimulation, loading} = usePhishing();
    const [simulations, setSimulations] = useState([])

    const intl = useIntl();
    const params = useParams();
    const [form] = Form.useForm();
    const templateId = params.id;

    useEffect(() => {
        if (templateId) {
            loadSimulations(templateId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadSimulations = async (id: string) => {
        const data = await getSimulations(id)
        setSimulations(data);
    };

    const submitForm = async (values: any) => {
        if (!templateId) {
            return
        }
        const response = await saveSimulation(values, templateId);

        setSimulations(response)

        languageChangeHandle(values.language, response, true)
    };

    const languageChangeHandle = (language: string, data: any = {}, usePassedData = false) => {
        let simulation: any
        if (usePassedData) {
            simulation = data.filter((el: any) => el.language === language);
        } else {
            simulation = simulations.filter((el: any) => el.language === language);
        }

        if (simulation.length > 0) {
            form.setFieldsValue({
                ...simulation[0],
                gophishLandingPageId: simulation[0].gophishLandingPageId + '',
                gophishSendingProfileId: simulation[0].gophishSendingProfileId + '',
                gophishTemplateId: simulation[0].gophishTemplateId + '',
            })
        } else {
            form.resetFields()
            form.setFieldValue('language', language)
        }
    }

    return (
        <>
            <Form form={form} onFinish={submitForm}>
                <Select
                    name='language'
                    showArrow
                    label={intl.formatMessage({id: "general.language"})}
                    onChange={(value: any) => languageChangeHandle(value)}
                    dataKey={'language'}
                    url={'/api/v1/select-languages'}
                    customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                />
                <Input name="simulationTitle"
                       label={intl.formatMessage({id: 'phishing.template_name_in_chosen_language'})}
                       validation={{required: true}}
                />
                <Select
                    name='gophishTemplateId'
                    showArrow
                    label={intl.formatMessage({id: "phishing.gophish_templates"})}
                    dataKey={'gophishTemplates'}
                    url={'/api/v1/courses/gophish/templates'}
                    onChange={(value: any, options: any) => form.setFieldsValue({templateTitle: options.children})}
                    customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                />
                <Select
                    name='gophishLandingPageId'
                    showArrow
                    label={intl.formatMessage({id: "phishing.gophish_pages"})}
                    dataKey={'gophishPages'}
                    url={'/api/v1/courses/gophish/pages'}
                    onChange={(value: any, options: any) => form.setFieldsValue({landingPageTitle: options.children})}
                    customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                />
                <Select
                    name='gophishSendingProfileId'
                    showArrow
                    label={intl.formatMessage({id: "phishing.sending_profile"})}
                    dataKey={'gophishSendingProfiles'}
                    url={'/api/v1/courses/gophish/sending-profiles'}
                    onChange={(value: any, options: any) => form.setFieldsValue({sendingProfileTitle: options.children})}
                    customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                />
                <Input
                    name="url"
                    label={intl.formatMessage({id: 'phishing.url'})}
                    validation={{required: true}}
                />
                {/*necessary to reduce request amount*/}
                <span className={'hidden'}>
                    <Input name="id"/>
                    <Input name="templateTitle"/>
                    <Input name="landingPageTitle"/>
                    <Input name="sendingProfileTitle"/>
                </span>
            </Form>
            <DefaultLayout.PageFooter
                right={
                    <Button disabled={!templateId} loading={loading} onClick={form.submit} className='save-button'>
                        <FormattedMessage id="general.submit"/>
                    </Button>
                }
            />
        </>
    )
};

export default SimulationsForm;
