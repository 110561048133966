import React from 'react';
import {useParams} from 'react-router-dom';
import {AccessForm} from "components/AcessForm";

interface RecordType {
    key: string;
    name: string;
}

interface AccessInterface {
}

const Access: React.FC<AccessInterface> = ({}) => {
    const params = useParams();
    const questionPoolId = params.id;

    return (
        <AccessForm
            transferTitle='question_pools.organization_permissions'
            tableTitle='question_pools.users_management_access'
            url={
                {
                    C:`/api/v1/courses/question-pools/${questionPoolId}/access/admins`,
                    R:`/api/v1/question-pools/${questionPoolId}/access/admins`,
                    U:`/api/v1/courses/question-pools/{id}/access/admins`,
                    D:`/api/v1/courses/question-pools/{id}/access/admins`
                }
            }
            hasOrgAccess={false}
        />
    )
};

export default Access;
