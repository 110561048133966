import React from 'react';
import {useIntl} from 'react-intl';
import {Select} from 'components/Form';

interface TestStatusSelectInterface {
    name?: string | string[];
    label?: string;
    layout?: {
        labelCol: {
            span: number
        }
        wrapperCol: {
            span: number
        }
    }
    disabled?: boolean;
    customRules?: any;
    hasDefaultLayout?: boolean;
    type: string;
    isFormItem?: boolean;
    onChange: any;
}

const TestStatusSelect: React.FC<TestStatusSelectInterface> = ({
                                                                     name,
                                                                     label,
                                                                     layout,
                                                                     disabled = false,
                                                                     type,
                                                                     customRules = [],
                                                                     hasDefaultLayout,
                                                                     isFormItem = true,
                                                                     onChange
                                                                 }) => {
    const intl = useIntl();

    const expectedResult = {
        'NOT_STARTED': intl.formatMessage({ id: 'general.not_started' }),
        'PASSED': intl.formatMessage({ id: 'general.passed' }),
    };

    let formItemLayout = layout || {}

    if (hasDefaultLayout) {
        formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18}
        }
    }

    return (
        <Select
            customLayout={formItemLayout}
            isFormItem={isFormItem}
            name={name}
            onChange={() => onChange ? onChange(name): null}
            label={label ? intl.formatMessage({id: label}): null}
            manualOptions={expectedResult}
            disabled={disabled}
            customRules={customRules}
        />
    )
}
export default TestStatusSelect;
