import React from 'react';
import Spinner from "../../../components/Spinner";
import QuestionListItem from "./QuestionListItem";
import './styles.scss';

interface QuestionsListInterface {
    questions: any;
    questionBankId: any;
    handlePanel: Function;
    isLoadingQuestions: boolean;
    openQuestionId: number | null;
    onSelectQuestion: Function;
    selectedQuestions: any;
    questionTypes: Object;
}

const QuestionsList: React.FC<QuestionsListInterface> = ({ questions, handlePanel, isLoadingQuestions, openQuestionId, onSelectQuestion, selectedQuestions, questionTypes }) => {
    return (
        <>
            <div className='questions-list'>
                <Spinner spinning={isLoadingQuestions}>
                    {questions && questions.map((question: any, key: number) => (
                        <div key={key}>
                            <QuestionListItem 
                                question={question} 
                                questionIndex={key+1}
                                handlePanel={handlePanel}
                                onSelectQuestion={onSelectQuestion}
                                openQuestionId={openQuestionId}
                                selectedQuestions={selectedQuestions}
                                questionTypes={questionTypes}
                            />
                        </div>
                    ))}
                </Spinner>
            </div>
        </>
    );
};

export default QuestionsList;
