import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {config} from 'config/config';
import DefaultLayout from "components/DefaultLayout";
import useHandleError from "utils/useHandleError";
import {Search, Table} from "ui";
import moment from "moment/moment";
import {message, Modal, Popover, Tag} from "antd";
import {Button} from "ui";
import apiClient from "../../../utils/apiClient";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Switch from 'components/Form/Switch';
import './styles.scss';

const GeneralMessageList: React.FC<any> = ({}) => {
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false)
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);

    const intl = useIntl();
    const params = useParams();
    const [handleError] = useHandleError();
    const {confirm} = Modal;
    const navigate = useNavigate();

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const onSwitchChange = async (record: any, status: boolean) => {
        try {
          setIsSwitchLoading(true);
    
          record.isEnabled = !record.isEnabled;
    
          await apiClient.request(`/api/v1/general-messaging/${record.id}/update-status`, {isEnabled: status}, 'PUT');
    
          const messageId = 'general.status_updated';
    
          message.success(intl.formatMessage({id: messageId}), 7);
        } catch (err) {
          console.error(err);
          message.error(intl.formatMessage({id: 'error.action_failed'}));
        } finally {
          setIsSwitchLoading(false);
        }
      }

    const columns = [
        {
            title: intl.formatMessage({id: 'general.title'}),
            render: (_text: string, record: any) => {
                return (
                    <NavLink to={`/general-messaging/${record.id}`}>
                        {record.title}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.type'}),
            render: (_text: string, record: any, tag: any) => {
                let color;
                if (record.type === 'INFO') color = 'blue';
                if (record.type === 'ALERT') color = 'yellow';
                return (
                    <Tag color={color} key={tag} className={record.type}>
                        {intl.formatMessage({id: `general_messaging.type.${record.type.toLowerCase()}`})}
                    </Tag>
                );
            }
        },
        {
            title: intl.formatMessage({id: 'general.begin_date'}),
            sorter: true,
            key: 'startDate',
            render: (_text: string, record: any) => record.startDate ? (moment(record.startDate).format(config.defaultDateFormat)) : '-'
        },
        {
            title: intl.formatMessage({id: 'general.end_date'}),
            sorter: true,
            key: 'endDate',
            render: (_text: string, record: any) => record.endDate ? moment(record.endDate).format(config.defaultDateFormat) : '-'
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            dataIndex: 'isEnabled',
            key: 'isEnabled',
            render: (_text: string, record: any) => {
                return (<Switch key={`general-messaging-switch-${record.id}`}  onChange={(status: boolean) => onSwitchChange(record, status)} defaultChecked={record.isEnabled} disabled={isSwitchLoading}/>)
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <>
                        <NavLink to={`/general-messaging/${record.id}`}>
                            <div className="popover-item">
                                <FormattedMessage id="general.edit"/>
                            </div>
                        </NavLink>
                    </>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        },
    ];

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'general_messaging.delete_message'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                deleteGeneralMessage(id);
            }
        });
    }

    const deleteGeneralMessage = async (id: number) => {
        try {
            await apiClient.request(`/api/v1/general-messaging/${id}/delete`, [], 'DELETE');
            setReload(true)
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'general_messaging.general_messages'})}/>
            <DefaultLayout.PageContent>
                <div className="search-bar-row">
                    <NavLink to='/general-messaging/create'>
                        <Button type='primary'>
                            <FormattedMessage id='general_messaging.new_message'/>
                        </Button>
                    </NavLink>
                    <div className="search">
                        <Search setSearchString={setSearchString} setReload={setReload}/>
                    </div>
                </div>
                <Table
                    columns={columns}
                    url={'/api/v1/general-messaging'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    setTotalRecords={setTotalRecords}
                    search={searchString}
                    scroll={{x: true}}
                />
            </DefaultLayout.PageContent>

        </DefaultLayout.PageLayout>
    );
};

export default GeneralMessageList;