import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Modal, Form} from 'antd';
import {Select} from 'components/Form';
import FileDownload from 'js-file-download';
import useQueryApiClient from "utils/useApiClient";

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
};

const LanguageExportModal: React.FC<any> = ({visible, onCancel, languageList}) => {
    const [exportLanguage, setExportLanguage] = useState('');

    const intl = useIntl();
    const [form] = Form.useForm();

    const {fetch: handleExport, isLoading} = useQueryApiClient({
        request: {
            disableOnMount: true,
            method: 'GET',
            multipart: true,
            url: `/api/v1/translations/export?language=EN&translationLanguage=${exportLanguage}`,
        },
        onSuccess: (response) => {
            FileDownload(response, 'Translations.xlsx');
            onCancel()
        },
    });

    return (
        <Form form={form} onFinish={handleExport} {...formItemLayout}>
            <Modal
                className="import-modal"
                title={intl.formatMessage({id: 'system.language_export_modal'})}
                open={visible}
                onOk={() => {
                    form.submit()
                }}
                onCancel={onCancel}
                okText={intl.formatMessage({id: 'system.language.export'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
                okButtonProps={{
                    loading: isLoading,
                }}
            >
                <Select
            onChange={(value: any) => setExportLanguage(value)}
            allowSearch
                    customLayout={formItemLayout}
                    name='uploadLanguage'
                    label={intl.formatMessage({ id: 'system.language.export_language' })}
                    url={`/api/v1/languages`}
                />
            </Modal>
        </Form>
    )
}

export default LanguageExportModal;
