import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import Languages from './Languages';
import Translations from './Translations';

const SystemLanguages: React.FC = () => {
    const [activeTabKey, setActiveTabKey] = useState('language')
    const intl = useIntl();
    const params = useParams();
    const tab = params.tab;
    const navigate = useNavigate();

    useEffect(() => {
        if (tab) {
            setActiveTabKey(tab)
        }
    }, [])

    const tabChangeHandle = (tab: string) => {
        setActiveTabKey(tab)
        navigate(`/system/languages/${tab}`)
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'system.system' })} breadcrumb={[{
                name: intl.formatMessage({ id: 'general.edit_languages' }),
                path: '/system/languages'
            },]} />
            <DefaultLayout.PageContent>
                <Tabs destroyInactiveTabPane
                    activeKey={activeTabKey}
                    onTabClick={tabChangeHandle}
                    items={[
                        {
                            label: intl.formatMessage({ id: 'general.languages' }),
                            key: 'language',
                            children: <Languages />,
                        },
                        {
                            label: intl.formatMessage({ id: 'system.languages.translation_strings' }),
                            key: 'translations',
                            children: <Translations />,
                        },

                    ]}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
}
export default SystemLanguages;
