import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {message, Modal, Form, Radio} from 'antd';
import useQueryApiClient from 'utils/useApiClient';
import FileDownload from 'js-file-download';
import {UsersExportModalInterface} from '../types';
import { useLocaleContext } from 'context/LocaleContext';

const UsersExportModal: React.FC<UsersExportModalInterface> = ({
    visible,
    onCancel,
    activeOrganizationId,
    activeOrganizationType,
    selectedRowKeys,
}) => {
    const { locale } = useLocaleContext();

    const intl = useIntl();
    const [exportForm] = Form.useForm();

    const {appendData: exportUsers, isLoading: exportLoading} = useQueryApiClient({
        request: {
            url: '/api/v1/auth/users/export',
            method: 'POST',
            mustRetry: true,
            multipart: true
        },
        onSuccess: (response) => {
            if (response) {
                FileDownload(response, 'CloudStudy-Customer-Users.xlsx');
                message.success(intl.formatMessage({ id: 'users.export.successful_export_in_message' }));
            }
        }
    });

    const exportCustomerUsers = async (values: any) => {
        if (!activeOrganizationId) {
            message.error(intl.formatMessage({ id: 'users.select_customer_in_filter_first' }));
            return;
        }

        if (!activeOrganizationType) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            return;
        }

        const bodyFormData = new FormData();

        bodyFormData.append('filterBy', activeOrganizationType.toString());
        bodyFormData.append('language', locale);

        if (activeOrganizationType === 'CUSTOMER') {
            bodyFormData.append('customerId', activeOrganizationId.toString());
        } else {
            bodyFormData.append('organizationId', activeOrganizationId.toString());
        }

        if (values.exportOption === 'selected') {
            if (!Array.isArray(selectedRowKeys) || !selectedRowKeys.length) {
                message.error(intl.formatMessage({ id: 'campaign.please_select_atleast_one_user' }));
                return;
            }

            bodyFormData.append('selectedUsers', selectedRowKeys.join(','));
        }

        exportUsers(bodyFormData);
    };

    return (
        <Modal
            className="user-list-modal"
            title={intl.formatMessage({ id: 'users.export.export' })}
            open={visible}
            onOk={() => {
                exportForm.submit();
            }}
            confirmLoading={exportLoading}
            onCancel={onCancel}
            okText={intl.formatMessage({ id: 'general.export.send' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
        >
            <Form 
                form={exportForm} 
                onFinish={(v: any) => {
                    exportCustomerUsers(v);
                    onCancel();
                }}
            >
                <Form.Item
                    name="exportOption"
                    label={intl.formatMessage({ id: 'users.export.export_option' })}
                >
                    <Radio.Group defaultValue='all'>
                        <Radio value="all"><FormattedMessage id='users.export.export_all_users' /></Radio>
                        <Radio value="selected"><FormattedMessage id={intl.formatMessage({ id: 'users.export.export_selected_users' }, { amount: Array.isArray(selectedRowKeys) ? selectedRowKeys.length : 0 })} /></Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default UsersExportModal;
