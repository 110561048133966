import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Card, message } from 'antd';
import { Input } from 'components/Form';
import apiClient from 'utils/apiClient';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import DefaultUnauthorizedLayout from 'components/DefaultUnauthorizedLayout';
import Logo from 'components/Logo';

const PasswordResetConfirm: React.FC = ({}: any) => {
    const intl = useIntl();
    const [inProgress, setInProgress] = useState(false);

    const [inputTextLength, setInputTextLength] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [inputConfirmTextLength, setInputConfirmTextLength] = useState(0);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const token = params.token;

    const submitForm = async (values: any) => {
        setInProgress(true);
        try {
            let parsedValues = {
                ...values,
                password: values['password'],
                password_confirmation: values['password_confirmation'],
            };

            await apiClient.request(`/api/v1/reset-password/${token}`, parsedValues, 'PUT');
            setInProgress(false);
            message.success(intl.formatMessage({ id: 'login.password_successfully_changed' }));
            navigate('/');
        } catch (error) {
            message.error(intl.formatMessage({ id: 'login.token_is_not_valid' }));
            setInProgress(false);
        }
    };

    const suffix = (field: 'password' | 'confirm') => {
        if (field === 'password') {
            return showPassword ? (
                <i
                    style={{ width: 20 }}
                    onClick={() => setShowPassword(false)}
                    className="far fa-eye-slash"
                ></i>
            ) : (
                <i
                    style={{ width: 20 }}
                    onClick={() => setShowPassword(true)}
                    className="far fa-eye"
                ></i>
            );
        } else {
            return showConfirmPassword ? (
                <i
                    style={{ width: 20 }}
                    onClick={() => setShowConfirmPassword(false)}
                    className="far fa-eye-slash"
                ></i>
            ) : (
                <i
                    style={{ width: 20 }}
                    onClick={() => setShowConfirmPassword(true)}
                    className="far fa-eye"
                ></i>
            );
        }
    };

    return (
        <DefaultUnauthorizedLayout className={styles.center} showLanguageChange={true}>
            <Card className={styles.card}>
                <div className={styles.logoContainer}>
                    <Logo />
                </div>
                <p className="please-login-text">
                    <FormattedMessage id="login.forgot_your_password" />
                </p>
                <p className="reset-password-text">
                    <FormattedMessage id="login.well_help_you_reset" />
                </p>
                <Form form={form} onFinish={submitForm}>
                    <Input
                        className={styles.inputField}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        prefix={<i className=" fal fa-lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={intl.formatMessage({ id: 'login.password' })}
                        customLayout={true}
                        onChange={(event: any) => setInputTextLength(event.target.value.length)}
                        customRules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'validation.field_required',
                                }),
                            },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                                message: intl.formatMessage({
                                    id: 'validation.password_pattern',
                                }),
                            },
                        ]}
                        suffix={inputTextLength > 0 && suffix('password')}
                    />
                    <Input
                        className={styles.inputField}
                        name="password_confirmation"
                        type={showConfirmPassword ? 'text' : 'password'}
                        prefix={<i className=" fal fa-lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={intl.formatMessage({ id: 'login.confirm_password' })}
                        customLayout={true}
                        onChange={(event: any) =>
                            setInputConfirmTextLength(event.target.value.length)
                        }
                        customRules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'validation.field_required',
                                }),
                            },
                            ({ getFieldValue }: any) => ({
                                validator(rule: any, value: any) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        intl.formatMessage({ id: 'validation.repeat_password' })
                                    );
                                },
                            }),
                        ]}
                        suffix={inputConfirmTextLength > 0 && suffix('confirm')}
                    />
                    <Form.Item>
                        <Button
                            type="primary"
                            className={styles.wideButton}
                            loading={inProgress}
                            onClick={form.submit}
                        >
                            <FormattedMessage id="login.reset_password" />
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </DefaultUnauthorizedLayout>
    );
};

export default PasswordResetConfirm;
