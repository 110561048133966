import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { config } from 'config/config';

interface LocaleContext {
    locale: string;
    setLocale: (value: string) => void;
}

const initialLocaleContextValue: LocaleContext = {
    locale: config.defaultEnvironmentLocale,
    setLocale: () => {},
};

const LocaleContext = createContext<LocaleContext>(initialLocaleContextValue);

export const useLocaleContext = () => useContext(LocaleContext);

interface Props {
    children: ReactNode;
}

const LocaleContextProvider = ({ children }: Props) => {
    const [locale, setLocale] = useState(
        localStorage.getItem('locale') ?? config.defaultEnvironmentLocale
    );

    useEffect(() => {
        if (locale !== localStorage.getItem('locale')) {
            localStorage.setItem('locale', locale);
        }
    }, [locale]);

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>{children}</LocaleContext.Provider>
    );
};

export default LocaleContextProvider;
