import {AudienceTabProps, CourseScenarioType, PhishingType} from 'components/Audiences/types';
import React, {useEffect, useState} from 'react';
import {Form, List, Popover, Modal} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {FormattedMessage, useIntl} from 'react-intl';
import {RequestSelect} from 'components/Form';
import FlexRow from "components/FlexRow";
import {Switch} from 'components/Form';
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import {Table, Button, Search, LocaleText} from "ui";
import {NavLink} from 'react-router-dom';
import Spinner from 'components/Spinner';

const LearningTab: React.FC<AudienceTabProps> = ({audience, reloadAudience}) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const [handleError] = useHandleError();
    const [reload, setReload] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [settingEnroll, setSettingEnroll] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [selectedProgram, setSelectedProgram] = useState<number>();
    const [searchString, setSearchString] = useState<string>('');


    // phishing
    const [phishingEnroll, setPhishingEnroll] = useState(false);
    const [selectPhishing, setSelectPhishing] = useState<number>();
    const [reload2, setReload2] = useState<boolean>(false);
    const [isLoading2, setIsLoading2] = useState<boolean>(false)
    const [searchString2, setSearchString2] = useState<string>('');



    const {confirm} = Modal;

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            learningEnroll: audience.learningEnroll,
            learningUnenroll: audience.learningUnenroll,
            phishingEnroll: audience.phishingEnroll,
            phishingUnenroll: audience.phishingUnenroll,
        });

        setSettingEnroll(audience.learningEnroll)
        setPhishingEnroll(audience.phishingEnroll)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audience])

    useEffect(() => {
        reloadAudience();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    const handleEnrollChange = (value: boolean) => {
        updateAudience({
            learningEnroll: value
        });
        setSettingEnroll(value)
    }

    const handleUnenrollChange = (value: boolean) => {
        updateAudience({
            learningUnenroll: value
        });
    }

    const handlePhishingEnrollChange = (value: boolean) => {
        updateAudience({
            phishingEnroll: value
        });
        setPhishingEnroll(value)
    }

    const handlePhishingUnenrollChange = (value: boolean) => {
        updateAudience({
            phishingUnenroll: value
        });
    }

    const updateAudience = async (audienceData: any) => {
        try {
            const response = await apiClient.request(`/api/v1/audiences/${audience.id}`, audienceData, 'PUT');
            if ("error" in response) {
                throw response
            }
        } catch (error) {
            handleError(error);
        }
        setReload(!reload);
    }

    const handleRowActionVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };


    const warnDeletion = async (userTypeId: number) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'programs.delete_program_confirm'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                deleteProgram(userTypeId);
            }
        });
    }

    const warnDeletion2 = async (userTypeId: number) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'programs.delete_phishing_confirm'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                deletePhishing(userTypeId);
            }
        });
    }

    const deleteProgram = async (id: number) => {
        try {
            const response = await apiClient.request(`/api/v1/audiences/${audience.id}/learning-programs/delete`, {
                learningProgramIds: [id]
            }, 'POST');
            if ("error" in response) {
                throw response
            }
            setReload(!reload)
        } catch (error) {
            handleError(error);
        }
    }

    const deletePhishing = async (id: number) => {
        try {
            const response = await apiClient.request(`/api/v1/audiences/${audience.id}/phishing/delete`, {
                phishingIds: [id]
            }, 'POST');
            if ("error" in response) {
                throw response
            }
            setReload2(!reload)
        } catch (error) {
            handleError(error);
        }
    }

    const handleEnroll = async (id: number) => {
        try {
            const response = await apiClient.request(`/api/v1/audiences/${audience.id}/learning-programs/${id}/enroll`, [], 'POST');
            if ("error" in response) {
                throw response
            }
            setReload(!reload)
        } catch (error) {
            handleError(error);
        }
    }

    const columns = [
        {
            key: 'title',
            title: intl.formatMessage({id: 'general.title'}),
            render: (record: any) => {
                return (
                    <NavLink to={`/learning/programs/${record.id}/students`}>
                        <LocaleText text={record.title} />
                    </NavLink>
                )
            }
        },
        {
            key: 'id',
            title: intl.formatMessage({id: 'audience.id'}),
            render: (record: any) => `ID${record.id.toString().padStart(5, "0")}`
        },
        {
            key: 'type',
            title: intl.formatMessage({id: 'general.type'}),
            render: (record: any) => {
                switch (record.type) {
                    case (CourseScenarioType.Certification):
                        return intl.formatMessage({id: 'campaign.certification_course'});
                        case (CourseScenarioType.TimedEvent):
                            return intl.formatMessage({id: 'campaign.timed_event'});
                }
            }
        },
        {
            key: 'description',
            title: intl.formatMessage({id: 'general.description'}),
            render: (record: any) => record.description
        },
        {
            title: intl.formatMessage({id: 'campaign.users_enrolled'}),
            key: 'usersEnrolled',
            render: (record: any) => {
                return (
                    <Spinner spinning={record.loading} opaqueH>
                        {record.usersEnrolled}
                    </Spinner>
                )
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <>
                        <div
                            className="popover-item"
                            onClick={() => {
                                warnDeletion(record.id);
                                setVisibleTooltip(null);
                            }}
                        >
                            <FormattedMessage id="general.delete" />
                        </div>
                        <div
                            className="popover-item"
                            onClick={() => {
                                handleEnroll(record.id);
                            }}
                        >
                            <FormattedMessage id="general.enroll" />
                        </div>
                    </>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleRowActionVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const phishingColumns = [
        {
            key: 'title',
            title: intl.formatMessage({id: 'general.title'}),
            render: (record: any) => {
                return (
                    <NavLink to={`#`}>
                        {record.title}
                    </NavLink>
                )
            }
        },
        {
            key: 'id',
            title: intl.formatMessage({id: 'audience.id'}),
            render: (record: any) => `ID${record.id.toString().padStart(5, "0")}`
        },
        {
            key: 'type',
            title: intl.formatMessage({id: 'general.type'}),
            render: (record: any) => {
                switch (record.type) {
                    case (PhishingType.Dynamic):
                        return intl.formatMessage({id: 'phishing.dynamic_scenario'});
                    case (PhishingType.Strictly):
                        return intl.formatMessage({id: 'phishing.strictly_scenario'});
                }
            }
        },
        {
            key: 'description',
            title: intl.formatMessage({id: 'general.description'}),
            render: (record: any) => record.description
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <div className="popover-item" onClick={() => {
                        warnDeletion2(record.id);
                        setVisibleTooltip(null);
                    }}>
                        <FormattedMessage id="general.delete"/>
                    </div>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleRowActionVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const getTableColumns = () => {
        if (!audience.isEditable()) {
            return columns.filter((column: any) => column.key !== 'actions')
        }

        return columns;
    }

    const getPhishingTableColumns = () => {
        if (!audience.isEditable()) {
            return phishingColumns.filter((column: any) => column.key !== 'actions')
        }

        return phishingColumns;
    }

    const addAudiencePhishing = async () => {
        setIsLoading2(true)
        try {
            const response = await apiClient.request(`api/v1/audiences/${audience.id}/phishing`, {
                "phishingIds": [
                    selectPhishing
                ],
            }, 'POST');
            if ("error" in response) {
                throw response
            }
            ;

            setReload2(true);
            setSelectPhishing(undefined);
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading2(false);

        }
    }

    const addAudienceProgramm = async () => {
        setIsLoading(true)
        try {
            const response = await apiClient.request(`api/v1/audiences/${audience.id}/learning-programs`, {
                "learningProgramIds": [
                    selectedProgram
                ],
            }, 'POST');
            if ("error" in response) {
                throw response
            }
            ;

            setReload(true);
            setSelectedProgram(undefined);
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);

        }
    }
    return <Form form={form}>
        <>
            <List
                className="audience-form-items-list"
                header={<FormattedMessage id='audience.learning'/>}
                bordered
                dataSource={[
                    <>
                        <Switch name="learningEnroll" isFormItem
                                label={intl.formatMessage({id: 'audience.learning_enroll'})}
                                valuePropName="checked"
                                onChange={handleEnrollChange}
                                disabled={!audience.isEditable()}
                        />
                        <Switch name="learningUnenroll" isFormItem
                                label={intl.formatMessage({id: 'audience.learning_unenroll'})}
                                valuePropName="checked"
                                onChange={handleUnenrollChange}
                                disabled={!audience.isEditable()}
                        />
                    </>,
                ]}
                renderItem={(item) => <>{item}</>}
            />

            {!!settingEnroll && <>
                <FlexRow
                    left={audience.isEditable() ? <>
                        <FormattedMessage id='programs.add_learning_program'/>
                        <RequestSelect isForm={false}
                                       customLayout={true}
                                       url={'/api/v1/courses/learning-program/options/search'}
                                       column
                                       showSearch
                                       useLocale={true}
                                       onChange={(programId: number) => setSelectedProgram(programId)}
                                       style={{minWidth: 200}}
                                       value={selectedProgram}
                        />
                        <Button type='primary' disabled={!selectedProgram}
                                onClick={() => addAudienceProgramm()}
                        >
                            <FormattedMessage id='general.add'/>
                        </Button>
                    </> : undefined}
                    right={
                        <Search setSearchString={setSearchString} setReload={setReload}/>
                    }
                />
                <Table
                    columns={getTableColumns()}
                    url={`/api/v1/audiences/${audience.id}/learning-programs`}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    search={searchString}
                    isLoading={isLoading}
                />
            </>
            }

            <List
                className="audience-form-items-list"
                header={<FormattedMessage id='audience.phishing'/>}
                bordered
                dataSource={[
                    <>
                        <Switch name="phishingEnroll" isFormItem
                                label={intl.formatMessage({id: 'audience.phishing_enroll'})}
                                valuePropName="checked"
                                onChange={handlePhishingEnrollChange}
                                disabled={!audience.isEditable()}
                        />
                        <Switch name="phishingUnenroll" isFormItem
                                label={intl.formatMessage({id: 'audience.phishing_unenroll'})}
                                valuePropName="checked"
                                onChange={handlePhishingUnenrollChange}
                                disabled={!audience.isEditable()}
                        />
                    </>,
                ]}
                renderItem={(item) => <>{item}</>}
            />

            {phishingEnroll ?
                <>
                    <FlexRow
                        left={audience.isEditable() ? <>
                            <FormattedMessage id='programs.add_phishing'/>
                            <RequestSelect isForm={false}
                                           customLayout={true}
                                           url={'/api/v1/courses/phishing/campaign/select'} // TODO change url
                                           column
                                           showSearch
                                           onChange={(programId: number) => setSelectPhishing(programId)}
                                           style={{minWidth: 200}}
                                           value={selectPhishing}
                            />
                            <Button type='primary' disabled={!selectPhishing}
                                    onClick={() => addAudiencePhishing()}
                            >
                                <FormattedMessage id='general.add'/>
                            </Button>
                        </> : undefined}
                        right={
                            <Search setSearchString={setSearchString2} setReload={setReload2}/>
                        }
                    />
                    <Table
                        columns={getPhishingTableColumns()}
                        url={`/api/v1/audiences/${audience.id}/phishing`}
                        setReload={setReload2}
                        reload={reload2}
                        rowSelection={false}
                        rowKey={'id'}
                        search={searchString2}
                        isLoading={isLoading2}
                    />
                </>
                : null
            }
        </>
    </Form>;

};

export default LearningTab;

