import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Statistic } from 'antd';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import useHandleError from 'utils/useHandleError';
import { config } from 'config/config';
import { useLocaleContext } from 'context/LocaleContext';
import jwt from 'utils/jwt';
import './styles.scss';

const mapStateToProps = (state: any) => ({ session: state.session });

interface EmbeddedDocMaterialPlayerProps {
    activityAttemptId?: number;
    isPreview?: boolean;
    language?: string;
    version?: string;
    session?: any;
}

const EmbeddedDocMaterialPlayer: React.FC<EmbeddedDocMaterialPlayerProps> = ({
    activityAttemptId,
    isPreview = false,
    language,
    version,
    session,
}) => {
    const { locale } = useLocaleContext();
    const [source, setSource] = useState<string | null>(null);
    const [beginActivity, setBeginActivity] = useState(false);
    const [uuid, setUuid] = useState();
    const [isMaterialLoading, setIsMaterialLoading] = useState(false);
    const [countdown, setCountdown] = useState<number>(0);
    const [countdownFinished, setCountdownFinished] = useState(false);
    const [handleError] = useHandleError();

    const intl = useIntl();

    const { Countdown } = Statistic;
    const onFinish = () => {
        setCountdownFinished(true);
        setCountdown(0);
    };

    useEffect(() => {
        if (session?.name) {
            startActivity();
        }
    }, [session]);

    const startActivity = async () => {
        setIsMaterialLoading(true);

        try {
            const response = await apiClient.request(
                '/api/v1/courses/embedded/serve-embeded-request',
                {
                    id: activityAttemptId,
                    language: locale,
                    version: version,
                    type: isPreview ? 'view': 'learn',
                },
                'POST'
            );

            if (response?.uuid) {
                setUuid(response.uuid);
                setSource(`${config.api.serveEmbeddedUrl}/${response.uuid}?token=${jwt.get()}`);
            }
            setBeginActivity(true);
            loadSettings();
        } catch (error) {
            handleError(error);
        } finally {
            setIsMaterialLoading(false);
        }
    };

    const loadSettings = async () => {
        let embeddedFileSettings = await apiClient.request(
            `/api/v1/courses/embedded/${activityAttemptId}/settings`,
            {},
            'GET'
        );
        if (embeddedFileSettings && embeddedFileSettings.settings) {
            setCountdown(Date.now() + 1000 * embeddedFileSettings.settings);
        } else {
            setCountdownFinished(true);
        }
    };

    const finishActivity = async () => {
        if (!countdown && countdownFinished) {
            try {
                if (!isPreview) {
                    await apiClient.request(
                        `/api/v1/courses/embedded/${uuid}/finish`,
                        {},
                        'POST'
                    );
                    window.close();

                    window.onbeforeunload = function () {
                        return intl.formatMessage({ id: 'general.close_tab_warning' });
                    };
                }
            } catch (error) {
                handleError(error);
            }
        }
    };

    return (
        <>
            {beginActivity ? (
                <Spinner spinning={isMaterialLoading}>
                    {source && (
                        <iframe
                            className="embedded-player"
                            title="Embedded"
                            src={source}
                            allowFullScreen
                        />
                    )}
                    {!isPreview && (
                        <div className={'flex justify-end'}>
                            <Button
                                type={'primary'}
                                onClick={() => finishActivity()}
                                disabled={countdown && !countdownFinished ? true : false}
                            >
                                {countdown ? (
                                    <span className={'embedded-file-countdown'}>
                                        <Countdown value={countdown} onFinish={onFinish} />
                                    </span>
                                ) : (
                                    <FormattedMessage id={'course.finish_activity'} />
                                )}
                            </Button>
                        </div>
                    )}
                </Spinner>
            ) : (
                <Spinner spinning={isMaterialLoading} />
            )}
        </>
    );
};

export default connect(mapStateToProps)(EmbeddedDocMaterialPlayer);
