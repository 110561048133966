import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, message, Progress, Space } from 'antd';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import { useBeforeunload } from 'react-beforeunload';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { config } from 'config/config';
import jwt from 'utils/jwt';
import './styles.scss';

const mapStateToProps = (state: any) => ({ session: state.session });

interface PdfMaterialPlayerProps {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    isFirstAttempt?: boolean;
    session?: any;
    parentActivityId?: number;
    campaignId?: string;
    language?: string;
    version?: string;
    isPreview?: boolean;
}

type ServerRequestType = {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    userTypeUuid: string;
    language?: string;
    version?: string;
    isPreview?: boolean;
};

type InitialLoadType = {
    uuid: string;
    pages: number;
};

const PdfMaterialPlayer: React.FC<PdfMaterialPlayerProps> = ({
    learningActivityId,
    activityAttemptId,
    type,
    isFirstAttempt,
    session,
    parentActivityId,
    campaignId,
    language,
    version,
    isPreview,
}) => {
    const [pdfMaterialData, setPdfMaterialData] = useState<any>();
    const [currentPage, setCurrentPage] = useState(0);
    const [isMaterialLoading, setIsMaterialLoading] = useState(false);
    const [isNextButtonLoading, setIsNextButtonLoading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [timerStart, seTimerStart] = useState<any>();
    const [finishLoading, setFinishLoading] = useState(false);
    const [source, setSource] = useState<string>();
    const [orientation, setOrientation] = useState('P');
    const [timer, setTimer] = useState<any>();
    const [timeoutId, setTimeoutId] = useState<any>(0);
    const [uuid, setUuid] = useState('');
    const navigate = useNavigate();
    const intl = useIntl();

    useEffect(() => {
        loadPdfMaterialPlayer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    document.addEventListener('visibilitychange', (event) => {
        if (document.visibilityState === 'visible') {
            if (
                pdfMaterialData &&
                (pdfMaterialData?.secondsOnSlide ? pdfMaterialData.secondsOnSlide : 5) &&
                timer
            ) {
                setIsNextButtonLoading(true);
                const pause = pdfMaterialData?.secondsOnSlide
                    ? pdfMaterialData.secondsOnSlide
                    : 5 - timer;
                if (pause > 0) {
                    setTimeout(function () {
                        setIsNextButtonLoading(false);
                    }, pause * 1000);
                }
            }
        } else {
            if (timerStart) {
                clearTimeout(timeoutId);
                setTimer(~~(moment().diff(timerStart) / 1000));
            }
        }
    });

    const loadPdfMaterialPlayer = async () => {
        setIsMaterialLoading(true);

        try {
            if (!learningActivityId || !type) {
                navigate(-1);
            }

            const parsedValues: ServerRequestType = {
                learningActivityId,
                activityAttemptId,
                type,
                userTypeUuid: session.active_user_type_uuid,
            };

            if (language) {
                parsedValues.language = language;
            }

            if (version) {
                parsedValues.version = version;
            }

            if (isPreview) {
                parsedValues.isPreview = isPreview;
            }

            const response = await coursesApiClient.request(
                '/api/v1/courses/pdf/prepare',
                parsedValues,
                'POST'
            );

            loadImage('current', response.page === 0 ? 1 : response.page, {
                uuid: response.uuid,
                pages: response.data.pages,
            });
            setPdfMaterialData(response.data);
            setUuid(response.uuid);
            startActivity(response.uuid);

            if (isFirstAttempt) {
                consumeLicenceAccess();
            }
        } catch (error: any) {
            if (error.message === 'already_active_scorm') {
                message.error(intl.formatMessage({ id: 'error.already_active_activity' }));
            } else {
                console.error(error);
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
        } finally {
            setIsMaterialLoading(false);
        }
    };

    const consumeLicenceAccess = async () => {
        try {
            const values = {
                activityId: learningActivityId,
                userTypeId: session.active_user_type_id,
                customerId: session.organization.organization_id,
                parentActivityId: parentActivityId,
                campaignId: campaignId,
            };

            return await apiClient.request('/api/v1/licences/consume', values, 'POST');
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            console.error(error);
        }
    };

    const loadImage = async (direction: string, page: number, initialLoad?: InitialLoadType) => {
        setCurrentPage(page);
        setSource(`${config.api.serveUrl}/pdf/${initialLoad?.uuid ? initialLoad?.uuid : uuid}?token=${jwt.get()}&direction=${direction}&page=${page}`
        );
        setPercent(
            (page / (initialLoad?.pages ? initialLoad?.pages : pdfMaterialData.pages)) * 100
        );
        setIsNextButtonLoading(true);
        buttonLoadingTimer();
    };

    const startActivity = (uuid: string) => {
        if (isPreview) {
            return;
        }
        coursesApiClient.request(`/api/v1/courses/pdf/${uuid}/initialize`, {}, 'POST');
    };

    const endActivity = () => {
        if (isPreview) {
            return;
        }
        coursesApiClient.request(`/api/v1/courses/pdf/${uuid}/finish`, {}, 'POST');
    };

    useBeforeunload((event) => {
        if (pdfMaterialData) {
            if (!finishLoading) {
                endActivity();
            }

            if (currentPage !== pdfMaterialData.pages) {
                event.preventDefault();
            }
        }
    });

    const finish = async () => {
        setFinishLoading(true);
        if (isPreview) {
            return;
        }
        try {
            await coursesApiClient.request(`/api/v1/courses/pdf/${uuid}/finish`, {}, 'POST');
            window.close();
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            console.error(error);
            setFinishLoading(false);
        }
    };

    const buttonLoadingTimer = () => {
        const timeout = setTimeout(
            function () {
                setIsNextButtonLoading(false);
            },
            pdfMaterialData?.secondsOnSlide ? pdfMaterialData.secondsOnSlide * 1000 : 5 * 1000
        );
        setTimeoutId(timeout);
    };

    const toTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <Spinner spinning={isMaterialLoading}>
                <div className="image-wrapper">
                    {source && (
                        <iframe
                            className={orientation === 'P' ? 'pdf-player' : 'pdf-player-land'}
                            height={'100%'}
                            width={'100%'}
                            title="MANUAL_PLAYER"
                            src={source}
                        />
                    )}
                </div>
            </Spinner>
            <div className="pdf-progress-bar">
                {pdfMaterialData && <Progress showInfo={false} percent={percent} />}
            </div>
            <div className="pdf-material-toolbar">
                {pdfMaterialData && (
                    <div>
                        {currentPage} / {pdfMaterialData.pages}
                    </div>
                )}
                <Space>
                    {currentPage !== 1 && (
                        <Button
                            loading={isNextButtonLoading}
                            type="primary"
                            onClick={() => {
                                toTop();
                                loadImage('back', currentPage - 1);
                            }}
                        >
                            <FormattedMessage id="campaign.previous" />
                        </Button>
                    )}
                    {pdfMaterialData && currentPage !== pdfMaterialData.pages && (
                        <Button
                            loading={isNextButtonLoading}
                            type="primary"
                            onClick={() => {
                                toTop();
                                loadImage('next', currentPage + 1);
                            }}
                        >
                            <FormattedMessage id="general.next" />
                        </Button>
                    )}
                    {pdfMaterialData && currentPage >= pdfMaterialData.passRate && (
                        <Button loading={finishLoading} type="primary" onClick={() => finish()}>
                            <FormattedMessage id="general.finish" />
                        </Button>
                    )}
                </Space>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(PdfMaterialPlayer);
