import React from 'react';
import { useIntl } from 'react-intl';
import { Form, InputNumber as AntDInputNumber } from 'antd';
import { constructValidationRules } from './validations';
import './styles.scss';

const InputNumber = ({error, className, name, label, validation = {}, customRules, validateTrigger, customLayout, initialValue = 0, style, ...props}: any) => {
  const intl = useIntl();

  let labelCol: any = { span: 8 };
  let wrapperCol: any = { span: 6 };

  if (customLayout) {
    if (typeof customLayout === 'object') {
      labelCol = customLayout.labelCol || undefined;
      wrapperCol = customLayout.wrapperCol || undefined;
    } else {
      labelCol = undefined;
      wrapperCol = undefined;
    }
  }

  return (
    <Form.Item
      initialValue={initialValue}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      label={label}
      rules={customRules ? customRules : constructValidationRules(validation, intl)}
      validateTrigger={validateTrigger}
      style={style}
    >
      <AntDInputNumber autoComplete="off" {...props} className={"default-input-field " + (className || '')} />
    </Form.Item>
  )
}

export default InputNumber;
