import React, {useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import moment from 'moment/moment';
import {Table} from 'ui';
import {LogDetailsModal} from 'components/Modals';
import {EyeOutlined} from '@ant-design/icons';

interface EventsTabProps {
    userTypeId: number;
}

const EventsTab: React.FC<EventsTabProps> = ({userTypeId}) => {
    const [showLogDetails, setShowLogDetails] = useState(false);
    const [logDetailsContent, setLogDetailsContent] = useState<any>({});

    const intl = useIntl();
    const [reload, setReload] = useState(false);

    const parseEventDiff = (oldValues: any, newValues: any) => {
        return {
            old: JSON.parse(oldValues),
            ...JSON.parse(newValues)
        }
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'general.event_type'}),
            dataIndex: 'event_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_subject_type'}),
            dataIndex: 'event_subject_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_object_type'}),
            dataIndex: 'event_object_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator_type'}),
            dataIndex: 'event_initiator_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator'}),
            dataIndex: 'email'
        },
        {
            title: intl.formatMessage({id: 'general.created_at'}),
            render: (_: string, record: any) => moment(record.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            title: intl.formatMessage({id: 'system.audit.attributes'}),
            key: 'attributes',
            render: (_: string, record: any) => {
                return <p
                    className='description-icon'
                    onClick={() => {
                        setShowLogDetails(true);
                        setLogDetailsContent(parseEventDiff(record.event_data_old, record.event_data_new))
                    }}
                    >
                    <FormattedMessage id={'general.show'}/> <EyeOutlined />
                </p>
            }
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                url={`api/v1/customer-users/${userTypeId}/user-events`}
                setReload={setReload}
                rowSelection={false}
                reload={reload}
                rowKey={'id'}
                scroll={{x: 800}}
            />
            <LogDetailsModal
                visible={showLogDetails}
                onCancel={setShowLogDetails}
                content={logDetailsContent}
                excludeKeys={['student_user_type_uuid', 'student_user_type']}
            />
        </>
    );
};

export default EventsTab;
