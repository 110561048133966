import React, {useState} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {NavLink, useNavigate} from 'react-router-dom';
import {Button, Input, Popover, message, Tag, Modal} from 'antd';
import {PlusOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import debounce from "lodash/debounce";
import DefaultLayout from 'components/DefaultLayout';
import FlexRow from 'components/FlexRow';
import moment from 'moment';
import apiClient from 'utils/apiClient';
import './styles.scss';
import {Table} from 'ui'
import {changeUserTableFilter} from "../../../store/actions/saveUserTableFilter";
import useQueryApiClient from 'utils/useApiClient';
import Spinner from 'components/Spinner';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    };
};

const {Search} = Input;
const {confirm} = Modal;

const mapDispatchToProps = (dispatch: any) => ({
    changeUserTableFilter: (userTableFilter: any) => dispatch(changeUserTableFilter(userTableFilter)),
});
const CustomerList: React.FC<any> = ({changeUserTableFilter}) => {

    const [reload, setReload] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [searchString, setSearchString] = useState('');

    const [loading, setLoading] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);


    const intl = useIntl();
    const navigate = useNavigate();

    const deleteCustomer = async (id: string) => {
        try {
            setLoading(true);
            setVisibleTooltip(null);

            let validationResponse = await apiClient.request(`/api/v1/customers/${id}/validate-deletion`, {}, 'POST');

            if (validationResponse.hasOwnProperty('result')) {
                if (validationResponse.result.activeLicences > 0) {
                    Modal.warning({
                        title: intl.formatMessage({id: "general.attention"}),
                        content: intl.formatMessage({id: "organization.deletion.still_has_valid_licences"})
                    });
                } else if (validationResponse.result.actualUsers > 0) {
                    confirm({
                        title: intl.formatMessage({id: "general.attention"}),
                        content: intl.formatMessage({id: "organization.deletion.still_has_undeleted_users"}),
                        icon: <ExclamationCircleOutlined/>,
                        okText: intl.formatMessage({id: 'general.yes'}),
                        cancelText: intl.formatMessage({id: 'general.no'}),

                        onOk() {
                            queueDeletion(id);
                        }
                    });
                } else {
                    await softDelete(id);

                    message.success(intl.formatMessage({id: "organization.deletion.successful_deleted"}));

                    setReload(true);
                }
            }
        } catch (err: any) {
            if (err.hasOwnProperty('message') && err.message === 'customer_already_added_to_deletion_queue') {
                message.error(intl.formatMessage({id: "error.customer_already_added_to_deletion_queue"}));
            } else {
                message.error(intl.formatMessage({id: "error.delete_item"}));
            }

            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const softDelete = async (id: string) => {
        await apiClient.request(`/api/v1/customers/${id}/soft-delete`, {}, 'POST');
    };

    const queueDeletion = async (id: string) => {
        try {
            setLoading(true);

            await apiClient.request(`/api/v1/customers/${id}/queue-deletion`, {}, 'POST');
        } catch (err) {
            message.error(intl.formatMessage({id: "error.delete_item"}));
            console.error(err);
        } finally {
            setLoading(false);
            message.success(intl.formatMessage({id: "organization.deletion.added_to_deletion_queue"}));
        }
    };

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'general.customer'}),
            dataIndex: 'name',
            render: (_text: string, record: any) => {
                return (<a onClick={() => {
                    navigate(`/customers/${record.id}/users`);
                    changeUserTableFilter({})
                }}>{record.name}</a>)
            }
        },
        {
            title: intl.formatMessage({id: 'general.start_date'}),
            sorter: true,
            key: 'begin_date',
            render: (value: string, record: any) => record.agreementStatus ? moment(record.beginDate).format('DD.MM.YYYY') : '-'
        },
        {
            title: intl.formatMessage({id: 'general.end_date'}),
            sorter: true,
            key: 'end_date',
            render: (value: string, record: any) => {
                let color;
                if (record && record.endDate) {
                    if (moment(record.endDate) < moment()) color = 'date-color';
                    return (
                        <div className={color}>
                            {moment(record.endDate).format('DD.MM.YYYY')}
                        </div>)
                } else {
                    return '-'
                }
            }
        },
        {
            title: intl.formatMessage({id: 'organization.agreement_changes'}),
            key: 'agreement_changes',
            render: () => '-'
        },
        {
            title: intl.formatMessage({id: 'organization.agreement_status'}),
            key: 'status',
            sorter: true,
            render: (_text: string, record: any, key: number) => (
                record.agreementStatus
                    ? <Tag color={record.agreementStatus === 'ACTIVE' ? 'green' : 'red'} key={key}>
                        {intl.formatMessage({id: `agreement.status.${record.agreementStatus}`})}
                    </Tag>
                    : '-'
            )
        },
        {
            title: intl.formatMessage({id: 'organization.active_students'}),
            key: 'activeStudents',
            sorter: true,
            render: (_text: string, record: any) => (
                <NavLink to={`/customers/${record.id}/users`}>
                    {record.activeStudentCount}
                </NavLink>
            )
        },
        {
            key: 'actions',
            render: (text: string, record: any) => {
                const customerId = record.id;

                const content = <>
                    {
                        record.agreement ?
                            <NavLink to={`/customers/${customerId}/agreement`}>
                                <div className="popover-item">
                                    <FormattedMessage id="organization.edit_agreement"/>
                                </div>
                            </NavLink>
                            :
                            <NavLink to={`/customers/${customerId}/agreement`}>
                                <div className="popover-item">
                                    <FormattedMessage id="organization.add_agreement"/>
                                </div>
                            </NavLink>
                    }
                    <NavLink to={`/customers/${customerId}/edit`}>
                        <div className="popover-item">
                            <FormattedMessage id="organization.edit_customer"/>
                        </div>
                    </NavLink>
                    <div className="popover-item" onClick={() => {
                        deleteCustomer(customerId)
                    }}>
                        <FormattedMessage id="organization.delete_customer"/>
                    </div>
                    <Spinner spinning={isLoading}>
                        <div className='popover-item delete-item' onClick={() => {
                            inheritTemplates({}, { id: record.id })
                        }}>
                            <FormattedMessage id='organization.inherit_templates' />
                        </div>
                    </Spinner>
                </>;

                return (
                    <Popover
                        open={customerId === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, customerId)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        },
    ];

    const loadOptionsTimeOut = debounce(function (searchValue: string) {
        const searchString = searchValue.replace(/\s+/g, '');
        if (searchValue.length === 0 || searchString.length >= 3) {
            setSearchString(searchValue);
            setReload(true)
        }
    }, 800);

    const { appendData: inheritTemplates, isLoading } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/mail-templates/inherit/customer/:id',
            method: 'POST',
        },
        onSuccess: () => {
            setVisibleTooltip(null);
        }
    });

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'general.customers'})}/>
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                        <NavLink to="/customers/add">
                            <Button type="primary" icon={<PlusOutlined/>}>
                                <FormattedMessage id="organization.add_customer"/>
                            </Button>
                        </NavLink>
                    }
                    right={
                        <Search
                            allowClear
                            placeholder={intl.formatMessage({id: "general.search"})}
                            onChange={value => loadOptionsTimeOut(value.target.value)}
                        />
                    }
                />
                <Table
                    columns={columns}
                    url={'/api/v1/customers'}
                    setReload={setReload}
                    reload={reload}
                    rowKey={'id'}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    search={searchString}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
