import {routes} from 'config/config';
import useJwt from 'utils/useJwt';
import {useNavigate} from 'react-router-dom';
import useHandleError from './useHandleError';
import {AxiosRequestConfig} from 'axios';
import {useEffect, useState} from 'react';
import {axiosInstance} from "./axios";
import type {RcFile} from "antd/es/upload/interface";

interface InvalidRequestResponse {
    status_code: number;
    message: string;
}

interface RequestProps {
    url: string;
    data?: any;
    method?: RequestMethod;
    mustRetry?: boolean;
    multipart?: boolean;
    withCredentials?: boolean;
    enableOnMount?: boolean;
    disableOnMount?: boolean;
    baseUrl?: string;
}

interface UseQueryApiClientProps {
    request: RequestProps;
    onSuccess?: (response: any, passOnSuccess?: any) => void;
    onError?: (response: any) => void;
    onFinally?: () => void;
    enabled?: boolean;
}

interface ErrorProps {
    [key: string]: string[] | string;
}

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

function useQueryApiClient({request, onSuccess, onError, onFinally, enabled = true}: UseQueryApiClientProps) {
    const method = request?.method || 'GET';
    const [receivedData, setReceivedData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(enabled ? method === 'GET' && !request?.disableOnMount : false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const navigate = useNavigate();
    const {getHeader} = useJwt();
    const [handleError] = useHandleError();

    const enableOnMount = request?.enableOnMount; // For methods except 'GET'
    const disableOnMount = request?.disableOnMount; // For method 'GET'

    useEffect(() => {
        //Enable or disable on mount fetch
        if (!disableOnMount && (enableOnMount || method === 'GET')) {
            call(
                request.url,
                request?.data,
                request?.method,
                request?.mustRetry,
                request?.multipart,
                request?.withCredentials,
                {},
                request.baseUrl
            );
        }
    }, [enabled, disableOnMount, enableOnMount]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetch = () => {
        setIsFetching(true);
        call(request.url, request?.data, method, request?.mustRetry, request?.multipart, request?.withCredentials, {}, request.baseUrl);
    };

    const parsedError = (response: InvalidRequestResponse) => {
        return {
            status: response.status_code,
            message: response.message,
        };
    };

    const appendData = (data?: any, urlParams?: any, passOnSuccess?: any) => {
        let url = request.url;
        if (urlParams) {
            Object.entries(urlParams).forEach((entry: any) => {
                const key = entry[0];
                const value = entry[1];

                url = url.replace(':' + key, value);
            });
        }
        let hasHtml: string | null = null;

        if (data) {
            Object.entries(data).forEach((entry: any) => {
                if (entry[0].includes('HTML')) {
                    const key = entry[0].replace('HTML', '');
                    data[key] = parseEditorData(entry[1]);
                    hasHtml = entry[0];
                }
            });
        }

        if (hasHtml) {
            data = Object.keys(data).filter(objKey =>
                objKey !== hasHtml).reduce((newObj: any, key: any) => {
                    newObj[key] = data[key];
                    return newObj;
                }, {}
            );
        }

        call(url, data, request?.method, request?.mustRetry, request?.multipart, request?.withCredentials, passOnSuccess, request.baseUrl);
    };

    const parseEditorData = (data: any) => {
        if (data?.type) {
            return data.level.content;
        } else {
            return data;
        }
    }

    const call: any = async (
        url: string,
        data: any = {},
        method: RequestMethod = 'GET',
        mustRetry: boolean = true,
        multipart: boolean = false,
        withCredentials: boolean = false,
        passOnSuccess: any = {},
        baseUrl: string = ''
    ) => {
        if (!enabled) {
            return;
        }

        setIsLoading(true);

        const requestConfig: AxiosRequestConfig = {
            url: url,
            method: method,
            baseURL: baseUrl || routes.api.baseUrl,
            responseType: multipart ? 'blob' : 'json',
            withCredentials: withCredentials,
            headers: {
                Authorization: getHeader(),
                'Content-Type': multipart ? 'multipart/form-data' : 'application/json'
            },
        };

        //set data in right place
        if (method === 'GET') {
            requestConfig.params = data;
        } else {
            requestConfig.data = data;
        }

        try {
            //call request
            const response = await axiosInstance.request(requestConfig);

            const responseContent = response.data;

            //if status code is error type, throw error
            if (responseContent && responseContent.status_code > 299) {
                throw parsedError(responseContent);
            }

            if (responseContent?.code > 299 && responseContent?.error) {
                throw parsedError({status_code: responseContent.code, message: responseContent.error});
            }

            setReceivedData(responseContent);
            setIsSuccess(true);
            onSuccess && onSuccess(responseContent, passOnSuccess); //Call onSuccess if set

            return responseContent;
        } catch (e: any) {
            const response = e.response;

            // if (response.status >= 500 && window.runConfig.nodeEnv === 'production') {
            //   navigate('/500');
            // }

            setIsError(true);

            const actualError: ErrorProps =
                typeof response === 'object' && response.hasOwnProperty('data') ? response.data : e;

            onError && onError(actualError); //Call onSuccess if set
            handleError(actualError); //hook for global handling of errors

            throw actualError;
        } finally {
            onFinally && onFinally(); //Call onFinally if set
            setIsFetching(false);
            setIsLoading(false);
        }
    };

    return {
        data: receivedData,
        isLoading: isLoading,
        isSuccess: isSuccess,
        fetch: fetch,
        isError: isError,
        isFetching: isFetching,
        appendData: appendData,
    };
}

export default useQueryApiClient;