import React from 'react';
import {useIntl} from 'react-intl';
import {Table} from 'antd';
import {Modal} from 'ui';
import { LogDetailsModalInterface } from './types';

const formatObjectToString = (obj: any) => {
    let content = Object.keys(obj).map(function(key) {
        return <p><b>{key}:</b> {(typeof obj[key] === 'object' && obj[key] !== null) ? <div><pre>{JSON.stringify(obj[key], null, 2) }</pre></div> : String(obj[key])}</p>
    })

    return content
}

const LogDetailsModal: React.FC<LogDetailsModalInterface> = ({ title = 'system.audit.log_details_modal_title', visible, onCancel, content, excludeKeys = [] }) => {
    const intl = useIntl();

    const formatProperties = (json: any) => {
        const changedFields = Object.keys(json).reduce((acc: any, key) => {
            if (['old', ...excludeKeys].includes(key)) {
                return acc;
            }
    
            const newValue = json[key]
            const oldValue = json.old[key]
    
            if (newValue === oldValue) {
                return acc;
            }
    
            acc.push({
                field: key,
                new: (typeof newValue === 'object' && newValue !== null) ? formatObjectToString(newValue) : String(newValue),
                old: (typeof oldValue === 'object' && oldValue !== null) ? formatObjectToString(oldValue) : String(oldValue)
            })
    
            return acc;
        }, [])
    
        return <Table columns={changesTableColumns} dataSource={changedFields} style={{marginTop: '20px', maxWidth: '100%'}} />
    }

    const changesTableColumns = [
        {
            title: intl.formatMessage({id: 'general.field'}),
            dataIndex: 'field',
            render: (_: string, record: any) =>
                <b>{record.field}</b>
        },
        {
            title: intl.formatMessage({id: 'system.audit.new'}),
            dataIndex: 'new',
            render: (_: string, record: any) =>
                record.new
        },
        {
            title: intl.formatMessage({id: 'system.audit.old'}),
            dataIndex: 'old',
            textWrap: 'word-break',
            render: (_: string, record: any) =>
                record.old
        }
    ]

    return (
        <Modal
            open={visible}
            width={'75%'}
            title={intl.formatMessage({id: title})}
            onCancel={() => onCancel(false)}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
        >
            {formatProperties(content)}
        </Modal>
    )
};
export default LogDetailsModal;
